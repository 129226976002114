<template>
  <component
    :is="floatingComponent"
    :shown="isOpen"
    :distance="10"
    :skidding="rightPosition"
    :container="container"
    :placement="placement"
    :delay="{ show: 0, hide: timeout }"
    :triggers="triggers"
    :auto-hide="autoHide"
    @apply-show="isOpen = true; emit('menuUpdated', true)"
    @apply-hide="isOpen = false; emit('menuUpdated', false)"
  >
    <slot />
    <template #popper="{ hide }">
      <div
        class="p-2 w-[258px]"
        :class="containerClass"
      >
        <slot
          name="header"
          :hide="hide"
        />
        <ul
          v-for="(item, index) in visibleMenuItems"
          :key="index"
          class="w-full"
        >
          <li
            class="menu-item flex items-center hover:bg-gray-50 hover:rounded-lg cursor-pointer py-[10px] px-4 lg:py-4 gap-x-4 lg:gap-x-5"
            @click="hide(); handleItemClick(item)"
          >
            <DsIcon
              v-if="item.icon"
              :icon="item.icon"
              class="w-5 min-w-[20px] h-5 lg:h-6"
              color-class="fill-ds-dark-primary text-ds-dark-primary items-center"
              size="medium"
              type="solid"
            />
            <span class="text-sm lg:text-[16px] lg:leading-6 font-semibold text-ds-dark-primary">{{ item.text }}</span>
          </li>
        </ul>
        <slot
          name="footer"
          :hide="hide"
        />
      </div>
    </template>
  </component>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import DsIcon from './DsIcon.vue'
import { MenuItem } from './interfaces/IMenuItem'

const props = withDefaults(
  defineProps<{
    timeout?: number;
    menuItems: MenuItem[];
    rightPosition?: number,
    container?: string
    triggers?: string[]
    placement?: string
    containerClass?: string
    autoHide?: boolean
    floatingComponent?: 'VMenu' | 'VDropdown'
    forceClose?: number
  }>(),
  {
    timeout: 250,
    rightPosition: -70,
    container: 'body',
    triggers: () => ['hover'],
    placement: 'bottom-start',
    containerClass: '',
    autoHide: true,
    floatingComponent: 'VMenu',
    forceClose: 0,
  },
)
const emit = defineEmits<{
  (e: 'menuUpdated', menuOpen: boolean): boolean;
  (e: 'routeClicked', routeName: string | undefined): string | undefined;
}>()

const isOpen = ref(false)

const visibleMenuItems = computed(() =>
  props.menuItems?.filter((item: MenuItem) => item.condition !== undefined ? item.condition : true),
)

const handleItemClick = (item: MenuItem) => {
  if (typeof item.action === 'function') {
    item.action()
    return
  }
  emit('routeClicked', item.route)
}

watch(() => props.forceClose, () => isOpen.value = false)
</script>

<style lang="scss">
.fade-enter-active {
  -moz-transition-duration: 100ms;
  -webkit-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.fade-leave-active {
  -moz-transition-duration: 100ms;
  -webkit-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.fade-enter-to,
.slide-leave {
  opacity: 1;
}

.fade-enter,
.slide-leave-to {
  opacity: 0;
}

.v-popper--theme-dropdown .v-popper__arrow-container {
  @apply hidden;
}

.v-popper--theme-dropdown .v-popper__inner {
  @apply rounded-2xl #{!important};
}
</style>
