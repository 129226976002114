import { useQuery } from '@tanstack/vue-query'
import { billingApiClient } from '@core-lib/webapp-services/client'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { computed, reactive } from 'vue'
import { DateTime } from 'luxon'
import { maxTeamMembersForFreePlan, maxTeamMembersForProPlan } from '@core-lib/const/organization'
import { BillingFeatureCode } from '@core-lib/openapi/client'

const userClosedPricingAnnouncement = reactive({
  limit90: sessionStorage.getItem('limit90') == 'true',
  limit100: sessionStorage.getItem('limit100') == 'true',
  freezing: sessionStorage.getItem('freezing') == 'true',
  closed: sessionStorage.getItem('closed') == 'true',
})

export const useBillingUsageFetcher = () => {
  const { data, refetch } = useQuery({
    queryKey: ['billing', authFacade.selectedOrgId, 'usage'],
    queryFn: async () => {
      const response = await billingApiClient.getBillingUsage()
      return {
        count: response.data.counts.requirementActions,
        limit: response.data.limits.requirementActions,
        freezesAt: response.data.freezesAt,
      }
    },
    initialData: {
      count: 0,
      limit: 100,
      freezesAt: null,
    },
    enabled: authFacade.isAuthenticated,
  })

  const bgColor = computed(() => {
    if (data.value.count < 60) return 'bg-ds-green-primary'
    if (data.value.count < 90) return 'bg-amber-500'
    return 'bg-red-500'
  })

  const percentage = computed(() => {
    if (!data.value.limit) return null
    return (data.value.count / data.value.limit) * 100
  })

  const timeLeft = computed(() => {
    if (!data.value.freezesAt) return null
    const timeLeft = DateTime.fromISO(data.value.freezesAt).diffNow('hours').hours
    const secondsLeft = DateTime.fromISO(data.value.freezesAt).diffNow('seconds').seconds
    if (secondsLeft < 0) return null
    return Math.floor(timeLeft)
  })
  const closeTropees = computed(() => {
    if (!timeLeft.value) return `Your Tropees have been closed. <span class="underline">Upgrade now to resume your Tropees and increase your limit.</span>`
    const time = timeLeft.value === 0 ? `less then 1 hour`: `${timeLeft.value} hour${timeLeft.value > 1 ? 's' : ''}`
    return `Your Tropees will be closed in ${time}. <span class="underline">Upgrade now to keep your Tropees open and increase your limit.`
  })

  const closePricingAnnouncement = (id: 'limit90' | 'limit100' | 'freezing' | 'closed') => {
    userClosedPricingAnnouncement[id] = true
    sessionStorage.setItem(id, 'true')
  }

  const announcementBarData = computed<{
    id: 'limit90' | 'limit100' | 'freezing' | 'closed'
    textColor: string
    bgColor: string
    message: string
  } | null>(() => {
    if (!percentage.value || percentage.value < 60) return null
    if (percentage.value < 90) {
      return {
        id: 'limit90',
        textColor: 'text-orange-500 hover:text-orange-500',
        bgColor: 'bg-orange-100',
        message: `You’ve used ${Math.floor(percentage.value/ 10) * 10}% of your Free plan's 100 actions. <span class="underline">Upgrade now to increase your limit.</span>`,
      }
    }
    if (percentage.value < 100) {
      return {
        id: 'limit100',
        textColor: 'text-red-500 hover:text-red-500',
        bgColor: 'bg-red-200',
        message: `You’ve used ${Math.floor(percentage.value/ 10) * 10}% of your Free plan's 100 actions. <span class="underline">Upgrade now to increase your limit.</span>`,
      }
    }

    return {
      id: timeLeft.value ? 'freezing' : 'closed',
      textColor: 'text-red-500 hover:text-red-500',
      bgColor: 'bg-red-200',
      message: `Important: You've hit your monthly action limit. ${closeTropees.value}`,
    }
  })

  return {
    actionsCount: data,
    refetch,
    bgColor,
    announcementBarData,
    userClosedPricingAnnouncement,
    closePricingAnnouncement,
  }
}

export const useBillingCurrentPlan = () => {
  const { data, refetch } = useQuery({
    queryKey: ['billing', authFacade.selectedOrgId, 'current-plan'],
    queryFn: async () => {
      const response = await billingApiClient.getCurrentPlan()
      return response.data.currentPlan
    },
    enabled: authFacade.isAuthenticated,
  })

  const seatsCount = computed(() => {
    if (!data.value?.features) return 1
    const seatsFeature = data.value.features.find(feature => feature.code === 'seats_count')
    return seatsFeature?.quantity || 1
  })

  const nextBillingDate = computed(() => {
    const nextMonthDate = DateTime.now().plus({ months: 1 }).startOf('month')
    return `${nextMonthDate.toFormat('MMM d')}st, ${nextMonthDate.year}`
  })
  const getOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'],
      v = n % 100
    return s[(v - 20) % 10] || s[v] || s[0]
  }

  const planStartedAt = computed(() => {
    if (!data.value || !data.value.startedAt) return null
    const startedAt = DateTime.fromISO(data.value.startedAt)
    return `${startedAt.toFormat('MMM d')}${getOrdinal(DateTime.fromISO(data.value?.startedAt || '').day)}, ${startedAt.year}`
  })

  const items = computed(() =>
    ({
      free: [
        '100 monthly <span class="underlined-hoverable">actions</span>',
        `Up to ${maxTeamMembersForFreePlan} users`,
        'Support via email',
      ],
      pro: [
        'Unlimited <span class="underlined-hoverable">actions</span>',
        `Up to ${maxTeamMembersForProPlan} users`,
        'Priority support',
        'Zapier integration',
      ],
    }))

  const isProPlan = computed(() => data.value && !data.value.isFree)

  return {
    currentPlan: data,
    refetch,
    nextBillingDate,
    items,
    planStartedAt,
    currentPlanItems: computed(() => data.value?.isFree ? items.value.free : items.value.pro),
    isProPlan,
    seatsCount,
  }
}

export const useBooleanBillingFeatureAccess = (featureCode: BillingFeatureCode) => {
  const { currentPlan } = useBillingCurrentPlan()

  return computed(() => {
    return !!currentPlan.value?.features.some(f => f.code === featureCode)
  })
}
