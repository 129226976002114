export const removeSearchParameter = (paramToRemove: string | string[]) => {
  const searchParams = new URLSearchParams(window.location.search.substring(1))
  const paramsToRemove = Array.isArray(paramToRemove)? paramToRemove : [paramToRemove]
  for (const param of paramsToRemove) {
    searchParams.delete(param)
  }
  const searchParamsString = searchParams.toString()
  const search = searchParamsString ? `?${searchParamsString}` : ''
  window.history.replaceState(
    history.state,
    document.title,
    `${window.location.pathname}${search}`,
  )
}

export const addSearchParameter = (paramKey: string, paramValue: string) => {
  const searchParams = new URLSearchParams(window.location.search.substring(1))
  searchParams.append(paramKey, paramValue)
  const searchParamsString = searchParams.toString()
  const search = `?${searchParamsString}`
  window.history.replaceState(
    history.state,
    document.title,
    `${window.location.pathname}${search}`,
  )
}
