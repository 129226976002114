import * as Sentry from '@sentry/browser'
import { SeverityLevel } from '@sentry/types/types/severity'
import { ScopeContext } from '@sentry/types/types/scope'
import { Nullable } from '@core-lib/models/common'

type BadRequest = { message: string, context: object, code: string }

const isBadRequest = (e: unknown): e is BadRequest => {
  return !!(e as { context: unknown }).context
}

type ErrorContext = { messagePrefix?: string, extra?: Record<string, unknown>, tags?: ScopeContext['tags'], severity: SeverityLevel }

export class TropeeError extends Error {
  constructor(public originalError: Error, public context: ErrorContext) {
    const fixedPrefix = '[CUSTOM] '
    const prefix = context.messagePrefix ? `${fixedPrefix}${context.messagePrefix}: ` : fixedPrefix
    super(`${prefix}${originalError.message}`)
  }

  public static create(originalError: unknown, context: ErrorContext) {
    return new TropeeError(originalError as Error, context)
  }
}

export class ErrorTracker {
  public static authenticate({ id }: { id: string }) {
    Sentry.setUser({ id })
  }
  public static setOrgId(id: Nullable<string>) {
    Sentry.setExtra('organizationId', id)
  }
  public static disconnect() {
    Sentry.setUser(null)
  }

  private static track(originalError: unknown, context: ErrorContext, throwAfterTrack = false) {
    const contextToUse = isBadRequest(originalError) ? Object.assign({}, originalError.context, context) : context
    const e = TropeeError.create(originalError, contextToUse)
    Sentry.captureException(e.originalError, {
      tags: Object.assign({ isCustom: 'true' }, e.context.tags || {}),
      level: e.context.severity,
      extra: e.context.extra || {},
    })
    console.debug(originalError, context)
    if (throwAfterTrack) {
      throw e
    }
  }

  public static trackAndThrow(originalError: unknown, context: ErrorContext) {
    this.track(originalError, context, true)
  }

  public static trackAndContinue(originalError: unknown, context: ErrorContext) {
    this.track(originalError, context, false)
  }
}
