import { useToaster } from '@core-lib/composables/toasters'
import { acceptedImageTypes } from '@core-lib/const/image'
import { ref } from 'vue'

const { notify } = useToaster()
export const useImageValidator = (files: FileList | File[] ) => {

  const invalidError = ref('')

  const determineImageType = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        const arr = (new Uint8Array(e.target?.result as ArrayBuffer)).subarray(0, 12)
        let header = ''
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16).padStart(2, '0')
        }

        switch (true) {
        case /89504e47/.test(header):
          resolve('image/png')
          break
        case /ffd8ff/.test(header):
          resolve('image/jpeg')
          break
        case /47494638/.test(header):
          resolve('image/gif')
          break
        case /52494646.{8}57454250/.test(header):
          resolve('image/webp')
          break
        default:
          resolve(file.type.split('/')[0] + '/*') // Or reject if you prefer
          break
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsArrayBuffer(file.slice(0, 12))
    })
  }

  const isImageValid = async (withNotify = true, formats = acceptedImageTypes) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const fileType = await determineImageType(files[0])
        if (!formats.includes(fileType)) {
          invalidError.value = 'Unsupported image format'
          if (withNotify) {
            notify({
              text: invalidError.value,
              type: 'error',
            })
          }
          return false
        }
      }
    } catch (error) {
      invalidError.value = 'Error reading image. Please try again.'
      if (withNotify) {
        notify({
          text: invalidError.value,
          type: 'error',
        })
      }
      return false
    }
    invalidError.value = ''
    return true
  }
  return {
    isImageValid,
    invalidError,
  }
}
