<template>
  <LoyaltyLoginModal
    :show="showLoginModal"
    :organization="organization"
    @hide="closeModal"
  />
</template>

<script lang="ts" setup>
import { useLoginModalController } from '@core-lib/composables/login-modal'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import LoyaltyLoginModal from '@core-lib/components/auth/LoyaltyLoginModal.vue'
import { OrganizationPublicProfile } from '@core-lib/openapi/client'

defineProps<{
  organization: OrganizationPublicProfile
}>()

const { showLoginModal } = useLoginModalController()

const closeModal = () => {
  authFacade.closeModal()
}
</script>

<style scoped>

</style>
