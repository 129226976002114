import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useProdConnect } from '@/composables/prod-connect'

const appVersion = <string>import.meta.env.VITE_BUILD_NUMBER
const appEnv = <string>import.meta.env.VITE_ENV
const { apiBaseUrl } = useProdConnect()

const env = {
  app: {
    name: 'explore',
    isProd: appEnv === 'prod',
    isLocal: appEnv === 'local',
    version: appVersion,
    env: appEnv,
    isSsr: <boolean>import.meta.env.SSR,
  },
  web3: {
    rpcUrl: {
      ethereumMainnet: <string>import.meta.env.VITE_RPC_URL_ETHEREUM_MAINNET,
      polygonMainnet: 'https://polygon-mainnet.g.alchemy.com/v2/6Uh4BG0DYQJvROdAgovS9e-G7k1eeZ09',
    },
    login: {
      ud: {
        clientId: import.meta.env.VITE_UD_CLIENT_ID,
      },
      walletConnect: {
        projectId: '8f590bf7ff633882c4bed228a40e6fee',
      },
    },
  },
  featureFlag: {
    launchDarkly: {
      clientId: <string>import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    },
  },
  analytics: {
    segmentWriteKey: 'bSPOaAY0UlQMAvUexP9CVVYKxwHs2jC6',
  },
  sentry: {
    isEnabled: !!<string>import.meta.env.VITE_SENTRY_DSN,
    dsn: <string>import.meta.env.VITE_SENTRY_DSN,
    release: appVersion,
    tracesSampleRate: parseInt(<string>import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || '1.0'),
  },
  datadog: {
    clientToken: <string>import.meta.env.VITE_DD_FE_TOKEN,
    site: 'datadoghq.eu',
    sampleRate: parseInt(<string>import.meta.env.VITE_DD_SAMPLE_RATE || '100'),
  },
  auth: {
    crossmint: {
      apiKey: <string>import.meta.env.VITE_CROSSMINT_API_KEY,
    },
    magic: {
      publicApiKey: <string>import.meta.env.VITE_MAGIC_PUBLIC_API_KEY,
    },
  },
  api: {
    baseUrl: appEnv !== 'prod' ? apiBaseUrl.value || <string>import.meta.env.VITE_API_BASE_URL : <string>import.meta.env.VITE_API_BASE_URL,
  },
  telegram: {
    botId: Number(<string>import.meta.env.VITE_TELEGRAM_BOT_ID),
  },
}

ServiceContainer.env = {
  env: appEnv,
  crossmint:  env.auth.crossmint,
  magic: env.auth.magic,
  analytics: env.analytics,
  isSsr: env.app.isSsr,
  pusher: {
    appKey: <string>import.meta.env.VITE_PUSHER_APP_KEY,
  },
  telegramBotId: env.telegram.botId,
  apiBaseUrl: env.api.baseUrl,
  appName: env.app.name,
  isCreatorApp: false,
  ethereumMainnetRpcUrl: env.web3.rpcUrl.ethereumMainnet,
  walletConnectProjectId: env.web3.login.walletConnect.projectId,
}

export default env
