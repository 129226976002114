<template>
  <component
    v-if="isCustomIcon"
    :is="comp"
    :class="`${iconSize} ${colorClass}`"
  />
  <i
    v-else
    :class="`fi fi-${iconType}-${iconName} ${iconSize} ${colorClass}`"
    class="flex items-center"
  />
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, withDefaults } from 'vue'
import { IconType, SimpleIconType } from '@core-design/types/icon'

const props = withDefaults(
  defineProps<{
      icon: IconType;
      size?: string;
      type?: SimpleIconType;
      colorClass?: string | Record<string, boolean>;
    }>(), {
    size: 'medium',
    type: 'regular',
    colorClass: '',
  },
)

const iconNameVal = computed(() => {
  if (typeof props.icon === 'string') {
    return props.icon
  }
  return props.icon.name
})

const iconTypeVal = computed(() => {
  if (typeof props.icon === 'string') {
    return props.type
  }
  return props.icon.type
})

const comp = computed(() => {
  return iconNameVal.value ? defineAsyncComponent(
    () =>
      import(
        `../../assets/icons/custom-icons/${iconNameVal.value.substring(2)}.vue`
      ),
  ) : ''
})

const iconName = computed(() => {
  return iconNameVal.value.toLowerCase().replace(/ /g, '-')
})

const iconType = computed(() => {
  return iconTypeVal.value === 'brands' ? iconTypeVal.value : iconTypeVal.value.toLowerCase()[0] + 's'
})

const isCustomIcon = computed(() => {
  return iconNameVal.value.substring(0, 2) === 'c_'
})

const iconSize = computed(() => {
  switch (props.size) {
  case 'xs':
    return 'w-[16px] h-[16px] text-[16px] leading-none'
  case 'small':
    return 'w-[18px] h-[18px] text-lg leading-none'
  case 'medium':
    return 'w-5 h-5 text-xl leading-none'
  case 'large':
    return 'w-6 h-6 text-2xl leading-none'
  default:
    return props.size + ' leading-none'
  }
})
</script>

<style scoped>
@import "@core-design/assets/icons/uicons-regular-straight.css";
@import "@core-design/assets/icons/uicons-bold-straight.css";
@import "@core-design/assets/icons/uicons-solid-straight.css";
@import "@core-design/assets/icons/uicons-brands.css";

i:before {
  @apply origin-center align-middle;
}
</style>
