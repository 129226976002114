import { onBeforeMount, onBeforeUnmount, watch } from 'vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { useWindow } from '@core-lib/composables/window'
import { useSelectedOrganization } from '@core-lib/composables/selected-organization'
import { excludedReferrers } from '@core-lib/const/analytics'

const { window, document } = useWindow()

function getUtmParams() {
  const qp = new URLSearchParams(window.location.search)
  const utmParams: Record<string, string> = {}
  for (const [key, value] of qp.entries()) {
    if (key.toLowerCase().startsWith('utm_')) {
      utmParams[key.toLowerCase()] = value
    }
  }
  if (Object.keys(utmParams).length > 0) {
    return utmParams
  } else if (!localStorage.getItem('utm_params') && document.referrer && !qp.has('provider')) {
    const referrer = new URL(document.referrer)
    if (!excludedReferrers.includes(referrer.hostname)) {
      return { utm_source: referrer.hostname, utm_medium: 'referral' }
    }
  }
  return null
}

const setupFirstTouchUtm = () => {
  if (localStorage.getItem('utm_params')) return
  const utmParams = getUtmParams()
  if (!utmParams) return
  localStorage.setItem('utm_params', JSON.stringify(utmParams))
}

function setupLastTouchUtm() {
  const utmParams = getUtmParams()
  if (!utmParams) return
  localStorage.setItem('utm_params_last_touch', JSON.stringify(utmParams))
}

export const useAppLoaded = () => {
  useSelectedOrganization() // For legacy redirect
  const onSocialLogin = async () => {
    await ServiceContainer.authService.appLoaded()
  }
  setupFirstTouchUtm()
  setupLastTouchUtm()
  onBeforeMount(async () => {
    await ServiceContainer.authService.appLoaded()
    window.addEventListener('social_login', onSocialLogin)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('social_login', onSocialLogin)
  })
  watch(authFacade.authSession, async (authSession) => {
    if (!authSession) return
    const utmParams = JSON.parse(localStorage.getItem('utm_params') || '{}')
    const utmParamsLastTouch = JSON.parse(localStorage.getItem('utm_params_last_touch') || '{}')
    await ServiceContainer.apiService.post('/user/session/set-utm-params', { utmParams, utmParamsLastTouch })
  }, { immediate: true })
}
