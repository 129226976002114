import { Maybe } from '@core-lib/models/common'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IconType } from '@core-design/types/icon'

class AnnouncementService {
  async fetchAnnouncement(): Promise<Maybe<Announcement>> {
    try {
      const result = await ServiceContainer.apiService.get<Announcement>(`/miscellaneous/announcement`)
      if (!result.message || result.message.length === 0) return undefined
      return result
    } catch (e) {
      console.error(e)
      return undefined
    }
  }

  async fetchAnnouncementModal(): Promise<Maybe<{ config: AnnouncementModalContent }>> {
    try {
      const result = await ServiceContainer.apiService.get<{ config: AnnouncementModalContent }>(`/miscellaneous/announcement-config`)
      if (!result.config || !result.config.isEnabled) return undefined
      return result
    } catch (e) {
      console.error(e)
      return undefined
    }
  }
}

export type Announcement = {
  message: string
}

export type AnnouncementModalContent = {
  title: string,
  posts: AnnouncementPost[],
  secondaryAction?: {
    url: string,
    label: string,
    icon?: IconType
  },
  primaryAction?: {
    url: string,
    label: string,
    icon?: IconType
  },
  lastResetRequest: number
  paths: string[]
  isEnabled: boolean
  onlyVisibleForConnectedUsers: boolean
}

export type AnnouncementPost = {
  mediaUrl: string,
  title: string,
  description: string
}

export default new AnnouncementService()