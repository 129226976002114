import { UserPrivateProfile, UserPublicProfile } from '@core-lib/openapi/client/api'
import { Maybe, Nullable } from '@core-lib/models/common'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { isEmbed, redirectToNewTab } from '@core-lib/helpers/url'
import { useStorage } from '@vueuse/core'

const nonceStorage = useStorage<Nullable<string>>('auth.nonce', null)

class SocialService {
  public async getRedirectUrl(socialName: string, redirectUri: string, portalId?: string) {
    const response = await ServiceContainer.apiService.post<{ url: string, nonce: string }>(`/social/${socialName}/connect`, {
      redirectUri,
      isPopup: isEmbed,
      portalId,
    })
    nonceStorage.value = response.nonce

    return response.url
  }
  public async disconnect(socialName: string, user: Maybe<UserPublicProfile | UserPrivateProfile>) {
    if (!user) {
      throw new Error('User is not logged in')
    }
    await ServiceContainer.apiService.post<{ url: string }>(`/social/${socialName}/disconnect`, {
      user,
    })
  }

  public openNewTab(url: string) {
    redirectToNewTab(url)
  }
}

export default new SocialService()