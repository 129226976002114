import { map } from 'lodash'
import { DropMethod } from '@core-lib/openapi/client'

export const utilityTwitterMessage = 'Check out this reward on Tropee 🌴'
export const utilityRaffleTwitterMessage = 'Check out this giveaway on Tropee 🌴'
export const userTwitterMessage = 'Check out this organization on Tropee 🌴'
export const utilityV4ParticipatedRaffleTwitterMessage = (utilityName: string) => `Just entered this giveaway on Tropee 🎉\n\n${utilityName}`
export const utilityV4WonRaffleTwitterMessage = (utilityName: string) => `Just won this giveaway on Tropee 🎉\n\n${utilityName}`

export const collectionPageTwitterMessage = (collectionName : string) => {
  collectionName = extractTwitterHandleFromUrl(collectionName)
  if (collectionName) {
    return `Check out the rewards available for ${collectionName} holders on Tropee 🌴`
  }
  return 'Check out the rewards available on Tropee 🌴'
}

export const utilityv3TwitterMessage = (collectionNames : Array<string> | undefined, dropMethod: DropMethod | undefined) => {
  const noCollectionText = dropMethod === DropMethod.Raffle ? 'Check out this giveaway on Tropee 🌴': 'Check out this reward on Tropee 🌴'

  if (!collectionNames) {
    return noCollectionText
  }
  collectionNames = map(collectionNames, (collectionName) => {
    return extractTwitterHandleFromUrl(collectionName)
  }).filter((collectionName) => collectionName)

  if (!collectionNames.length) {
    return noCollectionText
  }

  collectionNames = [...new Set(collectionNames)]
  return `📢 Calling all ${collectionNames.join(' & ')} holders, this one's for you!

${noCollectionText}`
}
export const utilityv4TwitterMessage = (collectionNames : Array<string> | undefined, dropMethod: DropMethod | undefined, utilityName: string) => {
  const noCollectionText = (dropMethod === DropMethod.Raffle ? 'Check out this giveaway on Tropee 🌴': 'Check out this reward on Tropee 🌴') + `\n\n${utilityName}`

  if (!collectionNames) {
    return noCollectionText
  }
  collectionNames = map(collectionNames, (collectionName) => {
    return extractTwitterHandleFromUrl(collectionName)
  }).filter((collectionName) => collectionName)

  if (!collectionNames.length) {
    return noCollectionText
  }

  collectionNames = [...new Set(collectionNames)]
  return `📢 Calling all ${collectionNames.join(' & ')} holders, this one's for you!

${noCollectionText}`
}

export const utilityv3ClaimedTwitterMessage = (collectionNames : Array<string> | undefined, creatorHandle: string | undefined, utilityName: string ) => {
  return utilityv4ActivatedTwitterMessage(collectionNames, creatorHandle, utilityName)
}

export const utilityv4ActivatedTwitterMessage= (collectionNames : Array<string> | undefined, creatorHandle: string | undefined, utilityName: string) => {
  const noCollectionText = `Just claimed this on Tropee 🎉\n\n${utilityName}`

  if (collectionNames) {
    collectionNames = map(collectionNames, (collectionName) => {
      return extractTwitterHandleFromUrl(collectionName)
    }).filter((collectionName) => collectionName)
  }

  if (!collectionNames?.length && !creatorHandle) {
    return noCollectionText
  }

  collectionNames = [...new Set(collectionNames)]
  const creatorPrefix = collectionNames?.length ? ' @' : '@'
  const userCreatorTwitter = creatorHandle ? creatorPrefix + extractTwitterHandleFromUrl(creatorHandle) : ''
  return `${noCollectionText}
  
Thank you ${collectionNames.join(' & ')}${userCreatorTwitter} 🫡`
}

const extractTwitterHandleFromUrl = (collectionName: string) => {
  return collectionName.replace('https://twitter.com/', '@').replace('@null', '')
}

export const raffleWinnerAnnouncement = (creatorHandles: Array<string> | undefined, daysUntilClaimEnd: number) => {
  const creatorHandlesRow = creatorHandles?.map((creatorHandle) => {
    return `\n🏆 @${creatorHandle}`
  })

  return `Congratulations to the winner(s) of our giveaway! 🎉
${creatorHandlesRow}

Hurry up! Only ${daysUntilClaimEnd} day${daysUntilClaimEnd > 1 ? 's' : ''} remaining to claim your reward on Tropee 👇`
}

export const utilityCreatedTwitterMessage = () => {
  return `Just created a new Tropee for you guys 🎉
To enter, complete the actions here ⬇️`
}

export const loyaltyTwitterMessage = 'Check out this Tropee!'
export const rewardTwitterMessage = 'Check out this reward!'
export const taskTwitterMessage = 'Check out this task!'