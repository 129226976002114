import { ComputedRef, Ref } from 'vue'
import { Maybe, Nullable } from '@core-lib/models/common'
import { LoginType } from '@core-lib/models/wallet'

export enum AuthStateCode {
  INIT = 'init',
  MODAL_SHOWN = 'modal_show',
  GO_TO_LOGIN = 'go_to_login',
  AUTHENTICATED = 'authenticated',
  FAILED = 'failed',
}

export type AuthInitiator<T extends undefined | unknown = undefined> = T extends undefined ? { code: string } : { code: string, metadata: T }

export type AuthFlowState = {
  code: AuthStateCode
  initiator?: AuthInitiator
  error?: unknown
}

export interface AuthService {
  authenticatedWalletAddress: ComputedRef<Maybe<string>>
  isSigning: Ref<boolean>
  isValidatingSocialLogin: Ref<boolean>
  authenticate: (connectionType: LoginType) => Promise<void>
  disconnect: () => void
  renewSession: () => Promise<void>
  goToLogin: (initiator?: AuthInitiator) => void
  getAuthInitiator: <T = unknown>() => Nullable<AuthInitiator<T>>
  appLoaded: () => Promise<void>
  openLoginModal: () => void
}
