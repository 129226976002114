import { init, useOnboard } from '@web3-onboard/vue'
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
// The reason I'm rewriting this one because for some reason when I use the actual library (@web3-onboard/coinbase) the dynamic import fails and we get an error
// like CoinbaseWalletSDK is not a constructor. You are welcome to give it a shot. Just change this import to from '@web3-onboard/coinbase'
import coinbaseWalletModule from './node_modules_rewrite/@web3-onboard/coinbase'
import { computed, ComputedRef, reactive, ref, Ref, watch } from 'vue'
import { ethers } from 'ethers'
import { EIP1193Provider } from '@web3-onboard/common'
import { buildWalletMessage } from '@core-lib/const/wallet-message'
import { WalletNotConnected, WalletProviderType, WalletSignatureFailed } from '@core-lib/models/wallet'
import { SignatureCanceller as BaseSignatureCanceller, WalletInterface } from '@core-lib/webapp-services/wallet'
import { useStorage } from '@vueuse/core'
import { Maybe, Nullable } from '@core-lib/models/common'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { sleep } from '@core-lib/helpers/sleep'

const testWalletKey = 'holder_test_wallet'
const testProvider = 'test_provider'

type WalletData = {
  address: string
  provider: EIP1193Provider | 'test_provider'
  chainId: string
  providerName: string
  token?: string
}

export class SignatureCanceller extends BaseSignatureCanceller {}

export class Wallet implements WalletInterface {
  private readonly onboard
  private readonly testWallet
  public readonly hasTestWallet: ComputedRef<boolean>
  public readonly connectedWallet: Ref<Maybe<WalletData>>
  public readonly isWalletConnected: ComputedRef<boolean>
  public readonly formattedWalletAddress: ComputedRef<Maybe<string>>
  public readonly messageSigningStatus: { inProgress: boolean, hideModal: boolean }

  constructor() {
    const allWallets = injectedModule({
      filter: {
        [ProviderLabel.Detected]: true,
      },
      displayUnavailable: false,
    })
    const walletConnect = walletConnectModule({
      projectId: ServiceContainer.env.walletConnectProjectId,
    })
    const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })

    const wallets = [
      allWallets,
      walletConnect,
      coinbaseWalletSdk,
    ]

    init({
      wallets,
      chains: [
        {
          id: '0x1',
          token: 'ETH',
          label: 'Ethereum',
          rpcUrl: ServiceContainer.env.ethereumMainnetRpcUrl,
        },
      ],
      connect: {
        autoConnectLastWallet: false,
        disableUDResolution: true,
      },
      accountCenter: {
        desktop: {
          enabled: false,
        },
        mobile: {
          enabled: false,
        },
      },
    })
    this.messageSigningStatus = reactive({ inProgress: false, hideModal: false })

    this.onboard = useOnboard()
    // @todo Move test part to TestWallet
    this.testWallet = useStorage(testWalletKey, '')
    this.hasTestWallet = computed(() => !!this.testWallet.value)
    this.connectedWallet = ref()
    watch(this.onboard.connectedWallet, () => {
      this.connectedWallet.value = this.calculateWallet()
    }, { immediate: true })
    this.isWalletConnected = computed(() => this.connectedWallet.value !== null)
    this.formattedWalletAddress = computed(() => this.connectedWallet.value ? this.getFormattedWalletAddress() : undefined)

  }

  private calculateWallet(): Maybe<WalletData> {
    const v = this.onboard.connectedWallet.value

    if (!v) return null
    return {
      address: this.testWallet.value || v.accounts[0].address,
      chainId: v.chains[0].id,
      provider: v.provider,
      providerName: this.onboard.alreadyConnectedWallets.value[0],
    }
  }

  public setTestWallet(walletAddress: string) {
    this.testWallet.value = walletAddress
  }

  public async connect(walletType: WalletProviderType) {
    switch (walletType) {
    case WalletProviderType.METAMASK:
      await this.onboard.connectWallet({ autoSelect: { label: 'Metamask', disableModals: true } })
      break
    case WalletProviderType.COINBASE:
      await this.onboard.connectWallet({ autoSelect: { label: 'Coinbase wallet', disableModals: true } })
      break
    case WalletProviderType.WALLET_CONNECT:
      await this.onboard.connectWallet({ autoSelect: { label: 'WalletConnect', disableModals: true } })
      break
    case WalletProviderType.PHANTOM:
      await this.onboard.connectWallet({ autoSelect: { label: 'Phantom', disableModals: true } })
      break
    }

  }

  public async connectOrFail(walletType: WalletProviderType) {
    await this.disconnect()
    await this.connect(walletType)
    return await this.getWalletAddressOrFail()
  }

  public async disconnect() {
    await this.onboard.disconnectConnectedWallet()
    localStorage.removeItem('onboard.js:last_connected_wallet')
  }

  public async getWalletAddressOrFail() {
    let count = 0
    while (count < 5) {
      count++
      if (this.connectedWallet.value) return this.connectedWallet.value.address
      await sleep(1000)
    }
    throw new Error('No wallet connected')
  }

  public getWalletAddress() {
    return this.connectedWallet.value?.address
  }

  public getFormattedWalletAddress() {
    const address = this.connectedWallet.value?.address
    if (!address || !ethers.utils.isAddress(address)) return address

    return ethers.utils.getAddress(address)
  }

  public getFormattedWalletAddressOrFail() {
    const address = this.getFormattedWalletAddress()
    if (!address) throw new Error('No wallet connected')
    return address
  }

  public get connectingWallet() {
    return this.onboard.connectingWallet
  }

  public isValidWalletAddress(address: string) {
    try {
      ethers.utils.getAddress(address)
      return true
    } catch (e) {
      return false
    }
  }

  async getProvider(): Promise<ethers.providers.Web3Provider> {
    //await this.connect()
    const connectedWallet = this.connectedWallet.value
    if (!connectedWallet) throw new Error('Tried to get provided without connected wallet')
    const providerValue = connectedWallet.provider
    // return (connectedWallet.chainId === solanaChainId ? providerValue : new ethers.providers.Web3Provider(providerValue === testProvider ? {} : providerValue)) as ethers.providers.Web3Provider
    return (new ethers.providers.Web3Provider(providerValue === testProvider ? {} : providerValue)) as ethers.providers.Web3Provider
  }

  async getSigner() {
    const provider = await this.getProvider()
    await provider.send('eth_requestAccounts', [])
    return provider.getSigner()
  }

  async signMessage(message: string, cancelFn: Nullable<SignatureCanceller>, hideModal = false) {
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(WalletSignatureFailed.timedOut()), 4 * 60 * 1000)
    })
    const cancelPromise = new Promise((resolve, reject) => {
      cancelFn?.onCancel(() => {
        reject(WalletSignatureFailed.cancelled())
      })
    })
    try {
      this.messageSigningStatus.inProgress = true
      this.messageSigningStatus.hideModal = hideModal
      const signer = await this.getSigner()
      return await Promise.race([
        signer.signMessage(message),
        timeoutPromise,
        cancelPromise,
      ]) as string
    } catch (e) {
      if ((e as Error).message === 'Already processing eth_requestAccounts. Please wait.') {
        throw WalletSignatureFailed.alreadyConnecting()
      }
      const isRejected = (e as Error).message === 'Failed to get the signature.' || (e as { code: string }).code === 'ACTION_REJECTED'
      throw isRejected ? WalletSignatureFailed.rejected() : e
    } finally {
      this.messageSigningStatus.inProgress = false
    }
  }

  async getWalletSignature(validForSeconds: number, cancelFn?: SignatureCanceller, hideModal = false): Promise<{ token: string, message: string, signature: string }> {
    const address = this.getWalletAddress()
    if (!address) throw new WalletNotConnected()

    const now = Math.round(new Date().getTime() / 1000)
    const messageHeader = buildWalletMessage(validForSeconds < 3600)
    const message = `${messageHeader}\n\n${address}|${now}|${validForSeconds}`
    const signature = await this.signMessage(message, cancelFn || new SignatureCanceller(), hideModal)
    const signedToken = `${message} ${signature}`

    return { token: signedToken, message, signature }
  }
}

const wallet = new Wallet()

export default wallet
