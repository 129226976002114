<template>
  <Teleport
    to="#app"
  >
    <transition name="fade">
      <div
        v-show="show"
        class="fixed inset-0 overflow-y-auto cursor-default z-[90]"
        :class="[wrapperClass]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 transition-opacity z-[91]"
          :class="[layoutBgClass || 'bg-ds-dark-primary bg-opacity-50']"
          @click="modalOutsideClicked"
          aria-hidden="true"
        />
        <transition
          name="slide"
          :css="isMobile"
        >
          <div
            v-if="show || noRerender && firstOpenHappened"
            class="base-modal-container flex items-center justify-center md:min-h-screen text-center md:p-4 absolute bottom-0 md:bottom-none w-full"
            :class="[containerClass, {'transition transition-[visibility]': noRerender, 'invisible': noRerender && !show, 'visible': noRerender && show},]"
          >
            <div
              class="inline-block relative bg-white text-left transform transition-all w-full z-[92]"
              :class="[shadowClass, borderRadiusClass, overflowClass, customSize, {'px-8 pb-8 pt-6 md:p-8': !withoutPadding}]"
            >
              <div
                v-if="backButton"
                class="absolute z-20"
                :class="backButtonPositionClass || 'left-[26px] top-[26px]'"
                @click="$emit('back')"
              >
                <DsIcon
                  v-if="isMobile"
                  icon="angle-small-left"
                  class="cursor-pointer text-ds-dark-primary"
                  size="large"
                />
                <DsIconButton
                  v-else
                  :icon="{name: 'angle-left', type: 'bold'}"
                  style-type="primary"
                  icon-size="small"
                  :mobile-size="true"
                />
              </div>
              <template
                v-if="closeButton"
              >
                <div
                  class="absolute z-20"
                  :class="closeButtonPositionClass || ' right-[26px] top-[26px] md:right-[18px] md:top-[18px]'"
                  @click="$emit('hide')"
                >
                  <DsIcon
                    v-if="isMobile && !mobileCloseIsIconButton"
                    icon="cross-small"
                    class="cursor-pointer text-ds-dark-primary"
                    size="large"
                  />
                  <DsIconButton
                    v-else
                    :icon="{name: 'cross', type: 'bold'}"
                    :style-type="closeIconButtonStyleType || 'primary'"
                    icon-size="small"
                    :mobile-size="true"
                  />
                </div>
              </template>
              <h2
                v-if="$slots.title"
                class="text-xl md:text-2xl font-semibold"
              >
                <slot name="title" />
              </h2>
              <div
                class="mt-auto"
                :class="[contentClass, $slots.title ? 'h-[calc(100%-32px)]': 'h-full']"
              >
                <slot />
              </div>
              <div
                v-if="$slots.modalFooter"
                class="pt-6"
              >
                <slot name="modalFooter" />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import DsIconButton from '@core-design/components/Design/DsIconButton.vue'
import useIsMobile from '@core-lib/composables/isMobile'
import { IconButtonType } from '@core-design/types/icon'

const props = withDefaults(defineProps<{
  show?: boolean
  scrollable?: boolean
  withoutPadding?: boolean
  closeButton?: boolean
  customSize?: string
  backButton?: boolean
  shadowClass?: string
  borderRadiusClass?: string
  wrapperClass?: string
  closeButtonPositionClass?: string
  backButtonPositionClass?: string
  contentClass?: string
  containerClass?: string
  mobileCloseIsIconButton?: boolean
  noRerender?: boolean
  layoutBgClass?: string
  closeOnClickOutside?: boolean
  closeIconButtonStyleType?: IconButtonType
}>(), {
  show: false,
  scrollable: false,
  withoutPadding: false,
  closeButton: false,
  customSize: 'max-w-[459px]',
  backButton: false,
  shadowClass: 'shadow-xl',
  borderRadiusClass: 'rounded-t-[32px] md:rounded-[32px]',
  wrapperClass: '',
  closeButtonPositionClass: '',
  backButtonPositionClass: '',
  contentClass: '',
  containerClass: '',
  mobileCloseIsIconButton: false,
  noRerender: false,
  layoutBgClass: '',
  closeOnClickOutside: true,
  closeIconButtonStyleType: 'primary',
})

const emit = defineEmits(['hide', 'back'])

const isMobile = useIsMobile()

const overflowClass = computed(() => props.scrollable ? 'overflow-scroll' : 'overflow-inherit')

const firstOpenHappened = ref(false)

onMounted(() => {
  if (props.show) {
    document.body.classList.add('tropee-full-height')
    document.body.classList.add('!overflow-hidden')
  }
})

watch(() => props.show, show => {
  if (show) {
    firstOpenHappened.value = true
    document.body.classList.add('tropee-full-height')
    document.body.classList.add('!overflow-hidden')
  } else {
    document.body.classList.remove('tropee-full-height')
    document.body.classList.remove('!overflow-hidden')
  }
})

onBeforeUnmount(() => {
  document.body.classList.remove('tropee-full-height')
  document.body.classList.remove('!overflow-hidden')
})
const modalOutsideClicked = () => {
  if (props.closeOnClickOutside) {
    emit('hide')
  }
}
</script>
<style lang="scss">
.fade-enter-active {
  transition: all 0.15s ease-in;
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.15s ease-out;
  transition-delay: 0.15s;
  opacity: 1;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}
.slide-enter-active {
  transition: all 0.3s ease-in;
  transform: translateY(0);
}

.slide-leave-active {
  transition: all 0.3s ease-out;
  transform: translateY(0);
}

.slide-enter-from {
  transform: translateY(100%);
}

.slide-leave-to {
  transform: translateY(100%);
}
</style>