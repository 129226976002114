import { AuthSession } from '@core-lib/webapp-services/auth-providers/auth-provider'
import { useStorage } from '@vueuse/core'
import { computed, ComputedRef, ref, Ref, watch } from 'vue'
import {
  AuthFlowState,
  AuthInitiator,
  AuthStateCode,
} from '@core-lib/webapp-services/service-container/services/auth-service'
import { Nullable, Optional } from '@core-lib/models/common'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { AdminRouteName, CreatorRouteName, HolderRouteName } from '@core-lib/routes'
import { removeSearchParameter } from '@core-lib/ui-helper/search-param-remover'
import { useAppName } from '@core-lib/composables/app-name'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { ErrorTracker } from '@core-lib/webapp-services/error-tracker'
import { useLoginModalController } from '@core-lib/composables/login-modal'
import { useWindow } from '@core-lib/composables/window'

const authSessionKey = 'auth_session_v2'
const authInitiatorKey = 'auth_initiator'

const { appName } = useAppName()
const { showLoginModal } = useLoginModalController()
const { window } = useWindow()

function paramsToObject(params: URLSearchParams) {
  const result: Record<string, string> = {}
  const entries = params.entries()
  for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}

export class AuthFacade {
  public readonly authSession: Ref<Nullable<AuthSession>>
  public readonly selectedOrgId: Ref<Nullable<string>>
  public readonly conversionId: Ref<Nullable<string>>
  public readonly userIdentifier: ComputedRef<string>
  public readonly userId: ComputedRef<Optional<string>>
  public readonly isAuthenticated: ComputedRef<boolean>
  public readonly hasSession: ComputedRef<boolean>
  public readonly isUserReady: ComputedRef<Optional<boolean>>
  public readonly authFlowState: Ref<AuthFlowState>
  public readonly temporaryWalletAddress: ComputedRef<Optional<string>>
  private authInitiatorCache: Nullable<AuthInitiator> = null

  constructor() {
    this.authSession = AuthFacade.getAuthSession()
    const expiresAt = this.authSession.value?.expiresAt
    if (expiresAt && expiresAt < (Date.now() / 1000 +  3600)) {
      this.authSession.value = null
    }
    this.selectedOrgId = useStorage<Nullable<string>>('selectedOrgId', null)
    if (!this.selectedOrgId.value && this.authSession.value) {
      this.selectedOrgId.value = this.authSession.value.mainOrgId
    }
    this.conversionId = useStorage<Nullable<string>>('conversionId', null)
    if (!this.conversionId.value && this.authSession.value?.defaultOrganization) {
      this.conversionId.value = this.authSession.value.defaultOrganization.conversionId
    }

    this.userIdentifier = computed(() => {
      return this.authSession.value?.userId || 'anon'
    })
    this.isAuthenticated = computed(() => {
      return !!this.authSession.value?.isUserReady
    })
    this.isUserReady = computed(() => {
      return this.authSession.value?.isUserReady
    })
    this.hasSession = computed(() => {
      return !!this.authSession.value
    })
    this.temporaryWalletAddress = computed(() => {
      return this.authSession.value?.address
    })
    this.userId = computed(() => {
      return this.authSession.value?.userId
    })
    this.authFlowState = ref({ code: AuthStateCode.INIT })
    watch(this.authSession, async (authSession, oldAuthSession) => {
      if (authSession && !this.selectedOrgId.value) {
        this.selectedOrgId.value = authSession.defaultOrganization?.id || authSession.mainOrgId
      }
      if (authSession && !this.conversionId.value && authSession.defaultOrganization) {
        this.conversionId.value = authSession.defaultOrganization.conversionId
      }
      if (!oldAuthSession || authSession) return
      this.selectedOrgId.value = null
      this.conversionId.value = null
      ServiceContainer.apiService.post('/user/session/invalidate-session', {
        sessionId: oldAuthSession.sessionId,
      }).catch()
      TropeeAnalyticsFactory.instance().identify('', {})
      ErrorTracker.disconnect()
      this.authInitiatorCache = null
    }, { flush: 'pre' })
    watch(this.selectedOrgId, (orgId) => {
      ErrorTracker.setOrgId(orgId)
    }, { immediate: true })
  }

  public async logout() {
    this.authSession.value = null
  }

  public showModal(initiator?: AuthInitiator) {
    showLoginModal.value = true
    if (initiator) {
      this.authInitiatorCache = initiator
    }
  }

  public closeModal() {
    showLoginModal.value = false
  }

  public async goToLogin<T = undefined>(initiator?: AuthInitiator<T>) {
    const redirect = btoa(JSON.stringify({
      path: window.location.pathname,
      query: paramsToObject(this.getSearchParamsWithInitiator(initiator)),
    }))
    const routeName = appName === 'creator' ? CreatorRouteName.LOGIN : (appName === 'admin' ? AdminRouteName.ADMIN_LOGIN : HolderRouteName.LOGIN)
    await ServiceContainer.router.push({ name: routeName, query: { redirect } })
  }

  private getSearchParamsWithInitiator<T = undefined>(initiator?: AuthInitiator<T>) {
    const searchParams = new URLSearchParams(window.location.search.substring(1))
    if (initiator) {
      searchParams.append(authInitiatorKey, btoa(JSON.stringify(initiator)))
    }
    return searchParams
  }

  public async appendInitiatorFromCacheToRoute() {
    const initiator = this.getAuthInitiator()
    if (!initiator) return
    await window.history.replaceState(
      history.state,
      document.title,
      decodeURIComponent(`${window.location.pathname}?${this.getSearchParamsWithInitiator(initiator)}`))
  }

  public routeChanged() {
    const searchParams = new URLSearchParams(window.location.search.substring(1))
    const authInitiatorBase64 = searchParams.get(authInitiatorKey)
    if (!authInitiatorBase64) {
      this.authInitiatorCache = null
      return
    }
    removeSearchParameter(authInitiatorKey)
    this.authInitiatorCache = JSON.parse(atob(authInitiatorBase64))
  }

  public getAuthInitiator<T = undefined>(): Nullable<AuthInitiator<T>> {
    return this.authInitiatorCache as Nullable<AuthInitiator<T>>
  }

  public setAuthInitiator(initiator: Nullable<AuthInitiator>) {
    this.authInitiatorCache = initiator
  }

  private static getAuthSession() {
    if (window.location.pathname.startsWith('/preview')) return ref(null)
    return useStorage<AuthSession>(
      authSessionKey,
      null,
      undefined,
      {
        serializer: {
          read: (v) => v ? JSON.parse(v) : null,
          write: (v) => JSON.stringify(v),
        },
      },
    )
  }
}

export default new AuthFacade()
