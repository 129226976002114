import { createHash } from 'crypto'
import { RequirementFrontendVariation, RequirementType, RequirementV2 } from '@core-lib/openapi/client'
import { nanoid } from 'nanoid'
import { IconType } from '@core-lib/models/utility'
import { LensApplicationCode, LensApplicationMapper } from '@core-lib/services/lens-application-mapper'
import { RequirementGroup } from './requirement-group'
import { Optional } from '@core-lib/models/common'

export enum RequirementTextType {
  Simple = 'simple',
  SemiBold = 'semi_bold',
  BlueLink = 'blue_link',
  TwitterUrl = 'twitter_url',
  DiscordUrl = 'discord_url',
  LensUrl = 'lens_url',
  AllowlistModal = 'allowlist_modal',
  Connect = 'connect',
  CollectionId = 'collection_id',
  Break = 'break',
  OpenSeaLink = 'open_sea_link',
  ExternalLinkAndSetDataClick = 'external_link_and_set_data_click',
  ProductHuntUrl = 'product_hunt_url',
  TwitchUrl = 'twitch_url',
  LoyaltyPage = 'loyalty_page',
  Calendly = 'calendly',
}

export type AllRequirementMetadata = {
  type?: string
  frontendVariation?: RequirementFrontendVariation
}

export type AllRequirement = RequirementV2 & {
  isMandatory: boolean
  metadata?: AllRequirementMetadata | undefined
};

export type RequirementTextItem = {
  type: RequirementTextType,
  content: string,
  url?: string,
  action?: string,
  actionValue?: string,
  walletAddresses?: string[]
}

export const isClickable = (item: RequirementTextItem) => ![RequirementTextType.Simple, RequirementTextType.SemiBold, RequirementTextType.Break].includes(item.type)

export const lastClickedRequirementIdWithSocialAccountVerification = 'last_clicked_requirement_id_social_verification'
export enum RecurringUnit {
  DAILY = 24,
  WEEKLY = 168,
  MONTHLY = 730,
}

export const recurringUnits = [RecurringUnit.DAILY, RecurringUnit.WEEKLY, RecurringUnit.MONTHLY]

export const getRecurringLabel = (unit: RecurringUnit.DAILY | RecurringUnit.WEEKLY | RecurringUnit.MONTHLY) => {
  switch (unit) {
  case RecurringUnit.DAILY:
    return 'daily'
  case RecurringUnit.WEEKLY:
    return 'weekly'
  case RecurringUnit.MONTHLY:
    return 'monthly'
  }
}

export const saveSocialAccountRequirementTypes: RequirementType[] = [RequirementType.InstagramFollow, RequirementType.InstagramLike, RequirementType.TiktokFollow, RequirementType.TiktokWatch, RequirementType.TelegramJoinChannel]
export const dropdownRequirementTypes: RequirementType[] = [RequirementType.AuthCode, RequirementType.Email, RequirementType.QuestionSimple, RequirementType.QuestionMultiChoice, RequirementType.Refer, RequirementType.VisitPage, RequirementType.PhoneNumber, ...saveSocialAccountRequirementTypes, RequirementType.FileUpload, RequirementType.TypeformSubmit, RequirementType.Custom]
export const dropdownRequirementTypesWithArrow: RequirementType[] = [RequirementType.AuthCode, RequirementType.Email, RequirementType.QuestionSimple, RequirementType.QuestionMultiChoice, RequirementType.Refer, RequirementType.PhoneNumber]
export const recurringEntryRequirementTypes: RequirementType[] = [RequirementType.VisitPage, RequirementType.TiktokWatch, RequirementType.YoutubeWatch, RequirementType.TwitterTweet, RequirementType.ConnectToTropee, RequirementType.Custom]
export const nonVerifiableRequirementTypes: RequirementType[] = [RequirementType.LoyaltyJoin]
export const neverEndingRequirementTypes: RequirementType[] = [RequirementType.Refer]

export const lensApplication = LensApplicationMapper.create(LensApplicationCode.LENSTER)

export const hashConfig = (type: RequirementType, config: object) => {
  const isListGroup = RequirementType.Denylist === type || RequirementType.Allowlist === type
  const string = isListGroup ? 'list' : JSON.stringify([
    type,
    Object.keys(config).sort(),
    Object.values(config).sort(),
  ])
  return createHash('sha256').update(string).digest('hex')
}

export const buildBaseRequirement = ({ bonus }: { type?: RequirementType, bonus: number, config?: object }): {
  id: string,
  bonus: number,
  hash: string
} => {
  return {
    id: nanoid(8),
    bonus,
    hash: '',
  }
}

type GroupConfig = {
  account: {
    required: boolean
    canConnect: boolean
  }
  needsWallet: boolean
  isRecurring: boolean
  verifyAfterAuth: boolean
}

export const groupConfigs: Record<RequirementGroup, GroupConfig> = {
  [RequirementGroup.Virtual]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: false,
  },
  [RequirementGroup.Token]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: true,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Poap]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: true,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Twitter]: {
    account: {
      required: true,
      canConnect: true,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: false,
  },
  [RequirementGroup.Discord]: {
    account: {
      required: true,
      canConnect: true,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: false,
  },
  [RequirementGroup.Lens]: {
    account: {
      required: true,
      canConnect: false,
    },
    needsWallet: true,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.List]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: true,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.VisitPage]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: true,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Refer]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Nft]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: true,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Auth]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Quiz]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Youtube]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Instagram]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Linkedin]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Question]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Telegram]: {
    account: {
      required: true,
      canConnect: true,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Email]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.ProductHunt]: {
    account: {
      required: true,
      canConnect: true,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.AllDone]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Facebook]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Tiktok]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Pinterest]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Twitch]: {
    account: {
      required: true,
      canConnect: true,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.DownloadApp]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Kick]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.PhoneNumber]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Loyalty]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.File]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Calendly]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Typeform]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Connect]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
  [RequirementGroup.Custom]: {
    account: {
      required: false,
      canConnect: false,
    },
    needsWallet: false,
    isRecurring: false,
    verifyAfterAuth: true,
  },
}

export const getGroupForRequirement = (type: RequirementType, frontendVariation?: Optional<RequirementFrontendVariation>) => {
  const keyValuePairs: Record<RequirementType, RequirementGroup> = {
    [RequirementType.NftOwn]: RequirementGroup.Nft,
    [RequirementType.NftOwnWithProperties]: RequirementGroup.Nft,
    [RequirementType.NftOwnWithTokenIds]: RequirementGroup.Nft,
    [RequirementType.NftOwnCount]: RequirementGroup.Nft,
    [RequirementType.NftOwnSince]: RequirementGroup.Nft,
    [RequirementType.NftOwnMinted]: RequirementGroup.Nft,
    [RequirementType.PoapOwn]: RequirementGroup.Poap,
    [RequirementType.TokenHold]: RequirementGroup.Token,
    [RequirementType.TokenHoldSince]: RequirementGroup.Token,
    [RequirementType.TwitterFollow]: RequirementGroup.Twitter,
    [RequirementType.TwitterLike]: RequirementGroup.Twitter,
    [RequirementType.TwitterRetweet]: RequirementGroup.Twitter,
    [RequirementType.TwitterTweet]: RequirementGroup.Twitter,
    [RequirementType.TwitterQuote]: RequirementGroup.Twitter,
    [RequirementType.DiscordJoin]: RequirementGroup.Discord,
    [RequirementType.DiscordRole]: RequirementGroup.Discord,
    [RequirementType.LensFollow]: RequirementGroup.Lens,
    [RequirementType.LensLike]: RequirementGroup.Lens,
    [RequirementType.LensMirror]: RequirementGroup.Lens,
    [RequirementType.VisitPage]: RequirementGroup.VisitPage,
    [RequirementType.Allowlist]: RequirementGroup.List,
    [RequirementType.Denylist]: RequirementGroup.List,
    [RequirementType.QuestionSimple]: RequirementGroup.Question,
    [RequirementType.QuestionMultiChoice]: RequirementGroup.Question,
    [RequirementType.InstagramFollow]: RequirementGroup.Instagram,
    [RequirementType.InstagramLike]: RequirementGroup.Instagram,
    [RequirementType.LinkedinFollow]: RequirementGroup.Linkedin,
    [RequirementType.LinkedinShare]: RequirementGroup.Linkedin,
    [RequirementType.LinkedinLikePost]: RequirementGroup.Linkedin,
    [RequirementType.LinkedinCommentPost]: RequirementGroup.Linkedin,
    [RequirementType.LinkedinRepost]: RequirementGroup.Linkedin,
    [RequirementType.YoutubeWatch]: RequirementGroup.Youtube,
    [RequirementType.YoutubeLike]: RequirementGroup.Youtube,
    [RequirementType.YoutubeSubscribe]: RequirementGroup.Youtube,
    [RequirementType.ProductHuntVotePost]: RequirementGroup.ProductHunt,
    [RequirementType.ProductHuntDiscoverProduct]: RequirementGroup.ProductHunt,
    [RequirementType.Email]: RequirementGroup.Email,
    [RequirementType.AuthCode]: frontendVariation === RequirementFrontendVariation.Quiz ? RequirementGroup.Quiz : RequirementGroup.Auth,
    [RequirementType.AllDone]: RequirementGroup.AllDone,
    [RequirementType.Refer]: RequirementGroup.Refer,
    [RequirementType.TelegramJoin]: RequirementGroup.Telegram,
    [RequirementType.TelegramJoinChannel]: RequirementGroup.Telegram,
    [RequirementType.FacebookPageLike]: RequirementGroup.Facebook,
    [RequirementType.FacebookPostLike]: RequirementGroup.Facebook,
    [RequirementType.TiktokFollow]: RequirementGroup.Tiktok,
    [RequirementType.TiktokWatch]: RequirementGroup.Tiktok,
    [RequirementType.PinterestFollow]: RequirementGroup.Pinterest,
    [RequirementType.TwitchFollow]: RequirementGroup.Twitch,
    [RequirementType.TwitchSubscribe]: RequirementGroup.Twitch,
    [RequirementType.DownloadAndroidApp]: RequirementGroup.DownloadApp,
    [RequirementType.DownloadIosApp]: RequirementGroup.DownloadApp,
    [RequirementType.KickFollow]: RequirementGroup.Kick,
    [RequirementType.LoyaltyJoin]: RequirementGroup.Loyalty,
    [RequirementType.PhoneNumber]: RequirementGroup.PhoneNumber,
    [RequirementType.FileUpload]: RequirementGroup.File,
    [RequirementType.CalendlyBook]: RequirementGroup.Calendly,
    [RequirementType.ConnectToTropee]: RequirementGroup.Connect,
    [RequirementType.TypeformSubmit]: RequirementGroup.Typeform,
    [RequirementType.Custom]: RequirementGroup.Custom,
    [RequirementType.Generic]: RequirementGroup.Custom,
    [RequirementType.TipJar]: RequirementGroup.Custom,
    [RequirementType.GameFortuneWheel]: RequirementGroup.Custom,
  }
  return keyValuePairs[type as keyof typeof keyValuePairs]
}

export const getInitialEntriesForType = (type: RequirementType, frontendVariation?: Optional<RequirementFrontendVariation>, metadataType?: Optional<string>) => {
  const keyValuePairs: Record<RequirementType, number> = {
    [RequirementType.NftOwn]: 1,
    [RequirementType.NftOwnWithProperties]: 1,
    [RequirementType.NftOwnWithTokenIds]: 1,
    [RequirementType.NftOwnCount]: 5,
    [RequirementType.NftOwnSince]: 5,
    [RequirementType.NftOwnMinted]: 10,
    [RequirementType.PoapOwn]: 1,
    [RequirementType.TokenHold]: 1,
    [RequirementType.TokenHoldSince]: 1,
    [RequirementType.TwitterFollow]: 5,
    [RequirementType.TwitterLike]: 1,
    [RequirementType.TwitterRetweet]: 10,
    [RequirementType.TwitterTweet]: 10,
    [RequirementType.TwitterQuote]: 10,
    [RequirementType.DiscordJoin]: 1,
    [RequirementType.DiscordRole]: 5,
    [RequirementType.LensFollow]: 5,
    [RequirementType.LensLike]: 1,
    [RequirementType.LensMirror]: 10,
    [RequirementType.VisitPage]: 1,
    [RequirementType.Allowlist]: 1,
    [RequirementType.Denylist]: 0,
    [RequirementType.QuestionSimple]: 1,
    [RequirementType.QuestionMultiChoice]: 1,
    [RequirementType.InstagramFollow]: 5,
    [RequirementType.InstagramLike]: 1,
    [RequirementType.LinkedinFollow]: metadataType === 'company' ? 5 : 1,
    [RequirementType.LinkedinShare]: 1,
    [RequirementType.LinkedinLikePost]: 1,
    [RequirementType.LinkedinCommentPost]: 1,
    [RequirementType.LinkedinRepost]: 1,
    [RequirementType.YoutubeWatch]: 1,
    [RequirementType.YoutubeLike]: 1,
    [RequirementType.YoutubeSubscribe]: 1,
    [RequirementType.ProductHuntVotePost]: 5,
    [RequirementType.ProductHuntDiscoverProduct]: 1,
    [RequirementType.Email]: 10,
    [RequirementType.AuthCode]: frontendVariation === RequirementFrontendVariation.Quiz || frontendVariation === RequirementFrontendVariation.OnetimeCode ? 1 : 5,
    [RequirementType.AllDone]: 10,
    [RequirementType.Refer]: 20,
    [RequirementType.TelegramJoin]: 1,
    [RequirementType.TelegramJoinChannel]: 1,
    [RequirementType.FacebookPostLike]: 1,
    [RequirementType.FacebookPageLike]: 5,
    [RequirementType.TiktokFollow]: 5,
    [RequirementType.TiktokWatch]: 1,
    [RequirementType.PinterestFollow]: 5,
    [RequirementType.TwitchFollow]: 5,
    [RequirementType.TwitchSubscribe]: 10,
    [RequirementType.DownloadAndroidApp]: 10,
    [RequirementType.DownloadIosApp]: 10,
    [RequirementType.KickFollow]: 1,
    [RequirementType.PhoneNumber]: 1,
    [RequirementType.LoyaltyJoin]: 1,
    [RequirementType.FileUpload]: 1,
    [RequirementType.CalendlyBook]: 10,
    [RequirementType.ConnectToTropee]: 1,
    [RequirementType.TypeformSubmit]: 10,
    [RequirementType.Custom]: 10,
    [RequirementType.Generic]: 1,
    [RequirementType.TipJar]: 5,
    [RequirementType.GameFortuneWheel]: 5,
  }
  return keyValuePairs[type as keyof typeof keyValuePairs] // https://tropee.notion.site/Default-actions-worth-entries-fbefc94de009490389beb34a9be3ae44
}

export const getNftIcon = (withBgClass = false) => ({ icon: 'c_NFT' as IconType, iconClass: 'text-indigo-400', bgClass: withBgClass ? 'bg-indigo-100' : undefined })
export const getPoapIcon = (withBgClass = false) => ({ icon: 'c_POAP' as IconType, bgClass: withBgClass ? 'bg-violet-100' : undefined })
export const getTokenHoldIcon = (withBgClass = false) => ({ icon: 'c_Wallet' as IconType, iconClass: 'text-violet-400', bgClass: withBgClass ? 'bg-violet-100' : undefined })
export const getTwitterIcon = (withBgClass = false) => ({ icon: 'c_X' as IconType,
  iconClass: 'text-sky-400', bgClass: withBgClass ? 'bg-sky-100' : undefined })
export const getDiscordIcon = (withBgClass = false) => ({ icon: { name: 'discord', type: 'brands' } as IconType,
  iconClass: 'text-violet-400', bgClass: withBgClass ? 'bg-violet-100' : undefined })
export const getLensIcon = (withBgClass = false) => ({ icon: { name: 'c_LensV2', type: 'brands' } as IconType, bgClass: withBgClass ? 'bg-emerald-100' : undefined })
export const getVisitPageIcon = (withBgClass = false) => ({ icon: 'c_Web' as IconType, iconClass: 'text-amber-400', bgClass: withBgClass ? 'bg-amber-100' : undefined })
export const getListIcon = (withBgClass = false) => ({ icon: 'c_Allowlist' as IconType, iconClass: 'text-pink-400', bgClass: withBgClass ? 'bg-pink-100' : undefined })
export const getQuestionIcon = (withBgClass = false) => ({ icon: { name: 'interrogation', type: 'solid' } as IconType, iconClass: 'text-emerald-400', bgClass: withBgClass ? 'bg-emerald-100' : undefined })
export const getInstagramIcon = (withBgClass = false) => ({  icon: 'c_Instagram' as IconType, iconClass: 'text-rose-400', bgClass: withBgClass ? 'bg-rose-100' : undefined })
export const getLinkedinIcon = (withBgClass = false) => ({  icon: 'c_LinkedinInverse' as IconType, iconClass: 'text-blue-400', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getYoutubeIcon = (withBgClass = false) => ({  icon: 'c_Youtube' as IconType, iconClass: 'text-red-400', bgClass: withBgClass ? 'bg-red-100' : undefined })
export const getProductHuntIcon = (withBgClass = false) => ({  icon: 'c_ProductHunt' as IconType, iconClass: 'text-[#FF6155]', bgClass: withBgClass ? 'bg-orange-100' : undefined })
export const getEmailIcon = (withBgClass = false) => ({ icon: 'c_EnvelopeFilled' as IconType, iconClass: 'text-teal-400', bgClass: withBgClass ? 'bg-teal-100' : undefined })
export const getSecretCodeIcon = (withBgClass = false) => ({ icon: { name: 'key', type: 'solid' } as IconType, iconClass: 'text-red-400', bgClass: withBgClass ? 'bg-red-100' : undefined })
export const getQuizIcon = (withBgClass = false) => ({ icon: 'c_Quiz' as IconType, iconClass: 'text-blue-400', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getAllDoneIcon = (withBgClass = false) => ({ icon: { name: 'star', type: 'solid' } as IconType, iconClass: 'text-ds-dark-primary', bgClass: withBgClass ? 'bg-gray-100' : undefined  })
export const getReferralIcon = (withBgClass = false) => ({ icon: 'c_Referral' as IconType, iconClass: 'text-orange-400', bgClass: withBgClass ? 'bg-orange-100' : undefined  })
export const getFacebookIcon = (withBgClass = false) => ({ icon: { name: 'facebook', type: 'brands' } as IconType, iconClass: 'text-blue-400', bgClass: withBgClass ? 'bg-blue-100' : undefined  })
export const getTiktokIcon = (withBgClass = false) => ({ icon: 'c_TikTok' as IconType, iconClass: 'text-pink-400', bgClass: withBgClass ? 'bg-pink-100' : undefined  })
export const getPinterestIcon = (withBgClass = false) => ({ icon: 'c_Pinterest' as IconType, iconClass: 'text-red-400', bgClass: withBgClass ? 'bg-red-100' : undefined  })
export const getTwitchIcon = (withBgClass = false) => ({ icon: 'c_Twitch' as IconType, iconClass: 'text-violet-400', bgClass: withBgClass ? 'bg-violet-100' : undefined  })
export const getAddAppIcon = () => ({ icon: 'c_AddApp' as IconType, iconClass: 'text-ds-dark-primary' })
export const getAppStoreIcon = () => ({ icon: 'c_AppStore' as IconType, iconClass: 'text-sky-400', bgClass: 'bg-sky-100' })
export const getGooglePlayIcon = () => ({ icon: 'c_GooglePlay' as IconType, iconClass: 'text-emerald-400', bgClass: 'bg-emerald-100' })
export const getKickIcon = (withBgClass = false) => ({ icon: 'c_Kick' as IconType, iconClass: 'text-emerald-400', bgClass: withBgClass ? 'bg-emerald-100' : undefined })
export const getLoyaltyJoinIcon = (withBgClass = false) => ({ icon: 'c_TropeeIcon' as IconType, iconClass: 'text-emerald-400', bgClass: withBgClass ? 'bg-emerald-100' : undefined })
export const getPhoneNumberIcon = (withBgClass = false) => ({ icon: 'c_PhoneFlip1Circle' as IconType, iconClass: 'text-cyan-400', bgClass: withBgClass ? 'bg-cyan-100' : undefined })
export const getFileIcon = (withBgClass = false) => ({ icon: 'c_FileUpload' as IconType, iconClass: 'text-indigo-400', bgClass: withBgClass ? 'bg-indigo-100' : undefined })
export const getCalendlyIcon = (withBgClass = false) => ({ icon: 'c_CalendlyUtilityIcon' as IconType, iconClass: '', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getTelegramIcon = (withBgClass = false) => ({ icon: 'c_Telegram' as IconType, iconClass: 'text-blue-400', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getConnectIcon = (withBgClass = false) => ({ icon: 'c_TropeeIcon' as IconType, iconClass: '', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getTypeformIcon = (withBgClass = false) => ({ icon: 'c_TropeeIcon' as IconType, iconClass: '', bgClass: withBgClass ? 'bg-blue-100' : undefined })
export const getCustomIcon = (withBgClass = false) => ({ icon: 'c_TropeeIcon' as IconType, iconClass: '', bgClass: withBgClass ? 'bg-blue-100' : undefined })

export const getRequirementGroupIcon = (group: RequirementGroup, withBgClass = false, type?: Optional<RequirementType>): {icon: IconType, iconClass?: string, bgClass?: string} => {
  const keyValuePairs: Record<RequirementGroup, {icon: IconType, iconClass?: string, bgClass?: string}> = {
    [RequirementGroup.Twitter]: getTwitterIcon(withBgClass),
    [RequirementGroup.Token]: getTokenHoldIcon(withBgClass),
    [RequirementGroup.Discord]: getDiscordIcon(withBgClass),
    [RequirementGroup.Lens]: getLensIcon(withBgClass),
    [RequirementGroup.Poap]: getPoapIcon(withBgClass),
    [RequirementGroup.List]: getListIcon(withBgClass),
    [RequirementGroup.VisitPage]: getVisitPageIcon(withBgClass),
    [RequirementGroup.Nft]: getNftIcon(withBgClass),
    [RequirementGroup.Question]: getQuestionIcon(withBgClass),
    [RequirementGroup.Instagram]: getInstagramIcon(withBgClass),
    [RequirementGroup.Youtube]: getYoutubeIcon(withBgClass),
    [RequirementGroup.Linkedin]: getLinkedinIcon(withBgClass),
    [RequirementGroup.ProductHunt]: getProductHuntIcon(withBgClass),
    [RequirementGroup.Email]: getEmailIcon(withBgClass),
    [RequirementGroup.Auth]: getSecretCodeIcon(withBgClass),
    [RequirementGroup.Quiz]: getQuizIcon(withBgClass),
    [RequirementGroup.AllDone]: getAllDoneIcon(withBgClass),
    [RequirementGroup.Refer]: getReferralIcon(withBgClass),
    [RequirementGroup.Virtual]: getTokenHoldIcon(withBgClass),
    [RequirementGroup.Facebook]: getFacebookIcon(withBgClass),
    [RequirementGroup.Tiktok]: getTiktokIcon(withBgClass),
    [RequirementGroup.Pinterest]: getPinterestIcon(withBgClass),
    [RequirementGroup.Twitch]: getTwitchIcon(withBgClass),
    [RequirementGroup.DownloadApp]: type === RequirementType.DownloadAndroidApp ? getGooglePlayIcon() : getAppStoreIcon(),
    [RequirementGroup.Kick]: getKickIcon(withBgClass),
    [RequirementGroup.PhoneNumber]: getPhoneNumberIcon(withBgClass),
    [RequirementGroup.Telegram]: getTelegramIcon(withBgClass),
    [RequirementGroup.Loyalty]: getLoyaltyJoinIcon(withBgClass),
    [RequirementGroup.File]: getFileIcon(withBgClass),
    [RequirementGroup.Calendly]: getCalendlyIcon(withBgClass),
    [RequirementGroup.Connect]: getConnectIcon(withBgClass),
    [RequirementGroup.Typeform]: getTypeformIcon(withBgClass),
    [RequirementGroup.Custom]: getCustomIcon(withBgClass),
  }
  return keyValuePairs[group as keyof typeof keyValuePairs]
}

export const getRequirementGroupName = (group: RequirementGroup): string => {
  const keyValuePairs: Record<RequirementGroup, string> = {
    [RequirementGroup.Twitter]: 'X (Twitter)',
    [RequirementGroup.Token]: 'Wallet balance',
    [RequirementGroup.Discord]: 'Discord',
    [RequirementGroup.Lens]: 'Lens',
    [RequirementGroup.Poap]: 'POAP',
    [RequirementGroup.List]: 'Wallet address',
    [RequirementGroup.VisitPage]: 'Visit website',
    [RequirementGroup.Nft]: 'NFT',
    [RequirementGroup.Question]: 'Question',
    [RequirementGroup.Instagram]: 'Instagram',
    [RequirementGroup.Youtube]: 'YouTube',
    [RequirementGroup.Linkedin]: 'LinkedIn',
    [RequirementGroup.ProductHunt]: 'Product Hunt',
    [RequirementGroup.Email]: 'Email',
    [RequirementGroup.Auth]: 'Secret code',
    [RequirementGroup.Quiz]: 'Quiz',
    [RequirementGroup.AllDone]: 'Bonus',
    [RequirementGroup.Refer]: 'Viral share',
    [RequirementGroup.Virtual]: '',
    [RequirementGroup.Facebook]: 'Facebook',
    [RequirementGroup.Tiktok]: 'TikTok',
    [RequirementGroup.Pinterest]: 'Pinterest',
    [RequirementGroup.Twitch]: 'Twitch',
    [RequirementGroup.DownloadApp]: 'Download app',
    [RequirementGroup.Telegram]: 'Telegram',
    [RequirementGroup.Kick]: 'Kick',
    [RequirementGroup.PhoneNumber]: 'Phone number',
    [RequirementGroup.Loyalty]: 'Loyalty',
    [RequirementGroup.File]: 'Upload media',
    [RequirementGroup.Calendly]: 'Calendly',
    [RequirementGroup.Typeform]: 'Typeform',
    [RequirementGroup.Connect]: 'Check-ins',
    [RequirementGroup.Custom]: 'Custom',
  }
  return keyValuePairs[group as keyof typeof keyValuePairs]
}

const tweetUrlRegex = new RegExp('/status/(\\d+)')
export const getTwitterIntent = (tweetUrl: string, intent: 'like' | 'retweet') => {
  const [,tweetId] = tweetUrlRegex.exec(tweetUrl) || []
  return `https://twitter.com/intent/${intent}?tweet_id=${tweetId}`
}
export const getTwitterIntentTweet = ({ tweetText, tweetUrl }: {tweetText: string, tweetUrl?: string}) => {
  const url = new URL('https://twitter.com/intent/tweet')
  url.searchParams.set('text', tweetText + (tweetUrl ? `\n${tweetUrl}` : ''))
  return url.toString()
}

export const allGroupRequirementsText: Record<RequirementGroup, { type: RequirementType, text: string, metadata?: AllRequirementMetadata | undefined, iconObj?: { icon: IconType, iconClass: string} | undefined }[]> = {
  [RequirementGroup.Nft]: [
    {
      type: RequirementType.NftOwn,
      text: 'Own an NFT',
    },
    {
      type: RequirementType.NftOwnWithProperties,
      text: 'Own an NFT with specific attributes',
    },
    {
      type: RequirementType.NftOwnWithTokenIds,
      text: 'Own an NFT with a specific token ID',
    },
    {
      type: RequirementType.NftOwnCount,
      text: 'Own a minimum number of NFTs',
    },
    {
      type: RequirementType.NftOwnSince,
      text: 'Own an NFT for a specified period of time',
    },
    {
      type: RequirementType.NftOwnMinted,
      text: 'Be an original minter of an NFT',
    },
  ],
  [RequirementGroup.Poap]: [
    {
      type: RequirementType.PoapOwn,
      text: 'Own a POAP',
    },
  ],
  [RequirementGroup.Token]: [
    {
      type: RequirementType.TokenHold,
      text: 'Hold a minimum number of tokens',
    },
    {
      type: RequirementType.TokenHoldSince,
      text: 'Hold a token for a specific period of time',
    },
  ],
  [RequirementGroup.Twitter]: [
    {
      type: RequirementType.TwitterFollow,
      text: 'Follow on X',
    },
    {
      type: RequirementType.TwitterLike,
      text: 'Like a Tweet ',
    },
    {
      type: RequirementType.TwitterRetweet,
      text: 'Repost a Tweet',
    },
    {
      type: RequirementType.TwitterTweet,
      text: 'Tweet on X',
    },
    {
      type: RequirementType.TwitterQuote,
      text: 'Quote Tweet on X',
    },
  ],
  [RequirementGroup.Discord]: [
    {
      type: RequirementType.DiscordJoin,
      text: 'Join a Discord server',
    },
    {
      type: RequirementType.DiscordRole,
      text: 'Verify role in Discord server',
    },
  ],
  [RequirementGroup.Lens]: [
    {
      type: RequirementType.LensFollow,
      text: 'Follow on Lens',
    },
    {
      type: RequirementType.LensLike,
      text: 'Like a Lens post',
    },
    {
      type: RequirementType.LensMirror,
      text: 'Mirror a Lens post',
    },
  ],
  [RequirementGroup.VisitPage]: [{
    type: RequirementType.VisitPage,
    text: 'Visit a page or site',
  }],
  [RequirementGroup.List]: [
    {
      type: RequirementType.Allowlist,
      text: 'Be part of a list of wallet addresses',
    },
    {
      type: RequirementType.Denylist,
      text: 'Exclude a list of wallet addresses',
    },
  ],
  [RequirementGroup.Question]: [
    {
      type: RequirementType.QuestionSimple,
      text: 'Answer a question',
    },
    {
      type: RequirementType.QuestionMultiChoice,
      text: 'Answer a multiple choice question',
    },
  ],
  [RequirementGroup.Instagram]: [
    {
      type: RequirementType.InstagramFollow,
      text: 'Follow a profile on Instagram',
    },
    {
      type: RequirementType.InstagramLike,
      text: 'Like a post on Instagram',
    },
  ],
  [RequirementGroup.Linkedin]: [
    {
      type: RequirementType.LinkedinLikePost,
      text: 'Like a post on LinkedIn',
    },
    {
      type: RequirementType.LinkedinCommentPost,
      text: 'Comment on a post on LinkedIn',
    },
    {
      type: RequirementType.LinkedinRepost,
      text: 'Repost a post on LinkedIn',
    },
    {
      type: RequirementType.LinkedinFollow,
      text: 'Follow a company on LinkedIn',
      metadata: {
        type: 'company',
      },
    },
    {
      type: RequirementType.LinkedinFollow,
      text: 'Follow a person on LinkedIn',
      metadata: {
        type: 'person',
      },
    },
  ],
  [RequirementGroup.Youtube]: [
    {
      type: RequirementType.YoutubeSubscribe,
      text: 'Subscribe to a channel on YouTube',
    },
    {
      type: RequirementType.YoutubeWatch,
      text: 'Watch a video on YouTube',
    },
    {
      type: RequirementType.YoutubeLike,
      text: 'Like a video on YouTube',
    },
  ],
  [RequirementGroup.ProductHunt]: [
    {
      type: RequirementType.ProductHuntVotePost,
      text: 'Vote for a product',
    },
    {
      type: RequirementType.ProductHuntDiscoverProduct,
      text: 'Discover a product',
    },
  ],
  [RequirementGroup.Email]: [
    {
      type: RequirementType.Email,
      text: 'Submit and verify an email',
    },
  ],
  [RequirementGroup.Auth]: [
    {
      type: RequirementType.AuthCode,
      text: 'Submit a secret code',
    },
    {
      type: RequirementType.AuthCode,
      text: 'Submit a one-time secret code',
      metadata: {
        frontendVariation: RequirementFrontendVariation.OnetimeCode,
      },
    },
  ],
  [RequirementGroup.Quiz]: [
    {
      type: RequirementType.AuthCode,
      text: 'Submit the correct answer',
      metadata: {
        frontendVariation: RequirementFrontendVariation.Quiz,
      },
    },
  ],
  [RequirementGroup.AllDone]: [
    {
      type: RequirementType.AllDone,
      text: 'Unlock bonus entries',
    },
  ],
  [RequirementGroup.Refer]: [
    {
      type: RequirementType.Refer,
      text: 'Refer friends for extra entries',
    },
  ],
  [RequirementGroup.Virtual]: [],
  [RequirementGroup.Telegram]: [
    {
      type: RequirementType.TelegramJoinChannel,
      text: 'Join a Telegram channel',
    },
  ],
  [RequirementGroup.Facebook]: [
    {
      type: RequirementType.FacebookPageLike,
      text: 'Like a page on Facebook',
    },
    {
      type: RequirementType.FacebookPostLike,
      text: 'Like a post on Facebook',
    },
  ],
  [RequirementGroup.Tiktok]: [
    {
      type: RequirementType.TiktokFollow,
      text: 'Follow on TikTok',
    },
    {
      type: RequirementType.TiktokWatch,
      text: 'Watch a video on TikTok',
    },
  ],
  [RequirementGroup.Pinterest]: [
    {
      type: RequirementType.PinterestFollow,
      text: 'Follow on Pinterest',
    },
  ],
  [RequirementGroup.Twitch]: [
    {
      type: RequirementType.TwitchFollow,
      text: 'Follow on Twitch',
    },
    {
      type: RequirementType.TwitchSubscribe,
      text: 'Subscribe on Twitch',
    },
  ],
  [RequirementGroup.DownloadApp]: [
    {
      type: RequirementType.DownloadIosApp,
      text: 'Download an iOS app',
      iconObj: getAppStoreIcon(),
    },
    {
      type: RequirementType.DownloadAndroidApp,
      text: 'Download an Android app',
      iconObj: getGooglePlayIcon(),
    },
  ],
  [RequirementGroup.Kick]: [
    {
      type: RequirementType.KickFollow,
      text: 'Follow on Kick',
    },
  ],
  [RequirementGroup.PhoneNumber]: [
    {
      type: RequirementType.PhoneNumber,
      text: 'Submit a phone number',
    },
  ],
  [RequirementGroup.Loyalty]: [
    {
      type: RequirementType.LoyaltyJoin,
      text: 'Join the loyalty program',
    },
  ],
  [RequirementGroup.File]: [
    {
      type: RequirementType.FileUpload,
      text: 'Upload media',
    },
  ],
  [RequirementGroup.Calendly]: [
    {
      type: RequirementType.CalendlyBook,
      text: 'Book a meeting with Calendly',
    },
  ],
  [RequirementGroup.Connect]: [
    {
      type: RequirementType.ConnectToTropee,
      text: 'Check-in to Tropee',
    },
  ],
  [RequirementGroup.Typeform]: [
    {
      type: RequirementType.TypeformSubmit,
      text: 'Submit a typeform',
    },
  ],
  [RequirementGroup.Custom]: [
    {
      type: RequirementType.Custom,
      text: 'Do a custom action',
    },
  ],
}

export const getGroupRequirementsText = (group: RequirementGroup) => {
  return allGroupRequirementsText[group]
}
