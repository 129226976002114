<template>
  <div class="w-full max-md:max-h-[412px] flex-1 flex flex-col justify-between md:justify-start gap-y-4 pt-8 p-6 md:p-0">
    <div>
      <div class="text-ds-dark-primary font-semibold text-lg">
        Check your email for a code
      </div>
      <div class="text-gray-500 text-sm md:text-[16px] md:leading-6 font-medium">
        We just sent you a temporary code. <br>
        Please check your inbox and paste it below.
      </div>
    </div>
    <div class="max-w-full">
      <DsVerificationCode
        :code-length="codeLength"
        :error="invalidCode ? 'This code is not valid. Please try again' : ''"
        :auto-focus="focusCodeInput"
        :verification-input-max-width="61"
        @update:model-value="$emit('changeCode', $event)"
      />
      <div
        v-if="!invalidCode"
        class="max-md:-mx-2 mt-4 md:mt-3 text-sm md:text-sm md:leading-6 font-medium text-gray-400 text-center"
      >
        Didn't get the code?
        <span
          class="cursor-pointer underline"
          :class="{'pointer-events-none !no-underline': isSendingCode || codeReRequested && codeRequestCountDown >= 0}"
          @click="$emit('sendCode')"
        >Click here to resend
          <span v-if="codeReRequested && codeRequestCountDown >= 0">({{ codeRequestCountDown }})</span>
        </span>
      </div>
    </div>
    <div class="flex gap-x-3 justify-between items-center md:mt-auto">
      <DsButton
        :style-type="isMobile ? 'secondary': 'tertiary'"
        class="max-md:w-full !text-gray-500 !px-6"
        @click="$emit('goBack')"
      >
        Back
      </DsButton>
      <DsButton
        class="max-md:w-full max-md:!max-h-[46px] md:min-w-[136px]"
        :disabled="code.length !== codeLength || isSendingCode"
        :loading="isVerifyLoading"
        :right-icon="{name: 'arrow-right', type: 'bold'}"
        right-icon-loading
        @click="$emit('verifyCode')"
      >
        {{ isMobile ? 'Next' : 'Continue' }}
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">

import DsButton from '@core-design/components/Design/DsButton.vue'
import DsVerificationCode from '@core-design/components/Design/DsVerificationCode.vue'
import { onMounted, ref, watch } from 'vue'
import useIsMobile from '@core-lib/composables/isMobile'

const props = withDefaults(defineProps<{
  code: string
  invalidCode: boolean
  isSendingCode: boolean
  codeReRequested: boolean
  isVerifyLoading: boolean
  codeRequestCountDown: number
  isHolderModal?: boolean
  isCreatorModal?: boolean
}>(), {
  isHolderModal: false,
  isCreatorModal: false,
})

const emit = defineEmits<{
  (e: 'changeCode', value: string): void
  (e: 'sendCode'): void
  (e: 'verifyCode'): void
  (e: 'goBack'): void
}>()

const codeLength = 7
const focusCodeInput = ref(0)
const isMobile = useIsMobile()
onMounted(() => {
  focusCodeInput.value++
})

watch(() => props.code, code => {
  if (code.length !== codeLength) return
  emit('verifyCode')
})
</script>

<style scoped>

</style>