import { useQuery } from '@tanstack/vue-query'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { organizationApiClient } from '@core-lib/webapp-services/client'
import { computed, ComputedRef, ref, watch } from 'vue'
import apiService, { ApiError } from '@core-lib/webapp-services/api-service'
import { LoyaltyProgramIdentifier } from '@core-lib/openapi/client'
export const useOrganizationListFetcher = () => {
  const { data: organizations, isFetched, refetch } = useQuery({
    queryKey: ['organizations', ServiceContainer.authFacade.userId],
    queryFn: async () => {
      const response = await organizationApiClient.getOrganizations()
      return response.data.organizations
    },
    initialData: () => [],
    enabled: authFacade.isAuthenticated,
  })

  return {
    organizations,
    isFetched,
    refetch,
  }
}

export const useOrganizationPublicProfileFetcher = (slug: string) => {
  const notFound = ref(false)

  const { error, data: organization, isFetched, refetch } = useQuery({
    queryKey: ['organization-profile'],
    queryFn: async () => {
      const response = await organizationApiClient.getOrganizationPublicProfileBySlug(slug)
      return response.data
    },
    retry: false,
  })

  watch(error, async e => {
    if (!e) return
    if ((e as { status: number }).status === 404 || (e instanceof ApiError && e.isNotFound())) {
      notFound.value = true
    } else {
      console.error(e)
    }
  })

  return {
    organization,
    isFetched,
    refetch,
    notFound,
  }
}

export const useOrganizationPublicProfileByIdentifierFetcher = (identifier: LoyaltyProgramIdentifier) => {
  const notFound = ref(false)

  const { error, data: organization, isFetched, refetch } = useQuery({
    queryKey: ['organization-profile'],
    queryFn: async () => {
      const response = await organizationApiClient.getOrganizationPublicProfileByIdentifier({ identifier })
      return response.data
    },
    retry: false,
  })

  watch(error, async e => {
    if (!e) return
    if ((e as { status: number }).status === 404 || (e instanceof ApiError && e.isNotFound())) {
      notFound.value = true
    } else {
      console.error(e)
    }
  })

  return {
    organization,
    isFetched,
    refetch,
    notFound,
  }
}

export const useGetOrganizationLoyaltyTropeeId = (slug?: string) => {
  const notFound = ref(false)

  const { error, data, isFetched, refetch } = useQuery({
    queryKey: ['organization-loyalty-tropee'],
    queryFn: async () => {
      if (!slug) return null
      return apiService.get<{ tropeeId: string }>(`/miscellaneous/org-loyalty-tropee?orgSlug=${slug}`)
    },
    retry: false,
  })

  watch(error, async e => {
    if (!e) return
    if ((e as { status: number }).status === 400 || (e as { status: number }).status === 404 || (e instanceof ApiError && e.isNotFound())) {
      notFound.value = true
    } else {
      console.error(e)
    }
  })

  return {
    tropeeId: computed(() => data.value?.tropeeId),
    isFetched,
    refetch,
    notFound,
  }
}

export type TropeeByLoyaltyIdentifierInput = {
  identifier: LoyaltyProgramIdentifier,
  returnLoyaltyForReward?: boolean,
  queryDisabled?: ComputedRef<boolean>,
}

export const useGetTropeeByLoyaltyIdentifier = (input: TropeeByLoyaltyIdentifierInput) => {
  const filterString = computed(() => JSON.stringify({ identifier: input.identifier, returnLoyaltyForReward: input.returnLoyaltyForReward, disabled: input.queryDisabled?.value ?? false }))
  const notFound = ref(false)

  const { error, data, isFetched, refetch } = useQuery({
    queryKey: ['organization-tropee', filterString],
    queryFn: async () => {
      const response = await organizationApiClient.getTropeeByLoyaltyIdentifier({ identifier: input.identifier, returnLoyaltyForReward: input.returnLoyaltyForReward })
      return response.data
    },
    enabled: computed(() => !input.queryDisabled?.value),
    retry: false,
  })

  const tropee = computed(() => data.value?.tropee)

  watch(error, async e => {
    if (!e) return
    if ((e as { status: number }).status === 400 || (e as { status: number }).status === 404 || (e instanceof ApiError && e.isNotFound())) {
      notFound.value = true
    } else {
      console.error(e)
    }
  })

  return {
    tropee,
    mustTropee: computed(() => {
      if (!tropee.value) throw new Error('Tried to access mustTropee before it is ready')
      return tropee.value
    }),
    isFetched,
    refetch,
    notFound,
  }
}
