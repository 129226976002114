import { ref } from 'vue'

const useIsScreenSmallerThan = (pixels: number) => {
  const isScreenSmallerThan = ref()
  const handleChange = (event: { matches: boolean }) => {
    isScreenSmallerThan.value = event.matches
  }
  const matchMedia = window.matchMedia(`(max-width: ${pixels}px)`)
  isScreenSmallerThan.value = matchMedia.matches
  if (matchMedia.addEventListener) {
    matchMedia.addEventListener('change', handleChange)
  } else {
    matchMedia.addListener(handleChange)
  }

  return isScreenSmallerThan
}

export default useIsScreenSmallerThan
