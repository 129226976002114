import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import authService from '@core-lib/webapp-services/auth/auth-service'

export const typeformLink = 'https://form.typeform.com/to/'

export enum TypeFormId {
  REPORT_ABUSE = 'lLjGWCN9',
  VERIFY = 'K0DIzOOg',
}

export const useTypeformPopup = (id: TypeFormId) => {
  return {
    toggle: () => {
      const { toggle } = createPopup(id, {
        hidden: {
          url: window.location.href,
          wallet_address: authService.authenticatedWalletAddress.value || '',
        },
        width: 500,
        height: 500,
        autoResize: true,
        hideFooter: true,
      })
      toggle()
    },
  }
}

export const useTypeformWindow = (id: TypeFormId) => {
  return {
    openFormInNewTab: () => {
      const url = new URL(`${typeformLink}${id}`)
      url.searchParams.set('url', window.location.href)
      if (authService.authSession.value) {
        url.searchParams.set('user_id', authService.authSession.value.userId)
      }
      window.open(url.toString(), '_blank')
    },
  }
}
