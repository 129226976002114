<template>
  <div class="w-full flex flex-col justify-between h-[252px] md:h-[258px]">
    <div class="text-center text-gray-500 text-sm md:text-[16px] md:leading-6 font-medium">
      {{ translator.translate('auth__we_just_sent_you_a_temporary_code') }} <br>
      {{ translator.translate('auth__please_check_your_inbox_and_paste_it_below') }}
    </div>
    <div class="max-w-full">
      <DsVerificationCode
        :code-length="codeLength"
        :error="invalidCode ? translator.translate('auth__that_email_does_not_look_right') : ''"
        :auto-focus="focusCodeInput"
        class="flex flex-col justify-center !max-w-[464px] mx-auto"
        code-input-class="!rounded-2xl !bg-gray-50"
        error-class="!text-xs !font-normal"
        :verification-input-max-width="61"
        @update:model-value="$emit('changeCode', $event)"
      />
      <div class="mt-4 text-sm font-medium text-gray-400 text-center">
        {{ translator.translate('auth__did_not_get_the_code') }}
        <span
          :class="isSendingCode || codeReRequested && codeRequestCountDown >= 0 ? 'pointer-events-none' : 'underline cursor-pointer md:hover:text-ds-dark-primary'"
          @click="$emit('sendCode')"
        >{{ translator.translate('auth__resend_it') }} <span v-if="codeRequestCountDown">({{ codeRequestCountDown }})</span></span>
      </div>
    </div>
    <div class="flex gap-x-3 mb-1.5 md:mb-0 justify-between items-center">
      <DsButton
        style-type="secondary"
        class="flex-1 min-w-[calc(50%-6px)] !text-gray-500 !px-6 !text-[16px] !leading-[24px] font-semibold"
        @click="$emit('goBack')"
      >
        {{ translator.translate('general__back') }}
      </DsButton>
      <DsButton
        class="flex-1 min-w-[calc(50%-6px)] !text-[16px] !leading-[24px] font-semibold"
        :disabled="code.length !== codeLength || isSendingCode"
        :loading="isVerifyLoading"
        loader-class="!h-[18px] md:!h-6"
        @click="$emit('verifyCode')"
      >
        {{ translator.translate('general__confirm') }}
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">

import DsButton from '@core-design/components/Design/DsButton.vue'
import DsVerificationCode from '@core-design/components/Design/DsVerificationCode.vue'
import { onMounted, ref, watch } from 'vue'
import translator from '@core-lib/webapp-services/i18n/translator'

const props = withDefaults(defineProps<{
  code: string
  invalidCode: boolean
  isSendingCode: boolean
  codeReRequested: boolean
  isVerifyLoading: boolean
  codeRequestCountDown: number
  isHolderModal?: boolean
  isCreatorModal?: boolean
}>(), {
  isHolderModal: false,
  isCreatorModal: false,
})

const emit = defineEmits<{
  (e: 'changeCode', value: string): void
  (e: 'sendCode'): void
  (e: 'verifyCode'): void
  (e: 'goBack'): void
}>()

const codeLength = 7
const focusCodeInput = ref(0)
onMounted(() => {
  focusCodeInput.value++
})

watch(() => props.code, code => {
  if (code.length !== codeLength) return
  emit('verifyCode')
})
</script>

<style scoped>

</style>