import {
  AuthProvider,
  AuthRedirect,
  AuthSession,
  convertSessionToAuthSession,
} from '@core-lib/webapp-services/auth-providers/auth-provider'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useSocialLoginModalController } from '@core-lib/composables/login-modal'
import { Session } from '@core-lib/models/session'
import { LoginType } from '@core-lib/models/wallet'
import { removeSearchParameter } from '@core-lib/ui-helper/search-param-remover'
import { useStorage } from '@vueuse/core'
import { Nullable } from '@core-lib/models/common'
import { currentUrl, isEmbed } from '@core-lib/helpers/url'
import { ActionToTrack, useTrackLastAction } from '@core-lib/composables/track-last-action'

const { showSocialLoginModal } = useSocialLoginModalController()
const nonceStorage = useStorage<Nullable<string>>('auth.nonce', null)
const { getLastAction } = useTrackLastAction(ActionToTrack.SIGNUP_SOURCE)
const nonceQueryParamKey = 'nonce'
const providerQueryParamKey = 'provider'

export class SocialAuthProvider implements AuthProvider {
  public readonly canSign = false

  constructor(private readonly provider: LoginType.GOOGLE | LoginType.TWITTER | LoginType.DISCORD) {}

  public static needToBeCalled() {
    return !!this.getNonce() && !!this.getProvider()
  }

  public static getProvider(): LoginType {
    const url = new URL(window.location.href)
    const provider = url.searchParams.get(providerQueryParamKey)
    if (!provider) throw new Error('Tried to get the provider when not preset')
    return provider as LoginType
  }

  private static getNonce() {
    try {
      this.getProvider()
    } catch (e) {
      nonceStorage.value = null
    }
    return nonceStorage.value
  }

  public async authenticate(): Promise<AuthSession> {
    const existingNonce = SocialAuthProvider.getNonce()
    if (existingNonce) {
      setTimeout(() => {
        removeSearchParameter([nonceQueryParamKey, providerQueryParamKey])
      }, 2000)
      showSocialLoginModal.value = true
      try {
        const { session } = await ServiceContainer.apiService.post<{ session: Session }>(`/user/session/login-social-validate`, {
          nonce: existingNonce,
          sourceUrl: window.location.href,
          lastAction: getLastAction(),
        })
        nonceStorage.value = null
        return convertSessionToAuthSession(session)
      } catch (e) {
        showSocialLoginModal.value = false
      }
    }
    const { url, nonce } = await ServiceContainer.apiService.post<{ url: string, nonce: string }>(`/user/session/login-social`, {
      redirectUri: currentUrl(),
      provider: this.provider,
      isPopup: isEmbed,
    })
    nonceStorage.value = nonce

    throw new AuthRedirect(url)
  }

  public async disconnect(): Promise<void> {
    return
  }
}
