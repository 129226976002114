import {
  UtilityClaimFlowActivated,
  UtilityClaimFlowEnteredRaffle,
  UtilityClaimFlowMinted,
  UtilityClaimFlowPaid,
} from '@core-lib/webapp-services/analytics/events/utility'

export const holderDidActionEventName = 'holder_did_action'

const fakePayload = { utilityId: '' }
export const holderDidActionEvents = [
  new UtilityClaimFlowMinted(fakePayload),
  new UtilityClaimFlowPaid(fakePayload),
  new UtilityClaimFlowActivated(fakePayload),
  new UtilityClaimFlowEnteredRaffle(fakePayload),
].map(e => e.name)

export type TrackInput = {
  payload: {
    event: string
    properties: object
  }
}

export type PageInput = {
  payload: {
    properties: object & { name: string }
  }
}

export type IdentifyInput = {
  payload: {
    userId: string
    traits: object
  }
}