import { z, ZodDate } from 'zod'

export const blockIdSchema = z.string().min(1).max(30).regex(/[a-zA-Z0-9]+/)

export type Nullable<T> = T | null
export type Optional<T> = T | undefined
export type NonOptional<T> = Exclude<T, undefined>
export type Maybe<T> = Optional<Nullable<T>>
export const isNullish = <T>(value: Maybe<T>): value is Exclude<typeof value, T> => value === undefined || value === null
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export const dateSchema = z.preprocess<ZodDate>((arg) => {
  return typeof arg == 'string' || arg instanceof Date ? new Date(arg) : new Date()
}, z.date())

export const idSchema = z.string().min(5).max(30).regex(/[a-zA-Z0-9-_]+/)
export const ulidValidationZod = idSchema

export type PaginatedByToken<ItemType> = {
  nextPage: string | null
  items: ItemType[]
}

export type PropType<Obj, Prop extends keyof Obj> = Obj[Prop];

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export type KeysOf<Type> = (keyof Type)[]