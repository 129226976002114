import { ref } from 'vue'

const showLoginModal = ref(false)
const showDelegateCashLoginModal = ref(false)
const showCrossmintSignModal = ref(false)
const showMagicModal = ref(false)
const showEmailModal = ref(false)
const showSocialLoginModal = ref(false)

export const useLoginModalController = () => {
  return {
    showLoginModal,
  }
}

export const useDelegateCashModalController = () => {
  return { showDelegateCashLoginModal }
}

export const useMagicModalController = () => {
  return { showMagicModal }
}

export const useEmailModalController = () => {
  return { showEmailModal }
}

export const useSocialLoginModalController = () => {
  return { showSocialLoginModal }
}

export const useCrossmintSignModalController = () => {
  return { showCrossmintSignModal }
}