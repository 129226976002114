import { ComputedRef, Ref, ref, watch } from 'vue'
import { Maybe } from '@core-lib/models/common'
import AnnouncementService, { AnnouncementModalContent } from '@core-lib/webapp-services/announcement-service'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

export const useAnnouncementModalFetcher = (currentPath: ComputedRef<string>) => {
  let announcement: Maybe<{ config: AnnouncementModalContent; }> = undefined
  const announcementModalContentValue: Ref<Maybe<AnnouncementModalContent>> = ref(undefined)
  const showModal = ref(false)
  const onlyVisibleForConnectedUsers = ref(false)
  const lastResetRequestNumber = ref(0)
  const isAuthenticated = authFacade.isAuthenticated

  const onUserHasClosedAnnouncementModal = () => {
    showModal.value = true
    announcementModalContentValue.value = undefined
    localStorage.setItem('closed_announcement_modal', lastResetRequestNumber.value.toString())
  }

  const getFilteredAnnouncement = () => {
    if (!announcement) return undefined
    announcement.config.posts = announcement.config.posts.filter(post => !!post.title && !!post.mediaUrl && !!post.description)
    return announcement
  }

  const init = async () => {
    announcement = await AnnouncementService.fetchAnnouncementModal()

    getAnnouncementModalContentValue()
  }

  const getAnnouncementModalContentValue = () => {
    if (!announcement?.config.isEnabled) return
    if (sessionStorage.getItem('fromWelcome')) return
    if (!isCurrentPathInPathsList(announcement.config.paths)) return

    const localStorageLastResetRequest = localStorage.getItem('closed_announcement_modal')

    lastResetRequestNumber.value = announcement.config.lastResetRequest
    showModal.value = !localStorageLastResetRequest || Number(localStorageLastResetRequest) < lastResetRequestNumber.value
    if (!showModal.value) {
      announcementModalContentValue.value = undefined
      return
    }
    if (announcement.config.onlyVisibleForConnectedUsers && !isAuthenticated.value) {
      onlyVisibleForConnectedUsers.value = true
      announcementModalContentValue.value = undefined
      return
    }
    announcementModalContentValue.value = getFilteredAnnouncement()?.config
  }

  const isCurrentPathInPathsList = (pathList: string[]) => {
    let isInList = false
    pathList.forEach(path => {
      console.log(currentPath.value)
      if (path === currentPath.value) {
        isInList = true
        return
      }
      if (path.endsWith('*')) {
        const regex = new RegExp(`${path.slice(0, -1)}.+`)
        if (regex.test(currentPath.value)) {
          isInList = true
          return
        }
      }
    })
    return isInList
  }

  const recheckPathMatch = () => {
    if (showModal.value || !isAuthenticated.value && onlyVisibleForConnectedUsers.value) return
    getAnnouncementModalContentValue()
  }

  watch(isAuthenticated, isAuthenticated => {
    if (!isAuthenticated || !onlyVisibleForConnectedUsers.value) return
    getAnnouncementModalContentValue()
  })

  watch(currentPath, () => {
    recheckPathMatch()
  })

  return {
    loadAnnouncementModal: init,
    announcementModalContent: announcementModalContentValue,
    onUserHasClosedAnnouncementModal,
  }
}
