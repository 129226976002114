// check if is the preview mode
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { AdminRouteName } from '@core-lib/routes'

export const useLoyaltyPreview = () => {
  const route = useRoute()
  const isLoyaltyPreview = computed(() => {
    // if the route starts with /admin, it's preview mode
    return route.path.startsWith('/admin')
  })

  const isOnboardingPreview = computed(() => route.name === AdminRouteName.ADMIN_ONBOARDING)

  return {
    isLoyaltyPreview,
    isOnboardingPreview,
  }
}