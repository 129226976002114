<template>
  <div
    class="border-solid border-gray-200 flex gap-x-1 justify-between px-3 py-3.5 items-center border rounded-lg hover:md:bg-gray-50 cursor-pointer"
    @click="copyLink"
  >
    <div class="flex gap-3 items-center w-[calc(100%-64px)]">
      <img
        v-if="image"
        class="h-6 w-6"
        :src="image"
        alt="Tropee"
      >
      <div class="text-[16px] leading-[24px] truncate">
        {{ text }}
      </div>
    </div>
    <div
      class="text-[16px] leading-[24px] font-medium cursor-pointer hover:text-gray-500"
      :class="{ 'text-green-500 hover:text-green-500 cursor-default': copySuccessful }"
    >
      {{ copySuccessful ? translate('copied') : translate('copy') }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useClipboard } from '@core-lib/composables/clipboard'
import { computed, ref } from 'vue'
import { useI18n } from '@core-lib/composables/i18n'
import { cleanUpUrl } from '@core-lib/helpers/url'

const props = withDefaults(defineProps<{
  text: string
  image?: string
  cleanUrl?: boolean
}>(), {
  image: '',
  cleanUrl: true,
})

const emit = defineEmits(['onCopy'])
const { translate } = useI18n({ prefix: 'general__' })

const text = computed(() => props.cleanUrl ? cleanUpUrl(props.text) : props.text)

const { copyToClipboard } = useClipboard()
const copySuccessful = ref(false)
const copyLink = () => {
  emit('onCopy')
  copyToClipboard(text.value)
  copySuccessful.value = true
  setTimeout(() => {
    copySuccessful.value = false
  }, 3000)
}
</script>
<style scoped>

</style>