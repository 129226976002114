<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
    <rect y="0.5" width="35" height="35" rx="8" fill="#3B99FC"/>
    <path
        d="M10.4871 13.4673C14.3602 9.59422 20.6398 9.59422 24.5129 13.4673L24.979 13.9335C25.1727 14.1271 25.1727 14.4411 24.979 14.6348L23.3845 16.2293C23.2877 16.3261 23.1307 16.3261 23.0338 16.2293L22.3924 15.5879C19.6904 12.8859 15.3096 12.8859 12.6076 15.5879L11.9207 16.2748C11.8239 16.3716 11.6669 16.3716 11.57 16.2748L9.97549 14.6803C9.78184 14.4866 9.78184 14.1726 9.97549 13.979L10.4871 13.4673ZM27.8106 16.765L29.2298 18.1842C29.4234 18.3779 29.4234 18.6918 29.2298 18.8855L22.8307 25.2847C22.637 25.4783 22.323 25.4783 22.1294 25.2847C22.1294 25.2847 22.1294 25.2847 22.1294 25.2847L17.5877 20.743C17.5393 20.6946 17.4608 20.6946 17.4124 20.743C17.4124 20.743 17.4124 20.743 17.4124 20.743L12.8708 25.2847C12.6771 25.4783 12.3632 25.4783 12.1695 25.2847C12.1695 25.2847 12.1695 25.2847 12.1695 25.2847L5.77024 18.8854C5.57659 18.6918 5.57659 18.3778 5.77024 18.1841L7.1894 16.765C7.38306 16.5713 7.69704 16.5713 7.89069 16.765L12.4324 21.3067C12.4809 21.3551 12.5593 21.3551 12.6078 21.3067C12.6078 21.3067 12.6078 21.3067 12.6078 21.3067L17.1493 16.765C17.3429 16.5713 17.6569 16.5713 17.8506 16.765C17.8506 16.765 17.8506 16.765 17.8506 16.765L22.3923 21.3067C22.4407 21.3551 22.5192 21.3551 22.5676 21.3067L27.1093 16.765C27.303 16.5714 27.6169 16.5714 27.8106 16.765Z"
        fill="white"/>
  </svg>
</template>
<script>
export default {
  name: 'WalletConnect_Logo'
}
</script>
