<template>
  <LoginModal
    :show="showLoginModal"
    @hide="closeModal"
  />
  <LoginInProgressModal />
  <FinishSignupModal />
  <TheMessageSigningExplanation />
</template>

<script lang="ts" setup>
import { useLoginModalController } from '@core-lib/composables/login-modal'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import LoginModal from '@core-lib/components/auth/LoginModal.vue'
import LoginInProgressModal from '@core-lib/components/LoginInProgressModal.vue'
import TheMessageSigningExplanation from '@core-lib/components/auth/TheMessageSigningExplanation.vue'
import FinishSignupModal from '@core-lib/components/auth/FinishSignupModal.vue'

const { showLoginModal } = useLoginModalController()

const closeModal = () => {
  authFacade.closeModal()
}
</script>

<style scoped>

</style>