export const buildWalletMessage = (isForClaim: boolean) => isForClaim ? 'Please sign this message to claim your utility' : 'Please sign this message to connect to Tropee'

export enum SignatureMessageType {
  LOGIN = 'login',
  CLAIM = 'claim',
  ACTIVATE = 'activate',
  DELEGATE_CASH = 'delegate_cash',
}

const messages: Record<SignatureMessageType, string> = {
  [SignatureMessageType.LOGIN]: 'Please sign this message to connect to tropee',
  [SignatureMessageType.CLAIM]: 'Please sign this message to claim your utility',
  [SignatureMessageType.ACTIVATE]: 'Please sign this message to activate your utility',
  [SignatureMessageType.DELEGATE_CASH]: 'Please sign this message to delegate you wallet',
}

export const walletMessageTemplate = '{message}\n\nSigned at {date}'

export const buildWalletMessageWithVars = ({ signatureMessageType, date }: { signatureMessageType: SignatureMessageType, date: Date }) => {
  return walletMessageTemplate.replace('{message}', messages[signatureMessageType])
    .replace('{date}', date.toISOString())
}