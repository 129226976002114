import { computed, Ref, ref, watch } from 'vue'
import axios from 'axios'
import { ConnectionType, LoginType } from '@core-lib/models/wallet'
import {
  AuthProvider,
  AuthRedirect,
  AuthSession,
  convertSessionToAuthSession,
} from '@core-lib/webapp-services/auth-providers/auth-provider'
import { authProviders } from '@core-lib/webapp-services/auth-providers/providers-list'
import {
  AuthInitiator,
  AuthService as AuthServiceInterface,
} from '@core-lib/webapp-services/service-container/services/auth-service'
import { SignatureMessageType } from '@core-lib/const/wallet-message'
import { Maybe, Nullable } from '@core-lib/models/common'
import { useLoginModalController } from '@core-lib/composables/login-modal'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { ErrorTracker } from '@core-lib/webapp-services/error-tracker'
import { AuthAuthenticated, AuthFailed, AuthStarted } from '@core-lib/webapp-services/analytics/events/auth'
import { CodeAuthProvider } from '@core-lib/webapp-services/auth-providers/code-auth-provider'
import { useStorage } from '@vueuse/core'
import { Session } from '@core-lib/models/session'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { SocialAuthProvider } from '@core-lib/webapp-services/auth-providers/social-auth-provider'
import { openSignInWindow } from '@core-lib/webapp-services/auth/auth-opener'
import { isEmbed, redirectToSameTab } from '@core-lib/helpers/url'

const lastAuthProviderKey = 'last_auth_provider'

const { showLoginModal } = useLoginModalController()

export class AuthService implements AuthServiceInterface {
  public readonly authSession: Ref<Maybe<AuthSession>>
  public readonly isSigning: Ref<boolean>
  public readonly isValidatingSocialLogin: Ref<boolean>
  public readonly userIdentifier = computed(() => this.authSession.value?.address || 'anon')
  public readonly authenticatedWalletAddress = computed(() => this.authSession.value?.address)
  protected readonly lastAuthProvider: Ref<Nullable<LoginType>>

  constructor (protected authProviders: Partial<Record<LoginType, AuthProvider>>) {
    this.isSigning = ref(false)
    this.isValidatingSocialLogin = ref(false)
    this.lastAuthProvider = useStorage<Nullable<LoginType>>(lastAuthProviderKey, null)
    this.authSession = authFacade.authSession
    if (this.authSession.value) {
      this.identifyOtherServices()
    }
    watch(this.authSession, async (authenticatedWallet, oldAuthenticatedWallet) => {
      if (!oldAuthenticatedWallet || authenticatedWallet) return
      await this.currentAuthProvider?.disconnect()
      this.lastAuthProvider.value = null
    })
  }

  protected identifyOtherServices() {
    if (!this.authSession.value) return
    TropeeAnalyticsFactory.instance().identify(`u.${this.authSession.value.userId}`, { 'auth.provider': this.lastAuthProvider.value || 'unknown', email: this.authSession.value.email })
    ErrorTracker.authenticate({ id: this.authSession.value.userId })
  }

  public async renewSession(): Promise<void> {
    if (!this.authSession.value) return
    const api = axios.create({
      baseURL: ServiceContainer.env.apiBaseUrl,
    })

    const response = await api.post<{ newSession: Session }>('/user/private/complete-profile', {}, {
      headers: {
        ['authorization']: `Session ${this.authSession.value.sessionId}`,
      },
    })

    this.authSession.value = convertSessionToAuthSession(response.data.newSession)
  }

  public stopSigning() {
    this.isSigning.value = false
  }

  async appLoaded() {
    if (this.authSession.value && !this.authSession.value.mainOrgId) {
      await this.renewSession()
      this.identifyOtherServices()
    }
    // Third party logins
    if (new URLSearchParams(window.location.search).has('logout')) {
      await authFacade.logout()
    }
    if (authFacade.isAuthenticated.value) return
    if (CodeAuthProvider.needToBeCalled()) {
      await this.authenticate(ConnectionType.CODE)
    } else if (SocialAuthProvider.needToBeCalled()) {
      console.log('need to be vcalled')
      this.isValidatingSocialLogin.value = true
      try {
        await this.authenticate(SocialAuthProvider.getProvider())
      } finally {
        this.isValidatingSocialLogin.value = false
      }
    }
  }

  async authenticate(connectionType: LoginType, signatureMessageType?: SignatureMessageType) {
    const wasFromModal = showLoginModal.value
    if (connectionType !== LoginType.EMAIL) {
      showLoginModal.value = false
    }
    TropeeAnalyticsFactory.instance().track(new AuthStarted({ connectionType: connectionType }))
    const authProvider = this.authProviders[connectionType]
    if (!authProvider) throw new Error('Provider not found. Maybe you wanted to use extended auth-service?')
    try {
      this.authSession.value = await authProvider.authenticate({
        signatureMessageType: signatureMessageType || SignatureMessageType.LOGIN,
      })
      this.lastAuthProvider.value = connectionType
      TropeeAnalyticsFactory.instance().track(new AuthAuthenticated({ connectionType: connectionType }))
      this.identifyOtherServices()
      if (window.dataLayer) {
        window.dataLayer.push({ 'event': 'connect_wallet' })
      }
    } catch (e) {
      if (e instanceof AuthRedirect) {
        localStorage.setItem('last_redirect', connectionType)
        if (isEmbed) {
          openSignInWindow(e.url, 'Sign in')
        } else {
          redirectToSameTab(e.url)
        }
        return
      }
      TropeeAnalyticsFactory.instance().track(new AuthFailed({ connectionType: connectionType }))
      if (!wasFromModal) {
        throw e
      } else {
        console.debug(e)
      }
    }
  }

  get currentAuthProvider(): Nullable<AuthProvider> {
    if (!this.lastAuthProvider.value) return null
    return this.authProviders[this.lastAuthProvider.value] || null
  }

  disconnect(): void {
    authFacade.logout()
  }

  getAuthInitiator<T>(): Nullable<AuthInitiator<T>> {
    return authFacade.getAuthInitiator<T>()
  }

  goToLogin(initiator: AuthInitiator | undefined): void {
    authFacade.goToLogin(initiator)
  }

  openLoginModal() {
    showLoginModal.value = true
  }
}

export default new AuthService(authProviders)
