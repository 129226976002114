import { SignatureMessageType } from '@core-lib/const/wallet-message'
import { Session } from '@core-lib/models/session'

export enum AuthType {
  SESSION = 2,
}

export enum FailedToAuthenticateCode {
  REJECTED = 'rejected',
  NOT_CONNECTED = 'not_connected',
  UNKNOWN = 'unknown',
}

export class FailedToAuthenticate extends Error {
  constructor(public code: FailedToAuthenticateCode) {
    super('Failed to authenticate')
  }

  public static notConnected() {
    return new FailedToAuthenticate(FailedToAuthenticateCode.NOT_CONNECTED)
  }

  public static rejected() {
    return new FailedToAuthenticate(FailedToAuthenticateCode.REJECTED)
  }

  public static unknown() {
    return new FailedToAuthenticate(FailedToAuthenticateCode.UNKNOWN)
  }
}

export type AuthSession = {
  address: string
  expiresAt: number
  timestamp: number
  type: AuthType.SESSION
  isFirstSession: boolean
  defaultOrganization?: {
    id: string
    conversionId: string
  }
  sessionId: string
  isUserReady: boolean
  mainOrgId: string
  userId: string
  email: string
}

export class AuthRedirect {
  constructor(public readonly url: string) {}
}

export type AuthenticateInput = {
  signatureMessageType: SignatureMessageType
}

export interface AuthProvider {
  readonly canSign: boolean
  authenticate(input: AuthenticateInput): Promise<AuthSession>
  disconnect(): Promise<void>
}

export const convertSessionToAuthSession = (session: Session): AuthSession => ({
  type: AuthType.SESSION,
  sessionId: session.id,
  address: session.walletAddress,
  timestamp: session.timestamp,
  expiresAt: session.expiresAt,
  isFirstSession: session.isFirstSession,
  defaultOrganization: session.defaultOrganization,
  mainOrgId: session.mainOrgId,
  isUserReady: session.isUserReady,
  email: session.email,
  userId: session.userId,
})