<template>
  <Transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="!nonce"
      class="w-full h-full flex-1 flex flex-col text-ds-dark-primary justify-between w-full md:w-[460px] p-8 pb-4 md:p-0"
    >
      <div>
        <div class="font-semibold text-lg md:text-2xl">
          Welcome to Tropee
        </div>
        <div class="md:hidden text-gray-500 text-sm md:text-[16px] md:leading-6 font-medium">
          Please choose a sign-up option
        </div>
      </div>
      <div class="flex flex-col items-center w-full gap-y-3">
        <div class="w-full flex flex-col gap-y-3">
          <DsInput
            v-model.trim="email"
            placeholder="you@example.com"
            width-class="w-full"
            type="email"
            field-class="!justify-start"
            :has-error="!!emailErrorMessage"
            :helper-text="emailErrorMessage"
            leading-icon="c_EnvelopeFilled"
            trailing-icon="arrow-small-right"
            trailing-icon-clickable
            @trailing-icon-click="sendCodeForLogin"
            @enter="sendCodeForLogin"
          />
        </div>
        <div class="flex items-center w-full gap-x-1.5 px-3.5">
          <div class="flex-1 h-[1px] bg-gray-300" />
          <div class="text-gray-400 text-xs font-semibold">
            OR
          </div>
          <div class="flex-1 h-[1px] bg-gray-300" />
        </div>

        <div class="grid grid-cols-2 flex-row flex-wrap w-full gap-2">
          <template
            v-for="connection in connections"
            :key="connection.connectionType"
          >
            <div
              class="wallet-option"
              :class="[connection.class]"
              @click="connect(connection.connectionType)"
            >
              <div class="flex flex-row items-center gap-x-3 w-full">
                <div class="w-7 h-7 flex items-center justify-center">
                  <component
                    :is="connection.icon"
                    class="w-full"
                  />
                </div>
                <div class="font-semibold text-[16px] text-ds-dark-primary leading-6">
                  {{ connection.name }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <p class="text-xs text-center min-[365px]:w-[299px] md:w-[341px] mx-auto text-gray-400">
        By connecting to Tropee, you agree to our <a
          class="underline"
          href="https://www.tropee.com/terms"
          target="_blank"
        >Terms of Service</a> and our <a
          class="underline"
          href="https://www.tropee.com/privacy-policy"
          target="_blank"
        >Privacy Policy</a>
      </p>
    </div>
    <VerifyEmailWithCode
      v-else
      :code="code"
      :code-re-requested="codeReRequested"
      :code-request-count-down="codeRequestCountDown"
      :invalid-code="invalidCode"
      :is-sending-code="isSendingCode"
      :is-verify-loading="isVerifyLoading"
      :is-holder-modal="isHolderModal"
      :is-creator-modal="isCreatorModal"
      @change-code="handleCodeChange"
      @send-code="sendCodeForLogin"
      @verify-code="verifyEmailLoginCode"
      @go-back="nonce = ''"
    />
  </Transition>
</template>
<script setup lang="ts">

import VerifyEmailWithCode from '@core-lib/components/auth/VerifyEmailWithCode.vue'
import DsInput from '@core-design/components/Design/DsInput.vue'
import ConnectionType, { LoginType } from '@core-lib/models/wallet'
import GoogleColored from '@core-design/assets/icons/custom-icons/Google.vue'
import { useIsMobileDevice } from '@core-lib/composables/isMobile'
import { useVerifyEmailWithCode } from '@core-lib/composables/verify-email-with-code'
import { computed, ComputedRef, onMounted, ref, watch } from 'vue'
import { useZodValidateSimple } from '@core-lib/composables/zod-validate'
import { z } from 'zod'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { AuthService } from '@core-lib/webapp-services/service-container/services/auth-service'

const props = withDefaults(defineProps<{
  isHolderModal?: boolean
  isCreatorModal?: boolean
  authService: AuthService
}>(), {
  isHolderModal: false,
  isCreatorModal: false,
})

const connections: ComputedRef<{ connectionType: LoginType, name: string, icon: unknown, class?: string}[]> = computed(() => {
  return [
    {
      connectionType: LoginType.GOOGLE,
      name: 'Continue with Google',
      icon: GoogleColored,
      class: 'col-span-full',
    },
  ]
})

const isMobileDevice = useIsMobileDevice()
const metamaskDetected = ref(false)

const email = ref('')
const { errorMessage: emailErrorMessage, validate: validateEmail } = useZodValidateSimple(
  z.string({ required_error: 'This field is required' }).trim().email('Email address is invalid'),
  email,
)

const {
  code, nonce, isSendingCode, invalidCode, codeReRequested, codeRequestCountDown, isVerifyLoading, sendCodePressed,
  handleCodeChange, sendCodeForLogin, verifyEmailLoginCode,
} = useVerifyEmailWithCode({ email, validate: validateEmail, isForLogin: true, withNotify: true })

watch(email, () => {
  if (!sendCodePressed.value) return
  validateEmail()
})

const connect = async (loginType: LoginType) => {
  if (props.isHolderModal && loginType !== ConnectionType.EMAIL) {
    await authFacade.appendInitiatorFromCacheToRoute()
  }
  await props.authService.authenticate(loginType)
}

onMounted(() => {
  const w = window as unknown as { ethereum: { isMetaMask?: boolean } }
  if (w.ethereum) {
    handleEthereum()
  } else {
    window.addEventListener('ethereum#initialized', handleEthereum, {
      once: true,
    })

    // If the event is not dispatched by the end of the timeout,
    // the user probably doesn't have MetaMask installed.
    setTimeout(handleEthereum, 3000) // 3 seconds
  }

  function handleEthereum() {
    const { ethereum } = w
    metamaskDetected.value = !!(ethereum && ethereum.isMetaMask)
  }
})
</script>
<style scoped>
.wallet-option {
  @apply h-[60px] w-full bg-white rounded-2xl pl-4 pr-3 flex items-center justify-between cursor-pointer md:hover:bg-gray-100 border border-gray-100 hover:border-gray-200 active:border-gray-200;
  box-shadow: 0 8px 40px 0 rgba(27, 32, 50, 0.04), 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 2px 0 rgba(29, 29, 27, 0.15);
}
</style>
