<template>
  <div
    id="navbar-ds"
    ref="navbarDs"
    class="w-full top-0 left-0 mx-auto navbar-transition h-[72px] border-b border-b-transparent z-[39]"
    :class="{'navbar-move': userClosedAnnouncement}"
  >
    <div
      id="navbar-general"
      ref="generalNavbarSection"
      class="tropee-container mx-auto py-1.5 w-full flex items-center justify-between h-[71px] gap-x-0 lg:gap-x-8"
      :class="isTransparent ? 'max-lg:bg-transparent' : 'max-lg:bg-white'"
    >
      <div
        v-if="isMobileNavActive && maybeUser"
        class="my-4 flex items-center relative max-w-[calc(100%-40px)]"
      >
        <div>
          <DsAvatar
            :avatar="user.avatar || ''"
            :wallet-address="user.id || ''"
            class="w-10 h-10 mr-3 rounded-full object-cover"
          />
        </div>
        <div class="text-[1rem] font-semibold max-w-[calc(100%-52px)]">
          <div class="truncate">
            {{ user.firstName || user.email }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex gap-x-4 lg:gap-x-8 items-center flex-grow"
      >
        <div class="w-[126px]">
          <DsIcon
            class="transition-opacity duration-300 ease-in-out"
            icon="c_TropeeLogo"
            :class="{'cursor-pointer': navbarLogoClickable}"
            :color-class="isTransparent && !isMobileNavActive ? 'text-white' : 'text-ds-dark-primary'"
            size="h-[38px] w-[126px]"
            @click="logoClicked"
          />
        </div>
      </div>
      <div class="flex gap-x-4 lg:gap-x-8 items-center transition-opacity duration-300 ease-in-out">
        <div
          v-if="!hideCreateTropeeButton"
          @click="goToLanding"
          class="text-[16px] leading-6 font-semibold hidden transition-all duration-100 lg:flex lg:items-center lg:gap-x-1 hover:drop-shadow-[0_0_7px_rgba(29,29,27,0.12)] group cursor-pointer"
        >
          <DsIcon
            icon="c_Stars"
            size="w-[14px] h-[14px]"
            :color-class="isTransparent ? 'text-white group-hover:text-opacity-[.65]' : 'text-ds-green-primary'"
          />
          <span
            :class="isTransparent ? 'colored-item-transparent group-hover:opacity-[.65]' : 'colored-item'"
            style="text-shadow: 0 0 15px rgba(255, 255, 255, 0.45);"
          >
            {{ translate('create_a_tropee') }}</span>
        </div>
        <div
          v-if="isAuthenticated"
          class="hidden lg:flex lg:items-center gap-x-8"
        >
          <UserMenuItem
            menu-container="#navbar-ds"
            app="holder"
          />
        </div>
        <DsButton
          v-if="!isAuthenticated"
          class="hidden lg:inline-block text-center hover:shadow-[0_0_7px_rgba(29,29,27,0.12)] text-base leading-5 !px-9"
          @click="connectWallet"
        >
          Log in
        </DsButton>
        <div
          class="lg:hidden max-lg:w-10 max-lg:h-10 max-lg:flex max-lg:justify-center max-lg:items-center"
          @click="toggleMobileNav"
        >
          <DsIcon
            :class="{ activate: isMobileNavActive, deactivate: !isMobileNavActive }"
            class="block cursor-pointer"
            :color-class="isTransparent && !isMobileNavActive ? 'text-white' : 'text-ds-dark-primary' "
            icon="c_MenuBurger"
            size="text-[24px] w-6 h-6"
            type="bold"
          />
        </div>
      </div>
    </div>
    <TransitionGroup name="menu-slide">
      <DsMobileNav
        v-if="isMobileNavActive"
        :is-authenticated="isAuthenticated"
        :menu-items="holderCurrentMenuItems"
        :socials="socials"
        :has-announcement="isAnnouncementVisible"
        :navbar-section="navbarDsCasted"
        :announcement-section="announcementSection"
        :hide-create-tropee-button="hideCreateTropeeButton"
        @route-clicked="routeClicked"
        @connect-wallet-clicked="connectWallet"
        @create-button-clicked="handleCreateClickFromMobileNav"
      />
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { Maybe } from '@core-lib/models/common'
import useIsScreenSmallerThan from '@core-lib/composables/is-screen-smaller-than'
import { useScreenSize } from '@core-lib/composables/screen-size'
import DsAvatar from '@core-design/components/Design/DsAvatar.vue'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import DsMobileNav from '@core-design/components/Design/DsMobileNav.vue'
import { useNavigation } from '@core-lib/composables/navigation'
import UserMenuItem from '@core-lib/components/UserMenuItem.vue'
import { CreatorRouteName } from '@core-lib/routes'
import { useRouter } from 'vue-router'
import { useGoToLandingPage } from '@core-lib/composables/go-to-landing-page'
import { useThisUserPublicProfileFetcher } from '@core-lib/composables/user-fetcher'
import { useI18n } from '@core-lib/composables/i18n'

const props = withDefaults(
  defineProps<{
    transparent?: boolean
    isAnnouncementVisible?: boolean
    disableAnnouncementBar?: boolean
    announcementContent?: string
    userClosedAnnouncement?: boolean
    announcementSection?: Maybe<HTMLElement>
    navbarLogoClickable?: boolean
    hideCreateTropeeButton?: boolean
  }>(),
  {
    transparent: false,
    isAnnouncementVisible: false,
    disableAnnouncementBar: false,
    announcementContent: '',
    userClosedAnnouncement: false,
    announcementSection: null,
    navbarLogoClickable: false,
    hideCreateTropeeButton: false,
  },
)

const router = useRouter()
const currentRoutePath = computed(() => router.currentRoute.value.path?.toString() || '')
const { mustUser: user, user: maybeUser } = useThisUserPublicProfileFetcher()
const { goToLandingPage } = useGoToLandingPage()

const isScreenSmallerThanLarge = useIsScreenSmallerThan(1023)

const {
  isMobileNavActive, getPathByName, holderCurrentMenuItems, routeClicked, connectWallet,
} = useNavigation()

const isAuthenticated = computed(() => !!maybeUser)

const navbarDs = ref<HTMLElement | null | undefined>(null)
const navbarDsCasted = computed(() => navbarDs.value as HTMLElement)
const generalNavbarSection = ref<HTMLElement | null | undefined>(null)
const adjustSliderHeightNumber = ref(0)

const isTransparent = ref(props.transparent)
watch(() => props.transparent, transparent => isTransparent.value = transparent)

watch(() => props.userClosedAnnouncement, closed => {
  if (closed) {
    setTimeout(() => {
      if (navbarDs.value) {
        navbarDs.value.style.position = 'fixed'
        const baseLayout = document.querySelector('#base-layout') as HTMLElement
        if (baseLayout) {
          baseLayout.style.paddingTop = `${navbarDs.value.clientHeight}px`
        }
      }
    }, 300)
  }
})

const socials = ref([
  {
    icon: 'c_XColored',
    link: 'https://twitter.com/TropeeHQ',
  },
  {
    icon: 'c_DiscordColored',
    link: 'https://discord.gg/z6x6yRskNQ',
  },
  {
    icon: 'c_LinkedinColored',
    link: 'https://www.linkedin.com/company/tropee/',
  },
])

const handleCreateClickFromMobileNav = () => {
  isMobileNavActive.value = false
  routeClicked(getPathByName(CreatorRouteName.DASHBOARD))
}

const toggleMobileNav = () => {
  isMobileNavActive.value = !isMobileNavActive.value
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  if (props.disableAnnouncementBar) {
    const baseLayout = document.querySelector('#base-layout') as HTMLElement
    if (navbarDs.value) {
      navbarDs.value.style.position = 'fixed'
      if (baseLayout) {
        baseLayout.style.paddingTop = `${navbarDs.value.clientHeight}px`
      }
    }
  }
})

onUnmounted(() => {
  const baseLayout = document.querySelector('#base-layout') as HTMLElement
  if (baseLayout) {
    baseLayout.style.paddingTop = '0'
  }
})

const logoClicked = () => {
  if (!props.navbarLogoClickable) return
  window.location.href = 'https://tropee.com'
}
const adjustToAnnouncementBar = (progress = 0) => {
  if (!navbarDs.value) return

  const baseLayout = document.querySelector('#base-layout') as HTMLElement
  if (props.isAnnouncementVisible) {
    if (progress >= 56) {
      navbarDs.value.style.position = 'fixed'
      if (baseLayout) {
        baseLayout.style.paddingTop = `${navbarDs.value.offsetHeight}px`
      }
    } else {
      navbarDs.value.style.position = 'static'
      if (baseLayout) {
        baseLayout.style.paddingTop = '0'
      }
    }
    adjustSliderHeightNumber.value++
    return
  }
  navbarDs.value.style.position = 'fixed'
  if (baseLayout) {
    baseLayout.style.paddingTop = `${navbarDs.value.offsetHeight}px`
  }
}

const goToLanding = () => {
  const splittedPath = currentRoutePath.value.toLowerCase().split('/')
  const currentPathChunk = splittedPath[splittedPath.length - 1]
  goToLandingPage(`utm_source=createbutton-participant-${currentPathChunk}&utm_medium=referral`)
}

const handleScroll = () => {
  if (!navbarDs.value) return

  const progress = window.scrollY

  adjustToAnnouncementBar(progress)
  if (progress > 0) {
    navbarDs.value.classList.add('bg-white')
    isTransparent.value = false
    navbarDs.value.classList.add('!border-b-gray-200')
    if (progress > 100) {
      navbarDs.value.classList.remove('navbar-transition')
    } else {
      navbarDs.value.classList.add('navbar-transition')
    }
  } else {
    navbarDs.value.classList.remove('bg-white')
    navbarDs.value.classList.remove('!border-b-gray-200')
    isTransparent.value = props.transparent
  }
}

watch(isMobileNavActive, (val) => {
  const progress = window.scrollY
  if (!navbarDs.value) return

  if (val) {
    navbarDs.value.classList.add('bg-white')
    navbarDs.value.classList.add('z-50')

    document.body.classList.add('overflow-y-hidden')
  } else {
    navbarDs.value.classList.remove('z-50')
    if (progress > 0) {
      navbarDs.value.classList.add('bg-white')
    } else {
      navbarDs.value.classList.remove('bg-white')
    }
    document.body.classList.remove('overflow-y-hidden')
  }
}, { immediate: true })

watch(isScreenSmallerThanLarge, (isSmaller) => {
  nextTick(() => {
    if (!isSmaller) isMobileNavActive.value = false
  })
})

const { width } = useScreenSize()
watch(width, () => {
  nextTick(() => {
    if (width.value >= 1024) isMobileNavActive.value = false
  })
})
const { translate } = useI18n({ prefix: 'claim_widget__' })
</script>

<style lang="scss">
.colored-item {
  background: linear-gradient(90deg, #48EFCA 0%, #7F41B9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  &:hover {
    background: linear-gradient(90deg, #48EFCA 0%, #411966 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.colored-item-transparent {
  background: linear-gradient(90deg, #FFFFFF 0%, #E6FFF9 16.67%, #48EFCA 53.65%, #CA91FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  &:hover {
    background: linear-gradient(90deg, #FFFFFF 0%, #E6FFF9 16.67%, #48EFCA 53.65%, #CA91FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.connect_btn {
  &:hover {
    filter: drop-shadow(0px 0px 6px rgba(76, 230, 202, 0.25));

    @apply border-transparent
  }
}

.menu-slide-enter-active {
  @apply ease-in duration-300;
}

.menu-slide-leave-active {
  @apply ease-in-out duration-300;
}

.menu-slide-enter-from,
.menu-slide-leave-to {
  @apply translate-x-full;
}

.navbar-transition {
  transition: all 0.15s ease-in-out, border-bottom-color 0.01ms linear, background-color 0.01ms linear, visibility 0.01ms linear;
}

@keyframes moveToTop {
  0% {
    margin-top: 56px;
  }
  100% {
    margin-top: 0;
  }
}

.navbar-move {
  animation: moveToTop 0.3s;
}

</style>
