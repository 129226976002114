import { useRouter } from 'vue-router'
import { CreatorRouteName } from '@core-lib/routes'

export const useCreateUtility = () => {
  const router = useRouter()

  const goToCreate = async () => {
    await router.push({
      name: CreatorRouteName.TEMPLATE,
    })
  }

  return {
    goToCreate,
    templatesDisabled: false,
  }
}