<template>
  <div class="pointer-events-none">
    <div
      id="base-layout"
      class="w-full overflow-auto min-h-screen h-full scroll-smooth flex flex-col justify-between"
    >
      <div class="flex-1 flex flex-col">
        <slot />
      </div>
    </div>
    <footer />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

.back-to-top {
  color: black !important;
  transition: z-index 0.5s step-start, opacity 0.5s linear;

  @media (max-width: 639px) {
    display: none;
  }
}

.back-to-top.button-hidden {
  z-index: -1;
  opacity: 0;
  transition: z-index 0.5s step-end, opacity 0.5s linear;
}
</style>
