import { useQuery } from '@tanstack/vue-query'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { computed } from 'vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { userApiClient } from '@core-lib/webapp-services/client'
import { LoyaltyProgramIdentifier } from '@core-lib/openapi/client'

type ThisUserPublicUser = {
  id: string
  bio: string
  createdAt: string
  username: string
  walletAddress: string
  walletAddresses: string[]
  avatar: string
  isCertified: boolean
  socialHandles: {
    twitter: string
  }
  firstName: string
  lastName: string
  email: string
  avatarIdentifier: string
  fullName: string
}

export const useThisUserPrivateFetcher = () => {
  const { data: user, isFetched, refetch } = useQuery({
    queryKey: ['thisPrivateUser', ServiceContainer.authFacade.userId],
    queryFn: async () => {
      const response = await ServiceContainer.defaultOpenApi.getUserPrivateProfile()
      return response.data
    },
    enabled: authFacade.hasSession,
  })

  return {
    user,
    isFetched,
    refetch,
  }
}

export const useThisUserPublicProfileFetcher = () => {
  const { data: user, isFetched, refetch } = useQuery({
    queryKey: ['thisUser', ServiceContainer.authFacade.userId],
    queryFn: async (): Promise<ThisUserPublicUser> => {
      const response = await ServiceContainer.defaultOpenApi.getUserPrivateProfile()
      return {
        id: response.data.id,
        bio: response.data.bio,
        createdAt: new Date().toISOString(),
        username: response.data.username,
        walletAddress: response.data.walletAddress,
        avatar: response.data.avatar,
        isCertified: response.data.isVerified,
        socialHandles: {
          twitter: response.data.socialHandles.twitter,
        },
        walletAddresses: response.data.web3Wallets.map(w => w.walletAddress),
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        avatarIdentifier: response.data.id.slice(-4),
        fullName: response.data.fullName,
      }
    },
    enabled: ServiceContainer.authFacade.isAuthenticated,
  })

  return {
    user: user,
    mustUser: computed(() => {
      if (!user.value) throw new Error('Tried to access mustUser before it is ready')
      return user.value
    }),
    isFetched,
    refetch,
  }
}

export const useValidateEmailVerificationCode = () => {
  const requestEmailVerificationCode = async (email: string, redirectUri: string, isForProfile?: boolean)=> {
    return await ServiceContainer.apiService.post(`/social/email/request-code`, {
      email,
      redirectUri,
      isForProfile,
    })
  }

  const validateEmailVerificationCode = async (code: string) => {
    return await ServiceContainer.apiService.post(`/social/email/verify-code`, {
      code,
    })
  }

  return { requestEmailVerificationCode, validateEmailVerificationCode }
}

export const useGetUserLoyaltyPoints = (identifier: LoyaltyProgramIdentifier) => { //useGetOrganizationUserPoints
  const { data, refetch, isFetched } = useQuery({
    queryKey: ['user-loyalty-points', authFacade.userIdentifier, identifier],
    queryFn: async () => {
      const response = await userApiClient.getUserLoyaltyPoints({ identifier })
      return {
        availablePoints: response.data.availablePoints,
        totalPoints: response.data.totalPoints,
      }
    },
    enabled: authFacade.isAuthenticated,
    initialData: () => ({ availablePoints: 0, totalPoints: 0 }),
  })

  return {
    availablePoints: computed(() => data.value.availablePoints),
    totalPoints: computed(() => data.value.totalPoints),
    refetch,
    isFetched,
  }
}