<template>
  <span
    ref="wrapper"
    class="relative flex items-center"
    :style="{fontSize: `${fontSizeInPx}px`, fontWeight: fontWeight}"
  >
    <span
      ref="numberContainer"
      class="ds-animated-number relative flex w-0 overflow-hidden transition-width duration-500"
      :class="numberContainerClass"
      :style="{height: containerHeight + 'px'}"
    />
    <span v-if="label">&nbsp;{{ label }}</span>
  </span>
</template>

<script setup lang="ts">

import { nextTick, ref, watch } from 'vue'

const props = withDefaults(defineProps<{
  number: number,
  fontSizeInPx: number
  fontWeight?: number
  label?: string
  numberContainerClass?: string
  containerHeight: number
}>(), {
  label: '',
  fontWeight: 400,
  numberContainerClass: '',
})

const digitWidth = ref(0)
const displayNumber = ref(props.number)
const wrapper = ref<HTMLElement | null>(null)
const numberContainer = ref<HTMLElement | null>(null)

const fontDimensionMapping: Record<number, Record<number, number>> = {
  14: { 600: 9.33 },
} //font size -> font weight -> digit width

watch(() => props.number, (number) => {
  update(number)
}, { immediate: true })

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop)
}

function getCanvasFont() {
  if (!wrapper.value) return ''
  const fontWeight =  props.fontWeight || getCssStyle(wrapper.value as HTMLElement, 'font-weight') || '600'
  const fontSize = props.fontSizeInPx + 'px'
  const fontFamily = getCssStyle(wrapper.value as HTMLElement, 'font-family') || 'Inter'
  console.log('measure', `${fontWeight} ${fontSize} ${fontFamily}`)
  return `${fontWeight} ${fontSize} ${fontFamily}`
}

function measureDigitWidth() {
  if (fontDimensionMapping[props.fontSizeInPx] && fontDimensionMapping[props.fontSizeInPx][props.fontWeight]) {
    return fontDimensionMapping[props.fontSizeInPx][props.fontWeight]
  }

  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  if (!context) return 0
  context.font = getCanvasFont()
  const digitToMeasure = context.measureText('0')
  return digitToMeasure.width
}

function scrollNumber(digits: string[]) {
  if (!numberContainer.value) return
  // eslint-disable-next-line no-undef
  const innerElements = numberContainer.value.querySelectorAll('span[data-value]') as NodeListOf<HTMLElement>
  let containerNewWidth = 0
  innerElements.forEach((tick, i) => {
    const digit = parseInt(digits[i])
    tick.style.transform = `translateY(-${100 * digit}%)`
    const elementWidth = measureDigitWidth()
    tick.style.flexBasis = `${elementWidth}px`
    containerNewWidth+= elementWidth
  })

  setTimeout(() => {
    if (!numberContainer.value) return
    numberContainer.value.style.width = `${containerNewWidth}px`
  }, 50)
}

function addDigit(digit: string, fresh?: boolean) {
  const spanList = Array(10)
    .join('0')
    .split('0')
    .map((x, j) => `<span style="min-height:${props.containerHeight}px;display:flex;align-items:center;justify-content:center;">${j}</span>`)
    .join('')

  if (!numberContainer.value) return

  numberContainer.value.insertAdjacentHTML(
    'beforeend',
    `<span class="transition-all duration-150" style="transform: translateY(-1000%); flex-basis: ${digitWidth.value}px; height: ${props.containerHeight}px;" data-value="${digit}">
        ${spanList}
      </span>`)

  const firstDigit = numberContainer.value.lastElementChild as HTMLElement

  setTimeout(() => {
    firstDigit.className = 'visible'
  }, fresh ? 0 : 100)
}

function removeDigit() {
  if (!numberContainer.value) return
  const firstDigit = numberContainer.value.lastElementChild as HTMLElement
  firstDigit.classList.remove('visible')
  firstDigit.remove()
}

function init() {
  const digits = props.number.toString().split('')

  for (let i = 0; i < digits.length; i++) {
    addDigit(digits[0], true)
  }
  scrollNumber(digits)
}

function update(num: number) {
  const toDigits = num.toString().split('')
  const fromDigits = displayNumber.value.toString().split('')

  for (let i = fromDigits.length - toDigits.length; i > 0; i--) {
    removeDigit()
  }
  for (let i = toDigits.length - fromDigits.length; i > 0; i--) {
    addDigit(toDigits[i])
  }

  scrollNumber(toDigits)
  displayNumber.value = num
}

watch(numberContainer, numberContainer => {
  if (!numberContainer) return
  nextTick(() => {
    digitWidth.value = measureDigitWidth()
    init()
  })
})

</script>

<style lang="scss">
.ds-animated-number > span {
  display: flex;
  text-align: center;
  flex-direction: column;
  opacity: 0;
  flex-shrink: 2;
  width: 1px;

  position: absolute;
  right: 0;

  line-height: inherit;
  transition: all 1s ease;
}

.ds-animated-number > span.visible {
  position: static;
  opacity: 1;
  flex-shrink: 1;
}

.ds-animated-number-fade-up {
  background: linear-gradient(180deg, rgba(156, 163, 175, 0.00) 34.9%, #9CA3AF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ds-animated-number-fade-down {
  background: linear-gradient(180deg, #9CA3AF 0%, rgba(156, 163, 175, 0.00) 61.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.dark .ds-animated-number {
  background: transparent !important;
}
</style>