<template>
  <div
    id="announcement"
    ref="announcementSection"
    class="h-14 max-h-[56px] w-full transition-[margin] relative z-[39]"
  >
    <div
      class="flex max-h-[56px] items-center px-4 lg:px-8 w-full"
      :class="[bgColor, textColor]"
    >
      <Vue3Marquee
        :duration="duration"
        pause-on-hover
        :pause="pause"
        :pause-on-click="pause"
        class="flex-1 max-h-[56px] w-full z-[32] py-[18px] font-medium text-sm truncate whitespace-nowrap mr-4"
        :class="[bgColor, textColor, clickable ? 'cursor-pointer': 'hover:cursor-default']"
      >
        <div
          class="text-center w-full max-h-[56px] mx-1"
          v-html="content"
        />
      </Vue3Marquee>

      <DsIconButton
        class="announcement-close z-[33]"
        :class="{ '!text-ds-dark-primary': closeDark }"
        :style-type="closeDark ? 'transparent-light': 'primary'"
        icon-size="small"
        :mobile-size="true"
        icon="cross-small"
        @click.stop.prevent="$emit('close')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Vue3Marquee } from 'vue3-marquee'
import DsIconButton from '@core-design/components/Design/DsIconButton.vue'
import { Nullable } from '@core-lib/models/common'
import { ref, Ref } from 'vue'

withDefaults(defineProps<{
  duration: number
  content: string
  bgColor?: string
  textColor?: string
  clickable?: boolean
  closeDark?: boolean
  pause?: boolean
}>(), {
  bgColor: 'bg-white',
  textColor: 'text-gray-500 hover:text-ds-dark-primary',
  clickable: false,
  closeDark: false,
  pause: false,
})

defineEmits<{
  (e: 'close'): void
}>()

const announcementSection: Ref<Nullable<HTMLElement>> = ref(null)

defineExpose({
  announcementSection,
})

</script>

<style scoped>

</style>