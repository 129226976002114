<template>
  <BaseModal
    :show="show"
    :close-button="true"
    close-button-position-class="top-[26px] md:top-[24px] right-[26px]"
    without-padding
    custom-size="mobile-announcement-modal md:w-[568px] md:px-[26px] md:max-h-[603px] md:py-6"
    container-class="announcement-modal-container"
    content-class="h-full"
    shadow-class="shadow-[0_0_30px_rgba(27,32,50,0.15)]"
    @hide="$emit('hide')"
  >
    <div
      v-if="announcement"
      class="flex flex-col text-ds-dark-primary h-full"
    >
      <div class="flex items-center gap-x-1.5 md:px-3 md:py-1.5">
        <img
          width="18"
          height="18"
          src="../assets/images/tropee-logo-rounded.svg"
          alt="logo"
        >
        <div class="font-semibold text-lg">
          {{ announcement.title }}
        </div>
      </div>
      <DsCarousel
        class="overflow-x-hidden flex-1 mt-1.5 md:mt-0.5 group"
        :fix-width="568"
        :per-view="{ default: 1}"
        :spacing="{ default: 24}"
        show-dots
        :chevrons-on-hover="true"
        :force-show-chevrons="announcement.posts.length > 1"
        :recheck-chevrons="recheckCarouselChevrons"
        left-chevron-class="hidden group-hover:flex left-[6px]"
        right-chevron-class="hidden group-hover:flex right-[6px]"
      >
        <div
          v-for="(post, index) in announcement.posts"
          :key="index"
          class="flex items-center keen-slider__slide basis-auto min-w-fit w-full"
        >
          <div class="w-full flex-1 relative h-full">
            <div
              class="border border-gray-50 bg-white shadow-[0_0_5px_rgba(31,41,55,0.1)] rounded-3xl flex flex-col overflow-hidden h-full"
            >
              <div class="flex items-center justify-center overflow-hidden bg-gray-100 px-6">
                <img
                  :src="post.mediaUrl"
                  class="w-full h-[137px] md:h-[242px] m-6 rounded-lg object-cover"
                >
              </div>
              <div class="my-4 px-6">
                <div class="font-semibold text-[16px] leading-6 mb-1">
                  {{ post.title }}
                </div>
                <div
                  class="!text-sm !text-ds-dark-primary font-normal"
                  v-html="post.description"
                />
              </div>
            </div>
          </div>
        </div>
      </DsCarousel>
      <div
        v-if="announcement.secondaryAction || announcement.primaryAction"
        class="mt-6 lg:mt-4 flex gap-x-3"
      >
        <div
          v-if="announcement.secondaryAction"
          class="flex-1"
        >
          <DsButton
            style-type="secondary"
            :left-icon="announcement.secondaryAction.icon"
            class="w-full"
            @click="openActionUrl(announcement?.secondaryAction?.url)"
          >
            {{ announcement.secondaryAction.label }}
          </DsButton>
        </div>
        <div
          v-if="announcement.primaryAction"
          class="flex-1"
        >
          <DsButton
            style-type="primary"
            :left-icon="announcement.primaryAction.icon"
            class="w-full"
            @click="openActionUrl(announcement?.primaryAction?.url)"
          >
            {{ announcement.primaryAction.label }}
          </DsButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@core-lib/components/BaseModal.vue'
import DsCarousel from '@core-design/components/Design/DsCarousel.vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import { AnnouncementModalContent } from '@core-lib/ui-helper/announcement-modal-content'
import { Maybe } from '@core-lib/models/common'
import { ref, watch } from 'vue'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'

const props = defineProps<{
  show: boolean,
  announcement: Maybe<AnnouncementModalContent>
}>()

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const recheckCarouselChevrons = ref(0)
watch(() => props.show, show => {
  setTimeout(() => {
    if (show) {
      TropeeAnalyticsFactory.instance().track({ name: 'announcement_modal.showed' })
      if (props.announcement?.posts && props.announcement?.posts.length > 1) {
        recheckCarouselChevrons.value++
      }
    }
  }, 1000)
}, { immediate: true })
const openActionUrl = (url?: string) => {
  TropeeAnalyticsFactory.instance().track({ name: 'announcement_modal.primary_action_clicked' })
  emit('hide')
  if (!url) return
  window.open(url, '_blank')
}
</script>

<style lang="scss">
.announcement-modal-container {
  @apply max-md:p-0 max-md:flex-wrap max-md:content-end max-md:w-full max-md:h-full md:min-h-screen #{!important};
}

.mobile-announcement-modal {
  @apply max-md:mb-0 max-md:px-8 max-md:pt-6 max-md:pb-5 max-md:max-h-[575px] #{!important};
}

.announcement-modal-container {
  .slider-wrapper {
    margin-left: -10px !important;
    width: calc(100% + 20px) !important;
  }

  .keen-slider {
    @apply max-md:max-h-full #{!important};
  }

  .keen-slider__slide {
    padding: 10px;
    overflow: hidden;
  }
}
</style>