<template>
  <BaseModal
    :close-button="true"
    :show="showSocialLoginModal"
    close-button-position-class="right-4 top-4 md:top-6 md:right-6"
    without-padding
    custom-size="mobile-login-container md:w-[476px] md:p-12"
    shadow-class="md:shadow-[0px_0px_30px_rgba(27,32,50,0.15)]"
    wrapper-class="login-in-progress-modal"
    @hide="showSocialLoginModal = false"
  >
    <div class="h-full">
      <div class="text-center text-ds-dark-primary font-semibold text-2xl mb-1 md:mb-2">
        Logging you in
      </div>
      <div class="text-center text-gray-500 font-medium text-sm md:text-[16px] md:leading-6 mb-[30px] md:mb-8">
        Please wait a moment...
      </div>
      <div class="m-auto flex items-center justify-center w-12 h-12">
        <DsIcon
          icon="c_LoadingSpinner"
          class="spinner-dark"
          size="w-8 h-8"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from '@core-lib/components/BaseModal.vue'
import { useSocialLoginModalController } from '@core-lib/composables/login-modal'
import DsIcon from '@core-design/components/Design/DsIcon.vue'

const { showSocialLoginModal } = useSocialLoginModalController()
</script>

<style lang="scss">
.login-in-progress-modal {
  .base-modal-container {
    @apply max-md:flex-wrap max-md:p-0 max-md:content-end max-md:w-full h-full #{!important};
  }

  .mobile-login-container {
    @apply max-md:mb-0 max-md:rounded-t-[32px] max-md:rounded-b-none max-md:p-8 #{!important};
  }
}
</style>