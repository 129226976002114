import apiService from '@/services/api-service'

class UtilityCampaignService {
  public async requestReview(utilityCampaignId: string) {
    await apiService.post(`/creator/utility-campaign/${utilityCampaignId}/request-review`, {})
  }

  public async getVersion(id: string) {
    const response = await apiService.get<{ version: number }>(`/holder/utility-campaign/${id}/version`)

    return response.version
  }

  public async getCreatorsPublishedIds() {
    const response = await apiService.get<{ tropeeIds: string[] }>(`/creator/utility-campaign/published-ids`)

    return response.tropeeIds
  }
}

export default new UtilityCampaignService()