<template>
  <div class="mx-auto inline-flex items-center justify-center py-8">
    <button
      v-if="showButton"
      class="max-w-fit flex items-center gap-x-2 px-4 h-12 text-ds-dark-primary rounded-full cursor-pointer bg-white/[.12] border border-white/[.16] hover:border-[#d1d5db59] active:border-gray-300 shadow-[0_10px_40px_0_rgba(0,0,0,0.05),0_4px_5px_0_rgba(0,0,0,0.06),0_0_2px_0_rgba(0,0,0,0.15)] dark:border-transparent hover:dark:border-transparent active:dark:border-transparent dark:bg-white/[.78] md:hover:dark:bg-white/[.94] active:dark:bg-white/[.94]"
      @click="isDisabled ? '': goToLandingPageNewTab(`utm_source=tropee&utm_medium=page&utm_campaign=footer_logo_cta&utm_content=${organizationSlug || 'page_not_found'}`)"
    >
      <img
        class="h-5 w-5 min-w-[20px]"
        src="@core-design/assets/images/tropee-logo-transparent-dark.svg"
        alt="Tropee"
      >
      <span class="text-[16px] leading-6 font-semibold whitespace-nowrap">{{ translate('create_your_tropee') }}</span>
    </button>
  </div>
</template>
<script setup lang="ts">

import { useGoToLandingPage } from '@core-lib/composables/go-to-landing-page'
import { useI18n } from '@core-lib/composables/i18n'

const { goToLandingPageNewTab } = useGoToLandingPage()
const { translate } = useI18n({ prefix: 'tropee_page__' })

withDefaults(defineProps<{
  organizationSlug?: string
  isDisabled?: boolean
  showButton?: boolean
}>(), {
  organizationSlug: '',
  isDisabled: false,
  showButton: true,
})
</script>
<style scoped>

</style>
