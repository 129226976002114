<template>
  <div class="ds-footer w-full bg-white">
    <div class="tropee-full-screen bg-white">
      <div
        v-if="showExtended"
        class="mx-auto lg:max-w-[930px] xl:max-w-[1105px] 2xl:max-w-[1301px] 3xl:max-w-[1467px] py-4 sm:pt-5 md:pb-18"
      >
        <div
          class="px-4 md:px-6 lg:px-5 2xl:px-0 mx-auto footer-grid grid grid-cols-1 md:grid-cols-footer-desktop lg:grid-cols-lg-footer-desktop xl:grid-cols-xl-footer-desktop 2xl:grid-cols-2xl-footer-desktop"
        >
          <div class="mt-10 sm:mt-0 md:mr-0">
            <div class="footer-heading">
              {{ subscribed ? 'Thanks for signing up!' : 'Stay in the loop' }}
            </div>
            <div class="mt-4 md:mt-3">
              <DsInput
                placeholder="Your email address"
                width-class="w-full md:w-40 lg:w-64 xl:w-78"
                trailing-icon="arrow-small-right"
                :disabled="subscribed"
                :trailing-icon-clickable="true"
                @trailing-icon-click="subscribe"
                @enter="subscribe"
                v-model="subscriptionEmail"
                :has-error="showError && !isEmailValid"
                :helper-text="!showError || isEmailValid ? '' : 'Please provide a valid email'"
              />
            </div>
            <div class="hidden mt-5 md:flex items-center">
              <DsIcon
                class="mr-2 cursor-pointer"
                color-class="text-ds-dark-primary"
                icon="c_TropeeLogo"
                size="h-[38px] w-[126px]"
                @click="goToHomepage"
              />
            </div>
          </div>
          <div class="mt-10 sm:mt-7 md:mt-0">
            <div class="footer-heading">
              Explore
            </div>
            <ul
              role="list"
              class="mt-6 space-y-3"
            >
              <li
                v-for="item in navigation.explore"
                :key="item.name"
              >
                <a
                  :href="item.href"
                  :target="item.href.startsWith('https://') ? '_blank' : '_self'"
                  class="footer-link"
                >{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="mt-8 sm:mt-4 md:mt-0 md:pl-2 lg:pl-6 2xl:pl-2">
            <div class="footer-heading">
              About
            </div>
            <ul
              role="list"
              class="mt-6 space-y-3"
            >
              <li
                v-for="item in navigation.about"
                :key="item.name"
              >
                <a
                  :href="item.href"
                  :target="item.href.startsWith('https://') ? '_blank' : '_self'"
                  class="footer-link"
                >{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="mt-8 sm:mt-4 md:mt-0 md:pl-4 lg:pl-12 2xl:pl-5">
            <div class="footer-heading">
              Connect
            </div>
            <ul
              role="list"
              class="mt-6 space-y-3"
            >
              <li
                v-for="item in navigation.connect"
                :key="item.name"
              >
                <a
                  :href="item.href"
                  :target="item.href.startsWith('https://') ? '_blank' : '_self'"
                  class="footer-link"
                >{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr
        class="mx-auto text-gray-200 tropee-full-screen"
        :class="{'mt-6 md:mt-0': showExtended}"
      >
      <div class="mt-11 mb-10 flex gap-7 justify-center">
        <a
          v-for="item in navigation.other"
          :key="item.name"
          :href="item.href"
          :target="item.href.startsWith('https://') ? '_blank' : '_self'"
          class="text-xs leading-6 text-gray-500 font-medium hover:text-ds-dark-primary"
          v-html="item.name"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DsInput from './DsInput.vue'
import DsIcon from './DsIcon.vue'
import { computed, ref } from 'vue'
import { z } from 'zod'
import { useCreateUtility } from '@core-lib/composables/create-utility'

const emit = defineEmits<{
  (e: 'emailSubscriptionSubmit', email: string): void
}>()

withDefaults(
  defineProps<{
    showExtended?: boolean;
    subscribed?: boolean
  }>(),
  {
    showExtended: false,
    subscribed: false,
  },
)

const { templatesDisabled } = useCreateUtility()
const navigation = {
  explore: [
    { name: 'Blog', href: 'https://www.tropee.com/blog' },
    { name: 'Made with Tropee', href: 'https://www.tropee.com/made-with-tropee' },
    ...(templatesDisabled ? [] : [{ name: 'Template directory', href: '/creator/templates' }]),
  ],
  about: [
    { name: 'Careers', href: 'https://tropee.notion.site/Careers-with-Tropee-01090e6f87ab4f87a6931e99e8b9a6b0' },
    {
      name: 'Partnerships',
      href: 'https://tropee.notion.site/tropee/Partner-with-Tropee-272c146a33ae4a8dbda794c17593860f',
    },
    { name: 'FAQ', href: 'https://tropee.notion.site/Tropee-FAQ-42efa1022c514c7095a251f966321f9d' },
  ],
  connect: [
    { name: 'X', href: 'https://twitter.com/TropeeHQ' },
    { name: 'Discord', href: 'https://discord.com/invite/z6x6yRskNQ' },
    { name: 'LinkedIn', href: 'https://www.linkedin.com/company/tropee/' },
  ],
  other: [
    { name: '&copy Tropee', href: 'https://www.tropee.com' },
    { name: 'Privacy', href: 'https://www.tropee.com/privacy-policy' },
    { name: 'Terms of Use', href: 'https://www.tropee.com/terms' },
    { name: 'Help center', href: 'https://tropee.notion.site/Tropee-FAQ-42efa1022c514c7095a251f966321f9d' },
  ],
}

const subscriptionEmail = ref('')
const emailSchema = z.string().email()
const isEmailValid = computed(() => emailSchema.safeParse(subscriptionEmail.value).success)
const showError = ref(false)
const subscribe = () => {
  if (!isEmailValid.value) {
    showError.value = true
    return
  }
  emit('emailSubscriptionSubmit', subscriptionEmail.value)
}
const goToHomepage = () => window.location.href = '/'
</script>

<style scoped>
.footer-heading {
  @apply text-[1rem] font-semibold leading-6 text-gray-900
}

.footer-link {
  @apply text-sm leading-6 text-gray-500 font-medium hover:text-ds-dark-primary
}

</style>
