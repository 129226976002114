<template>
  <svg
    class="spinner"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM2.24651 9C2.24651 12.7298 5.27015 15.7535 9 15.7535C12.7298 15.7535 15.7535 12.7298 15.7535 9C15.7535 5.27015 12.7298 2.24651 9 2.24651C5.27015 2.24651 2.24651 5.27015 2.24651 9Z"
      class="circle"
    />
    <path
      d="M3.56296 1.82793C4.69479 0.969905 6.01362 0.391936 7.41162 0.141273C8.80962 -0.10939 10.2471 -0.0256295 11.6065 0.385706C12.9659 0.797042 14.2087 1.52427 15.2332 2.50794C16.2577 3.49161 17.0349 4.70379 17.5012 6.04536L15.3759 6.78402C15.0262 5.77784 14.4433 4.86871 13.6749 4.13096C12.9065 3.39321 11.9745 2.84778 10.9549 2.53928C9.93532 2.23078 8.85721 2.16796 7.80871 2.35595C6.76021 2.54395 5.77109 2.97743 4.92222 3.62094L3.56296 1.82793Z"
      class="circle-spinner"
    />
  </svg>
</template>

<style lang="scss" scoped>
.spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle {
  fill: rgba(#1D1D1B, 0.2);
}

.circle-spinner {
  fill: #ffffff;
}

.spinner-dark {
  .circle {
    fill: #D1D5DB;
  }

  .circle-spinner {
    fill: #1D1D1B;
  }
}

.spinner-danger {
  .circle-spinner {
    fill: #EF4444;
  }
}

.spinner-gray {
  .circle-spinner {
    fill: #9CA3AF;
  }
}

.admin-primary {
  .circle {
    @apply fill-ly-pink-darker;
  }

  .circle-spinner {
    @apply fill-white;
  }

  &.destructive {
    .circle {
      @apply fill-red-700;
    }

    .circle-spinner {
      @apply fill-white;
    }
  }
}

.admin-secondary, .admin-tertiary {
  .circle {
    @apply fill-stone-300;
  }

  .circle-spinner {
    @apply fill-ds-dark-primary;
  }

  &.destructive {
    .circle {
      @apply fill-red-100;
    }

    .circle-spinner {
      @apply fill-red-500;
    }
  }
}

.admin-dark-mode {
  .circle {
    @apply fill-stone-400;
  }

  .circle-spinner {
    @apply fill-white;
  }
}

</style>
