import { computed, Ref, ref } from 'vue'

const useProfileImageSetter = () => {

  const selectedFileUploader = ref()

  const showFileSelectModal = ref(false)
  const avatarFileSelect = ref()
  const bannerFileSelect = ref()

  const fileSelectModalTitle = computed(() => {
    if (!selectedFileUploader.value) return ''
    return (selectedFileUploader.value === avatarFileSelect.value) ? 'Update profile image' : 'Update cover image'
  })

  const fileSelectModalDescription = computed(() => {
    if (!selectedFileUploader.value) return ''
    return (selectedFileUploader.value === avatarFileSelect.value) ? 'Recommended: 180px x 180px. Max 15mb in JPG, GIF or PNG.' : 'Recommended: 1420px x 280px. Max 100mb in JPG, GIF or PNG.'
  })

  const onRequestFileSelect = (fileUploader : Ref) => {
    selectedFileUploader.value = fileUploader
    showFileSelectModal.value = true
  }
  const onModalFileSelectClick = () => {
    showFileSelectModal.value = false
    selectedFileUploader.value.openUploadDialog()
  }

  return {
    fileSelectModalTitle,
    fileSelectModalDescription,
    showFileSelectModal,
    avatarFileSelect,
    bannerFileSelect,
    onRequestFileSelect,
    onModalFileSelectClick,
  }
}

export { useProfileImageSetter }
