import { ActionEvent, FlowEvent, GapEvent } from '@core-lib/webapp-services/analytics/events/base'

abstract class CreatorOnboardingFlow extends FlowEvent {
  protected flow = 'admin_onboarding'
}

// Whenever next button is clicked and backend responded with success
export class CreatorOnboardingFlowPassed extends CreatorOnboardingFlow {
  protected step

  constructor({ question, skipped }: { question: string, skipped: boolean }) {
    super({ skipped })
    this.step = `passed_${question}`
  }
}

export class CreatorClickedShareEvent extends ActionEvent {
  public action = 'creator_clicked_share'

  constructor(type: 'task' | 'reward' | 'tropee', location: string) {
    super({ type, location })
  }
}

// Only when task goes from draft to live
export class CreatorPublishedTaskEvent extends ActionEvent {
  public action = 'creator_published_task'
}

// Only when reward goes from draft to live
export class CreatorPublishedRewardEvent extends ActionEvent {
  public action = 'creator_published_reward'

  constructor(payload: { dropMethod: string, rewardType: string }) {
    super(payload)
  }
}

export class CreatorCreatedTaskEvent extends ActionEvent {
  public action = 'creator_created_task'

  constructor(payload: { templateId: string }) {
    super(payload)
  }
}

export class CreatorCreatedRewardEvent extends ActionEvent {
  public action = 'creator_created_reward'

  constructor(payload: { templateId: string }) {
    super(payload)
  }
}

export class CreatorOnboardingV2FlowPassed extends FlowEvent {
  protected flow = 'admin_onboarding_v2'
  protected step

  constructor({ step, skipped }: { step: string, skipped: boolean }) {
    super({ skipped })
    this.step = `passed_${step}`
  }
}

export class CreatorMemberPartialSearchEvent extends ActionEvent implements GapEvent {
  public gapSeconds = 24 * 3600
  public action = 'creator_member_partial_search'
}

export class CreatorMemberActionsColumnSortEvent extends ActionEvent implements GapEvent {
  public gapSeconds = 24 * 3600
  public action = 'creator_member_actions_column_sort'
}

export class CreatorMemberRewardColumnSortEvent extends ActionEvent implements GapEvent {
  public gapSeconds = 24 * 3600
  public action = 'creator_member_reward_column_sort'
}
