import { useStorage } from '@vueuse/core'

export enum ActionToTrack {
  SIGNUP_SOURCE = 'signup_source',
}

export const useTrackLastAction = (actionToTrack: ActionToTrack) => {
  const storage = useStorage<string>(`last_action_tracking.${actionToTrack}`, null)

  return {
    setLastAction: (action: string) => storage.value = action,
    getLastAction: () => {
      const value = storage.value
      storage.value = null
      return value || undefined
    },
  }
}
