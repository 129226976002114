import { ApiService } from '@core-lib/webapp-services/service-container/services/api-service'
import { CreatorApi, DefaultApi, UserApi, UtilityApi, UtilityTransactionApi } from '@core-lib/openapi/client'
import { AuthService } from '@core-lib/webapp-services/service-container/services/auth-service'
import { Router } from 'vue-router'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { embedConfig } from '@core-lib/helpers/embed'

type Services = {
  apiService: ApiService
  authService: AuthService
  router: Router
  openApi: {
    default: DefaultApi
    utilityTransaction: UtilityTransactionApi
    creator: CreatorApi
    user: UserApi
    utility: UtilityApi
  }
}

type Env = {
  telegramBotId: number
  isSsr: boolean
  apiBaseUrl: string
  appName: string
  env: string
  isCreatorApp: boolean
  ethereumMainnetRpcUrl: string
  walletConnectProjectId: string
  pusher: {
    appKey: string
  }
  crossmint: {
    apiKey: string
  }
  magic: {
    publicApiKey: string
  }
  analytics: {
    segmentWriteKey: string
  }
}

export class ServiceContainer {
  private static services: Services
  public static env: Env

  public static setServices(services: Services) {
    this.services = services
  }

  public static get apiService() {
    return this.services.apiService
  }

  public static get isEmbed() {
    return window.location.host.startsWith('embed.') || window.location.host.endsWith('.embed.preview.tropee.com') || window.location.host.startsWith('moein.')
  }

  public static get isCustomDomain() {
    return !window.location.host.endsWith('tropee.com')
  }

  public static get embedConfig() {
    return embedConfig
  }

  public static get router() {
    return this.services.router
  }

  public static get authService() {
    return this.services.authService
  }

  public static get authFacade() {
    return authFacade
  }

  public static get defaultOpenApi() {
    return this.services.openApi.default
  }

  public static get utilityTransactionOpenApi() {
    return this.services.openApi.utilityTransaction
  }

  public static get creatorOpenApi() {
    return this.services.openApi.creator
  }

  public static get userOpenApi() {
    return this.services.openApi.user
  }

  public static get utilityOpenApi() {
    return this.services.openApi.utility
  }

  public static get isProd() {
    return this.env.env === 'prod'
  }

  public static get isLocal() {
    return this.env.env === 'local'
  }
}
