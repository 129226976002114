import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

export const useProdConnect = () => {
  const apiBaseUrl = useStorage('api_base_url', '')

  return {
    apiBaseUrl,
    isConnected: computed(() => !!apiBaseUrl.value),
  }
}