import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'

type UpdateUserProfileInput = {
  username?: string
  email?: string
  bio?: string
  avatar?: string
  banner?: string
  website?: string
  termsAccepted?: true
  firstName?: string
  lastName?: string
  fullName?: string
}

class UserProfileService {
  async updateUserProfileV2(payload: UpdateUserProfileInput) {
    return await ServiceContainer.apiService.put(`/user/private/profile-v2`, payload)
  }

  async validateEmailVerificationCode(code: string) {
    await ServiceContainer.apiService.post(`/user/private/email/validate-code`, {
      code,
    })
  }
}

export default new UserProfileService()
