<template>
  <DsMenu
    v-if="userIsFetched"
    :menu-items="menuItems"
    :container="menuContainer"
    :right-position="-87"
    :triggers="isScreenSmallerThanLarge ? ['hover', 'click'] : ['hover']"
    :key="userIsCertified.toString()"
    container-class="max-lg:w-[177px]"
    @menu-updated="(n) => profileHighlighted = n"
    @route-clicked="routeClicked"
  >
    <template #header>
      <div class="max-lg:h-[54px] px-3 lg:pl-[18px] lg:pr-4 lg:py-4 mb-2 border rounded-lg border-gray-100 flex items-center relative group">
        <DsAvatar
          :avatar="userAvatar || ''"
          :wallet-address="userId || ''"
          class="w-9 h-9 min-w-[36px] lg:w-10 lg:h-10 lg:min-w-[40px] rounded-full mr-3 object-cover"
        />
        <div class="text-[1rem] text-ds-dark-primary font-semibold min-w-0">
          <div class="truncate">
            {{ user.firstName || user.email }}
          </div>
        </div>
      </div>
    </template>
    <div
      class="w-8 h-8 lg:w-9 lg:h-9 relative rounded-full overflow-hidden cursor-pointer border border-transparent transition-all duration-200"
      :class="{ '!border-[#6B7280]/[.25] shadow-[0px_0px_10px_0px_rgba(29,29,27,0.25)]': profileHighlighted }"
    >
      <DsAvatar
        v-if="userIsFetched"
        :avatar="userAvatar || ''"
        :wallet-address="userId || ''"
        class="w-8 h-8 lg:w-9 lg:h-9 object-cover"
      />
    </div>
  </DsMenu>
</template>
<script setup lang="ts">

import DsMenu from '@core-design/components/Design/DsMenu.vue'
import DsAvatar from '@core-design/components/Design/DsAvatar.vue'
import { useNavigation } from '@core-lib/composables/navigation'
import { computed, ref } from 'vue'
import useIsScreenSmallerThan from '@core-lib/composables/is-screen-smaller-than'
import { MenuItem } from '@core-design/components/Design/interfaces/IMenuItem'
import { useThisUserPublicProfileFetcher } from '@core-lib/composables/user-fetcher'

const props = defineProps<{
  menuContainer: string
  app: 'holder' | 'creator'
}>()

const isScreenSmallerThanLarge = useIsScreenSmallerThan(1023)
const { mustUser: user, isFetched: userIsFetched } = useThisUserPublicProfileFetcher()
const userId = computed(() => user.value.id)
const userAvatar = computed(() => user.value.avatar)
const userIsCertified = computed(() => user.value.isCertified || false)

const { holderCurrentMenuItems, creatorCurrentMenuItems, routeClicked } = useNavigation()
const menuItems = computed<MenuItem[]>(() => props.app === 'holder' ? holderCurrentMenuItems.value : creatorCurrentMenuItems.value)
const profileHighlighted = ref(false)
</script>

<style scoped>

</style>