const externalScripts = {
  installGtm() {
    if (!import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_CODE) return
    const script = document.createElement('script')
    script.setAttribute('id', 'google_tag_manager_external_script')
    script.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\nnew Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,\'script\',\'dataLayer\',\'' + import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_CODE + '\');'

    const noScript = document.createElement('noscript')
    noScript.setAttribute('id', 'google_tag_manager_external_noscript')
    noScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_CODE + '}"\n' +
        'height="0" width="0" style="display:none;visibility:hidden"></iframe>'

    document.head.prepend(script)
    document.body.prepend(noScript)
  },
}

export default externalScripts