<template>
  <button
    :class="[
      hasIcon && !$slots.default ? 'px-5 sm:px-4 items-center': 'items-stretch',
      {'pl-4 pr-5 sm:pl-5 sm:pr-6 !items-center': leftIcon && $slots.default},
      {'pl-5 pr-4 sm:pl-6 sm:pr-5 !items-center': rightIcon && $slots.default},
      {'px-6 sm:px-9': !hasIcon && $slots.default},
      {'justify-center px-9 sm:px-12 py-[15px] sm:py-3': loading && !showTextOnLoading},
      { 'loading': loading },
      styleType,
      {
        'outline outline-1 outline-white': outline,
        'outline-none': !outline,
      },
    ]"
    :data-style="styleType"
    type="button"
    class="ds-tropee-button inline-flex justify-center rounded-full py-3 sm:py-3 text-sm font-semibold relative group"
    @click="buttonClick"
  >
    <DsIcon
      v-if="leftIcon"
      :icon="leftIcon"
      :class="[{'animate-spin': leftIconSpinning, 'mr-2': $slots.default }, iconClass ]"
      class="ds-tropee-button-icon"
      type="bold"
      :size="iconSize"
    />
    <slot
      v-if="$slots.leftIcon"
      name="leftIcon"
    />
    <span
      class="flex items-center"
      :class="[{ 'ml-5 md:ml-0': showTextOnLoading && loading && !rightIconLoading, 'relative': loading && loadingPositionedRelativeTo === 'label' }, labelWrapperClass]"
    >
      <span
        :class="{
          'absolute z-10 top-1/2 -translate-y-1/2 align-middle':
            showTextOnLoading && loading,
          '-left-5 md:-left-6': showTextOnLoading && loading && loadingPositionedRelativeTo === 'label',
          'left-3': showTextOnLoading && loading && loadingPositionedRelativeTo === 'button'
        }"
      >
        <DsIcon
          v-show="loading && !rightIconLoading"
          icon="c_LoadingSpinner"
          :class="[spinnerClass, loaderClass]"
          size="w-3.5 h-3.5 md:w-4.5 md:h-4.5"
        />
      </span>
      <slot v-if="showTextOnLoading || (loading && rightIconLoading) || !loading" />
    </span>
    <span
      v-if="rightIcon || rightIconLoading"
      class="block min-w-[16px] w-4"
      :class="[{'ml-2': $slots.default}, rightIconWrapperClass]"
    >
      <DsIcon
        :icon="rightIconLoading && loading ? 'c_LoadingSpinner' : (rightIcon || '')"
        :class="[{'animate-spin': rightIconSpinning, [spinnerClass]: loading && rightIconLoading}, iconClass]"
        class="ds-tropee-button-icon"
        type="bold"
        :size="iconSize"
      />
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import DsIcon from './DsIcon.vue'
import { IconType } from '@core-design/types/icon'

const props = withDefaults(
  defineProps<{
    styleType?:
      | 'primary'
      | 'primary-reversed'
      | 'secondary'
      | 'secondary-white'
      | 'tertiary'
      | 'danger-primary'
      | 'danger-secondary'
      | 'danger-tertiary'
      | 'text'
      | 'twitter'
      | 'transparent'
      | 'empty'
    loading?: boolean
    showTextOnLoading?: boolean
    outline?: boolean
    leftIcon?: IconType
    leftIconSpinning?: boolean
    rightIcon?: IconType
    rightIconSpinning?: boolean
    rightIconLoading?: boolean
    loadingPositionedRelativeTo?: 'button' | 'label'
    labelWrapperClass?: string
    iconClass?: string
    iconSize?: string
    rightIconWrapperClass?: string
    loaderClass?: string
  }>(),
  {
    styleType: 'primary',
    loading: false,
    showTextOnLoading: false,
    outline: false,
    leftIcon: '',
    leftIconSpinning: false,
    rightIcon: '',
    rightIconSpinning: false,
    rightIconLoading: false,
    loadingPositionedRelativeTo: 'label',
    labelWrapperClass: '',
    iconClass: '',
    iconSize: 'xs',
    rightIconWrapperClass: '',
    loaderClass: '',
  },
)

const hasIcon = computed(() => !!props.leftIcon || !!props.rightIcon)
const spinnerClass = computed(() => {
  if (['secondary', 'tertiary'].includes(props.styleType)) {
    return 'spinner-dark'
  } else if (
    ['danger-secondary', 'danger-tertiary'].includes(props.styleType)
  ) {
    return 'spinner-danger'
  }
  return ''
})

const buttonClick = (event: Event) => {
  // using try-catch to avoid event bubbling stop on any error
  try {
    const targetElement = event.currentTarget as HTMLElement
    if (targetElement) {
      targetElement.blur()
    }
  } catch (e) {
    console.log(e)
  }
}
</script>

<style lang="scss" scoped>
.ds-tropee-button {
  &[data-style="primary"] {
    @apply bg-ds-dark-primary text-white disabled:text-gray-400 disabled:bg-gray-100 border border-transparent enabled:focus:outline-none;

    &.loading {
      @apply bg-ds-dark-primary/80 cursor-default;
    }

    &:not(.loading):enabled {
      &:hover {
        @apply md:bg-ds-black-hover;
      }

      &:active, &:focus {
        @apply text-white/80 bg-ds-black-active;
      }
    }
  }

  &[data-style="primary-reversed"] {
    @apply bg-white text-ds-dark-primary disabled:bg-gray-100 border border-transparent enabled:focus:outline-none hover:md:bg-gray-100;
  }

  &[data-style="secondary"] {
    @apply text-ds-dark-primary border border-gray-300 disabled:text-gray-400 disabled:border-gray-100 enabled:focus:outline-none;

    &:not(.loading) {
      @apply enabled:hover:md:bg-gray-50 enabled:active:bg-gray-100 enabled:focus:bg-gray-100;
    }
  }

  &[data-style="secondary-white"] {
    @apply text-white border border-gray-300 disabled:text-gray-400 disabled:border-gray-100 enabled:focus:outline-none;

    &:not(.loading) {
      @apply enabled:hover:md:bg-gray-50/[.12] enabled:active:bg-gray-100/[.25] enabled:focus:bg-gray-100/[.25];
    }

    .ds-tropee-button-icon {
      @apply text-white;
    }
  }

  &[data-style="tertiary"] {
    @apply bg-transparent text-ds-dark-primary border border-transparent enabled:focus:outline-none;

    &:not(.loading) {
      @apply disabled:text-gray-400 enabled:hover:md:bg-gray-50 enabled:active:bg-gray-100 enabled:focus:bg-gray-100;
    }
  }

  &[data-style="text"] {
    @apply bg-transparent text-ds-dark-primary border-none enabled:focus:outline-none py-0 px-[20px] text-xs lg:text-[16px] leading-6;

    &:not(.loading) {
      &:enabled {
        &:hover {
          @apply md:text-gray-500;
        }

        &:active, &:focus {
          @apply text-ds-dark-primary;
          &:hover {
            @apply md:text-gray-500
          }
        }
      }
    }
  }

  &[data-style="danger-primary"] {
    @apply bg-red-500 text-white disabled:text-gray-400 disabled:bg-gray-100 border border-transparent enabled:focus:outline-none;

    &:not(.loading) {
      &:enabled {
        &:hover {
          @apply md:bg-ds-red-400;
        }

        &:active, &:focus {
          @apply text-white/80 bg-ds-red-700;
        }
      }
    }
  }

  &[data-style="danger-secondary"] {
    @apply bg-white text-red-500 border border-red-500 disabled:text-gray-400 disabled:border-gray-100 enabled:focus:outline-none;

    &:not(.loading) {
      &:enabled {
        &:hover {
          @apply md:bg-ds-red-100;
        }

        &:active, &:focus {
          @apply text-ds-red-700 border-ds-red-700 bg-ds-red-200;
        }
      }
    }
  }

  &[data-style="danger-tertiary"] {
    @apply bg-white text-red-500 border border-transparent disabled:text-gray-400 enabled:focus:outline-none;

    &:not(.loading) {
      &:enabled {
        &:hover {
          @apply md:bg-ds-red-100;
        }

        &:active, &:focus {
          @apply bg-ds-red-200 text-ds-red-700;
        }
      }
    }
  }

  &[data-style="twitter"] {
    @apply bg-ds-sky-default text-white disabled:text-ds-sky-disabled-text disabled:bg-ds-sky-disabled border border-transparent enabled:focus:outline-none;

    &.loading {
      @apply bg-ds-sky-hover text-white cursor-default;
    }

    &:not(.loading):enabled {
      &:hover {
        @apply md:bg-ds-sky-hover;
      }

      &:active, &:focus {
        @apply text-white/80 bg-ds-sky-hover;
      }
    }
  }

  &[data-style="transparent"] {
    @apply text-white border border-white/5 bg-white/5 disabled:text-white/50 enabled:focus:outline-none;

    &.loading {
      @apply bg-white/25 border-white/25 text-white cursor-default;
    }

    &:not(.loading):enabled {
      &:hover {
        @apply md:border-white/10 md:bg-white/10;
      }

      &:active, &:focus {
        @apply border-white/[.16] bg-white/[.16];
      }
    }
  }

  &[data-style="empty"] {
    @apply text-white border border-transparent disabled:text-opacity-50 enabled:focus:outline-none;

    &.loading {
      @apply cursor-default;
    }
  }
}

.ds-tropee-button-icon {
  &::before {
    @apply align-middle;
  }
}
</style>
