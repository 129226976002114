import { AuthProvider, AuthSession, convertSessionToAuthSession } from './auth-provider'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { Session } from '@core-lib/models/session'

const queryParamKey = 'login_with_code'

export class CodeAuthProvider implements AuthProvider {
  public readonly canSign = false

  public async disconnect(): Promise<void> {
    return
  }

  public static needToBeCalled() {
    const url = new URL(window.location.href)
    return url.searchParams.has(queryParamKey)
  }

  public async authenticate(): Promise<AuthSession> {
    const url = new URL(window.location.href)
    const code = url.searchParams.get(queryParamKey)

    const { session } = await ServiceContainer.apiService.post<{ session: Session }>('/user/session/login-with-code', {
      code,
    })

    return convertSessionToAuthSession(session)
  }
}
