<script lang="ts">
import { RestOfTrackMethodArgs, TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { Event } from '@core-lib/webapp-services/analytics/events/base'

export default {
  methods: {
    track(event: Event, ...params: RestOfTrackMethodArgs) {
      return TropeeAnalyticsFactory.instance().track(event, ...params)
    },
  },
}
</script>