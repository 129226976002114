import {
  extendedAuthProviders as authProviders,
} from '@core-lib/webapp-services/auth-providers/extended-providers-list'
import { SignatureMessageType } from '@core-lib/const/wallet-message'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import { walletTruncate } from '@core-lib/helpers/string'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { WalletProviderType, WalletSignatureFailed } from '@core-lib/models/wallet'
import { onUnmounted, ref, watch } from 'vue'
import { useToaster } from '@core-lib/composables/toasters'
import translator from '@core-lib/webapp-services/i18n/translator'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { WalletConnectedEvent } from '@core-lib/webapp-services/analytics/events/others'
import web3WalletManager from '@core-lib/webapp-services/web3/web3-wallet-manager'

const { notify } = useToaster()

const walletConnecting = ref(false)

export const useWalletLoader = () => {
  const startLoader = () => walletConnecting.value = true
  const stopLoader = () => walletConnecting.value = false
  onUnmounted(() => walletConnecting.value = false)

  return { startLoader, stopLoader, walletConnecting }
}

const showWalletConnectModal = ref(false)
const hasWalletError = ref(false)
const walletAlreadyConnected = ref(false)

export const useConnectWallet = () => {
  const walletError = ref('')
  const signingInProgress = ServiceContainer.authService.isSigning

  watch(signingInProgress, (value) => {
    if (value) {
      showWalletConnectModal.value = false
    }
  })

  const connectWallet = async (connectionType: WalletProviderType) => {
    walletAlreadyConnected.value = false
    hasWalletError.value = false
    try {
      await authProviders[connectionType].authenticate({
        signatureMessageType: SignatureMessageType.LOGIN,
      })
      TropeeAnalyticsFactory.instance().track(new WalletConnectedEvent(connectionType))
      notify({
        text: translator.translate('settings__wallet_successfully_added'),
        type: 'success',
      })
    } catch (e) {
      if (e instanceof ApiError && e.code === 'duplicated_wallet' ) {
        hasWalletError.value = true
        walletError.value = walletTruncate((e.context as {walletAddress: string})?.walletAddress)
      }
      if (e instanceof ApiError && e.code === 'already_added' ) {
        walletAlreadyConnected.value = true
        notify({
          text: translator.translate('settings__wallet_already_connected'),
          type: 'error',
        })
      }
    } finally {
      showWalletConnectModal.value = false
    }
  }

  return { hasWalletError, walletError, showWalletConnectModal, signingInProgress, walletAlreadyConnected, connectWallet,
  }
}

const showLoyaltyWalletConnectModal = ref(false)
const hasLoyaltyWalletError = ref(false)

export const useLoyaltyConnectWallet = () => {
  const walletError = ref('')

  const connectWalletLoyalty = async (walletProviderType: WalletProviderType) => {
    walletAlreadyConnected.value = false
    hasLoyaltyWalletError.value = false
    try {
      await web3WalletManager.addWallet(walletProviderType)
      hasLoyaltyWalletError.value = false
      TropeeAnalyticsFactory.instance().track(new WalletConnectedEvent(walletProviderType))
    } catch (e) {
      hasLoyaltyWalletError.value = true
      if (e instanceof ApiError && e.code === 'duplicated_wallet' ) {
        walletError.value = 'Duplicated wallet'
      }
      if (e instanceof ApiError && e.code === 'already_added' ) {
        walletAlreadyConnected.value = true
        walletError.value = 'Wallet already connected'
      }
      if (e instanceof WalletSignatureFailed) {
        walletError.value = 'Sign failed'
        console.log('Sign failed')
      }
      walletError.value = 'Sign failed'
    } finally {
      setTimeout(() => showLoyaltyWalletConnectModal.value = false,100)
    }
  }

  return { hasLoyaltyWalletError, walletError, showLoyaltyWalletConnectModal, walletAlreadyConnected, connectWalletLoyalty }
}
