export enum HolderRouteName {
  COLLECTION_PORTAL = 'CollectorPortal',
  UTILITY_CAMPAIGN_PREVIEW_V4 = 'TropeePreviewV4',
  UTILITY = 'Tropee',
  PORTAL_SETTINGS = 'PortalSettings',
  SOCIAL_CALLBACK = 'SocialCallback',
  USER_PROFILE = 'UserProfile',
  USER_PROFILE_SETTINGS = 'ExploreProfileSettings',
  HOMEPAGE = 'Homepage',
  NOTIFICATIONS = 'ExploreNotifications',
  NOT_FOUND = 'ExploreNotFound',
  LOGIN = 'ExploreLogin',
  EXPLORE = 'Explore',
  ACCOUNT = 'Account',
  WELCOME = 'ExploreWelcome',
  ORGANIZATION_PROFILE = 'OrganizationProfile',
  ORGANIZATION_LOYALTY = 'OrganizationLoyalty',
  ORGANIZATION_LOYALTY_CUSTOM = 'OrganizationLoyaltyCustom',
  ORGANIZATION_LOYALTY_NEW = 'OrganizationLoyaltyNew',
  ORGANIZATION_HOMEPAGE = 'OrganizationHomepage',
  LOYALTY_REWARD = 'LoyaltyReward',
  CALENDLY = 'Calendly',
  LOYALTY_CALENDLY = 'LoyaltyCalendly',
  LOYALTY_NOT_FOUND = 'LoyaltyNotFound',
  EMAIL_SUBSCRIPTIONS = 'EmailSubscriptions',
  EMAIL_UNSUBSCRIBE = 'EmailUnsubscribe',
}

export enum CreatorRouteName {
  HOME = 'Home',
  DASHBOARD = 'Dashboard',
  ANALYTICS = 'Analytics',
  TEMPLATE = 'Template',
  BUILDER = 'Builder',
  LOGIN = 'CreatorLogin',
  USER_SETTINGS = 'CreatorUserSettings',
  ORGANIZATION_SETTINGS = 'CreatorOrganizationSettings',
  PRICING = 'CreatorPricing',
  INTEGRATION = 'CreatorIntegration',
  WELCOME = 'CreatorWelcome',
  ONBOARDING = 'CreatorOnboarding',
  NOTIFICATIONS = 'CreatorNotifications',
  AI_GENERATION = 'AiGeneration',
  AI_ASSISTANT = 'AiAssistant',
}

export enum AdminRouteName {
  ADMIN = 'AdminHome',
  ADMIN_HOME = 'AdminHomeTemp',
  ADMIN_REWARDS = 'AdminRewards',
  ADMIN_ANALYTICS = 'AdminAnalytics',
  ADMIN_TASK_ACTIVITY = 'AdminTaskActivity',
  ADMIN_SETTINGS = 'AdminSettings',
  ADMIN_APPEARANCE = 'AdminAppearance',
  ADMIN_REVIEWS = 'AdminReviews',
  ADMIN_ORGANIZATION_ACCOUNT = 'AdminOrganizationAccount',
  ADMIN_LOGIN = 'AdminLogin',
  ADMIN_PRICING = 'AdminPricing',
  ADMIN_BILLING = 'AdminBilling',
  ADMIN_MEMBERS = 'AdminMembers',
  ADMIN_ONBOARDING = 'AdminOnboarding',
  ADMIN_INTEGRATION = 'AdminIntegration',
}

export const holderRoutePath: Record<HolderRouteName, string> = {
  [HolderRouteName.COLLECTION_PORTAL]: '/collection/:path',
  [HolderRouteName.UTILITY_CAMPAIGN_PREVIEW_V4]: '/preview-v4',
  [HolderRouteName.UTILITY]: '/t/:utilityCampaignId',
  [HolderRouteName.PORTAL_SETTINGS]: '/account/settings/collection/:path',
  [HolderRouteName.SOCIAL_CALLBACK]: '/social/callback',
  [HolderRouteName.USER_PROFILE]: '/user/:username',
  [HolderRouteName.USER_PROFILE_SETTINGS]: '/account/settings',
  [HolderRouteName.HOMEPAGE]: '/',
  [HolderRouteName.NOTIFICATIONS]: '/notifications',
  [HolderRouteName.NOT_FOUND]: '/not-found',
  [HolderRouteName.LOGIN]: '/login',
  [HolderRouteName.EXPLORE]: '/explore',
  [HolderRouteName.ACCOUNT]: '/account',
  [HolderRouteName.WELCOME]: '/welcome',
  [HolderRouteName.ORGANIZATION_PROFILE]: '/:slug/tropees',
  [HolderRouteName.ORGANIZATION_LOYALTY]: '/:slug',
  [HolderRouteName.ORGANIZATION_LOYALTY_CUSTOM]: '/',
  [HolderRouteName.ORGANIZATION_LOYALTY_NEW]: '/:slug/loyalty',
  [HolderRouteName.ORGANIZATION_HOMEPAGE]: '/:slug',
  [HolderRouteName.LOYALTY_REWARD]: '/r/:utilityCampaignId',
  [HolderRouteName.CALENDLY]: '/calendly/:utilityCampaignId',
  [HolderRouteName.LOYALTY_CALENDLY]: '/ly-calendly/:organizationId',
  [HolderRouteName.LOYALTY_NOT_FOUND]: '/loyalty/not-found',
  [HolderRouteName.EMAIL_SUBSCRIPTIONS]: '/account/subscriptions',
  [HolderRouteName.EMAIL_UNSUBSCRIBE]: '/account/unsubscribe',
}

export const creatorRoutePath: Record<CreatorRouteName, string> = {
  [CreatorRouteName.HOME]: '/home',
  [CreatorRouteName.DASHBOARD]: '/dashboard',
  [CreatorRouteName.ANALYTICS]: '/analytics',
  [CreatorRouteName.TEMPLATE]: '/templates',
  [CreatorRouteName.BUILDER]: '/utility/:id',
  [CreatorRouteName.LOGIN]: '/login',
  [CreatorRouteName.USER_SETTINGS]: '/account/settings',
  [CreatorRouteName.ORGANIZATION_SETTINGS]: '/organization/settings',
  [CreatorRouteName.PRICING]: '/pricing',
  [CreatorRouteName.INTEGRATION]: '/account/integrations',
  [CreatorRouteName.WELCOME]: '/welcome',
  [CreatorRouteName.NOTIFICATIONS]: '/notifications',
  [CreatorRouteName.AI_GENERATION]: '/ai-generation',
  [CreatorRouteName.ONBOARDING]: '/onboarding',
  [CreatorRouteName.AI_ASSISTANT]: '/ai-assistant',
}

export const adminRoutePath: Record<AdminRouteName, string> = {
  [AdminRouteName.ADMIN]: '/admin',
  [AdminRouteName.ADMIN_HOME]: '/admin/home',
  [AdminRouteName.ADMIN_REWARDS]: '/admin/rewards',
  [AdminRouteName.ADMIN_ANALYTICS]: '/admin/analytics',
  [AdminRouteName.ADMIN_TASK_ACTIVITY]: '/admin/activity-log',
  [AdminRouteName.ADMIN_SETTINGS]: '/admin/settings',
  [AdminRouteName.ADMIN_APPEARANCE]: '/admin/appearance',
  [AdminRouteName.ADMIN_REVIEWS]: '/admin/reviews',
  [AdminRouteName.ADMIN_BILLING]: '/admin/billing',
  [AdminRouteName.ADMIN_ORGANIZATION_ACCOUNT]: '/admin/account',
  [AdminRouteName.ADMIN_LOGIN]: '/admin/login',
  [AdminRouteName.ADMIN_PRICING]: '/admin/pricing',
  [AdminRouteName.ADMIN_MEMBERS]: '/admin/members',
  [AdminRouteName.ADMIN_ONBOARDING]: '/admin/onboarding',
  [AdminRouteName.ADMIN_INTEGRATION]: '/admin/integration',
}
