import {
  CollectionApi,
  CreatorApi,
  DefaultApi,
  UserApi,
  UtilityApi,
  UtilityTransactionApi,
} from '@core-lib/openapi/client/api'
import { BASE_PATH } from '@core-lib/openapi/client/base'
import { ApiService } from '@/services/api-service'
import axios from 'axios'
import appEnv from '@/app-env'
import { lastPublicApiSecret } from '@core-lib/openapi/secret'

const axiosInstance = axios.create({
  headers: {
    'x-api-key': lastPublicApiSecret,
  },
})
ApiService.prepareAxios(axiosInstance)

const apiBaseUrlOrigin = appEnv.api.baseUrl.startsWith('/') ? '' : (new URL(appEnv.api.baseUrl).origin)
const basePath = apiBaseUrlOrigin ? `${apiBaseUrlOrigin}${BASE_PATH}` : BASE_PATH

export const apiClient = new DefaultApi(undefined, basePath, axiosInstance)
export const creatorApiClient = new CreatorApi(undefined, basePath, axiosInstance)
export const collectionApiClient = new CollectionApi(undefined, basePath, axiosInstance)
export const userApiClient = new UserApi(undefined, basePath, axiosInstance)
export const utilityApiClient = new UtilityApi(undefined, basePath, axiosInstance)
export const utilityTransactionApiClient =  new UtilityTransactionApi(undefined, basePath, axiosInstance)
