<template>
  <BaseModal
    :close-button="true"
    :show="show"
    without-padding
    close-button-position-class="top-[26px] right-[26px] md:top-[18px] md:right-[18px]"
    custom-size="h-[404px] md:h-[350px] w-full md:w-[524px] md:p-8"
    shadow-class="md:shadow-[0px_0px_12px_0px_rgba(0,0,0,0.10)]"
    wrapper-class="tropee-login-modal"
    content-class="h-full flex-1 flex flex-col"
    @hide="$emit('hide')"
  >
    <div class="flex-1 w-full flex flex-col h-full">
      <LoginForm
        is-holder-modal
        :auth-service="authService"
      />
    </div>
  </BaseModal>
</template>

<script setup lang="ts">

import BaseModal from '@core-lib/components/BaseModal.vue'
import { watch } from 'vue'
import LoginForm from '@core-lib/components/auth/LoginForm.vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import authService from '@core-lib/webapp-services/auth/auth-service'
import { useSocialLoginModalController } from '@core-lib/composables/login-modal'

defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const { showSocialLoginModal } = useSocialLoginModalController()

const isAuthenticated = authFacade.isAuthenticated
watch(isAuthenticated, isAuthenticated => {
  if (!isAuthenticated) return
  emit('hide')
  showSocialLoginModal.value = false
}, { immediate: true })
</script>

<style lang="scss">
.tropee-login-modal {

  .base-modal-container {
    @apply max-md:p-0 max-md:flex-wrap  max-md:content-end  max-md:w-full h-full #{!important};
  }

  .mobile-login-container {
    @apply max-md:mb-0 max-md:rounded-t-[32px] max-md:h-fit max-md:flex max-md:flex-col #{!important};
  }
}
</style>