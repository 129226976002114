export const usePreventTouchMove = () => {
  function handleTouchMove (e: TouchEvent) {
    e.preventDefault()
  }

  function preventTouchMove() {
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
  }
  function allowTouchMove() {
    document.removeEventListener('touchmove', handleTouchMove)
  }

  return {
    preventTouchMove,
    allowTouchMove,
  }
}