import {
  AuthenticateInput,
  AuthProvider,
  AuthSession,
  convertSessionToAuthSession,
  FailedToAuthenticate,
} from './auth-provider'
import { WalletProviderType, WalletSignatureFailed } from '@core-lib/models/wallet'
import { SignatureCanceller } from '../wallet'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { buildWalletMessageWithVars } from '@core-lib/const/wallet-message'
import { Session } from '@core-lib/models/session'
import { ErrorTracker } from '@core-lib/webapp-services/error-tracker'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import walletService from '@core-lib/webapp-services/web3/wallet-service'
import { useToaster } from '@core-lib/composables/toasters'

const { notify } = useToaster()
export class BlockNativeAuthProvider implements AuthProvider {
  public readonly canSign = true

  constructor(private walletConnectionType: WalletProviderType) {}

  public async disconnect(): Promise<void> {
    await walletService.disconnect()
  }

  private async getFormattedWalletAddress() {
    return await walletService.connectOrFail(this.walletConnectionType)
  }

  private async getSession({ signatureMessageType }: AuthenticateInput) {
    const date = new Date()
    const message = buildWalletMessageWithVars({ date, signatureMessageType })
    try {
      const connectedWalletAddress = await this.getFormattedWalletAddress()
      ServiceContainer.authService.isSigning.value = true
      const messageResponse = await ServiceContainer.apiService.post<{
        message: string,
        nonce: string
      }>('/user/session/signature/message', {
        message: message,
        walletAddress: connectedWalletAddress,
      })
      const signature = await walletService.signMessage(messageResponse.message, new SignatureCanceller(), false)
      const { code } = await ServiceContainer.apiService.post<{ code: string }>('/user/session/signature/validate', {
        nonce: messageResponse.nonce,
        signature: signature,
        walletAddress: connectedWalletAddress,
      })
      const { session } = await ServiceContainer.apiService.post<{ session: Session }>('/user/session/login-with-code', {
        code,
      })

      return { signature, session }
    } catch (e) {
      if ((e as Error).message === 'No wallet connected') {
        notify({
          text: 'Please make sure your wallet is connected',
          type: 'error',
        })
        throw FailedToAuthenticate.notConnected()
      }
      if (e instanceof WalletSignatureFailed) {
        throw FailedToAuthenticate.rejected()
      } else if (e instanceof ApiError && ['duplicated_wallet', 'already_added'].includes(e.code)) {
        throw e
      } else {
        ErrorTracker.trackAndThrow(e, { extra: { location: 'block_native_auth' }, severity: 'warning' })
        throw FailedToAuthenticate.unknown()
      }
    } finally {
      ServiceContainer.authService.isSigning.value = false
    }
  }

  public async authenticate(input: AuthenticateInput): Promise<AuthSession> {
    const { session } = await this.getSession(input)

    return convertSessionToAuthSession(session)
  }
}
