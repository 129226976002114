import { LoyaltyProgramIdentifier } from '@core-lib/openapi/client'
import { useQuery } from '@tanstack/vue-query'
import { loyaltyApiClient } from '@core-lib/webapp-services/client'
import { ref, watch } from 'vue'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'

export const useLoyaltyConfig = (identifier: LoyaltyProgramIdentifier) => {
  const { data, error } = useQuery({
    queryKey: ['loyaltyConfig', identifier],
    queryFn: async () => {
      const response = await loyaltyApiClient.getLoyaltyConfig({ identifier })
      return response.data.config
    },
    refetchOnWindowFocus: false,
  })

  const notFound = ref(false)

  watch(error, async e => {
    if (!e) return
    if ((e as { status: number }).status === 404 || (e instanceof ApiError && e.isNotFound())) {
      notFound.value = true
    } else {
      console.error(e)
    }
  })

  return {
    loyaltyConfig: data,
    notFound,
  }
}
