<template>
  <div
    :class="[wrapperSize, {'disabled': disabled}, borderRadiusClass]"
    :data-style="getStyleType"
    :data-active="isActive"
    class="ds-icon-button"
  >
    <DsIcon
      :class="[{ 'animate-spin': spinning }, iconClass]"
      :icon="icon"
      :size="size"
      :type="typeof icon === 'string' ? 'solid' : icon.type"
    />
  </div>
</template>

<script lang="ts" setup>
import DsIcon from './DsIcon.vue'
import { IconButtonType, IconType } from '@core-design/types/icon'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    icon: IconType;
    styleType?: IconButtonType
    iconSize?: 'x-small' | 'small' | 'base' | 'large' | 'xlarge' | '2xlarge'
    disabled?: boolean
    rounded?: boolean
    spinning?: boolean
    isActive?: boolean
    mobileSize?: boolean
    desktopSize?: boolean
    iconClass?: string
    customWrapperSize?: string
  }>(), {
    styleType: 'primary',
    iconSize: undefined,
    disabled: false,
    rounded: false,
    spinning: false,
    isActive: false,
    mobileSize: false,
    desktopSize: false,
    iconClass: '',
    customWrapperSize: '',
  },
)

const getStyleType = computed(() => {
  if (props.styleType === 'transparent' && props.iconSize === 'small') {
    return 'transparent-small'
  }
  return props.styleType
})

const defaultIconSize = computed(() => props.iconSize || 'w-4 h-4 text-[16px] lg:w-[18px] lg:h-[18px] lg:text-lg leading-none')

const size = computed(() => {
  switch (props.iconSize) {
  case '2xlarge':
    return 'w-6 h-6 text-[24px] leading-none'
  case 'xlarge':
    return 'w-5 h-5 text-[20px] leading-none'
  case 'large':
    return 'w-[18px] h-[18px] text-lg leading-none'
  case 'small':
    return 'w-4 h-4 text-[16px] leading-none'
  case 'x-small':
    return 'w-[14px] h-[14px] text-[14px] leading-none'
  case 'base':
    return 'w-4 h-4 text-[16px] leading-none'
  default:
    return defaultIconSize.value
  }
})

const wrapperSize = computed(() => {
  if (props.customWrapperSize) {
    return props.customWrapperSize
  }
  if ((props.styleType === 'transparent') && props.iconSize === 'small') {
    return 'w-7 h-7'
  }
  if (props.mobileSize) {
    return 'w-10 h-10'
  }
  if (props.desktopSize) {
    return 'w-[46px] h-[46px]'
  }
  return 'w-10 h-10 lg:w-[46px] lg:h-[46px]'
})

const borderRadiusClass = computed(() => {
  if (props.rounded) return 'rounded-full'
  if (props.iconSize === 'small') {
    if (props.styleType === 'transparent') {
      return 'rounded-lg'
    }
    return 'rounded-xl'
  }
  return 'rounded-2xl'
})

</script>

<style lang="scss" scoped>
.ds-icon-button {
  @apply text-ds-dark-primary flex items-center justify-center inline-block cursor-pointer;
  @apply hover:bg-gray-50 active:bg-gray-100 focus:bg-gray-100 focus:outline-none;

  &.disabled {
    @apply text-gray-300 cursor-default pointer-events-none;
  }

  &[data-active="true"] {
    @apply bg-gray-100 hover:bg-gray-100;
  }

  &[data-style="secondary"] {
    @apply border border-gray-300;

    &.disabled {
      @apply border-gray-100;
    }
  }

  &[data-style="transparent"] {
    @apply bg-white/25 backdrop-blur-[3px] hover:bg-white/[.36] active:bg-white/[.72] focus:bg-white/[.72];

    &[data-active="true"] {
      @apply bg-white/[.72] hover:bg-white/[.72];
    }

    &.disabled {
      @apply bg-white/[.12];
    }
  }

  &[data-style="transparent-small"] {
    @apply text-white bg-ds-dark-primary/[.12] backdrop-blur-[3px] hover:bg-ds-dark-primary/[.36] active:bg-ds-dark-primary/[.72] focus:bg-ds-dark-primary/[.72];
    &.disabled {
      @apply text-gray-300;
    }
  }

  &[data-style="gray"] {
    @apply bg-gray-100 hover:bg-gray-200 active:bg-gray-200 focus:bg-gray-200;
    &.disabled {
      @apply text-gray-300 bg-ds-dark-primary/[.12] hover:bg-ds-dark-primary/[.12];
    }
  }

  &[data-style="transparent-grayish"] {
    @apply text-ds-dark-primary bg-white/25 hover:backdrop-blur-[3px] focus:backdrop-blur-[3px] active:backdrop-blur-[3px] hover:bg-gray-50 active:bg-gray-100 focus:bg-gray-100;
    &.disabled {
      @apply cursor-default text-gray-300;
    }
  }

  &[data-style="transparent-light"] {
    @apply text-white border border-white/5 backdrop-blur-[16px] bg-gray-50/5 hover:bg-gray-50/10 active:bg-gray-50/[.16] focus:bg-gray-50/[.16];
    &.disabled {
      @apply cursor-default text-white/50;
    }
  }

  &[data-style="transparent-white"] {
    @apply text-ds-dark-primary bg-transparent hover:bg-white/25 active:bg-white/50 focus:bg-white/50;
    &.disabled {
      @apply cursor-default text-gray-300;
    }
  }
}

</style>