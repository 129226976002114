import { useRoute } from 'vue-router'
import { computed } from 'vue'

export const useAdminApp = () => {
  const route = useRoute()
  const isAdmin = computed(() => {
    // if the route starts with /admin, it's preview mode
    return route.path.startsWith('/admin')
  })

  return {
    isAdmin,
  }
}