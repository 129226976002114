<template>
  <div class="max-w-full">
    <form
      ref="verificationForm"
      class="max-w-full inline-flex gap-x-1.5"
      :class="formClass"
    >
      <input
        v-for="(n, index) in code"
        ref="verificationBox"
        :key="index"
        type="number"
        class="code-input w-full p-0 text-center basis-0 grow min-w-0 rounded-xl text-[32px] leading-8 md:text-[40px] md:leading-[54px] text-ds-dark-primary focus:!outline-none focus:!shadow-none focus:ring-0"
        :class="[error ? 'border-2 border-red-500 focus:!border-red-500' : `${isAdmin ? 'bg-stone-100 border-stone-100 hover:bg-stone-200 hover:border-stone-200 focus:bg-stone-50 focus:border-stone-200' : 'hover:bg-gray-50 focus:bg-gray-50 border-gray-200 focus:border-gray-200'} border-2`, {'!text-xl md:!text-[26px]': code[index] === undefined || code[index] === ''}, codeInputClass]"
        :style="{ height: newHeight + 'px', maxWidth: verificationInputMaxWidth }"
        pattern="\d*"
        :id="'input_' + index"
        maxlength="1"
        v-model="code[index]"
        @input="handleInput"
        @keypress="isNumber"
        @keydown.delete="handleDelete"
        @paste="onPaste"
        @focus="preventTouchMove"
        @blur="allowTouchMove"
      >
    </form>
    <div
      v-if="error"
      class="mt-3 text-sm font-medium text-red-500 text-center"
      :class="errorClass"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed, nextTick, onMounted, Ref, ref, watch } from 'vue'
import { useScreenSize } from '@core-lib/composables/screen-size'
import { usePreventTouchMove } from '@core-lib/composables/touch-move'

const props = withDefaults(
  defineProps<{
    codeLength?: number
    error?: string
    autoFocus?: number
    codeInputClass?: string
    errorClass?: string
    formClass?: string
    verificationInputMaxWidth?: number
    isAdmin?: boolean
  }>(),
  {
    codeLength: 7,
    error: '',
    autoFocus: undefined,
    codeInputClass: '',
    errorClass: '',
    formClass: '',
    verificationInputMaxWidth: undefined,
    isAdmin: false,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const { preventTouchMove, allowTouchMove } = usePreventTouchMove()

const verificationForm = ref<HTMLElement | null>(null)

const code: Ref<string[]> = ref(Array(props.codeLength))
let dataFromPaste: string[] | undefined
const keysAllowed: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]

const newWidth = ref<number>()
const aspectRatio = 61/76
const verificationBox = ref<HTMLElement[] | null>(null)

// Calculate the corresponding height based on the newWidth and aspectRatio
const newHeight = computed(() => {
  if (!newWidth.value) return
  return newWidth.value / aspectRatio
})

const getCurrentWidth = () => {
  if (verificationBox.value && verificationBox.value[0]) {
    const verificationBoxCurrentWidth = verificationBox.value[0].offsetWidth
    newWidth.value = (props.verificationInputMaxWidth && verificationBoxCurrentWidth > props.verificationInputMaxWidth) ? props.verificationInputMaxWidth : verificationBox.value[0].offsetWidth
  }
}
const { width } = useScreenSize()
watch(width, () => {
  nextTick(() => {
    getCurrentWidth()
  })
})

onMounted(() => getCurrentWidth())

function isNumber(event: Event) {

  (event.currentTarget as HTMLInputElement).value = ''
  const keyPressed: string = (event as KeyboardEvent).key
  if (keyPressed === 'v' && ((event as KeyboardEvent).ctrlKey || (event as KeyboardEvent).metaKey)) return
  if (!keysAllowed.includes(keyPressed)) {
    event.preventDefault()
  }
}
function handleInput(event: Event) {

  const val = (event.target as HTMLInputElement).value
  const inputType = (event as InputEvent).inputType
  let currentActiveElement = event.target as HTMLInputElement

  if (inputType === 'insertText') {
    if (val.length === 1) {
      const nextSibling = currentActiveElement.nextElementSibling as HTMLElement
      if (nextSibling) {
        nextSibling.focus()
      } else {
        currentActiveElement.blur()
      }
    } else {
      for (const num of val.split('')) {
        let id: number = parseInt(currentActiveElement.id.split('_')[1])
        currentActiveElement.value = num
        code.value[id] = num
        if (currentActiveElement.nextElementSibling) {
          currentActiveElement =
              currentActiveElement.nextElementSibling as HTMLInputElement;
          (currentActiveElement.nextElementSibling as HTMLElement)?.focus()
        } else {
          currentActiveElement.blur()
        }
      }
    }
  }

  if (inputType === 'insertFromPaste' && dataFromPaste) {
    for (const num of dataFromPaste) {
      let id: number = parseInt(currentActiveElement.id.split('_')[1])
      currentActiveElement.value = num
      code.value[id] = num
      if (currentActiveElement.nextElementSibling) {
        currentActiveElement =
          currentActiveElement.nextElementSibling as HTMLInputElement
        currentActiveElement.focus()
      } else {
        currentActiveElement.blur()
      }
    }
  }
  emit('update:modelValue', code.value.join(''))
}
function handleDelete(event: Event) {
  //keydown event = move to previous element then only delete number

  let value = (event.target as HTMLInputElement).value
  let currentActiveElement = event.target as HTMLInputElement
  if (!value)
    (currentActiveElement.previousElementSibling as HTMLElement)?.focus()
}

function onPaste(event: Event) {
  dataFromPaste = (event as ClipboardEvent).clipboardData
    ?.getData('text')
    .trim()
    .split('')

  if (dataFromPaste) {
    for (const num of dataFromPaste) {
      if (!keysAllowed.includes(num)) event.preventDefault()
    }
  }
}

watch(() => props.autoFocus, () => {
  if (!verificationForm.value) return
  const firstCodeInput = verificationForm.value.querySelector('.code-input') as HTMLElement
  if (!firstCodeInput) return
  firstCodeInput.focus()
})
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.code-input {
  -moz-appearance: textfield;
}
</style>