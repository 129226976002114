<template>
  <DsBottomSheet
    :show="show"
    wrapper-class="font-inter w-full shadow-[0_0_30px_0_rgba(27,32,50,0.15)]"
    content-class="h-full flex-1 flex flex-col"
    title-description-class="max-md:!text-sm"
    close-button
    @hide="$emit('hide')"
  >
    <template #title>
      <LoyaltyLoginModalTitle :organization="organization" />
    </template>
    <template #description>
      {{ translateLY('collect_points_unlock_amazing_rewards') }}
    </template>
    <LoyaltyLogin />
  </DsBottomSheet>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { useSocialLoginModalController } from '@core-lib/composables/login-modal'
import DsBottomSheet from '@core-design/components/Design/DsBottomSheet.vue'
import LoyaltyLogin from '@core-lib/components/auth/LoyaltyLogin.vue'
import { OrganizationPublicProfile } from '@core-lib/openapi/client'
import { useI18n } from '@core-lib/composables/i18n'
import LoyaltyLoginModalTitle from '@core-lib/components/auth/LoyaltyLoginModalTitle.vue'

defineProps<{
  show: boolean
  organization: OrganizationPublicProfile
}>()

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const { translate: translateLY } = useI18n({ prefix: 'loyalty__' })

const { showSocialLoginModal } = useSocialLoginModalController()

const isAuthenticated = authFacade.isAuthenticated
watch(isAuthenticated, isAuthenticated => {
  if (!isAuthenticated) return
  emit('hide')
  showSocialLoginModal.value = false
}, { immediate: true })
</script>

<style lang="scss">
.tropee-login-modal {

  .base-modal-container {
    @apply max-md:p-0 max-md:flex-wrap  max-md:content-end  max-md:w-full h-full #{!important};
  }

  .mobile-login-container {
    @apply max-md:mb-0 max-md:rounded-t-[32px] max-md:h-fit max-md:flex max-md:flex-col #{!important};
  }
}
</style>
