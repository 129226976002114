import { ref } from 'vue'
import { ClaimResponse } from '@/services/claimer'
import { useWindow } from '@core-lib/composables/window'

const claimResponse = ref<ClaimResponse>({
  claimSecret: '',
  outputBlocks: [],
  metadata: {
    gatedContentCode: null,
    claimSecret: '',
    postClaimData: {},
    claimData: {},
    gatedContentMetadata: {},
    claimsStat: {
      count: 0,
      max: null,
    },
  },
})
const { window } = useWindow()

export const useIsPreview = () => {
  return window.location.pathname.startsWith('/preview')
}

export const usePreviewClaimResponse = () => {
  return {
    setClaimResponse: (response: ClaimResponse) => {
      claimResponse.value = response
    },
    claimResponse: claimResponse,
  }
}