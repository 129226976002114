import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { CreatorRouteName, creatorRoutePath, HolderRouteName, holderRoutePath } from '@core-lib/routes'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import appEnv from '@/app-env'
import { isEmbed } from '@core-lib/helpers/url'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
export import RouteName = HolderRouteName

const pathAndName = (routeName: RouteName) => ({
  path: holderRoutePath[routeName],
  name: routeName,
})

export const routes: Array<RouteRecordRaw> = [
  {
    ...pathAndName(RouteName.HOMEPAGE),
    component: () => import('../views/LoyaltyPage.vue'),
    beforeEnter: (to, from, next) => {
      if (ServiceContainer.isCustomDomain) {
        return next()
      } else if (appEnv.app.isLocal) {
        return next({
          name: CreatorRouteName.HOME,
        })
      } else {
        window.location.href = '/'
      }
      return false
    },
    meta: {
      hasLoyaltyLayout: true,
    },
  },
  {
    ...pathAndName(RouteName.LOGIN),
    component: () => import('../views/LoginPage.vue'),
    meta: {
      fullHeight: true,
    },
  },
  {
    ...pathAndName(RouteName.UTILITY_CAMPAIGN_PREVIEW_V4),
    component: () => import('../views/PreviewNFTUtility.vue'),
  },
  {
    ...pathAndName(RouteName.ORGANIZATION_LOYALTY),
    component: () => import('../views/LoyaltyPage.vue'),
    beforeEnter: (to, from, next) => {
      if (ServiceContainer.isCustomDomain) {
        return next({
          name: HolderRouteName.HOMEPAGE,
          query: to.query,
        })
      } else {
        return next()
      }
    },
    meta: {
      hasLoyaltyLayout: true,
    },
  },
  {
    ...pathAndName(RouteName.ORGANIZATION_LOYALTY_NEW),
    redirect: { name: RouteName.ORGANIZATION_LOYALTY },
  },
  {
    ...pathAndName(RouteName.LOYALTY_REWARD),
    component: () => import('../views/LoyaltyRewardPage.vue'),
    meta: {
      hasLoyaltyLayout: true,
    },
  },
  {
    ...pathAndName(RouteName.UTILITY),
    component: () => import('../views/UtilityVersionSelector.vue'),
    meta: {
      hideFooter: true,
    },
  },
  {
    ...pathAndName(RouteName.EMAIL_SUBSCRIPTIONS),
    component: () => import('../views/EmailSubscription.vue'),
    meta: {
      hideFooter: true,
    },
  },
  {
    ...pathAndName(RouteName.EMAIL_UNSUBSCRIBE),
    component: () => import('../views/EmailUnsubscribe.vue'),
    meta: {
      hideFooter: true,
      hideHeader: true,
      hasLoyaltyLayout: false,
    },
  },
  {
    ...pathAndName(RouteName.USER_PROFILE),
    component: () => import('../views/OrganizationProfile.vue'),
    beforeEnter: (to, from, next) => {
      return next({
        name: RouteName.ORGANIZATION_PROFILE, params: { slug: (to.params.username as string).toLowerCase() },
      })
    },
  },
  {
    name: 'OrganizationProfileLegacy',
    path: '/org/:slug',
    component: () => import('../views/OrganizationProfile.vue'),
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug as string
      return next({
        name: RouteName.ORGANIZATION_PROFILE, params: { slug: slug.toLowerCase() },
      })
    },
  },
  {
    ...pathAndName(RouteName.ORGANIZATION_PROFILE),
    component: () => import('../views/OrganizationProfile.vue'),
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug as string
      if (slug === slug.toLowerCase()) return next()
      return next({
        name: RouteName.ORGANIZATION_PROFILE, params: { slug: slug.toLowerCase() },
      })
    },
  },
  {
    ...pathAndName(RouteName.SOCIAL_CALLBACK),
    component: () => import('../views/SocialCallback.vue'),
  },
  {
    ...pathAndName(RouteName.CALENDLY),
    component: () => import('../views/Calendly.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    ...pathAndName(RouteName.LOYALTY_CALENDLY),
    component: () => import('../views/LoyaltyCalendly.vue'),
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    ...pathAndName(RouteName.NOT_FOUND),
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      navbarLogoClickable: true,
      hideNavbarCreateTropeeButton: true,
    },
  },
  {
    ...pathAndName(RouteName.LOYALTY_NOT_FOUND),
    component: () => import('../views/LoyaltyPageNotFound.vue'),
    meta: {
      hasLoyaltyLayout: true,
      hideHeader: true,
    },
  },
  ...Object.entries(creatorRoutePath).map(([routeName, path]) => {
    return {
      name: routeName,
      path: `/creator${path}`,
      component: () => import('../views/PageNotFound.vue'),
      beforeEnter: (to: RouteLocationNormalized) => {
        window.location.href = to.fullPath
        return false
      },
    }
  }),
  // This myst be the last route.
  {
    ...pathAndName(RouteName.ORGANIZATION_HOMEPAGE),
    component: () => import('../views/OrganizationProfile.vue'),
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug as string
      if (slug === slug.toLowerCase()) return next()
      return next({
        name: RouteName.ORGANIZATION_HOMEPAGE, params: { slug: slug.toLowerCase() },
      })
    },
  },
  {
    name: 'GlobalNotFound',
    path: '/:catchAll(.*)',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      navbarLogoClickable: true,
      hideNavbarCreateTropeeButton: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  if (isEmbed && ![RouteName.UTILITY, RouteName.NOT_FOUND].includes(to.name as RouteName)) return false
})

router.afterEach(async (to, from) => {
  if (to.name === from.name) return
  TropeeAnalyticsFactory.instance().page({
    path: to.fullPath,
    name: to.name,
    ...(to.name === RouteName.UTILITY ? { tropeeId: to.params.utilityCampaignId } : {}),
  })
  authFacade.routeChanged()
})

router.onError((error) => {
  if ((error as Error).message.includes('Failed to fetch dynamically')) {
    window.location.reload()
  }
})

export default router
