import { WalletAdapter } from '@core-lib/webapp-services/web3/wallet-adaptors/wallet-adapter'
import { encode } from 'bs58'

type Solana = {
  isPhantom: boolean
  connect: () => Promise<{
    publicKey: Uint8Array
  }>
  signMessage: (message: Uint8Array, encoding: string) => Promise<{ signature: Uint8Array }>
}

export class PhantomAdapter implements WalletAdapter {
  public readonly chainType: string = 'solana'

  private getProvider() {
    if ('phantom' in window) {
      const phantom = window.phantom as { solana?: Solana }
      const provider = phantom?.solana

      if (provider?.isPhantom) {
        return provider
      }
    }

    window.open('https://phantom.app/', '_blank')
    throw new Error('No wallet connected')
  }

  public async getWalletAddress(): Promise<string> {
    const resp = await this.getProvider().connect()
    return resp.publicKey.toString()
  }

  public async signMessage(message: string): Promise<string> {
    const encodedMessage = new TextEncoder().encode(message)
    const { signature } = await this.getProvider().signMessage(encodedMessage, 'utf8')
    return encode(signature)
  }
}
