<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="!nonce"
      class="text-center w-full max-md:mt-auto h-full flex-1 flex flex-col items-center justify-between md:justify-center md:gap-y-8 max-md:px-4 max-md:pb-8 max-md:pt-6"
      :class="{'md:max-w-[426px] md:-mt-[72px] max-md:max-h-[440px]': !isModal}"
    >
      <div>
        <div
          class="text-ds-dark-primary text-xl font-semibold"
          :class="isModal ? 'md:text-[22px]' : 'md:text-2xl'"
        >
          You’re almost there!
        </div>
        <div class="text-gray-500 text-sm md:text-[16px] md:leading-6 font-medium mt-2">
          Enter your email address to receive important updates about your Tropees
        </div>
      </div>
      <DsInput
        v-model.trim="email"
        wrapper-class="w-full"
        placeholder="you@example.com"
        width-class="w-full"
        field-class="!justify-start"
        leading-icon="c_EnvelopeFilled"
        :disabled="enteredEmailIsVerified"
        :has-error="isEmailAlreadyTaken || (!email ? false : !!validation.fieldErrorMessages.value.email)"
        :helper-text="isEmailAlreadyTaken ? 'This email is already connected to a different Tropee profile' : (!email ? '' : validation.fieldErrorMessages.value.email)"
        :error-absolute-positioned="isModal"
        @enter="finishSignUp"
      />
      <div class="flex max-md:flex-col items-center gap-3 w-full">
        <DsButton
          class="w-full flex-1"
          style-type="secondary"
          :loading="isDisconnecting"
          @click="disconnect"
        >
          Log out
        </DsButton>
        <DsButton
          class="w-full flex-1"
          :disabled="!email || isFinishing"
          :loading="isFinishing || isSendingCode"
          @click="finishSignUp"
        >
          Finish sign-up
        </DsButton>
      </div>
    </div>
    <VerifyEmailWithCode
      v-else
      :code="code"
      :code-re-requested="codeReRequested"
      :code-request-count-down="codeRequestCountDown"
      :invalid-code="invalidCode"
      :is-sending-code="isSendingCode"
      :is-verify-loading="isVerifyLoading || isFinishing"
      :is-modal="isModal"
      @change-code="handleCodeChange"
      @send-code="sendCode"
      @verify-code="handleVerifyCode"
    />
  </transition>
</template>

<script setup lang="ts">
import DsInput from '@core-design/components/Design/DsInput.vue'
import { useThisUserPrivateFetcher } from '@core-lib/composables/user-fetcher'
import { computed, ref, watch } from 'vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useZodValidateObject } from '@core-lib/composables/zod-validate'
import { z } from 'zod'
import { useVerifyEmailWithCode } from '@core-lib/composables/verify-email-with-code'
import VerifyEmailWithCode from '@core-lib/components/auth/VerifyEmailWithCode.vue'

withDefaults(defineProps<{
  isModal?: boolean
}>(), {
  isModal: false,
})

const { user } = useThisUserPrivateFetcher()
const email = ref('')
const isDisconnecting = ref(false)
const finishPressed = ref(false)
const isFinishing = ref(false)

watch(user, user => {
  if (!user) return
  email.value = user.isEmailVerified ? user.email : ''
}, { immediate: true })

const enteredEmailIsVerified = computed(() => {
  return user.value?.isEmailVerified && email.value === user.value.email
})

const validation = useZodValidateObject(z.object({
  email: z.string({ required_error: 'This field is required' }).trim().email('Email address is invalid'),
}), computed(() => ({
  email: email.value,
})))

const {
  code, nonce, isSendingCode, invalidCode, codeReRequested, codeRequestCountDown, isVerifyLoading, isEmailAlreadyTaken,
  handleCodeChange, sendCode, verifyEmailCode,
} = useVerifyEmailWithCode({ email, validate: validation.validate, withNotify: true })

watch(email, () => {
  isEmailAlreadyTaken.value = false
})
watch(email, () => {
  if (!finishPressed.value) return
  validation.validate()
})

const disconnect = async () => {
  isDisconnecting.value = true
  try {
    await ServiceContainer.authFacade.logout()
  } finally {
    isDisconnecting.value = false
  }
}

const saveUserData = async () => {
  try {
    isFinishing.value = true
    await ServiceContainer.authService.renewSession()
  } finally {
    isFinishing.value = false
  }
}

const finishSignUp = async () => {
  if (!email.value) return
  const isValid = await validation.validate()
  finishPressed.value = true
  if (!isValid) return
  if (enteredEmailIsVerified.value) {
    await saveUserData()
    return
  }
  await sendCode()
}

const handleVerifyCode = async () => {
  isFinishing.value = true
  await verifyEmailCode()
  if (invalidCode.value) {
    isFinishing.value = false
    return
  }
  await saveUserData()
}
</script>

<style scoped>

</style>