import { ref } from 'vue'
import { Maybe } from '@core-lib/models/common'
import AnnouncementService, {
  Announcement,
} from '@core-lib/webapp-services/announcement-service'

let announcement: Maybe<Announcement> = null
const announcementContentValue = ref('')
const hasAnnouncementValue = ref(false)
const userClosedAnnouncement = ref(false)

const useAnnouncementFetcher = () => {

  userClosedAnnouncement.value = sessionStorage.getItem('hasClosedAnnouncement') == 'true'
  if (userClosedAnnouncement.value) {
    hasAnnouncementValue.value = false
  }

  const onUserHasClosedAnnouncement = () => {
    userClosedAnnouncement.value = true
    hasAnnouncementValue.value = false
    sessionStorage.setItem('hasClosedAnnouncement', 'true')
  }

  const init = async () => {
    if (!userClosedAnnouncement.value && announcement === null) {
      announcement = await AnnouncementService.fetchAnnouncement()

      hasAnnouncementValue.value = announcement != null && announcement.message !== ''
      if (hasAnnouncementValue.value) {
        announcementContentValue.value = announcement?.message || ''
      }
    }
  }

  return {
    loadAnnouncement: init,
    hasAnnouncement: hasAnnouncementValue,
    announcementContent: announcementContentValue,
    onUserHasClosedAnnouncement,
  }
}

export { useAnnouncementFetcher }
