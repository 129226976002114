import XRegExp from 'xregexp'
import crypto from 'crypto'

export const sha256Hex = (value: string) => crypto.createHash('sha256').update(value).digest('hex')

export const middleTruncate = (input: string, length = 12) => {
  if (!input) return ''
  const separator = '...'

  if (input.length <= length) return input

  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow/2),
    backChars = Math.floor(charsToShow/2)

  return input.substr(0, frontChars) +
    separator +
    input.substr(input.length - backChars)
}

export function truncate(input: string, length = 12) {
  const separator = '...'
  if (input.length <= length) return input
  return input.slice(0, length) + separator
}

export const walletTruncate = (input: string) => {
  if (!input) return ''
  const separator = '...'

  if (input.length <= 13) return input

  return input.substr(0, 6) +
    separator +
    input.substr( -4)
}

export const usernameTruncate = (username: string, walletAddress: string) => {
  if (username === walletAddress) return walletTruncate(walletAddress)

  return username
}

export const slug = (str: string) => {
  return str
    .replace(/^\s+|\s+$/g, '').toLowerCase() // trim and lowercase
    .replace(XRegExp('[^\\p{L}0-9 -]', 'g'), '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-|-$/g, '') || '-' // Trim dashes
}

export const capitalize = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function replaceURLWithHTMLLinks(text: string) {
  const exp = /((?:https:\/\/|http:\/\/|www\.)[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig
  return text.replace(exp,(url) => {
    return `<a href=${(url.startsWith('www') ? 'https://' : '' ) + url} target="_blank" class="tropee-link">${url}</a>`
  })
}