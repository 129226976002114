import { IdentifyInput, PageInput, TrackInput } from '@core-lib/webapp-services/analytics/plugins/common'

let alreadyInitialized = false
const init = () => {
  if (alreadyInitialized) return
  alreadyInitialized = true
  // mixpanel.init('1401cad2f41d767b09aa6f9e4afb0cbc', {
  //   persistence: 'localStorage',
  //   api_host: 'https://mpapi.tropee.com',
  // })
  const maybeUtmParams = localStorage.getItem('utm_params')
  if (maybeUtmParams) {
    // mixpanel.register_once(JSON.parse(maybeUtmParams))
  }
}
export function pluginMixpanel() {
  return {
    name: 'mixpanel-custom',
    initialize: () => {
      init()
    },
    track: ({ payload }: TrackInput) => {
      init()
      // mixpanel.track(payload.event, payload.properties)
    },
    identify: ({ payload }: IdentifyInput) => {
      init()
      // if (payload.userId) {
      //   mixpanel.identify(payload.userId)
      //   mixpanel.people.set(payload.traits)
      // } else {
      //   mixpanel.reset()
      // }
    },
    page: ({ payload }: PageInput) => {
      init()
      // mixpanel.track(`Viewed ${payload.properties.name}`, payload.properties)
    },
    loaded: () => {
      return true
    },
  }
}
