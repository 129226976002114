<template>
  <AnnouncementBar
    v-if="showPricingAnnouncement && announcementBarData"
    ref="announcementComponentPricing"
    :duration="marqueDuration"
    :content="announcementBarData.message"
    :bg-color="announcementBarData.bgColor"
    :text-color="announcementBarData.textColor"
    :pause="!isScrolling"
    clickable
    close-dark
    :key="isScrolling"
    @close="hidePricingAnnouncementClicked"
    @click="goToPricing"
  />
  <AnnouncementBar
    v-if="hasAnnouncement"
    ref="announcementComponent"
    :duration="marqueDuration"
    :content="announcementContent"
    @close="hideAnnouncementClicked"
  />
  <NavBar
    v-if="!isUtilityPage && !isLoyaltyPage"
    :is-announcement-visible="hasAnnouncement || showPricingAnnouncement"
    :announcement-section="announcementSectionCasted"
    :announcement-content="announcementContent || announcementBarData?.message"
    :user-closed-announcement="userClosedAnnouncement || prisingAnnouncementClosed"
    :navbar-logo-clickable="navbarLogoClickable"
    :hide-create-tropee-button="hideNavbarCreateTropeeButton"
    @announcement-closed="onUserHasClosedAnnouncement"
  />
  <TropeeLoginModalWrapper />
  <AnnouncementModalWrapper />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import { CreatorRouteName, HolderRouteName } from '@core-lib/routes'
import { useAnnouncementFetcher } from '@core-lib/composables/announcement-fetcher'
import AnnouncementBar from '@core-lib/components/AnnouncementBar.vue'
import AnnouncementModalWrapper from '@core-lib/components/AnnouncementModalWrapper.vue'
import NavBar from '@/components/NavBar.vue'
import TropeeLoginModalWrapper from '@core-lib/components/TropeeLoginModalWrapper.vue'
import { useBillingCurrentPlan, useBillingUsageFetcher } from '@core-lib/composables/billing'
import useIsScreenSmallerThan from '@core-lib/composables/is-screen-smaller-than'
import utilityCampaignService from '@/services/utility-campaign-service'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

withDefaults(defineProps<{
  navbarLogoClickable?: boolean
  hideNavbarCreateTropeeButton?: boolean
}>(), {
  navbarLogoClickable: false,
  hideNavbarCreateTropeeButton: false,
})

const currentRouteName = computed(() => router.currentRoute.value.name)
const isScrolling = useIsScreenSmallerThan(1200)
const route = useRoute()
const { currentPlan } = useBillingCurrentPlan()
const isAuthenticated = authFacade.isAuthenticated

const announcementComponent = ref()
const announcementComponentPricing = ref()
const announcementSectionCasted = computed(() => announcementComponent.value?.announcementSection as HTMLElement || announcementComponentPricing.value?.announcementSection as HTMLElement)

const isUtilityPage = computed(() => currentRouteName.value === HolderRouteName.UTILITY)
const isLoyaltyPage = computed(() => currentRouteName.value === HolderRouteName.ORGANIZATION_LOYALTY)

const userClosedAnnouncement = ref(false)
const { hasAnnouncement, announcementContent, onUserHasClosedAnnouncement, loadAnnouncement } = useAnnouncementFetcher()

const marqueDuration = 55

const hideAnnouncementClicked = () => {
  userClosedAnnouncement.value = true
  onUserHasClosedAnnouncement()
  if (isUtilityPage.value) {
    let existingQueryParam = router.currentRoute.value.query?.announcementClosed
    router.replace({
      path: router.currentRoute.value.path,
      query: {
        ...router.currentRoute.value.query,
        'announcementClosed': `${existingQueryParam ? Number(existingQueryParam) + 1 : 0}`,
      },
    })
  }
}
const hidePricingAnnouncementClicked = () => {
  if (!announcementBarData.value) return
  userClosedPricingAnnouncement[announcementBarData.value.id] = true
  closePricingAnnouncement(announcementBarData.value.id)
}

const prisingAnnouncementClosed = computed(() => {
  if (!announcementBarData.value) return false
  return userClosedPricingAnnouncement[announcementBarData.value.id] === true
})

const { announcementBarData, userClosedPricingAnnouncement, closePricingAnnouncement  } = useBillingUsageFetcher()

async function goToPricing() {
  await router.push({
    name: CreatorRouteName.PRICING,
  })
}
const utilityCampaignId = computed(() => route.params.utilityCampaignId as string)
const tropees = ref<string[]>([])
const getUserTropees = async () => {
  if (!isAuthenticated.value) return []
  try {
    tropees.value = await utilityCampaignService.getCreatorsPublishedIds()
  } catch (e) {
    console.error(e)
  }
}
getUserTropees()
const currentUsersTropee = computed(() => tropees.value.includes(utilityCampaignId.value))

const showPricingAnnouncement = computed(() => {
  if (!announcementBarData.value || !currentPlan.value?.isFree) return false
  return isUtilityPage.value && currentUsersTropee.value && userClosedPricingAnnouncement[announcementBarData.value.id] !== true
})

onMounted(async () => {
  await loadAnnouncement()
})
</script>

<style scoped>

</style>
