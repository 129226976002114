import { z } from 'zod'

export const JSON_FILE_PREFIX = 'jsonfile://'

const jsonFileSchema = z.object({
  fullPath: z.string().min(1).max(200).regex(/\.[a-zA-Z0-9]{1,5}$/),
  name: z.string().min(1).max(200).regex(/\.[a-zA-Z0-9]{1,5}$/),
  size: z.number(),
  type: z.string(),
  url: z.string().min(1).optional(),
})
export type JsonFile = {
  fullPath: string
  name: string
  size: number
  type: string
  url?: string
}
export const isJsonFile = (json: string) => json.startsWith(JSON_FILE_PREFIX)

export const parse = (json: string): JsonFile => {
  if (!isJsonFile(json)) throw new Error('Not a JSON file')
  const obj = JSON.parse(json.substring(JSON_FILE_PREFIX.length))
  return jsonFileSchema.parse(obj)
}

export const parseJsonFile = parse

export const serialize = (file: JsonFile) => `${JSON_FILE_PREFIX}${JSON.stringify(file)}`

