<template>
  <div
    class="transition-opacity duration-200"
    :class="show ? 'opacity-100' : 'opacity-0'"
  >
    <div
      id="base-layout"
      class="w-full min-h-screen h-full scroll-smooth flex flex-col justify-between"
      :class="{ 'overflow-x-hidden max-w-full' : isHomepage || isOrganizationProfilePage || isExplorePage}"
    >
      <div id="utility_banner" />
      <TheHeaderDs
        v-if="!hideHeader"
        :navbar-logo-clickable="navbarLogoClickable"
        :hide-navbar-create-tropee-button="hideNavbarCreateTropeeButton"
      />
      <div class="flex-1 flex flex-col h-full">
        <slot />
      </div>
      <footer>
        <DsFooter
          v-if="!hideFooter"
          :class="{ 'mt-8': !isFullHeight }"
          :show-extended="isHomepage || isExplorePage"
          :subscribed="subscribed"
          @email-subscription-submit="subscribe"
        />
      </footer>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheHeaderDs from '@/layouts/TheHeaderDs.vue'
import { RouteName } from '@/router'
import { useRoute, useRouter } from 'vue-router'
import { computed, ComputedRef, ref, watch } from 'vue'
import { apiClient } from '@/openapi/client'
import DsFooter from '@core-design/components/Design/DsFooter.vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

const route = useRoute()
const router = useRouter()

const isLoginPage = computed(() => route.name === RouteName.LOGIN)
const isHomepage = computed(() => route.name === RouteName.HOMEPAGE)
const isExplorePage = computed(() => route.name === RouteName.EXPLORE)
const isOrganizationProfilePage = computed(() => route.name === RouteName.ORGANIZATION_PROFILE)
const meta = computed(() => route.meta)

const show = computed(() => !!route?.name && (!isLoginPage.value || isLoginPage.value && canShowConnectPage.value))
const canShowConnectPage = ref(false)

const isFullHeight = computed(() => meta.value.fullHeight)
const hideHeader = computed(() => meta.value.hideHeader || isLoginPage.value)
const hideFooter = computed(() => meta.value.hideFooter || isLoginPage.value)
const navbarLogoClickable: ComputedRef<boolean> = computed(() => (meta.value?.navbarLogoClickable || false) as boolean)
const hideNavbarCreateTropeeButton: ComputedRef<boolean> = computed(() => (meta.value?.hideNavbarCreateTropeeButton || false) as boolean)

watch([authFacade.isAuthenticated, meta], async ([isAuthenticated, meta]) => {
  if (!isAuthenticated && meta.requiresAuth) {
    await router.replace({ name: RouteName.LOGIN, query: { redirect: route.path } })
  }
}, { immediate: true })

const subscribed = ref(false)
const subscribe = async (email: string) => {
  await apiClient.subscribeNewsletter({
    email,
  })
  subscribed.value = true
}

watch(hideHeader, hideHeader => {
  if (!hideHeader || !isLoginPage.value) return
  canShowConnectPage.value = true
}, { immediate: true })
</script>

<style lang="scss" scoped>
</style>
