<template>
  <template v-if="activitiesFetched">
    <div
      v-if="userLoyaltyActivities.length"
      ref="list"
      class="flex flex-col overflow-y-auto min-h-[164px] md:min-h-[170px] max-[375px]:max-h-[539px] min-[376px]:max-h-[593px] md:max-h-[464px] px-4 md:rounded-b-[32px]"
    >
      <div
        v-for="(userLoyaltyActivity, index) in userLoyaltyActivities"
        :key="index"
        class="p-3 flex items-center gap-x-6 justify-between"
        :class="{'border-b border-b-gray-100': index < userLoyaltyActivities.length - 1, 'mb-2': index === userLoyaltyActivities.length - 1}"
      >
        <div class="flex-1 min-w-0 flex flex-col">
          <div
            class="truncate text-ds-dark-primary text-sm md:text-[16px] md:leading-6 font-medium"
            v-html="getActivityTitle(userLoyaltyActivity)"
          />
          <div
            class="text-sm md:text-[16px] md:leading-6 font-medium tracking-[0.14px] md:tracking-[0.16px]"
            :class="isIncomeActivity(userLoyaltyActivity) ? 'text-emerald-500' : 'text-gray-500'"
          >
            {{ isIncomeActivity(userLoyaltyActivity) ? '+' : '' }}{{ userLoyaltyActivity.points }}
            {{ userLoyaltyActivity.points === 1 ? translateLY('point') : translateLY('points') }}
          </div>
        </div>
        <div
          class="text-gray-500 text-sm md:text-[16px] md:leading-6 font-medium tracking-[0.14px] md:tracking-[0.16px]"
        >
          {{ getFormattedTimestamp(userLoyaltyActivity) }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="py-12 flex flex-col gap-y-1 items-center justify-center max-w-[335px] mx-auto"
    >
      <div class="text-[16px] leading-6 font-semibold text-center">
        {{ translateLY('no_activity_yet') }}
      </div>
      <div class="text-center text-gray-500 text-sm font-medium">
        {{ translateLY('activity_empty_state_description') }}
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { useGetUserLoyaltyActivity } from '@core-lib/composables/loyalty/user-loyalty-activities'
import { LoyaltyProgramIdentifier, UserLoyaltyActivity } from '@core-lib/openapi/client'
import { ref, watch } from 'vue'
import { useScroll } from '@vueuse/core'
import { useI18n } from '@core-lib/composables/i18n'
import { DateTime } from 'luxon'

const props = defineProps<{
  identifier: LoyaltyProgramIdentifier
}>()

const { translate: translateLY } = useI18n({ prefix: 'loyalty__' })

const {
  userLoyaltyActivities,
  fetchNextPage,
  hasNextPage,
  isFetched: activitiesFetched,
} = useGetUserLoyaltyActivity(props.identifier)

const list = ref()
const { arrivedState } = useScroll(list)
watch(arrivedState, (arrivedState) => {
  if (!arrivedState.bottom) return
  if (hasNextPage && hasNextPage.value) {
    fetchNextPage()
  }
})

function getActivityTitle(activity: UserLoyaltyActivity) {
  if (activity.metadata.type === 'join') {
    return 'Joined'
  }
  if (activity.metadata.type === 'action') {
    return activity.metadata.requirementName
  }
  if (activity.metadata.type === 'manual') {
    return `Creator manual update: <br/> ${activity.metadata.reason}`
  }
  return activity.metadata.tropee.title
}

function isIncomeActivity(activity: UserLoyaltyActivity) {
  return activity.metadata.type === 'action' || activity.metadata.type === 'join' || (activity.metadata.type === 'manual' && activity.points >= 0)
}

function getFormattedTimestamp(activity: UserLoyaltyActivity) {
  return DateTime.fromISO(activity.timestamp).toFormat('LLL dd, yyyy')
}
</script>
