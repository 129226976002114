import { ref } from 'vue'

export type NotificationType = 'success' | 'error' | 'warn'
const messages = ref<{ type?: NotificationType, text: string }[]>([])

export const useToaster = () => {

  const notify = (options: {text: string, type?: NotificationType}) => {
    if (messages.value.length === 3) messages.value.shift()
    messages.value.push({ type: options.type, text: options.text })
    setTimeout(() => {
      messages.value.shift()
    }, 3000)
  }

  return {
    messages,
    notify,
  }
}

export const useToasterMessages = () => {
  return {
    messages,
  }
}
