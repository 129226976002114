import translations from './translations.json'
import { useStorage } from '@vueuse/core'
import { Nullable, Optional } from '@core-lib/models/common'
import { formatNumber } from '@core-lib/helpers/utils'

const t = translations as unknown as Record<string, Record<string, string>>

const langFromParams = new URLSearchParams(window.location.search).get('lang')

export class Translator {
  public readonly selectedLanguage
  public static VALID_LANGUAGES = ['en', 'fr']

  constructor() {
    const browserLanguage = navigator.language?.split('-')[0] || 'en'

    this.selectedLanguage = useStorage<Nullable<string>>('selectedLanguage', Translator.VALID_LANGUAGES.includes(browserLanguage) ? browserLanguage : 'en')
  }

  public get language() {
    return langFromParams || 'en'
    // return this.selectedLanguage.value || 'en'
  }

  public setLanguage(lang: string) {
    if (!Translator.VALID_LANGUAGES.includes(lang)) {
      console.warn('Invalid language', lang)
      return false
    }
    if (this.selectedLanguage.value === lang) return false
    this.selectedLanguage.value = lang
    return true
  }

  public translate(key: string) {
    return t[this.language][key] as string || t['en'][key] as string
  }

  public translateN(key: string, n: number) {
    const keyToUse = `${key}[${n > 1 ? 'n' : '1'}]`
    const translation = t[this.language][keyToUse] as Optional<string> || t['en'][keyToUse] as Optional<string>
    if (!translation) throw new Error(`Missing translation for ${keyToUse}`)
    return translation.replace('{n}', String(formatNumber(n)))
  }

  public translateX(key: string, x: string) {
    const keyToUse = `${key}[x]`
    const translation = t[this.language][keyToUse] as Optional<string> || t['en'][keyToUse] as Optional<string>
    if (!translation) throw new Error(`Missing translation for ${keyToUse}`)
    return translation.replace('{x}', String(x))
  }

  public translateWithVars(key: string, vars: Record<string, string | number>) {
    const re = new RegExp(Object.keys(vars).map(k => `\\{${k}}`).join('|'),'gi')
    const translation = t[this.language][key] as Optional<string> || t['en'][key] as Optional<string>
    if (!translation) throw new Error(`Missing translation for ${key}`)
    return translation.replace(re, (matched: string) => {
      return String(vars[matched.slice(1, -1)])
    })
  }

}

export default new Translator()
