import { LoyaltyProgramIdentifier, UserLoyaltyActivity } from '@core-lib/openapi/client'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { userApiClient } from '@core-lib/webapp-services/client'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { computed } from 'vue'
import { PaginatedByToken } from '@core-lib/models/common'

export const useGetUserLoyaltyActivity = (identifier: LoyaltyProgramIdentifier) => {
  const {
    data,
    isFetching: isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetched,
  } = useInfiniteQuery<PaginatedByToken<UserLoyaltyActivity>>({
    queryKey: ['user-loyalty-activity', identifier, authFacade.userIdentifier],
    queryFn: async ({ pageParam = '' }) => {
      const response = await userApiClient.getUserLoyaltyActivities({
        identifier,
        page: {
          size: 10,
          token: pageParam,
        },
      })
      return {
        items: response.data.activities,
        nextPage: response.data.nextPage,
      }
    },
    keepPreviousData: true,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: authFacade.isAuthenticated,
  })

  const userLoyaltyActivities = computed<UserLoyaltyActivity[]>(() => {
    if (!data.value) return []

    return data.value.pages.reduce<UserLoyaltyActivity[]>((agg, page) => {
      return agg.concat(page.items)
    }, [])
  })

  return {
    userLoyaltyActivities,
    isLoading,
    fetchNextPage,
    refetch,
    isRefetching,
    hasNextPage,
    isFetched,
  }
}