<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.71875"
      width="24"
      height="24"
      rx="4"
      fill="#AB9EF2"
    />
    <g clip-path="url(#clip0_54923_696)">
      <path
        d="M5.51796 19.8385C7.9204 19.8385 9.72585 17.7491 10.8033 16.0981C10.6723 16.4634 10.5994 16.8287 10.5994 17.1793C10.5994 18.1436 11.1528 18.8303 12.2448 18.8303C13.7445 18.8303 15.3461 17.5154 16.176 16.0981C16.1178 16.3027 16.0887 16.4926 16.0887 16.668C16.0887 17.34 16.4672 17.7638 17.2389 17.7638C19.6705 17.7638 22.1166 13.4536 22.1166 9.68411C22.1166 6.74736 20.6314 4.16129 16.904 4.16129C10.352 4.16129 3.29025 12.1679 3.29025 17.34C3.29025 19.3709 4.38227 19.8385 5.51796 19.8385ZM14.6472 9.36268C14.6472 8.63214 15.0549 8.12078 15.6518 8.12078C16.2342 8.12078 16.6419 8.63214 16.6419 9.36268C16.6419 10.0932 16.2342 10.6192 15.6518 10.6192C15.0549 10.6192 14.6472 10.0932 14.6472 9.36268ZM17.7631 9.36268C17.7631 8.63214 18.1708 8.12078 18.7677 8.12078C19.3501 8.12078 19.7578 8.63214 19.7578 9.36268C19.7578 10.0932 19.3501 10.6192 18.7677 10.6192C18.1708 10.6192 17.7631 10.0932 17.7631 9.36268Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_54923_696">
        <rect
          width="18.8571"
          height="15.6772"
          fill="white"
          transform="translate(3.29025 4.16129)"
        />
      </clipPath>
    </defs>
  </svg>
</template>