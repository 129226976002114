export class ImageService {
  public static fitImageToWidth(imageUrl: string, width: number, forceFormat = false, allowAnimation = false, quality = 80, allowGifResize = true, blur: number | undefined = undefined) : string {
    if (!imageUrl || !imageUrl.startsWith('http')) return imageUrl
    return ImageService.fitImageToSize(imageUrl, 'width', width, forceFormat, allowAnimation, quality, allowGifResize, blur)
  }
  public static fitImageToHeight(imageUrl: string, height: number, forceFormat = false, allowAnimation = false, quality = 80, allowGifResize = true, blur: number | undefined = undefined) : string {
    if (!imageUrl || !imageUrl.startsWith('http')) return imageUrl
    return ImageService.fitImageToSize(imageUrl, 'height', height, forceFormat, allowAnimation, quality, allowGifResize, blur)
  }
  private static fitImageToSize(imageUrl: string, sizeKey: 'width' | 'height', sizeValue: number, forceFormat = false, allowAnimation = false, quality = 80, allowGifResize = true, blur: number | undefined = undefined) : string {
    if (!blur && (!imageUrl || window.location.hostname === 'localhost.tropee.com')) return imageUrl
    const extension = imageUrl.toLowerCase().split('.').pop() as string
    if (!allowGifResize && extension == 'gif') return imageUrl
    if (!forceFormat && !ImageService.isAllowedExtention(extension, blur != undefined)) return imageUrl
    const params: string[] = [
      'fit=scale-down',
      'format=auto',
      `quality=${quality}`,
      `onerror=redirect`,
      `anim=${allowAnimation}`,
      `${sizeKey}=${sizeValue}`,
    ]

    if (blur) {
      params.push(`blur=${blur}`)
    }

    if (['png', 'jpg', 'jpeg'].includes(extension)) {
      params.push('format=auto')
    }
    return `https://www.tropee.com/cdn-cgi/image/${params.join(',')}/${imageUrl}`
  }

  public static isAllowedExtention(extension: string, isBlur = false) : boolean {
    if (isBlur && extension == 'svg') return false
    return ImageService.allowedImageFormats.includes(extension)
  }
  private static allowedImageFormats = ['png', 'jpg', 'gif', 'jpeg', 'webp', 'svg']
}