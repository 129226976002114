import { useRoute } from 'vue-router'
import { ref } from 'vue'

export function useSocialErrors() {
  const route = useRoute()
  const showModal = ref(false)

  const twitterErrorKey = 'twitter_account_already_taken'
  const discordErrorKey = 'discord_account_already_taken'

  const errorRouteQuery = route.query.error
  const usernameRouteQuery = route.query.username
  const providerRouteQuery = route.query.provider
  const hasTwitterError = errorRouteQuery === twitterErrorKey
  const hasDiscordError = errorRouteQuery === discordErrorKey

  showModal.value = !!errorRouteQuery
  if (!!errorRouteQuery || providerRouteQuery) {
    window.history.replaceState(
      history.state,
      document.title,
      window.location.pathname,
    )
  }

  return { hasTwitterError, hasDiscordError, usernameRouteQuery, showModal }
}