<template>
  <BaseModal
    :show="shouldShowFileSelectModal"
    custom-size="md:max-w-[495px]"
    :close-button="false"
    :without-padding="true"
  >
    <div class="text-left py-5 md:py-8 px-4 md:px-8 flex flex-col">
      <h4 class="text-content-1400 text-[16px] leading-[24px] md:text-2xl font-semibold">
        {{ props.title }}
      </h4>
      <p class="text-content-1400 text-sm mt-1 md:mt-3 mb-4">
        {{ props.description }}
      </p>
      <DsButton
        class="mb-4 justify-center"
        @click="onModalFileSelectClick"
      >
        Select file
      </DsButton>
      <DsButton
        class="justify-center"
        style-type="secondary"
        @click="onCancel"
      >
        Cancel
      </DsButton>
    </div>
  </BaseModal>
</template>
<script lang="ts" setup>

import { onMounted, ref, watch } from 'vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import BaseModal from '@core-lib/components/BaseModal.vue'

const props = defineProps<{
  title: string,
  description: string,
  showFileSelectModal: boolean
}>()

const emit = defineEmits<{
  (event: 'file-select-click'): void,
  (event: 'cancel'): void
}>()

onMounted(() => {
  watch(() => props.showFileSelectModal, (value) => {
    shouldShowFileSelectModal.value = value
  }, { immediate: true })
})

const shouldShowFileSelectModal = ref(false)

const onCancel = () => {
  shouldShowFileSelectModal.value = false
  emit('cancel')
}

const onModalFileSelectClick = () => {
  shouldShowFileSelectModal.value = false
  emit('file-select-click')
}

</script>
