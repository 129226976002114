export function handleKeyDown(event: KeyboardEvent) {
  if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'a')) return
  if (!['0','1','2','3','4','5','6','7','8','9','Delete', 'Backspace', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.preventDefault()
    return
  }
}

export function handlePaste(event: ClipboardEvent, inputType: string) {
  const textValue = event.clipboardData?.getData('text') ?? ''
  if (inputType === 'number' && isNaN(parseInt(textValue))) {
    event.preventDefault()
    return false
  }
  return true
}