<template>
  <div
    class="rounded text-center min-w-[42px] h-[21px] flex items-center justify-center cursor-default"
    :class="[textColor, bgColor]"
  >
    <span
      v-if="text"
      class="pt-[1.5px] block w-full font-semibold uppercase tracking-[.24px]"
      :class="perConcept ? 'text-xs leading-[10px] tracking-[.24px]' : 'text-sm tracking-[.24px]'"
    >
      {{ text }}
      <span
        v-if="perConcept"
        class="-mt-px block uppercase text-[7px] leading-[7px] tracking-[0px]"
      > {{ conceptText }} </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { getRecurringLabel, recurringUnits } from '@core-lib/models/requirements-v2'

const props = withDefaults(defineProps<{
  text: string
  bgColor?: string
  textColor?: string
  perConcept?: boolean
  concept?: number | 'nft'
}>(), {
  bgColor: 'bg-emerald-500',
  textColor: 'text-white',
  perConcept: false,
  concept: undefined,
})

const conceptText = computed(() => {
  if (!props.concept) return ''
  if (props.concept === 'nft') return `per ${props.concept}`
  if (recurringUnits.includes(props.concept)) {
    return getRecurringLabel(props.concept)
  }
  return ''
})
</script>
