import Analytics, { AnalyticsInstance, PageData } from 'analytics'
import { getDeviceInfo } from '../device-detector'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { pluginGoogleAnalytics } from '@core-lib/webapp-services/analytics/plugins/google-analytics'
import { pluginHotjar } from '@core-lib/webapp-services/analytics/plugins/hotjar'
import { Event, isGapEvent } from '@core-lib/webapp-services/analytics/events/base'
import { pluginMixpanel } from '@core-lib/webapp-services/analytics/plugins/mixpanel'
import { useIsPreview } from '@core-lib/composables/preview'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

export type RestOfTrackMethodArgs = Parameters<(options?: unknown, callback?: (...params: unknown[]) => unknown) => Promise<void>>

type DeviceInfo = Record<string, string | number | boolean>

export interface TropeeAnalytics {
  track(event: Event, ...params: RestOfTrackMethodArgs): void

  identify(userId: string, traits: Record<string, string>): void

  page(data?: PageData, options?: unknown): void
}

export abstract class FlowEvent implements Event {
  protected abstract flow: string
  protected abstract step: string
  public get name() {
    return `flow-${this.flow}-${this.step}`
  }
}

function getExtraPayload() {
  return {
    webapp: ServiceContainer.env.isCreatorApp ? 'creator' : 'member',
    ...(authFacade.selectedOrgId.value ? { organizationId: authFacade.selectedOrgId.value } : {}),
  }
}

class TropeeAnalyticsImplementation implements TropeeAnalytics {
  private readonly analytics: AnalyticsInstance

  constructor(options: Parameters<typeof Analytics>[0], private readonly deviceInfo: DeviceInfo) {
    this.analytics = Analytics(options)
  }

  private setEventLastFired(event: Event) {
    localStorage.setItem(`analytics.gapSeconds.${event.name}`, String(Date.now()))
  }

  private getEventLastFired(event: Event) {
    return Number(localStorage.getItem(`analytics.gapSeconds.${event.name}`) || 0)
  }

  public track(event: Event, ...params: RestOfTrackMethodArgs) {
    if ('disable' in event && event.disable) return
    if (isGapEvent(event)) {
      const lastFired = this.getEventLastFired(event)
      if (Date.now() - lastFired < event.gapSeconds * 1000) return
    }
    const payload = {
      ...getExtraPayload(),
      ...event.payload,
    }
    this.analytics.track(event.name, payload, ...params).catch(console.error)
    this.setEventLastFired(event)
  }

  public identify(userId: string, traits: Record<string, string>) {
    if (userId) {
      this.analytics.identify(userId, { ...traits, ...this.deviceInfo }).catch(console.error)
    } else {
      this.analytics.reset().catch(console.error)
    }
  }

  public page(data?: PageData, options?: unknown) {
    this.analytics.page({
      ...data,
      ...getExtraPayload(),
    }, options).catch(console.error)
  }
}

class TropeeAnalyticsConsoleLog implements TropeeAnalytics {
  private static log(action: string, extra: object) {
    console.debug(`analytics.${action}`, extra)
  }

  public identify(userId: string, traits: Record<string, string>): void {
    TropeeAnalyticsConsoleLog.log('identify', { userId, traits })
  }

  public page(data?: PageData, options?: unknown): void {
    TropeeAnalyticsConsoleLog.log('page', { data, options, extraPayload: getExtraPayload() })
  }

  public track(event: Event, ...params: RestOfTrackMethodArgs): void {
    TropeeAnalyticsConsoleLog.log('track', { event, params, extraPayload: getExtraPayload() })
  }
}

export class TropeeAnalyticsFactory {
  private static _instance: TropeeAnalytics

  public static instance(): TropeeAnalytics {
    if (!this._instance) {
      this._instance = this.createInstance()
    }
    return this._instance
  }

  private static createInstance() {
    const deviceInfo: DeviceInfo = {}
    for (const attr of Object.entries(getDeviceInfo())) {
      deviceInfo[`device.${attr[0]}`] = attr[1]
    }
    const plugins = [
      pluginGoogleAnalytics(),
      pluginHotjar(),
      pluginMixpanel(),
    ]
    // if (ServiceContainer.env.analytics.segmentWriteKey) {
    //   plugins.push(pluginSegment())
    // }
    return ServiceContainer.isProd && !useIsPreview() ? new TropeeAnalyticsImplementation({ app: 'webapp', plugins }, deviceInfo) : new TropeeAnalyticsConsoleLog()
  }
}
