<template>
  <ImageOrVideo
    v-if="avatar && !imageError"
    :url="avatar"
    custom-class="bg-gray-50"
    @image-error="imageError = true"
  />
  <span
    v-else
    class="block"
    :style="walletColor"
  />
</template>

<script lang="ts" setup>
import ImageOrVideo from '@core-lib/components/ImageOrVideo.vue'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  walletAddress: string
  avatar: string
}>()

const colors = [
  '#2F2AE9',
  '#DD00B5',
  '#FF734D',
  '#FFBD40',
  '#F9F871',
  '#8A7AAC',
  '#F8EBFF',
  '#CC0000',
  '#6FA8B7',
]
const imageError = ref(false)
const stringToFixedColors = (str: string, excludeColor?: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const mod = colors.length - 1
  const index = Math.abs(hash) % mod
  const color = colors[index]
  if (color !== excludeColor) {
    return color
  }
  return colors[(index + 1) % mod]
}

const walletColor = computed(() => {
  const walletAddress = props.walletAddress
  if (!walletAddress) return `background: linear-gradient(232.72deg, ${colors[0]} 24.63%, ${colors[1]} 87.39%);`
  const halfSize = Math.floor(walletAddress.length / 2)
  const color1 = stringToFixedColors(walletAddress.substring(0, halfSize))
  const color2 = stringToFixedColors(walletAddress.substring(halfSize), color1)
  return `background: linear-gradient(232.72deg, ${color1} 24.63%, ${color2} 87.39%);`
})
watch(() => props.avatar, (value) => {
  if (value) imageError.value = false
})
</script>