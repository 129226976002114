<template>
  <div
    ref="sliderWrapper"
    :class="{ 'hidden-chevrons': chevronsOnHover, ' mb-4 sm:mb-6': carouselSliderItemCount <= 1}"
    class="relative slider-wrapper 2xl:!left-0 overflow-y-visible w-full "
  >
    <div
      class="relative h-full flex"
      :class="sliderContentWrapperClass"
    >
      <div
        ref="sliderRef"
        :class="[{ 'md:!overflow-hidden': showChevrons && !overflowVisible }, sliderClass]"
        class="keen-slider !overflow-visible"
      >
        <slot class="no-flick" />
      </div>
      <transition
        name="fade-delay"
        mode="out-in"
        :css="bounceChevrons"
      >
        <template v-if="onlyDisableChevrons ? true : showLeftChevron">
          <span
            v-if="$slots.leftChevron"
            class="absolute"
            :class="leftChevronClass"
            @click="prevClick"
          >
            <slot
              name="leftChevron"
              :disabled="onlyDisableChevrons ? !showLeftChevron : false"
            />
          </span>
          <button
            v-else
            :class="`${leftChevronClass}`"
            class="chevron-left arrow arrow--left absolute top-0 bottom-0 my-auto focus:outline-none rounded-full w-10 h-10 lg:h-[46px] lg:w-[46px] bg-tropee-text/20 backdrop-blur flex items-center justify-center"
            title="Previous"
            type="button"
            :disabled="onlyDisableChevrons ? !showLeftChevron : false"
            @click="prevClick"
          >
            <DsIcon
              class="text-white"
              :class="chevronsIconClass"
              :icon="leftChevronIcon"
              :size="leftChevronIconClass"
              type="bold"
            />
          </button>
        </template>
      </transition>
      <transition
        name="fade-delay"
        mode="out-in"
        :css="bounceChevrons"
      >
        <template v-if="onlyDisableChevrons ? true : showRightChevron">
          <span
            v-if="$slots.rightChevron"
            class="absolute"
            :class="rightChevronClass"
            @click="nextClick"
          >
            <slot
              name="rightChevron"
              :disabled="onlyDisableChevrons ? !showRightChevron : false"
            />
          </span>
          <button
            v-else
            :class="`${rightChevronClass}` "
            class="chevron-right arrow arrow--right absolute top-0 bottom-0 my-auto -ml-px focus:outline-none rounded-full w-10 h-10 lg:h-[46px] lg:w-[46px] bg-tropee-text/20 backdrop-blur flex items-center justify-center"
            title="Next"
            type="button"
            :disabled="onlyDisableChevrons ? !showRightChevron : false"
            @click="nextClick"
          >
            <DsIcon
              class="text-white"
              :class="chevronsIconClass"
              :icon="rightChevronIcon"
              :size="rightChevronIconClass"
              type="bold"
            />
          </button>
        </template>
      </transition>
    </div>

    <template v-if="fadeSides">
      <div
        v-if="current > 0"
        class="absolute bottom-0 -translate-y-1/2 top-1/2 h-[calc(100%+8px)] w-1/6 ease pointer-events-none -left-0.5 opacity-100 left-fade-shadow dark:left-fade-shadow-dark"
        :style="fadeStyle"
      />
      <div
        v-if="!isLastStep"
        class="absolute bottom-0 -translate-y-1/2 top-1/2 w-1/6 h-[calc(100%+8px)] ease pointer-events-none -right-0.5 rotate-180 opacity-100 right-fade-shadow dark:right-fade-shadow-dark"
        :style="fadeStyle"
      />
    </template>

    <DsCarouselSlider
      v-if="carouselSliderItemCount > 1 && slider && sliderReady && showDots"
      :active-item="current"
      :item-count="carouselSliderItemCount"
      :style-type="dotsStyle"
      @click="(item) => setSlideIndex(item)"
    />
  </div>
</template>

<script lang="ts" setup>
import 'keen-slider/keen-slider.min.css'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useKeenSlider } from 'keen-slider/vue'
import { useScreenSize } from '@core-lib/composables/screen-size'
import DsIcon from './DsIcon.vue'
import DsCarouselSlider from '@core-design/components/Design/DsCarouselSlider.vue'
import { IconType } from '@core-design/types/icon'
import { Nullable } from '@core-lib/models/common'

const props = withDefaults(defineProps<{
  chevronsOnHover?: boolean,
  showDots?: boolean,
  forceShowChevrons?: boolean,
  onlyDisableChevrons?: boolean,
  sliderClass?: string,
  cardClass?: string,
  leftChevronIcon?: IconType,
  leftChevronClass?: string,
  leftChevronIconClass?: string,
  rightChevronIcon?: IconType,
  rightChevronClass?: string,
  rightChevronIconClass?: string,
  chevronsIconClass?: string,
  overflowVisible?: boolean,
  loop?: boolean,
  snap?: boolean,
  centered?: boolean,
  perView?: { default?: number | 'auto', sm?: number | 'auto', md?: number | 'auto', lg?: number | 'auto', xl?: number | 'auto', '2xl'?: number | 'auto', '3xl'?: number | 'auto' },
  spacing?: { default?: number, sm?: number, md?: number, lg?: number, xl?: number, '2xl'?: number, '3xl'?: number },
  recheckChevrons?: number
  fixWidth?: number
  outsideWidth?: number
  bounceChevrons?: boolean
  autoplay?: boolean
  drag?: boolean
  startingIndex?: number
  dotsStyle?: 'standard' | 'green-animated'
  sliderContentWrapperClass?: string
  fadeSides?: boolean
  fadeColor?: string
}>(), {
  chevronsOnHover: false,
  showDots: false,
  forceShowChevrons: false,
  onlyDisableChevrons: false,
  loop: false,
  snap: true,
  centered: false,
  overflowVisible: false,
  initialIndex: 1,
  cardClass: '',
  sliderClass: '',
  leftChevronIcon: 'Angle small left',
  leftChevronClass: '',
  leftChevronIconClass: 'text-2xl lg:text-[28px]',
  rightChevronIcon: 'Angle small right',
  rightChevronClass: '',
  rightChevronIconClass: 'text-2xl lg:text-[28px]',
  chevronsIconClass: '',
  spacing: () => ({
    default: 0,
  }),
  perView: () => ({
    default: 'auto',
  }),
  recheckChevrons: 0,
  fixWidth: undefined,
  outsideWidth: undefined,
  bounceChevrons: false,
  autoplay: false,
  drag: true,
  startingIndex: 0,
  dotsStyle: undefined,
  sliderContentWrapperClass: '',
  fadeSides: false,
  fadeColor: 'transparent',
})

const emit = defineEmits<{
  (e: 'ready'): void
  (e: 'currentChange', current: number): void
  (e: 'isLastStep', isLastStep: boolean): void
}>()

const sliderWrapper = ref<Nullable<HTMLElement>>(null)
const current = ref(0)
const currentForDots = ref(0)
const sliderReady = ref(false)
const showChevrons = ref(true)
const { width } = useScreenSize()
const carouselWidth = ref(props.fixWidth || props.outsideWidth || width.value)
const animation = { duration: 5000, easing: (t: any) => t }
const cardWidth = ref(0)
const cardsLength = ref(0)

const [sliderRef, slider] = useKeenSlider({
  loop: props.loop,
  mode: props.snap ? 'snap' : 'free-snap',
  slides: {
    origin: props.centered ? 'center' : 'auto',
    perView: props.perView?.default,
    spacing: props.spacing?.default,
  },
  breakpoints: {
    '(min-width: 640px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.sm || props.perView?.default,
        spacing: props.spacing?.sm || props.spacing?.default,
      },
    },
    '(min-width: 768px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.md || props.perView?.default,
        spacing: props.spacing?.md || props.spacing?.default,
      },
    },
    '(min-width: 1024px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.lg || props.perView?.default,
        spacing: props.spacing?.lg || props.spacing?.default,
      },
    },
    '(min-width: 1280px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.xl || props.perView?.default,
        spacing: props.spacing?.xl || props.spacing?.default,
      },
    },
    '(min-width: 1536px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.['2xl'] || props.perView?.default,
        spacing: props.spacing?.['2xl'] || props.spacing?.default,
      },
    },
    '(min-width: 1920px)': {
      slides: {
        origin: props.centered ? 'center' : 'auto',
        perView: props.perView?.['3xl'] || props.perView?.default,
        spacing: props.spacing?.['3xl'] || props.spacing?.default,
      },
    },
  },
  initial: current.value,
  drag: props.drag,
  created: (s) => {
    if (props.autoplay){
      s.moveToIdx(2, true, animation)
      return
    }
    if (props.startingIndex > 0) {
      s.moveToIdx(props.startingIndex, true)
    }
    nextTick(() => {
      sliderReady.value = true
    })
  },
  slideChanged: (s) => {
    current.value = s.track.details.rel
  },
  dragEnded: () => {
    if (current.value !== currentForDots.value) {
      currentForDots.value = current.value
    }
  },
  updated(s) {
    if (props.autoplay) {
      s.moveToIdx(s.track.details.abs + 2, true, animation)
    }
  },
  animationEnded(s) {
    if (props.autoplay) {
      s.moveToIdx(s.track.details.abs + 2, true, animation)
    }
  },
})

const isLastStep = computed(() => current.value === sliderMaxIdx())
watch(isLastStep, isLastStep => {
  emit('isLastStep', isLastStep)
}, { immediate: true })

function sliderMaxIdx() {
  if (!sliderReady.value || !slider.value) {
    return 0
  }
  if (!isFinite(slider.value.track.details.maxIdx)) {
    return Math.ceil(slider.value.track.details.slidesLength * (cardWidth.value || 1) / carouselWidth.value)
  }
  if (!slider.value.track.details.maxIdx) {
    return 0
  }
  return slider.value?.track.details.maxIdx
}

const carouselSliderItemCount = computed(() => {
  if (!props.loop) {
    return sliderMaxIdx() + 1
  }
  return sliderMaxIdx() <= 1 ? 0 : sliderMaxIdx()
})

const shouldChevronsShow = () => {
  if (!sliderWrapper.value) return
  const cards =  sliderWrapper.value.querySelectorAll(props.cardClass || '.keen-slider__slide')
  cardsLength.value = cards.length
  let allCardsWidth = 0
  cards.forEach(c => allCardsWidth += c.clientWidth)
  cardWidth.value = allCardsWidth/cards.length

  if (!props.forceShowChevrons) {
    showChevrons.value = false
    return
  }
  if (sliderReady.value && cards.length > 0) {

    carouselWidth.value = carouselWidth.value > 1920 ? 1920 : carouselWidth.value
    showChevrons.value = (cardWidth.value * cards.length) > carouselWidth.value

    if (showChevrons.value) {
      document.body.style.overflowX = 'hidden'
    } else {
      if (slider.value) {
        slider.value.options.disabled = true
        slider.value.options.loop = false
        slider.value.update()
      }
    }
  }
}

watch(() => props.recheckChevrons, () => {
  carouselWidth.value = props.fixWidth || props.outsideWidth || width.value
  shouldChevronsShow()
})

const bounceElement = (element: HTMLElement) => {
  if (!props.bounceChevrons) return
  element.classList.add('bouncing')
  setTimeout(() => {
    element.classList.remove('bouncing')
  }, 400)
}

// const skipSideSteps = ref(false)
//
// function isLastCardFullyVisible() {
//   if (!sliderWrapper.value) return
//   const cards =  sliderWrapper.value.querySelectorAll(props.cardClass || '.keen-slider__slide')
//   const lastCard = cards[cards.length - 1]
//   return (props.fixWidth ?? width.value) - lastCard.getBoundingClientRect().right > 0
// }

const prevClick = (e: Event) => {
  if (props.onlyDisableChevrons && !showLeftChevron.value) return
  bounceElement(e.currentTarget as HTMLElement)
  setTimeout(() => {
    currentForDots.value -= 1
    slider.value?.prev()
    // setTimeout(() => {
    //   if (skipSideSteps.value && current.value === sliderMaxIdx() - 1) {
    //     slider.value?.prev()
    //   }
    // }, 300)
  }, props.bounceChevrons ? 150 : 0)
}

const nextClick = (e: Event) => {
  if (props.onlyDisableChevrons && !showRightChevron.value) return
  bounceElement(e.currentTarget as HTMLElement)
  setTimeout(() => {
    currentForDots.value += 1
    slider.value?.next()
    // setTimeout(() => {
    //   if (!props.loop && current.value === sliderMaxIdx() - 1 && (isLastCardFullyVisible() || skipSideSteps.value)) {
    //     skipSideSteps.value = true
    //     slider.value?.next()
    //   }
    // }, 300)
  }, props.bounceChevrons ? 150 : 0)
}

const fadeStyle = computed(() => {
  return {
    '--fade-color': props.fadeColor,
  }
})

const setSlideIndex = (index: number) => {
  currentForDots.value = index
  slider.value?.moveToIdx(index, !props.loop)
}

const showLeftChevron = computed(() => {
  return slider.value && sliderReady.value && showChevrons.value && (props.loop ? true : current.value !== 0)
})

const showRightChevron = computed(() => {
  return slider.value && sliderReady.value && showChevrons.value && (props.loop ? true : current.value < sliderMaxIdx())
})

watch([width, () => props.outsideWidth], () => {
  nextTick(() => {
    if (!props.fixWidth) {
      carouselWidth.value = props.outsideWidth || width.value
      shouldChevronsShow()
      return
    }
    shouldChevronsShow()
  })
}, { immediate: true })
watch(sliderReady, (ready) => {
  if (!ready) return
  emit('ready')
  shouldChevronsShow()
})

watch(current, current => {
  emit('currentChange', current)
})

onMounted(shouldChevronsShow)
onBeforeUnmount(() => {
  document.body.style.overflowX = 'auto'
})

</script>

<style lang="scss" scoped>
@import url('keen-slider/keen-slider.css');

.hidden-chevrons {
  .arrow {
    @apply lg:opacity-0;
    transition: opacity 0.2s linear, transform 0.2s ease-in;
  }

  &:hover {
    .arrow {
      @apply lg:opacity-100;
    }
  }
}

.arrow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bouncing {
  animation: bounce .15s infinite alternate;
  animation-iteration-count: 2;
}

.border-inner {
  border: 1px solid rgba(209, 213, 219, 0.25);
  border-radius: 8px;
}

.no-flick {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  -webkit-backface-visibility: hidden;
}

@keyframes bounce {
  to { transform: scale(0.9); }
}

.fade-delay-enter-active,
.fade-delay-leave-active {
  transition-duration: 0ms;
  transition-property: opacity;
  transition-timing-function: ease;
  transition-delay: 400ms;
}

.fade-delay-enter,
.fade-delay-leave-active {
  opacity: 0
}

.right-fade-shadow {
  //background: linear-gradient(90deg, rgba(241, 241, 239, 0.75) 0%, rgba(241, 241, 239, 0) 100%);
  box-shadow: inset 28px 0 16px -2px var(--fade-color);
}

.right-fade-shadow-dark {
  box-shadow: inset 28px 0 16px -2px var(--fade-color);
}

.left-fade-shadow {
  box-shadow: inset 32px 0 18px -6px var(--fade-color);
}

.left-fade-shadow-dark {
  box-shadow: inset 32px 0 18px -6px var(--fade-color);
}
</style>
