import { ConnectionType, LoginType } from '@core-lib/models/wallet'
import { AuthProvider } from '@core-lib/webapp-services/auth-providers/auth-provider'
import { SocialAuthProvider } from '@core-lib/webapp-services/auth-providers/social-auth-provider'
import { CodeAuthProvider } from '@core-lib/webapp-services/auth-providers/code-auth-provider'
import { EmailAuthProvider } from '@core-lib/webapp-services/auth-providers/email-auth-provider'

export const authProviders: Partial<Record<LoginType, AuthProvider>> = {
  [ConnectionType.TWITTER]: new SocialAuthProvider(ConnectionType.TWITTER),
  [ConnectionType.DISCORD]: new SocialAuthProvider(ConnectionType.DISCORD),
  [ConnectionType.GOOGLE]: new SocialAuthProvider(ConnectionType.GOOGLE),
  [ConnectionType.CODE]: new CodeAuthProvider(),
  [ConnectionType.EMAIL]: new EmailAuthProvider(),
}
