<template>
  <div
    class="flex items-center justify-center m-auto"
    :class="{ 'gap-2 py-1 my-3': styleType === 'standard', 'gap-3 p-0 mt-2 md:mt-3': isGreenAnimated }"
  >
    <div
      v-for="(item, index) in itemCount"
      :key="index"
      :class="[{'w-15 bg-ds-green-primary': isGreenAnimated && activeItem === index, 'w-2 bg-ds-dark-primary dark:bg-white': !isGreenAnimated && activeItem === index, 'w-2 bg-gray-300 dark:bg-ds-dark-top-layer cursor-pointer': activeItem !== index}, itemClass]"
      class="flex rounded-full h-2 transition-all duration-300"
      @click="itemClicked(index)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

const props = withDefaults(defineProps<{
  itemCount: number
  activeItem: number
  itemClass?: string
  styleType?: 'green-animated' | 'standard'
}>(), {
  itemCount: 4,
  activeItem: 0,
  itemClass: '',
  styleType: 'green-animated',
})

const emit = defineEmits<{
  (e: 'click', item: number): void;
}>()

const activeItem = ref(props.activeItem)

watch(() => props.activeItem, (newVal) => {
  activeItem.value = newVal
})

const itemClicked = (item: number) => {
  emit('click', item)
}
const isGreenAnimated = computed(() => props.styleType === 'green-animated')
</script>

<style scoped>

</style>
