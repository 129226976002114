<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
    <rect y="0.5" width="35" height="35" rx="8" fill="#2C5FF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.5 30.25C24.2655 30.25 29.75 24.7655 29.75 18C29.75 11.2345 24.2655 5.75 17.5 5.75C10.7345 5.75 5.25 11.2345 5.25 18C5.25 24.7655 10.7345 30.25 17.5 30.25ZM14.25 14C13.8358 14 13.5 14.3358 13.5 14.75V21.25C13.5 21.6642 13.8358 22 14.25 22H20.75C21.1642 22 21.5 21.6642 21.5 21.25V14.75C21.5 14.3358 21.1642 14 20.75 14H14.25Z"
          fill="white"/>
  </svg>
</template>
<script>
export default {
  name: 'Coinbase_logo'
}
</script>
