import { HolderRouteName } from '@core-lib/routes'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const useIsLoyalty = () => {
  const route = useRoute()
  const router = useRouter()
  const currentRouteName = computed(() => router.currentRoute.value.name as HolderRouteName)

  const hasLoyaltyLayout = computed(() => route.meta && route.meta.hasLoyaltyLayout)
  const isLoyaltyPage = computed(() => currentRouteName.value === HolderRouteName.ORGANIZATION_LOYALTY)

  return {
    hasLoyaltyLayout,
    isLoyaltyPage,
  }
}