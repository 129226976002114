import { Plugin } from 'vue'
import featureFlagService from '@core-lib/webapp-services/feature-flag-service'

const featureFlagPlugin: Plugin = {
  install(Vue, options) {
    if (!options?.clientId) {
      console.error('Missing clientId in launchdarkly plugin. Not initializing LD')
      return
    }
    featureFlagService.init(options.clientId)
  },
}

export default featureFlagPlugin