import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestHeaders } from 'axios'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useWindow } from '@core-lib/composables/window'
import { useLocalStorage } from '@vueuse/core'
import { nanoid } from 'nanoid'

export { ApiError }

const { window } = useWindow()
const anonymousId = useLocalStorage('tropee_anon_id', nanoid(20))
export class ApiService {
  public axios: AxiosInstance

  constructor() {
    this.axios = axios.create({
      baseURL: ServiceContainer.env.apiBaseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    ApiService.prepareAxios(this.axios)
  }

  private static get deviceType() {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  public static prepareAxios(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use((config) => {
      if (!config.headers) {
        config.headers = new AxiosHeaders()
      }
      config.headers['x-app'] = ServiceContainer.env.appName
      config.headers['x-anonymous-id'] = anonymousId.value
      config.headers['x-device'] = this.deviceType
      config.headers['x-screen-size'] = `${window.innerWidth}x${window.innerHeight}`

      const authSession = authFacade.authSession.value
      if (authSession) {
        config.headers['authorization'] = `Session ${authSession.sessionId}`
      }
      const selectedOrgId = authFacade.selectedOrgId.value
      if (selectedOrgId) {
        config.headers['x-organization-id'] = selectedOrgId
      }
      return config
    })

    axiosInstance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const e = ApiError.wrapIfApiError(error as AxiosError)
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          if (error.response.data.error?.message === 'Session not found') {
            authFacade.logout().then(() => window.location.reload())
          }
        }
        throw e
      },
    )
  }

  async get<T>(path: string): Promise<T> {
    const response = await this.axios.get(path)
    return response.data
  }

  async post<T = unknown>(path: string, payload: object): Promise<T> {
    const response = await this.axios.post(path, payload)
    return response.data
  }

  async postWithHeaders<T = unknown>(path: string, payload: object, headers: AxiosRequestHeaders): Promise<T> {
    const response = await this.axios.post(path, payload, {
      headers: headers,
    })
    return response.data
  }

  async put(path: string, payload: object) {
    const response = await this.axios.put(path, payload)
    return response.data
  }

  async delete(path: string) {
    const response = await this.axios.delete(path)
    return response.data
  }
}

export default new ApiService()
