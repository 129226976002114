import axios, { AxiosHeaders } from 'axios'
import { FileUploaderService, UploadInput } from '@core-lib/composables/file-uploader'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'

export class FileUploaderServiceImplementation implements FileUploaderService {
  async upload({ file, fileName, isPublic, resourceType, resourceId, onUploadProgress, abortController }: UploadInput) {
    const path = `/creator/upload/${isPublic ? 'public' : 'private'}-file-upload-url`
    const extraParams = { resourceId, resourceType }
    const presignedPostResponse = await ServiceContainer.apiService.post<{
      presignedPost: {
        url: string
      },
      key: string
      downloadUrl: string
    }>(path, {
      fileName,
      fileType: file.type,
      isPublic,
      ...extraParams,
    })

    const uploadUrl = presignedPostResponse.presignedPost.url.replace('http://minio:10010', `${window.location.origin}/minio`)
    const axiosInstance = axios.create()
    axiosInstance.interceptors.request.use((config) => {
      if (!config.headers) {
        config.headers = new AxiosHeaders()
      }
      config.headers['Content-Type'] = file.type
      return config
    })
    await axiosInstance.put(uploadUrl, file, {
      signal: abortController ? abortController.signal : undefined,
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: function(progressEvent) {
        if (!progressEvent.total) return
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        if (onUploadProgress) onUploadProgress(percentCompleted)
      },
    })
    const url = `${uploadUrl}/${presignedPostResponse.key.split('::')[1]}`
    return { key: presignedPostResponse.key, url, downloadUrl: presignedPostResponse.downloadUrl }
  }

  public getAbortController(): AbortController {
    return new AbortController()
  }
}

export default new FileUploaderServiceImplementation()
