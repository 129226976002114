export const isiOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const getMetaMaskDappUrl = () => {
  return 'https://metamask.app.link/dapp/'
}

export const navigateToMetamask = () => {
  window.open(`${getMetaMaskDappUrl()}${window.location.href.replace('https://', '').replace('http://', '')}`)
}

export const isChrome = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    return true
  }
  return isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' && !isOpera && !isIEedge
}

export const isTouchEnabled =  ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 )

export function isFromWebView() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const standalone = window.navigator.standalone
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isSafari = /safari/.test(userAgent)
  if (isiOS()) {
    return !standalone && !isSafari
  }
  return userAgent.includes('wv')
}