import { onUnmounted, Ref, ref } from 'vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import ConnectionType from '@core-lib/models/wallet'
import { Session } from '@core-lib/models/session'
import { authProviders } from '@core-lib/webapp-services/auth-providers/providers-list'
import { EmailAuthProvider } from '@core-lib/webapp-services/auth-providers/email-auth-provider'
import { useValidateEmailVerificationCode } from '@core-lib/composables/user-fetcher'
import { nanoid } from 'nanoid'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import { useToaster } from '@core-lib/composables/toasters'
import { LoyaltyProgramIdentifier } from '@core-lib/openapi/client'
import { ActionToTrack, useTrackLastAction } from '@core-lib/composables/track-last-action'

type Input = {
  email: Ref<string>
  validate: () => Promise<boolean>
  isForLogin?: boolean
  withNotify: boolean
}
const { notify } = useToaster()
export const useVerifyEmailWithCode = ({ email, validate, withNotify }: Input) => {
  const code = ref('')
  const nonce = ref('')
  const sendCodePressed = ref(false)
  const isSendingCode = ref(false)
  const invalidCode = ref(false)
  const codeReRequested = ref(false)
  const codeRequestCountDown = ref(0)
  const countDownInterval = ref()
  const isVerifyLoading = ref(false)
  const isEmailAlreadyTaken = ref(false)
  const { getLastAction } = useTrackLastAction(ActionToTrack.SIGNUP_SOURCE)

  const emailAuthProvider = authProviders[ConnectionType.EMAIL] as EmailAuthProvider

  const { requestEmailVerificationCode, validateEmailVerificationCode } = useValidateEmailVerificationCode()

  const handleCodeChange = (input: string) => {
    code.value = input
    invalidCode.value = false
  }

  const setCountDownInterval = function () {
    countDownInterval.value = setInterval(() => {
      if (codeRequestCountDown.value === 0) {
        clearInterval(countDownInterval.value)
        codeReRequested.value = false
        return
      }
      codeRequestCountDown.value--
    }, 1000)
  }

  const sendCodeForLogin = async (loyaltyProgramIdentifier?: LoyaltyProgramIdentifier) => {
    const isValid = await validate()
    sendCodePressed.value = true
    if (!isValid) return

    isSendingCode.value = true
    try {
      const response = await ServiceContainer.apiService.post<{
        nonce: string,
        nextTryIn: number
      }>('/user/session/login-email', {
        email: email.value,
        loyaltyProgramIdentifier,
      })
      if (withNotify) {
        notify({
          type: 'success',
          text: 'A code has been sent to your email',
        })
      }
      nonce.value = response.nonce
      codeReRequested.value = true
      codeRequestCountDown.value = response.nextTryIn
      setCountDownInterval()
      ServiceContainer.authService.authenticate(ConnectionType.EMAIL)
    } catch (e) {
      notify({
        type: 'error',
        text: 'Could not send the email',
      })
    } finally {
      isSendingCode.value = false
    }
  }

  const sendCode = async () => {
    const isValid = await validate()
    sendCodePressed.value = true
    if (!isValid) return

    isSendingCode.value = true
    try {
      await requestEmailVerificationCode(email.value, window.location.pathname, true)
      if (withNotify) {
        notify({
          type: 'success',
          text: 'A code has been sent to your email',
        })
      }
      nonce.value = nanoid(5)
      codeReRequested.value = true
      codeRequestCountDown.value = 45
      setCountDownInterval()
      ServiceContainer.authService.authenticate(ConnectionType.EMAIL)
    } catch (e) {
      if (e instanceof ApiError && e.code === 'email_already_taken') {
        isEmailAlreadyTaken.value = true
        return
      }
      notify({
        type: 'error',
        text: 'Could not send the email',
      })
    } finally {
      isSendingCode.value = false
    }
  }
  const verifyEmailCode = async () => {
    isVerifyLoading.value = true
    try {
      await validateEmailVerificationCode(code.value)
    } catch (e) {
      invalidCode.value = true
    } finally {
      isVerifyLoading.value = false
    }
  }

  const verifyEmailLoginCode = async () => {
    isVerifyLoading.value = true
    try {
      const { session } = await ServiceContainer.apiService.post<{
        session: Session
      }>('/user/session/login-email-validate', {
        nonce: nonce.value,
        code: code.value,
        sourceUrl: window.location.href,
        lastAction: getLastAction(),
      })
      emailAuthProvider.finalizeAuthentication({ success: true, session })
    } catch (e) {
      invalidCode.value = true
      isVerifyLoading.value = false
    }
  }

  onUnmounted(() => clearInterval(countDownInterval.value))

  return {
    code, nonce, sendCodePressed, isSendingCode, invalidCode, codeReRequested, codeRequestCountDown, countDownInterval, isVerifyLoading, isEmailAlreadyTaken,
    handleCodeChange, sendCodeForLogin, verifyEmailLoginCode, sendCode, verifyEmailCode,
  }
}
