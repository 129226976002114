
export const useGoToLandingPage = () => {

  const goToLandingPage = async (queryString?: string) => {
    window.location.href = 'https://www.tropee.com' + (queryString ? `?${queryString}` : '')
  }
  const goToLandingPageNewTab = (queryString?: string) => {
    window.open('https://www.tropee.com' + (queryString ? `?${queryString}` : ''))
  }

  return {
    goToLandingPage,
    goToLandingPageNewTab,
  }
}