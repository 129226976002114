import { nanoid } from 'nanoid'
import { computed, ComputedRef, reactive, Ref, ref, watch } from 'vue'
import truncate from 'lodash/truncate'
import { JSON_FILE_PREFIX, JsonFile, parse, serialize } from '../models/json-file'
import { isEqual } from 'lodash'
import { UploadResourceType } from '../models/upload'
import { useToaster } from '@core-lib/composables/toasters'

export type UploadInput = {
  file: File
  fileName: string
  isPublic: boolean
  resourceId: string
  resourceType: UploadResourceType
  abortController?: AbortController
  onUploadProgress?: (progressPercentage: number) => void
}

export interface FileUploaderService {
  upload: (input: UploadInput) => Promise<{  key: string, url: string, downloadUrl: string }>
  getAbortController(): AbortController
}

type TemporaryUpload = {
  key: string
  url: string
}

export interface FileWrapper {
  id: string
  previewUrl: string
  fileName: string
  backendValue: string
}

class UrlFileWrapper implements FileWrapper {
  public readonly id: string
  constructor(public readonly url: string, private readonly originalName?: string) {
    this.id = nanoid(5)
  }

  public get previewUrl() {
    return this.url
  }

  public get fileName() {
    if (this.originalName) return this.originalName
    const fileUrl = this.url
    const name = decodeURIComponent(decodeURIComponent(fileUrl.substring(fileUrl.lastIndexOf('/') + 1)))

    return truncate(name, { length: 30 })
  }

  public get backendValue() {
    return this.url
  }
}

class LocalFileWrapper implements FileWrapper {
  public readonly id: string
  private readonly objectUrl: string = ''
  private _isUploadInProgress = false
  constructor(public file: File, public showPreviewImage: boolean) {
    this.id = nanoid(5)
    if (this.showPreviewImage) {
      this.objectUrl = URL.createObjectURL(this.file)
    }
  }

  public get previewUrl() {
    return this.objectUrl
  }

  cleanup() {
    if (this.objectUrl) URL.revokeObjectURL(this.objectUrl)
  }

  public setUploadInProgress() {
    this._isUploadInProgress = true
  }

  public get isUploadInProgress() {
    return this._isUploadInProgress
  }

  public get fileName() {
    return truncate(this.file.name, { length: 30 })
  }

  public get backendValue(): string {
    throw new Error('Tried to get backendValue of a local file. use areAllFilesUploader to check if all files are uploaded')
  }
}

class TemporaryUploadFileWrapper implements FileWrapper {
  public readonly id: string
  constructor(public readonly temporaryUpload: TemporaryUpload, public readonly previewUrl: string) {
    this.id = nanoid(5)
  }

  public get fileName() {
    const fileUrl = this.temporaryUpload.url
    const name = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
    const finalFileName = decodeURIComponent(decodeURIComponent(name)).replace(/%3A/g, ':')

    return truncate(finalFileName, { length: 30 })
  }

  public get backendValue() {
    return this.temporaryUpload.key
  }
}

class PrivateFileWrapper implements FileWrapper {
  public readonly id: string
  public static readonly prefix = JSON_FILE_PREFIX
  constructor(public readonly file: JsonFile) {
    this.id = nanoid(5)
  }

  public get previewUrl() {
    return ''
  }

  public get fileName() {
    return this.file.name
  }

  public get backendValue() {
    return serialize(this.file)
  }

  public static isPrivateFile(url: string) {
    return url.startsWith(this.prefix)
  }

  public static parse(url: string) {
    return new PrivateFileWrapper(parse(url))
  }
}

const isLocalFileWrapper = (fileWrapper: FileWrapper): fileWrapper is LocalFileWrapper => {
  return fileWrapper instanceof LocalFileWrapper
}

const isUrlFileWrapper = (fileWrapper: FileWrapper): fileWrapper is UrlFileWrapper => {
  return fileWrapper instanceof UrlFileWrapper
}

const isTemporaryUploadFileWrapper = (fileWrapper: FileWrapper): fileWrapper is TemporaryUploadFileWrapper => {
  return fileWrapper instanceof TemporaryUploadFileWrapper
}

const isPrivateFileWrapper = (fileWrapper: FileWrapper): fileWrapper is PrivateFileWrapper => {
  return fileWrapper instanceof PrivateFileWrapper
}

export type InitConfig = {
  initialFiles: string[]
  maxFilesAllowed?: number
  maxFileSize?: number
  randomizeFileName?: boolean
  isPublic: boolean
  resourceId: string
  resourceType: UploadResourceType
  showPreviewImage?: boolean
  fileUploaderService: FileUploaderService
}

const waitForImage = (url: string) => {
  return new Promise(r => {
    const img = new Image()
    img.src = url
    img.onload = r
    img.onerror = r // For some reason preview env can't load the image sometimes
  })
}

export type FileUploader = {
  areAllFilesUploaded: ComputedRef<boolean>
  addFiles: (files: FileList | File[], withNotify?: boolean) => DataTransfer
  files: Ref<FileWrapper[]>
  resetFiles: (files: string[]) => void
  getFile: (index: number) => FileWrapper
  hasFile: (index: number) => boolean
  removeFile: (index: number) => FileWrapper
  overallUploadProgress: ComputedRef<number>
  swapFiles: (firstIndex: number, secondIndex: number) => void
  maxFilesAllowed: number
  showPreviewImage: boolean
  backendValues: ComputedRef<string[]>
  uploadError: Ref<string>
  waitBackendValues: () => Promise<string[]>
  state: ComputedRef<(string | TemporaryUpload | JsonFile)[]>
}
const { notify } = useToaster()
export const useFileUploader = (initConfig: InitConfig): FileUploader => {
  const { initialFiles, maxFilesAllowed, maxFileSize, isPublic, resourceType, resourceId, showPreviewImage, fileUploaderService } = Object.assign(
    { isPublic: false, maxFilesAllowed: 1, showPreviewImage: true },
    initConfig,
  )
  const files = ref<FileWrapper[]>([])
  const uploadingFiles = reactive<Record<string, { progress: number, abortController: AbortController }>>({})
  const uploadError = ref('')

  const resetFiles = (newFiles: string[]) => {
    if (JSON.stringify(newFiles) === JSON.stringify(files.value.filter(f => !isLocalFileWrapper(f)).map(f => f.backendValue))) return
    files.value = newFiles.map(i => {
      return PrivateFileWrapper.isPrivateFile(i) ? PrivateFileWrapper.parse(i) : new UrlFileWrapper(i)
    })
  }
  resetFiles(initialFiles)

  const removeFile = (index: number) => {
    const filesCopy = [...files.value]
    const [file] = filesCopy.splice(index, 1) as FileWrapper[]
    if (isLocalFileWrapper(file)) file.cleanup()
    const uploadingFile = uploadingFiles[file.id]
    if (uploadingFile) {
      uploadingFile.abortController.abort()
      delete uploadingFiles[file.id]
    }
    files.value = filesCopy

    return file
  }

  const uploadFileAndWaitPreview = async (file: LocalFileWrapper) => {
    const media = file.file
    const extension = media.name.split('.').pop()
    file.setUploadInProgress()
    uploadingFiles[file.id] = {
      abortController: fileUploaderService.getAbortController(),
      progress: 0,
    }
    const tmpUploadUrlAndKey = await fileUploaderService.upload({
      file: media,
      fileName: `${nanoid(10)}.${extension}`,
      isPublic,
      resourceType,
      resourceId,
      onUploadProgress: (uploadedPercentage: number) => {
        uploadingFiles[file.id].progress = uploadedPercentage
      },
      abortController: uploadingFiles[file.id].abortController,
    })
    if (!isPublic) {
      return new PrivateFileWrapper({
        name: media.name,
        size: media.size,
        fullPath: tmpUploadUrlAndKey.key,
        type: media.type,
      })
    }
    const upload = new UrlFileWrapper(tmpUploadUrlAndKey.downloadUrl, media.name)
    if (showPreviewImage) await waitForImage(upload.url)

    return upload
  }

  const addFiles = (notValidatedFiles: FileList | File[], withNotify = true) => {
    uploadError.value = ''
    const dt = new DataTransfer()
    const filesCopy = [...files.value]
    const validatedFiles = []
    for (const notValidatedFile of notValidatedFiles) {
      if (maxFileSize && notValidatedFile.size > maxFileSize) {
        uploadError.value = 'The selected file is too big'
        if (withNotify) {
          notify({ text: uploadError.value, type: 'error' })
        }
        continue
      }
      validatedFiles.push(notValidatedFile)
      dt.items.add(notValidatedFile)
    }

    if (validatedFiles.length === 0) {
      return dt
    }

    if (filesCopy.length === 1 && maxFilesAllowed === 1) {
      removeFile(0)
      files.value = [new LocalFileWrapper(validatedFiles[0], showPreviewImage)]
    } else {
      const notUsedSlots = maxFilesAllowed - filesCopy.length
      const toAddFiles = []
      for (let i = 0; i < Math.min(validatedFiles.length, notUsedSlots); i++) {
        toAddFiles.push(new LocalFileWrapper(validatedFiles[i], showPreviewImage))
      }
      filesCopy.push(...toAddFiles)
      files.value = filesCopy
    }

    return dt
  }

  const areAllFilesUploaded = computed(() => {
    return !files.value.some(isLocalFileWrapper)
  })

  const swapFiles = (firstIndex: number, secondIndex: number) => {
    const filesCopy = [...files.value]
    const temp = filesCopy[firstIndex]
    filesCopy[firstIndex] = filesCopy[secondIndex]
    filesCopy[secondIndex] = temp

    files.value = filesCopy
  }

  const getFile = (index: number) => {
    return files.value[index]
  }

  const hasFile = (index: number) => {
    return files.value.length > index
  }

  const overallUploadProgress = computed(() => {
    const localFileWrappers = files.value.filter(f => isLocalFileWrapper(f)) as LocalFileWrapper[]
    if (localFileWrappers.length === 0) return -1

    const progressSum = localFileWrappers.reduce((sum, file) => sum + (uploadingFiles[file.id]?.progress || 0), 0)

    return Math.round(progressSum / localFileWrappers.length)
  })

  const nonLocalFilesComputed = computed(() => {
    return files.value.filter(f => !isLocalFileWrapper(f)).map(f => f.backendValue)
  })

  // The reason for this watch is to avoid communicating a change when the only change is the temporal files
  watch(nonLocalFilesComputed, (backendValuesComputed) => {
    if (isEqual(backendValuesComputed, backendValues.value)) return
    nonLocalFilesRef.value = backendValuesComputed
  })

  const nonLocalFilesRef = ref<string[]>(nonLocalFilesComputed.value)

  const backendValues = computed(() => nonLocalFilesRef.value)
  const waitBackendValues = (): Promise<string[]> => {
    if (areAllFilesUploaded.value) return Promise.resolve(backendValues.value)
    return new Promise(resolve => {
      const stop = watch(areAllFilesUploaded, newValue => {
        if (!newValue) return
        stop()
        resolve(backendValues.value)
      })
    })
  }

  const state = computed(() => {
    return files.value.filter(f => !isLocalFileWrapper(f)).map(f => {
      if (isUrlFileWrapper(f)) {
        return f.url
      } else if (isTemporaryUploadFileWrapper(f)) {
        return f.temporaryUpload
      } else if (isPrivateFileWrapper(f)) {
        return f.file
      } else {
        throw new Error('Got an invalid file here')
      }
    })
  })

  watch(files, async (newValue) => {
    const filesCopy: FileWrapper[] = [...newValue]
    let updated = false
    for (let index = 0; index < newValue.length; index++) {
      const file = newValue[index]
      if (!isLocalFileWrapper(file) || file.isUploadInProgress) {
        continue
      }
      updated = true
      const temporaryUploadFile = await uploadFileAndWaitPreview(file)
      filesCopy.splice(index, 1, temporaryUploadFile)
    }
    if (updated) {
      files.value = filesCopy
      if (files.value.length > 1 && files.value[0] instanceof UrlFileWrapper && files.value[0].backendValue.includes('/org/01GKP2XXZWYMWPCQBF77E9KES5')) {
        files.value = files.value.slice(1)
      }
      for (const file of filesCopy) {
        if (uploadingFiles[file.id]) delete uploadingFiles[file.id]
      }
    }
  })

  return {
    areAllFilesUploaded,
    addFiles,
    files,
    removeFile,
    getFile,
    hasFile,
    maxFilesAllowed,
    showPreviewImage,
    backendValues,
    waitBackendValues,
    state,
    resetFiles,
    swapFiles,
    overallUploadProgress,
    uploadError,
  }
}
