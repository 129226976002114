<template>
  <div class="w-full">
    <div
      v-show="menuListVisible"
      class="items-wrapper relative"
    >
      <nav
        id="items-container"
        class="flex flex-col gap-1 items"
        :class="navClass"
      >
        <button
          v-for="(item, index) in items"
          :id="'t'+(index+1)"
          :key="index + 1"
          :ref="el => setElement(index, el)"
          class="flex gap-4 items-center py-2.5 w-full min-h-[52px] px-3 rounded-lg cursor-pointer"
          :class="[isAdmin ? 'hover:bg-stone-50 active:bg-stone-50' : 'hover:bg-gray-50 active:bg-gray-50', itemClass]"
          :aria-current="activeItem === (index+1) ? 'page' : undefined"
          @click="changeItem(index + 1)"
        >
          <DsIcon
            v-if="item.icon && item.oldIcon"
            :icon="item.icon"
            :color-class="item.iconClass"
            :size="iconsSizeClass"
          />
          <span
            v-if="item.icon && !item.oldIcon"
            :class="isAdmin ? 'text-stone-400' : 'text-gray-400'"
          >
            <DsIconLucid
              :name="item.icon.toString()"
              :size="iconsSize"
              :class="item.iconClass"
            />
          </span>
          <span class="text-[16px] leading-[24px] font-semibold">
            {{ item.name }}
          </span>
          <span class="ml-auto">
            <DsIconLucid
              v-if="!item.hideArrow"
              name="ChevronRight"
              :size="24"
            />
          </span>
        </button>
      </nav>
    </div>
    <div
      v-show="!menuListVisible"
      :class="[contentWrapperClass]"
    >
      <div
        v-for="(item, index) in items"
        :key="index + 1"
      >
        <div
          v-show="activeItem === (index + 1)"
          class="item-content"
          :id="`item${index + 1}`"
          :class="[contentClass]"
        >
          <slot :name="`item${index+1}`" />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref, useSlots, watch } from 'vue'
import DsIconLucid from '@core-design/components/Design/DsIconLucid.vue'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import { IconType } from '@core-design/types/icon'
import { useAdminApp } from '@core-lib/composables/loyalty/admin-app'

type MenuItem = {
  name: string
  hideArrow?: boolean
  icon?: IconType
  oldIcon?: boolean
  iconClass?: string
  action?: () => void
}

const props = withDefaults(defineProps<{
  navClass?: string
  items: MenuItem[]
  contentWrapperClass?: string
  contentClass?: string
  itemClass?: string
  iconsSize?: number
  wrapperClass?: string
  iconsSizeClass?: string
  forceActiveItem?: number | null
}>(), {
  navClass: '',
  contentWrapperClass: '',
  contentClass: '',
  wrapperClass: '',
  iconsSizeClass: '',
  itemClass: '',
  iconsSize: 20,
  forceActiveItem: null,
})

const emit = defineEmits<{
  (e: 'collectionClick', collectionId: string): void
  (e: 'itemChange', item: number | null): void
  (e: 'load'): void
}>()

const { isAdmin } = useAdminApp()

const activeItem = ref<number | null>(props.forceActiveItem)

watch(() => props.forceActiveItem, (newVal) => {
  if (newVal === activeItem.value) return
  changeItem(newVal)
})

const itemList: Ref<HTMLElement[]> = ref([])

const setElement = (index: number, el: unknown) => {
  itemList.value[index] = el as HTMLElement
}

const changeItem = (newItem: number | null) => {
  emit('itemChange', newItem)
  activeItem.value = newItem
}

// when page renders too slow, the slider is not full width on the first active item state
onMounted(() => {
  emit('load')
})

const slots = useSlots()
const menuListVisible = computed(() => {
  return !activeItem.value || !slots[`item${activeItem.value}`]
})

</script>

<style lang="scss" scoped>

.item-fade-enter-active, .item-fade-leave-active {
  transition: opacity .1s ease;
}

.item-fade-enter-from, .item-fade-leave-to {
  opacity: 0;
}
//
//.menu-slide-enter-active {
//  @apply ease-in duration-1000;
//}
//
//.menu-slide-leave-active {
//  @apply ease-in-out duration-1000;
//}
//
//.menu-slide-enter-from,
//.menu-slide-leave-to {
//  @apply -translate-x-full duration-1000;
//}
//
//.content-slide-enter-active {
//  @apply ease-in duration-1000;
//}
//
//.content-slide-leave-active {
//  @apply ease-in-out duration-1000;
//}
//
//.content-slide-enter-from,
//.content-slide-leave-to {
//  @apply translate-x-full duration-1000;
//}
</style>
