<template>
  <div class="flex-1 w-full flex flex-col h-full">
    <div class="flex flex-col gap-y-2">
      <div
        v-for="walletType in walletConnectionTypesMore"
        :key="walletType.connectionType"
        class="h-[60px] w-full bg-white rounded-2xl pl-4 pr-3 flex items-center justify-between cursor-pointer border border-gray-200 md:hover:scale-[1.01] transition-transform"
        :class="{'pointer-events-none cursor-default md:!hover:scale-1': walletConnecting}"
        @click="handleConnectWallet(walletType.connectionType)"
      >
        <div class="flex flex-row items-center w-full">
          <div
            class="font-semibold text-[16px] text-ds-dark-primary leading-6"
            v-html="walletConnecting && lastConnectingType === walletType.connectionType ? 'Sign message': walletType.name"
          />
          <div
            v-if="walletConnecting && lastConnectingType === walletType.connectionType"
            class="w-4 h-4 relative ml-2"
          >
            <Vue3Lottie
              class="svg-animation absolute inset-0"
              :animation-data="pendingAnimation"
              renderer="html"
              no-margin
              width="100%"
            />
          </div>
          <div class="ml-auto w-6 h-6 flex items-center justify-center">
            <component
              :is="walletType.icon"
              class="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ConnectionTypeType, WalletProviderType } from '@core-lib/models/wallet'
import Metamask_Logo from '@core-lib/components/Metamask_Logo.vue'
import WalletConnect_Logo from '@core-lib/components/WalletConnect_Logo.vue'
import Coinbase_Logo from '@core-lib/components/Coinbase_logo.vue'
import PhantomLogo from '@core-lib/components/PhantomLogo.vue'
import { ref } from 'vue'
import { Optional } from '@core-lib/models/common'
import { Vue3Lottie } from 'vue3-lottie'
import pendingAnimation from '@core-lib/assets/animations/pending.json'
import { useLoyaltyConnectWallet, useWalletLoader } from '@core-lib/composables/connect-wallet'

const emit = defineEmits<{
  (e: 'connect', value: ConnectionTypeType): void
}>()

const lastConnectingType = ref<Optional<WalletProviderType>>(undefined)

const walletConnectionTypesMore: { connectionType: WalletProviderType, name: string, icon: unknown, class?: string }[] = [
  {
    connectionType: WalletProviderType.METAMASK,
    name: 'MetaMask',
    icon: Metamask_Logo,
  },
  {
    connectionType: WalletProviderType.COINBASE,
    name: 'Coinbase Wallet',
    icon: Coinbase_Logo,
  },
  {
    connectionType: WalletProviderType.WALLET_CONNECT,
    name: 'WalletConnect',
    icon: WalletConnect_Logo,
  },
  {
    connectionType: WalletProviderType.PHANTOM,
    name: 'Phantom',
    icon: PhantomLogo,
  },
]
const { startLoader, stopLoader, walletConnecting } = useWalletLoader()
const { connectWalletLoyalty, showLoyaltyWalletConnectModal } = useLoyaltyConnectWallet()

async function handleConnectWallet(walletType: WalletProviderType) {
  lastConnectingType.value = walletType
  startLoader()
  try {
    await connectWalletLoyalty(walletType)
    emit('connect', walletType)
  } finally {
    stopLoader()
    showLoyaltyWalletConnectModal.value = false
  }
}
</script>

<style scoped>

</style>
