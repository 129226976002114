<template>
  <DsBottomSheet
    :show="show"
    close-button
    wrapper-class="font-inter"
    content-class="!p-0 min-h-[164px] md:min-h-[170px] max-[375px]:max-h-[539px] min-[390px]:max-h-[593px] md:max-h-[464px] md:rounded-b-[32px]"
    @hide="$emit('hide')"
  >
    <template #title>
      {{ capitalize(translate('activity')) }}
    </template>
    <LoyaltyUserActivity
      :identifier="loyaltyProgramIdentifier"
    />
  </DsBottomSheet>
</template>
<script setup lang="ts">
import DsBottomSheet from '@core-design/components/Design/DsBottomSheet.vue'
import LoyaltyUserActivity from '@core-lib/components/settings/LoyaltyUserActivity.vue'
import { useI18n } from '@core-lib/composables/i18n'
import { capitalize } from 'lodash'
import { useLoyaltyProgramIdentifier } from '@core-lib/composables/loyalty/loyalty-program-identifier'

defineProps<{
  show: boolean
}>()

defineEmits<{
(e: 'hide'): void
}>()

const { loyaltyProgramIdentifier } = useLoyaltyProgramIdentifier()

const { translate } = useI18n({ prefix: 'settings__' })

</script>

<style scoped>

</style>
