import {
  holderDidActionEventName,
  holderDidActionEvents,
  IdentifyInput,
  TrackInput,
} from '@core-lib/webapp-services/analytics/plugins/common'

type Hotjar = (action: 'event' | 'identify', event: string, context?: object) => void
const emptyHj: Hotjar = (action, eventOrUserId, context) => {
  console.debug('hotjar', action, eventOrUserId, context)
}
const getHotjar = () => {
  return (window as { hj?: Hotjar }).hj || emptyHj
}

export function pluginHotjar() {
  return {
    name: 'hotjar',
    initialize: () => {
      return
    },
    track: ({ payload }: TrackInput) => {
      if (!holderDidActionEvents.includes(payload.event)) return
      getHotjar()('event', holderDidActionEventName)
    },
    identify: ({ payload }: IdentifyInput) => {
      getHotjar()('identify', payload.userId)
    },
  }
}
