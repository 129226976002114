import appEnv from './app-env'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { ExtraErrorData as ExtraErrorDataIntegration, HttpClient as HttpClientIntegration } from '@sentry/integrations'
import { VueQueryPlugin } from '@tanstack/vue-query'

import './index.css'
import 'floating-vue/dist/style.css'
import './assets/styles/main.scss'
import BaseLayoutV1 from './layouts/PreviewLayout.vue'
import ImageOrVideo from './components/ImageOrVideo.vue'
import AnalyticsMixin from './mixins/Analytics.vue'
import featureFlagCore from '@core-lib/webapp-services/plugins/feature-flag'
import FloatingVue from 'floating-vue'
import VueSnip from 'vue-snip'
import Vue3Marquee from 'vue3-marquee'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import apiService from '@/services/api-service'
import {
  apiClient,
  creatorApiClient,
  userApiClient,
  utilityApiClient,
  utilityTransactionApiClient,
} from '@/openapi/client'
import authService from '@core-lib/webapp-services/auth/auth-service'
import { sentryConfig } from '@core-lib/const/sentry'

ServiceContainer.setServices({
  apiService: apiService,
  authService: authService,
  router: router,
  openApi: {
    default: apiClient,
    creator: creatorApiClient,
    utilityTransaction: utilityTransactionApiClient,
    user: userApiClient,
    utility: utilityApiClient,
  },
})

const app = createApp(App)
app.use(VueSnip)
app.use(Vue3Marquee)

const isMobile = window.innerWidth < 768
app.use(FloatingVue, {
  distance: 8,
  themes: {
    'tooltip': {
      triggers: ['touch', isMobile ? 'click' : 'hover'],
      popperTriggers: ['touch', isMobile ? 'click' : 'hover'],
      placement: 'top',
      distance: 14,
      html: true,
      autoHide: true,
    },
    'dropdown': {
      triggers: ['click'],
      arrow: false,
      eagerMount: true,
    },
    'arrow-dropdown': {
      triggers: ['click', 'touch'],
      autoHide: true,
      placement: 'bottom-start',
      eagerMount: true,
    },
    'menu': {
      autoHide: true,
      eagerMount: true,
    },
  },
})
app.use(VueQueryPlugin)

// Tropee components
app.component('BaseLayout', BaseLayoutV1)
app.component('ImageOrVideo', ImageOrVideo)
app.mixin(AnalyticsMixin)

if (appEnv.sentry.isEnabled) {
  Sentry.init({
    app,
    dsn: appEnv.sentry.dsn,
    release: appEnv.sentry.release,
    environment: appEnv.app.env,
    integrations: [
      new ExtraErrorDataIntegration({
        depth: 8,
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [400, 500],
        failedRequestTargets: [
          new RegExp('^http://www.tropee.com/api/'),
        ],
      }),
    ],
    ...sentryConfig,
    tracesSampleRate: appEnv.sentry.tracesSampleRate,
    replaysOnErrorSampleRate: 0,
  })
}

app.use(featureFlagCore, {
  clientId: appEnv.featureFlag.launchDarkly.clientId,
})
app.use(router)

const appInitializer = async () => {
  app.mount('#app')
}

appInitializer().catch(console.error)
// export default viteSSR(App,{ routes: routes },
//   ({ app, initialState }) => {
//     const queryClient = new QueryClient()
//     console.log(import.meta.env.SSR, 'is ssr')
//     // Sync initialState with the client state
//     if (import.meta.env.SSR) {
//     // Indicate how to access and serialize VueQuery state during SSR
//       initialState.vueQueryState = { toJSON: () => dehydrate(queryClient) }
//     } else {
//     // Reuse the existing state in the browser
//       hydrate(queryClient, initialState.vueQueryState)
//     }
//
//     // Mount and provide the client to the app components
//     app.use(VueQueryPlugin, { queryClient })
//     const head = createHead()
//     app.use(head)
//
//     return { head }
//   })
