import { computed, watch } from 'vue'
import { useOrganizationListFetcher } from '@core-lib/composables/organization-fetcher'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { CreatorOrganization } from '@core-lib/openapi/client'

export const useSelectedOrganization = () => {
  const { organizations, refetch, isFetched } = useOrganizationListFetcher()
  const selectedOrganization = computed(() => {
    if (!authFacade.isAuthenticated.value || !authFacade.selectedOrgId.value || !isFetched.value) return undefined

    const selectedOrg =  organizations.value.find(org => org.id === authFacade.selectedOrgId.value)

    if (selectedOrg) return selectedOrg
    authFacade.selectedOrgId.value = null
    window.location.reload()
    return undefined
  })

  const setSelectedOrganization = (organization: CreatorOrganization) => {
    authFacade.selectedOrgId.value = organization.id
    authFacade.conversionId.value = organization.conversionId
  }

  watch(selectedOrganization, selectedOrganization => {
    if (!selectedOrganization) return
    if (!window.location.pathname.startsWith('/creator') || selectedOrganization.hasAccessToLegacy) return
    window.location.href = '/admin'
  })

  return {
    selectedOrganization,
    organizations,
    refetchOrganizations: refetch,
    setSelectedOrganization,
  }
}
