import { onBeforeMount, ref } from 'vue'

const useIsMobile = () => {
  const isMobile = ref<boolean>()
  const handleChange = (event: { matches: boolean }) => {
    isMobile.value = event.matches
  }
  onBeforeMount(() => {
    const matchMedia = window.matchMedia('(max-width: 767px)')
    isMobile.value = matchMedia.matches
    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange)
    } else {
      matchMedia.addListener(handleChange)
    }
  })

  return isMobile
}

export const useIsMobileDevice = () => {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
}

export default useIsMobile
