import { useToaster } from '@core-lib/composables/toasters'

const { notify } = useToaster()
const useClipboard = () => {
  const copyToClipboard = (text: string, successText?: string) => {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text)
      if (successText) {
        notify({
          text: successText,
          type: 'success',
        })
      }
    } else {
      console.warn('no clipboard available', text)
    }
  }

  return {
    copyToClipboard,
  }
}

export {
  useClipboard,
}