import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { computed, ComputedRef, ref } from 'vue'
import { MenuItem } from '@core-design/components/Design/interfaces/IMenuItem'
import { LocationQueryRaw, RouteParamsRaw, useRouter } from 'vue-router'
import translator from '@core-lib/webapp-services/i18n/translator'
import { capitalize } from '@core-lib/helpers/string'

export const useNavigation = () => {
  const isAuthenticated = authFacade.isAuthenticated
  const router = useRouter()
  const isMobileNavActive = ref(false)

  const getPathByName = (routeName: string, params?: RouteParamsRaw, query?: LocationQueryRaw) => {
    return router.resolve({
      name: routeName,
      params: params,
      query: query,
    }).fullPath || ''
  }

  const creatorMenuItems: ComputedRef<MenuItem[]> = computed<MenuItem[]>(() => {
    return [
      {
        text: 'Help center',
        route: 'https://help.tropee.com',
        icon: { name: 'Interrogation', type: 'regular' },
      },
      {
        text: 'Log out',
        route: '/disconnect',
        icon: 'Sign out alt',
      },
    ]
  })
  const creatorMenuItemsDisconnected = computed(() => creatorMenuItems.value.slice(0, -1))
  const creatorCurrentMenuItems = computed(() => isAuthenticated.value ? creatorMenuItems.value : creatorMenuItemsDisconnected.value)

  const holderMenuItems: ComputedRef<MenuItem[]> = computed(() => {
    return [
      {
        text: capitalize(translator.translate('general__log_out')),
        route: '/disconnect',
        icon: 'Sign out alt',
      },
    ]
  })
  const menuItemsDisconnected = computed(() => holderMenuItems.value.slice(0, -1))
  const holderCurrentMenuItems = computed(() => isAuthenticated.value ? holderMenuItems.value : menuItemsDisconnected.value)

  const routeClicked = (route: string | undefined) => {
    if (!route) return
    isMobileNavActive.value = false
    if (route === '/user/undefined' || '') return
    if (route === '/disconnect') {
      authFacade.logout()
      return
    }

    if (route.startsWith('https://')) {
      window.open(route)
    } else {
      router.push(route)
    }
  }

  const connectWallet = () => {
    isMobileNavActive.value = false
    authFacade.showModal()
  }

  return {
    isMobileNavActive,
    getPathByName,
    holderCurrentMenuItems,
    creatorCurrentMenuItems,
    routeClicked,
    connectWallet,
  }
}
