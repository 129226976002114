<template>
  <Teleport
    to="#app"
    :disabled="disableTeleport"
  >
    <transition
      name="fade"
      :css="!disableTransition"
    >
      <div
        v-show="show"
        class="ds-bottom-sheet fixed inset-0 cursor-default z-[90] flex justify-center"
        :class="[wrapperClass, {'items-center': !isMobile && !isForceMobile}]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 z-[91]"
          :class="[layoutBgClass || 'bg-ds-dark-primary dark:bg-ds-dark-bottom-most-layer bg-opacity-50 dark:bg-opacity-[64%]', {'ds-bottom-sheet-background-layer': delayedShown, 'transition-opacity': !disableTransition}]"
          @click="modalOutsideClicked"
          aria-hidden="true"
        />
        <transition
          name="slide"
          :css="(isMobile || isForceMobile) && !disableTransition"
        >
          <div
            v-if="show || noRerender && firstOpenHappened"
            class="ds-bottom-sheet-container overflow-hidden flex flex-col bg-white w-full shadow-xl rounded-t-[32px] z-[92] fixed bottom-0"
            :class="[containerClass, {'transition': noRerender && !disableTransition, 'invisible': noRerender && !show, 'visible': noRerender && show, 'overflow-hidden': $slots['sticky-footer'] || stickyFooter, 'max-w-[512px] rounded-b-[32px] bottom-auto max-h-[calc(100%-64px)]': !isMobile && !isForceMobile }]"
            :style="sheetStyle"
          >
            <div
              v-if="show"
              class="ds-bottom-sheet-container-instance -z-[1] absolute w-0 h-0"
            />
            <header
              v-if="showHeader"
              class="p-2 border-b-2"
              :class="[isAdmin ? 'border-stone-100' : 'border-gray-100', headerClass]"
            >
              <div
                class="flex justify-between"
                :class="[!!$slots.description ? 'items-start' : 'items-center', headerWrapperClass]"
              >
                <div class="overflow-hidden">
                  <div
                    v-if="backButton"
                    class="w-12 h-12 min-w-[48px] flex items-center justify-center"
                    @click="$emit('back')"
                  >
                    <div
                      class="w-10 h-10 min-w-[40px] flex items-center justify-center cursor-pointer text-ds-dark-primary rounded-full duration-200"
                      :class="[isAdmin ? 'hover:bg-stone-50 active:bg-stone-100' : 'hover:bg-gray-50 active:bg-gray-100', {'transition-colors': !disableTransition}]"
                    >
                      <DsIconLucid
                        name="ChevronLeft"
                        :size="24"
                      />
                    </div>
                  </div>
                  <div
                    v-if="$slots.title || $slots.description"
                    class="flex-1 min-w-0 flex flex-col"
                    :class="headerTitleWrapperClass"
                  >
                    <h2
                      v-if="$slots.title"
                      class="text-lg font-semibold tracking-[.25px] color-black ml-4"
                      :class="{ 'mb-4': backButton && !$slots.description, 'pt-[10px]': !!$slots.description }"
                    >
                      <slot name="title" />
                    </h2>
                    <p
                      v-if="$slots.description"
                      class="text-[16px] leading-[24px] font-medium ml-4 mb-4 w-[calc(100%+16px)]"
                      :class="[isAdmin ? 'text-stone-500' : 'text-gray-500', {'mt-1.5': !!$slots.title}, titleDescriptionClass]"
                    >
                      <slot name="description" />
                    </p>
                  </div>
                </div>
                <div
                  v-if="showDots && steps > 1"
                  class="px-3 py-2 rounded-[50px]"
                  :class="isAdmin ? 'bg-stone-100' : 'bg-gray-100'"
                >
                  <DsCarouselSlider
                    class="my-4"
                    :active-item="currentStep - 1"
                    :item-count="steps"
                    item-class="!cursor-default"
                  />
                </div>
                <div
                  v-if="closeButton"
                  :class="[{ 'mb-auto': backButton }, twMerge('w-12 h-12 min-w-[48px] flex items-center justify-center', closeButtonWrapperClass)]"
                  @click="$emit('hide')"
                >
                  <div
                    class="w-10 h-10 min-w-[40px] flex items-center justify-center cursor-pointer text-ds-dark-primary rounded-full duration-200"
                    :class="[isAdmin ? 'hover:bg-stone-50 active:bg-stone-100' : 'hover:bg-gray-50 active:bg-gray-100', {'transition-colors': !disableTransition}]"
                  >
                    <DsIconLucid
                      name="X"
                      :size="24"
                    />
                  </div>
                </div>
              </div>
              <slot
                v-if="$slots.headerBottomContent"
                name="headerBottomContent"
              />
            </header>
            <div
              ref="contentSlot"
              :class="twMerge('overflow-y-auto p-6', contentClass)"
            >
              <slot />
            </div>
            <footer
              v-if="$slots.footer"
              class="p-6 pt-3 border-t-2"
              :class="isAdmin ? 'border-stone-100' : 'border-gray-100'"
            >
              <slot name="footer" />
            </footer>
            <footer
              id="ds-bottom-sheet-sticky-footer"
              v-else-if="$slots['sticky-footer'] || stickyFooter"
              ref="stickyFooterElement"
              class="sticky bottom-0"
              :class="{[`border-t-2 ${isAdmin ? 'border-stone-100' : 'border-gray-100'} bg-white`]: contentIsScrollable, [stickyFooterScrollableClass]: contentIsScrollable}"
            >
              <slot name="sticky-footer" />
            </footer>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, provide, Ref, ref, useSlots, watch } from 'vue'
import useIsMobile from '@core-lib/composables/isMobile'
import DsCarouselSlider from '@core-design/components/Design/DsCarouselSlider.vue'
import DsIconLucid from '@core-design/components/Design/DsIconLucid.vue'
import { computed } from 'vue'
import { useScreenSize } from '@core-lib/composables/screen-size'
import { useAdminApp } from '@core-lib/composables/loyalty/admin-app'
import { twMerge } from 'tailwind-merge'
import { useScroll } from '@vueuse/core'

const props = withDefaults(defineProps<{
  show?: boolean
  closeButton?: boolean
  backButton?: boolean
  wrapperClass?: string
  contentClass?: string
  containerClass?: string
  titleDescriptionClass?: string
  noRerender?: boolean
  layoutBgClass?: string
  closeOnClickOutside?: boolean
  showDots?: boolean
  steps?: number
  headerWrapperClass?: string
  headerClass?: string
  stickyFooter?: boolean
  disableTeleport?: boolean
  isForceMobile?: boolean
  stickyFooterScrollableClass?: string
  headerTitleWrapperClass?: string
  closeButtonWrapperClass?: string
  disableTransition?: boolean
}>(), {
  show: false,
  closeButton: false,
  backButton: false,
  wrapperClass: '',
  contentClass: '',
  containerClass: '',
  titleDescriptionClass: '',
  noRerender: false,
  layoutBgClass: '',
  closeOnClickOutside: true,
  showDots: false,
  steps: 1,
  headerWrapperClass: '',
  headerClass: '',
  stickyFooter: false,
  disableTeleport: false,
  isForceMobile: false,
  stickyFooterScrollableClass: '',
  headerTitleWrapperClass: '',
  closeButtonWrapperClass: '',
  disableTransition: false,
})

const emit = defineEmits(['hide', 'back', 'bottomScroll'])

const isMobile = useIsMobile()
const { isAdmin } = useAdminApp()

const currentStep = ref(1)
const firstOpenHappened = ref(false)
const contentIsScrollable = ref(false)
const canTeleportToFooter = ref(false)
const delayedShown = ref(false)
const contentSlot = ref<HTMLElement | null>(null)
const stickyFooterElement = ref<HTMLElement | null>(null)
provide<Ref<boolean>>('contentIsScrollable', contentIsScrollable)
provide<Ref<boolean>>('canTeleportToFooter', canTeleportToFooter)
const appHeight = ref(`${window.innerHeight}px`)

watch(stickyFooterElement, stickyFooterElement => {
  if (!stickyFooterElement) return
  nextTick(() => {
    canTeleportToFooter.value = true
  })
})

const sheetStyle = computed(() => {
  return {
    '--app-height': appHeight.value,
  }
})

function detectContentIsScrollable() {
  if (!contentSlot.value) return
  contentIsScrollable.value = contentSlot.value.scrollHeight > contentSlot.value.clientHeight
}

watch(contentSlot, contentSlot => {
  if (!contentSlot) return
  nextTick(() => {
    detectContentIsScrollable()
    heightObserver.observe(contentSlot)
  })
})

const heightObserver = new ResizeObserver(detectContentIsScrollable)

const { arrivedState } = useScroll(contentSlot)
watch(arrivedState, (arrivedState) => {
  if (!arrivedState.bottom) return
  emit('bottomScroll')
})

function setAppHeightOnResize(event: Event) {
  if (!event.target || !('height' in event.target) || !event.target?.height || isNaN(event.target.height as number)) return
  appHeight.value = `${event.target.height}px`
}

onMounted(() => {
  if (props.show) {
    modalOpenEffects()
  }
  if (!visualViewport) return
  visualViewport.addEventListener('resize', setAppHeightOnResize)
})

watch(() => props.show, show => {
  if (show) {
    modalOpenEffects()

    appHeight.value = `${window.innerHeight}px`

    if (contentSlot.value) {
      heightObserver.observe(contentSlot.value)
    }
    firstOpenHappened.value = true
  } else {
    modalHiddenEffects()

    canTeleportToFooter.value = false
    heightObserver.disconnect()
  }
  setTimeout(() => delayedShown.value = show, 300)
})

watch([() => props.show, stickyFooterElement], ([show, stickyFooterElement]) => {
  if (show && stickyFooterElement) {
    canTeleportToFooter.value = true
  }
})

onBeforeUnmount(() => {
  modalHiddenEffects()
  if (!visualViewport) return
  visualViewport.removeEventListener('resize', setAppHeightOnResize)
})

function modalOpenEffects() {
  if (props.disableTeleport) return
  // When the modal is shown, we want a fixed body
  const scrollY = window.scrollY
  document.body.classList.add('!overflow-hidden')
  document.body.classList.add('tropee-full-height')
  // document.body.style.top = `-${scrollY}px`
}

function modalHiddenEffects() {
  if (props.disableTeleport) return
  //Check if other modal is still open:
  if (document.querySelectorAll('.ds-bottom-sheet-container-instance').length > 1) return
  // When the modal is hidden...
  // const scrollY = document.body.style.top
  document.body.classList.remove('!overflow-hidden')
  document.body.classList.remove('tropee-full-height')
  // window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

const modalOutsideClicked = () => {
  if (props.closeOnClickOutside) {
    emit('hide')
  }
}

const { height } = useScreenSize()
watch(height, () => {
  nextTick(() => {
    appHeight.value = `${window.innerHeight}px`
  })
})

const slots = useSlots()

const showHeader = computed(() => {
  return props.backButton || props.closeButton || props.showDots || slots.title || slots.description || slots.headerBottomContent
})

function closeOnEscape(event: { key: string, ctrlKey: boolean, altKey: boolean, shiftKey: boolean }) {
  if (event.key === 'Escape') {
    //if esc key was not pressed in combination with ctrl or alt or shift
    const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey)
    if (isNotCombinedKey) {
      emit('hide')
    }
  }
}

watch(() => props.show, show => {
  if (show) {
    document.addEventListener('keydown', closeOnEscape)
  } else {
    document.removeEventListener('keydown', closeOnEscape)
  }
}, { immediate: true })

</script>
<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 0.15s ease-in;
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.15s ease-out;
  transition-delay: 0.15s;
  opacity: 1;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: transform 0.25s ease-in;
  transform: translateY(0);
}

.slide-leave-active {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
  transition-property: transform, opacity;
}

.slide-enter-from {
  transform: translateY(100%);
}

.slide-leave-to {
  transform: translateY(100%);
}

@media screen and (max-width: 768px) {
  .ds-bottom-sheet-container {
    max-height: calc(var(--app-height) - 32px);
  }
}
</style>
<style lang="scss">
.ds-bottom-sheet {
  .ds-input {
    .label {
      @apply text-[16px] leading-6 #{!important};
    }
    .field {
      @apply py-3 #{!important};
    }
    .input {
      @apply text-[16px] leading-6 #{!important};
      &::placeholder {
        @apply text-[16px] leading-6 #{!important};
      }
    }
    .helper-text {
      @apply text-sm #{!important};
    }
  }

  .ds-text {
    .label {
      @apply text-[16px] leading-6 #{!important};
    }
    .textarea-field {
      @apply text-[16px] leading-6 #{!important};
      &::placeholder {
        @apply text-[16px] leading-6 #{!important};
      }
    }
    .helper-text {
      @apply text-sm #{!important};
    }
  }
}
</style>
