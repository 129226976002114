<template>
  <div>
    <div class="flex flex-col gap-3">
      <div class="bg-gray-100 p-6 rounded-2xl">
        <LoyaltyProfilePicture
          :user="user"
          @update-user="$emit('userUpdated')"
        />
      </div>
      <LInput
        app="member"
        v-model.trim="form.username"
        label="Username"
        class="w-full"
        placeholder="John_Doe"
        :helper-text="usernameHelperText"
        :has-error="isUsernameDuplicated || !!userProfileErrors.username"
      />
      <LInput
        app="member"
        readonly
        v-model.trim="form.email"
        :helper-text="userProfileErrors.email"
        :has-error="!!userProfileErrors.email"
        class="w-full"
        label="Email"
        placeholder=""
      />
    </div>
    <DsButton
      class="mt-6 px-9 !text-[16px] !leading-[24px] font-semibold min-w-[160px] min-h-[50px]"
      :class="{'hover:!bg-ds-dark-primary/80 !bg-ds-dark-primary/80': isSaving}"
      :loading="isSaving"
      @click="save"
    >
      {{ saveLabel }}
    </DsButton>
    <SettingFileUploadConfirmationModal
      :description="fileSelectModalDescription"
      :show-file-select-modal="showFileSelectModal"
      :title="fileSelectModalTitle"
      @cancel="showFileSelectModal = false"
      @file-select-click="() => onModalFileSelectClick()"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useZodValidateObject } from '@core-lib/composables/zod-validate'
import { z } from 'zod'
import userProfileService from '@core-lib/webapp-services/user-profile'
import DsButton from '@core-design/components/Design/DsButton.vue'
import SettingFileUploadConfirmationModal from '@core-lib/components/settings/SettingFileUploadConfirmationModal.vue'
import { useProfileImageSetter } from '@core-lib/composables/profile-image-setter'
import { cloneDeep } from 'lodash'

import { UserPrivateProfile } from '@core-lib/openapi/client'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import { capitalize } from '@core-lib/helpers/string'
import translator from '@core-lib/webapp-services/i18n/translator'
import LoyaltyProfilePicture from '@core-lib/components/settings/LoyaltyProfilePicture.vue'
import LInput from '@core-lib/components/loyalty/LInput.vue'

const props = defineProps<{
      user: UserPrivateProfile
    }>()

const emit = defineEmits<{
      (event: 'userUpdated'): void
    }>()

interface Form {
  username: string
  email: string
  avatar: string
}

const initialFormValues = () => {
  return {
    username: props.user.username || '',
    email: props.user.email,
    avatar: props.user.avatar,
  }
}
const form = ref<Form>(initialFormValues())

const {
  fileSelectModalTitle,
  fileSelectModalDescription,
  showFileSelectModal,
  onModalFileSelectClick,
} = useProfileImageSetter()

const validationForm = computed(() => {
  return cloneDeep(form.value)
})
const basicValidation = useZodValidateObject(
  z.object({
    avatar: z.string().url().or(z.literal('')).optional(),
    username: z.string().trim().min(3, capitalize(translator.translateN('settings__must_contain_n_or_more_characters', 3))).max(45, capitalize(translator.translateN('settings__must_contain_n_or_less_characters', 45))).regex(/^[A-Za-z0-9-_]+$/,capitalize(translator.translate('settings__must_contain_only_letters_numbers_hyphens_or_underscores'))),
    email: z.string().trim().email('Please enter a valid email address').optional().optional().or(z.literal('')),
  }),
  validationForm,
)
const hasUsername = computed(() => props.user.username && props.user.username !== props.user.walletAddress)
const username = ref(hasUsername.value ? props.user.username : '')
const isUsernameDuplicated = ref(false)

const userProfileErrors = computed(() => ({
  username: basicValidation.fieldErrorMessages.value.username,
  avatar: basicValidation.fieldErrorMessages.value.avatar,
  email: basicValidation.fieldErrorMessages.value.email,
}))
const usernameHelperText = computed(() => isUsernameDuplicated.value ? capitalize(translator.translate('settings__this_username_is_already_taken')) : userProfileErrors.value.username)

const isSaving = ref(false)
const isSaved = ref(false)
async function markSaved() {
  await new Promise(resolve => setTimeout(resolve, 1000))

  isSaving.value = false
  isSaved.value = true

  await new Promise(resolve => setTimeout(resolve, 3000))
  isSaved.value = false
}
watch(username, () => isUsernameDuplicated.value = false)
watch(
  () => form.value.email,
  (email) => {
    if (email)
      basicValidation.resetError()
  },
)

const save = async () => {
  if (!(await basicValidation.validate())) {
    return
  }
  isSaving.value = true

  const { username } = form.value

  try {
    await userProfileService.updateUserProfileV2({
      username: username || '',
    })
    emit('userUpdated')
    markSaved()
  } catch (e) {
    if (e instanceof ApiError && e.code === 'username_taken') {
      isUsernameDuplicated.value = true
      return
    }
    console.error('Error while updating profile', e)
  } finally {
    markSaved()
  }
}

const saveLabel = computed(() => {
  if (isSaved.value) {
    return capitalize(translator.translate('general__saved'))
  }
  return capitalize(translator.translate('general__save'))
})
</script>
