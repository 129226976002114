<template>
  <button
    class="h-10 rounded-full border border-gray-200 flex items-center gap-x-1.5 cursor-pointer md:hover:bg-gray-50 active:bg-gray-50 dark:border-ds-dark-top-layer dark:bg-ds-dark-bottom-layer hover:dark:bg-ds-dark-top-layer dark:text-white/[.87]"
    :class="organization ? 'pl-1.5 pr-3 sm:pr-[10px]' : 'justify-center w-10'"
    @click="goToLoyaltyPage"
  >
    <DsIconLucid
      name="ChevronLeft"
      :size="24"
    />
    <DsAvatarLetter
      v-if="organization"
      :identifier="organization.id"
      :name="organization.name"
      :media-url="organization.profile.logoUrl"
      class="webkit-border-radius-fixer"
      image-class="w-7 h-7 min-w-[28px] rounded-full"
      placeholder-text-class="text-xs leading-5"
      :avatar-type="organization.profile.avatarType"
    />
    <span
      v-if="!isLoyaltyPreview"
      class="hidden sm:flex items-center ml-0.5"
    >
      <span class="text-sm leading-8 font-semibold">
        {{ organization.name }}
      </span>
      <OrganizationCertifiedBadge
        v-if="organization.isCertified"
        class="ml-1"
        size="small"
      />
    </span>
  </button>
</template>

<script setup lang="ts">
import DsAvatarLetter from '@core-design/components/Design/DsAvatarLetter.vue'
import { useRouter } from 'vue-router'
import { OrganizationPublicProfile } from '@core-lib/openapi/client/api'
import DsIconLucid from '@core-design/components/Design/DsIconLucid.vue'
import OrganizationCertifiedBadge
  from '@core-design/components/ComponentsLayout/Loyalty/OrganizationCertifiedBadge.vue'
import { HolderRouteName } from '@core-lib/routes'
import { useLoyaltyPreview } from '@core-lib/composables/loyalty/loyalty-preview'

const props = defineProps<{
  organization: OrganizationPublicProfile
}>()

const emit = defineEmits<{
  (e: 'back'): void
}>()

const { isLoyaltyPreview } = useLoyaltyPreview()

const router = useRouter()

function goToLoyaltyPage() {
  if (isLoyaltyPreview.value) {
    emit('back')
    return
  }
  if (!props.organization) {
    router.go(-1)
    return
  }
  router.push({ name: HolderRouteName.ORGANIZATION_LOYALTY, params: { slug: props.organization.slug } })
}
</script>

<style scoped>

</style>
