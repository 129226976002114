<template>
  <BaseModal
    :show="show"
    without-padding
    custom-size="mobile-login-container md:w-[474px] px-4 md:px-6 md:pt-6 md:pb-5 max-md:h-[412px]"
    shadow-class="md:shadow-[0px_0px_12px_0px_rgba(0,0,0,0.10)]"
    wrapper-class="finish-signup-modal"
    content-class="h-full"
    :close-on-click-outside="false"
    @hide="show = false"
  >
    <div class="w-full flex flex-col h-full">
      <FinishSignUpForm is-modal />
    </div>
  </BaseModal>
</template>

<script setup lang="ts">

import BaseModal from '@core-lib/components/BaseModal.vue'
import { ref, watch } from 'vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import FinishSignUpForm from '@core-lib/components/auth/FinishSignUpForm.vue'

const show = ref(false)
const isUserReady = authFacade.isUserReady
watch(isUserReady, isUserReady => {
  show.value = isUserReady === false
}, { immediate: true })
</script>

<style lang="scss">
.finish-signup-modal {

  .base-modal-container {
    @apply max-md:p-0 max-md:flex-wrap  max-md:content-end  max-md:w-full h-full #{!important};
  }

  .mobile-login-container {
    @apply max-md:mb-0 max-md:rounded-t-[32px] #{!important};
  }
}
</style>