import { Nullable } from '@core-lib/models/common'
import { addSearchParameter } from '@core-lib/ui-helper/search-param-remover'

let windowObjectReference: Nullable<WindowProxy> = null
let previousUrl: Nullable<string> = null

const receiveMessage = (event: { origin: string, data: { action: string, provider: string } }) => {
  if (event.origin !== window.location.origin) return
  const { action, provider } = event.data
  if (action !== 'social_login') return
  addSearchParameter('provider', provider)
  window.removeEventListener('message', receiveMessage)
  window.dispatchEvent(new Event('social_login'))
}

const popupCenter = ({ url, title, w, h }: { url: string, title: string, w: number, h: number }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  return window.open(url, title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
  )
}

export const openSignInWindow = (url: string, title: string) => {
  // remove any existing event listeners
  window.removeEventListener('message', receiveMessage)

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
     or if such pointer exists but the window was closed */
    windowObjectReference = popupCenter({
      url,
      title,
      w: 600,
      h: 700,
    })
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
     then we load it in the already opened secondary window and then
     we bring such window back on top/in front of its parent window. */
    windowObjectReference = windowObjectReference = popupCenter({
      url,
      title,
      w: 600,
      h: 700,
    })
    windowObjectReference?.focus()
  } else {
    /* else the window reference must exist and the window
     is not closed; therefore, we can bring it back on top of any other
     window with the focus() method. There would be no need to re-create
     the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }

  // add the listener for receiving a message from the popup
  window.addEventListener('message', receiveMessage)
  // assign the previous URL
  previousUrl = url
}