<template>
  <div
    ref="notificationsList"
    class="ds-mobile-nav bg-white w-full fixed left-0 bottom-0 z-20 lg:hidden px-5 pt-3 pb-5 flex flex-col justify-between"
    :style="{'--extractable-height': `${extractableHeight}px`}"
  >
    <div class="select-none overflow-y-auto">
      <slot />
      <div
        v-for="menuItem in visibleMenuItems"
        :key="menuItem.text"
        :class="{ 'pb-0': menuItem.children && menuItem.expanded }"
        class="px-1 flex-1 pt-2 pb-2.5 text-2xl font-semibold text-ds-dark-primary"
      >
        <span
          class="flex items-center cursor-pointer inline-flex"
          @click="menuItem.children ? toggleChildren(menuItem) : itemClicked(menuItem)"
        >
          <span class="mr-2">{{ menuItem.text }}</span>
          <DsIcon
            v-if="menuItem.children"
            :class="{'rotate-180': menuItem.expanded}"
            class="h-6 transition-all duration-200 transform origin-center"
            icon="angle-small-down"
            size="large"
            type="bold"
          />
        </span>

        <div
          v-if="menuItem.children"
          v-show="menuItem.expanded"
          class="pt-2"
        >
          <div
            v-for="subMenuItem in menuItem.children"
            :key="subMenuItem.text"
            @click="emit('routeClicked', subMenuItem.route || '')"
            class="px-3 py-2 text-gray-500 text-xl cursor-pointer font-medium"
          >
            {{ subMenuItem.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <button
        v-if="!isAuthenticated"
        class="h-14 bg-ds-dark-primary py-4 text-white text-[16px] leading-6 font-semibold rounded-[100px] cursor-pointer w-full mb-3 hover:border-transparent focus:ring-0 focus:outline-none focus-visible:outline-none"
        @click="emit('connectWalletClicked')"
      >
        Log in
      </button>
      <button
        v-if="!hideCreateTropeeButton"
        class="h-14 text-[16px] leading-6 font-semibold rounded-[100px] py-4 border border-gray-300 w-full flex items-center justify-center gap-x-1 focus:ring-0 focus:outline-none focus-visible:outline-none"
        @click="emit('createButtonClicked')"
      >
        <DsIcon
          icon="c_Stars"
          size="w-[14px] h-[14px]"
          color-class="text-ds-green-primary"
        />
        <span class="colored-item">Create</span>
      </button>
      <hr
        v-if="socials"
        class="mt-8 text-gray-200"
      >
      <div
        v-if="socials"
        class="flex justify-center pt-7 pb-3"
      >
        <a
          v-for="social in socials"
          :key="social.icon"
          :href="social.link"
          target="_blank"
        >
          <DsIcon
            :icon="social.icon"
            color-class="fill-gray-400 mx-8"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import DsIcon from './DsIcon.vue'

import { SocialItem } from './interfaces/ISocialItem'
import { MenuItem } from './interfaces/IMenuItem'

import { Maybe } from '@core-lib/models/common'
import { useScreenSize } from '@core-lib/composables/screen-size'

const props = withDefaults(
  defineProps<{
    menuItems: MenuItem[]
    isAuthenticated: boolean
    socials?: SocialItem[] | null
    hasAnnouncement?: boolean
    navbarSection?: Maybe<HTMLElement>
    announcementSection?: Maybe<HTMLElement>
    hideCreateTropeeButton?: boolean
  }>(),
  {
    socials: null,
    hasAnnouncement: false,
    navbarSection: null,
    announcementSection: null,
    hideCreateTropeeButton: false,
  },
)

const emit = defineEmits<{
  (e: 'routeClicked', route: string): string
  (e: 'connectWalletClicked'): void
  (e: 'createButtonClicked'): void
}>()

const extractableHeight = ref(props.navbarSection?.offsetHeight || 0)

const calculateExtractableHeight = () => {
  const reviewBanner = document.querySelector('#utility_banner_container') as HTMLElement
  const reviewBannerHeight = reviewBanner ? reviewBanner.offsetHeight : 0
  if (!props.hasAnnouncement) {
    extractableHeight.value = (props.navbarSection?.offsetHeight || 0) + reviewBannerHeight
    return
  }
  const announcementBarBottom = props.announcementSection ? props.announcementSection.getBoundingClientRect().bottom : undefined
  if (announcementBarBottom !== undefined && announcementBarBottom >= 0 && props.navbarSection?.style.position !== 'fixed') {
    extractableHeight.value = (props.navbarSection?.offsetHeight || 0) + announcementBarBottom
  } else if (props.navbarSection?.style.position === 'fixed') {
    extractableHeight.value = (props.navbarSection?.offsetHeight || 0) + reviewBannerHeight
  }
}
watch(() => props.hasAnnouncement, () => {
  calculateExtractableHeight()
}, { immediate: true })
const expandedItem = ref('')
const structuredItems = computed<MenuItem[]>(() => {
  return props.menuItems.filter((item: MenuItem) => props.isAuthenticated || ['Resources', 'Explore'].includes(item.text ?? '')).map(
    (item: MenuItem) => ({
      ...item,
      expanded: expandedItem.value === item.text,
    }),
  )
})
const visibleMenuItems = computed(() =>
  structuredItems.value?.filter((item: MenuItem) => item.condition !== undefined ? item.condition : true),
)

const itemClicked = (item: MenuItem) => {
  if (item.route) {
    emit('routeClicked', item.route)
    return
  }
}

const toggleChildren = (item: MenuItem) => {
  if (expandedItem.value === item.text) {
    expandedItem.value = ''
  } else {
    expandedItem.value = item.text || ''
  }
}

const vh = ref(1)
const adjustNavbarHeight = () => {
  vh.value = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh.value}px`)
}

onMounted(() => {
  adjustNavbarHeight()
  window.addEventListener('resize', adjustNavbarHeight)
})

onBeforeUnmount(() => window.removeEventListener('resize', adjustNavbarHeight))

const { width } = useScreenSize()
watch(width, () => {
  nextTick(() => {
    calculateExtractableHeight()
  })
})
</script>

<style lang="scss">
.ds-mobile-nav {
  height: calc(100% - var(--extractable-height));

  @media screen and (min-width: 350px) {
    height: calc(var(--vh, 1vh) * 100 - var(--extractable-height));
  }
}

.colored-item {
  background: linear-gradient(90deg, #48EFCA 0%, #7F41B9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.45);
}

.menu-slide-enter-active {
  @apply ease-in duration-300;
}

.menu-slide-leave-active {
  @apply ease-in-out duration-300;
}

.menu-slide-enter-from,
.menu-slide-leave-to {
  @apply translate-x-full;
}
</style>
