<template>
  <Toaster />
  <div
    v-if="isConnectedToProd"
    :style="{ 'background-color': isConnectedToProd ? 'red' : 'orange' }"
    class="fixed"
    style="left: 40%;
    color: black;
    padding: 5px;
    z-index: 100;"
  >
    <div v-if="isConnectedToProd">
      You are connected to prod
    </div>
  </div>
  <router-view v-slot="{ Component, route }">
    <template v-if="appRoute.name !== undefined">
      <LoyaltyLayout v-if="hasLoyaltyLayout">
        <component
          :is="Component"
          :key="route.path"
        />
      </LoyaltyLayout>
      <PreviewLayout
        v-else-if="isPreview"
        class="overflow-hidden"
      >
        <transition
          mode="out-in"
          name="fade"
        >
          <component
            :is="Component"
            :key="route.path"
          />
        </transition>
      </PreviewLayout>
      <BaseLayout v-else>
        <component
          :is="Component"
          :key="route.path"
        />
      </BaseLayout>
    </template>
  </router-view>
</template>

<script lang="ts" setup>
import externalScripts from './plugins/external'
import PreviewLayout from './layouts/PreviewLayout.vue'
import BaseLayout from './layouts/BaseLayout.vue'
import { useIsPreview } from '@/composables/preview'
import { useProdConnect } from '@/composables/prod-connect'
import { useAppLoaded } from '@core-lib/composables/app-loaded'
import Toaster from '@core-lib/components/Toaster.vue'
import { useAnalytics } from '@core-lib/composables/analytics'
import LoyaltyLayout from '@/layouts/LoyaltyLayout.vue'
import { useIsLoyalty } from '@core-lib/composables/loyalty/is-loyalty'
import { useRoute } from 'vue-router'

const appRoute = useRoute()
const { hasLoyaltyLayout } = useIsLoyalty()
const isPreview = useIsPreview()
externalScripts.installGtm()

useAppLoaded()
useAnalytics()

const { isConnected: isConnectedToProd } = useProdConnect()
</script>

<style lang="scss">
@import "@core-lib/assets/fonts/fonts.css";

#app {
  overflow-x: scroll;
  font-family: 'Poppins', sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-tropee-text text-sm md:text-base md:overflow-visible;
}

.tropee-container {
  @apply w-full mx-auto relative px-4 md:px-6 lg:px-8;
  max-width: 1920px;
}

.tropee-full-screen {
  @apply overflow-x-hidden w-screen relative;
  left: calc(-50vw + 50%);
}

.tropee-full-height {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.tropee-heading {
  @apply text-2xl lg:text-3xl leading-tight font-semibold;
}

.tropee-rankings-container {
  @apply lg:flex lg:justify-between py-3 px-0 rounded-2xl
}

//tokenproof wrapper
.wrapper_f1q6wruf{
  background: rgba(0, 0, 0, 0.5) !important;
}

.wrapperOpen_f1w4fzq5{
  transform: none !important;
  transition: none !important;
}

.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.loader-layer {
  z-index: 99999999;
  @apply bg-white absolute w-full h-full top-0 opacity-0
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: .25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .15s ease;
}

.component-fade-enter-from, .component-fade-leave-to {
  opacity: 0;
}

.fade-faster-enter-active,
.fade-faster-leave-active {
  transition-duration: .15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-faster-enter,
.fade-faster-leave-active {
  opacity: 0
}

.fade-slow-enter-active, .fade-slow-leave-active {
  transition: opacity .3s ease;
}

.fade-slow-enter-from, .fade-slow-leave-to {
  opacity: 0;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none !important;
}

.overflow-y-scroll {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.external-link {
  @apply text-blue-600 border-b-2 border-blue-600 hover:text-blue-800 hover:border-blue-800
}

.horizontal-list-item {
  transition: all 0.25s;
}

.horizontal-list-enter-from, .horizontal-list-leave-to {
  opacity: 0;
}

.horizontal-list-leave-active {
  position: absolute;
}

.horizontal-list-move {
  transition-delay: 0.25s;
}

.horizontal-list-enter-to {
  transition-delay: 0.35s;
}

:root {
  --account-center-z-index: 40;
  --account-center-position-top: -11px;
  --account-center-position-right: 112px;

  @media screen and (min-width: 768px) {
    --account-center-position-right: 112px;
  }
  @media screen and (min-width: 1024px) {
    --account-center-position-right: 16px;
  }
  @media screen and (min-width: 1920px) {
    --account-center-position-right: auto;
  }

  --onboard-black: #1D1D1B;
  --onboard-connect-sidebar-color: #1D1D1B;
  --onboard-connect-header-color: #1D1D1B;
  --onboard-wallet-button-color: #1D1D1B;
  --onboard-wallet-button-color-hover: #1D1D1B;

  --wcm-z-index: 100 !important;
}

@media only percy {
  .percy-hide {
    visibility: hidden;
    display: none;
  }
}

.icon-fade-fast-enter-active,
.icon-fade-fast-leave-active {
  transition-duration: 50ms;
  transition-property: opacity;
  transition-timing-function: ease;
}

.icon-fade-fast-enter,
.icon-fade-fast-leave-active {
  opacity: 0
}

.ds-icon-link {
  @apply flex items-center w-12 h-12 justify-center rounded-2xl hover:bg-gray-50;
}
</style>
