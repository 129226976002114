export enum RequirementGroup {
    Virtual = 'virtual',
    Nft = 'nft',
    Token = 'token',
    Twitter = 'twitter',
    Poap = 'poap',
    Discord = 'discord',
    Lens = 'lens',
    List = 'list',
    VisitPage = 'visit_page',
    Refer = 'refer',
    Auth = 'auth',
    Youtube = 'youtube',
    Instagram = 'instagram',
    Linkedin = 'linkedin',
    Question = 'question',
    Telegram = 'telegram',
    Email = 'email',
    ProductHunt = 'product_hunt',
    AllDone = 'all_done',
    Quiz = 'quiz',
    Facebook = 'facebook',
    Tiktok = 'tiktok',
    Pinterest = 'pinterest',
    Twitch = 'twitch',
    DownloadApp = 'download_app',
    Kick = 'kick',
    Loyalty = 'loyalty',
    PhoneNumber = 'phone_number',
    File = 'file',
    Calendly = 'calendly',
    Connect = 'connect',
    Typeform = 'typeform',
    Custom = 'custom',
}
