<template>
  <div class="flex-1 flex flex-col justify-center gap-y-6 items-center w-full px-[56px]">
    <img
      class="h-[68px] w-[68px]"
      src="@core-design/assets/images/tropee-logo-green.svg"
      alt="Tropee"
    >
    <div class="text-xl tracking-8 font-semibold text-center dark:text-white/[.87]">
      {{ translate('page_not_found') }}
    </div>
    <LoyaltyCreateYourTropeeButton class="!py-0" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@core-lib/composables/i18n'
import LoyaltyCreateYourTropeeButton
  from '@core-design/components/ComponentsLayout/Loyalty/LoyaltyCreateYourTropeeButton.vue'

const { translate } = useI18n({ prefix: 'loyalty__' })

</script>

<style lang="scss">
</style>