<template>
  <div class="flex-1 w-full flex flex-col h-full">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="!nonce"
        class="flex flex-col gap-y-3"
      >
        <template v-if="showGoogleLogin">
          <div
            class="w-full flex items-center justify-center gap-x-2 p-3 rounded-2xl border border-gray-50 bg-white cursor-pointer md:hover:bg-gray-50 md:hover:border-gray-100 shadow-[0px_8px_40px_0px_rgba(27,32,50,0.04),0px_2px_5px_0px_rgba(0,0,0,0.05),0px_0px_2px_0px_rgba(29,29,27,0.15)]"
            @click="connectGoogle"
          >
            <DsIcon
              icon="c_Google"
              size="w-5 h-5 min-w-[20px]"
            />
            <span class="text-[16px] leading-[24px] font-semibold">{{ translator.translate('auth__continue_with_google') }}</span>
          </div>
          <div class="self-center w-[254px] flex items-center gap-x-1.5">
            <div class="h-px bg-gray-300 flex-1" />
            <span class="text-gray-400 text-xs font-semibold">OR</span>
            <div class="h-px bg-gray-300 flex-1" />
          </div>
        </template>
        <DsInput
          v-model.trim="email"
          class="min-w-0"
          placeholder="Email"
          width-class="w-full"
          wrapper-class="w-full"
          field-class="!justify-start"
          :has-error="!!emailErrorMessage"
          :helper-text="emailErrorMessage"
          @enter="sendCodeForLogin(loyaltyProgramIdentifier)"
          @focus="preventTouchMove"
          @blur="allowTouchMove"
        />
        <DsButton
          class="w-full !text-[16px] !leading-[24px] font-semibold"
          @click="sendCodeForLogin(loyaltyProgramIdentifier)"
        >
          {{ translator.translate('auth__continue_with_email') }}
        </DsButton>
        <div class="mt-1.5 md:mb-1.5 text-center self-center max-w-[299px] text-xs text-gray-400">
          <span>{{ translator.translate('auth__by_continuing_you_agree_to_tropees') }}&nbsp;</span>
          <a
            class="font-semibold hover:underline"
            href="https://www.tropee.com/terms"
            target="_blank"
          >{{ translator.translate('auth__terms_of_service') }}&nbsp;</a>
          <span>{{ translator.translate('general__and') }}&nbsp;</span>
          <a
            class="font-semibold hover:underline"
            href="https://www.tropee.com/privacy-policy"
            target="_blank"
          >{{ translator.translate('auth__privacy_policy') }}</a>
        </div>
      </div>
      <LoyaltyVerifyEmailWithCode
        v-else
        :code="code"
        :code-re-requested="codeReRequested"
        :code-request-count-down="codeRequestCountDown"
        :invalid-code="invalidCode"
        :is-sending-code="isSendingCode"
        :is-verify-loading="isVerifyLoading"
        @change-code="handleCodeChange"
        @send-code="sendCodeForLogin(loyaltyProgramIdentifier)"
        @verify-code="verifyEmailLoginCode"
        @go-back="nonce = ''"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">

import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import ConnectionType from '@core-lib/models/wallet'
import DsInput from '@core-design/components/Design/DsInput.vue'
import { useZodValidateSimple } from '@core-lib/composables/zod-validate'
import { z } from 'zod'
import { computed, ref } from 'vue'
import { useVerifyEmailWithCode } from '@core-lib/composables/verify-email-with-code'
import { NotificationType } from '@core-lib/composables/toasters'
import translator from '@core-lib/webapp-services/i18n/translator'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import LoyaltyVerifyEmailWithCode from '@core-lib/components/auth/LoyaltyVerifyEmailWithCode.vue'
import {
  useLoyaltyProgramIdentifier,
} from '@core-lib/composables/loyalty/loyalty-program-identifier'
import { usePreventTouchMove } from '@core-lib/composables/touch-move'
import useIsScreenSmallerThan from '@core-lib/composables/is-screen-smaller-than'
import { isFromWebView } from '@core-lib/helpers/metamask-detection'

defineEmits<{
  (e: 'notify', payload: {type: NotificationType, message: string}): void
}>()

const isScreenSmallerThanLarge = useIsScreenSmallerThan(1023)

const { loyaltyProgramIdentifier } = useLoyaltyProgramIdentifier()

const showGoogleLogin = computed(() => !(isScreenSmallerThanLarge.value && isFromWebView()))

const connectGoogle = () => {
  ServiceContainer.authService.authenticate(ConnectionType.GOOGLE)
}

const { preventTouchMove, allowTouchMove } = usePreventTouchMove()

const email = ref('')
const { errorMessage: emailErrorMessage, validate: validateEmail } = useZodValidateSimple(
  z.string({ required_error: translator.translate('general__this_field_is_required') }).trim().min(1, translator.translate('general__this_field_is_required')).email(translator.translate('general__that_email_does_not_look_right')),
  email,
)

const {
  code, nonce, isSendingCode, invalidCode, sendCodeForLogin, verifyEmailLoginCode, codeReRequested, codeRequestCountDown, isVerifyLoading, handleCodeChange,
} = useVerifyEmailWithCode({ email, validate: validateEmail, isForLogin: true, withNotify: false })
</script>

<style scoped>

</style>
