<template>
  <AnnouncementModal
    v-if="announcementModalContent"
    :show="showModal"
    :announcement="announcementModalContent"
    @hide="handleModalClose"
  />
</template>

<script setup lang="ts">
import AnnouncementModal from '@core-lib/components/AnnouncementModal.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useAnnouncementModalFetcher } from '@core-lib/composables/announcement-modal-fetcher'
import { useRouter } from 'vue-router'

const showModal = ref(false)

const router = useRouter()
const currentPath = computed(() => router.currentRoute.value.path)

const {
  loadAnnouncementModal,
  announcementModalContent,
  onUserHasClosedAnnouncementModal,
} = useAnnouncementModalFetcher(currentPath)

const handleModalClose = () => {
  showModal.value = false
  onUserHasClosedAnnouncementModal()
}

watch(announcementModalContent, content => {
  showModal.value = !!content
})

watch(showModal, show => {
  if (show) {
    document.querySelector('body')?.classList.add('overflow-y-hidden')
  } else {
    document.querySelector('body')?.classList.remove('overflow-y-hidden')
  }
}, { immediate: true })

onMounted(async () => {
  await loadAnnouncementModal()
})
</script>

<style scoped>

</style>