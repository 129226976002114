export interface Event {
  name: string
  payload?: object
}

export interface GapEvent {
  gapSeconds: number
}

export abstract class FlowEvent implements Event {
  protected abstract flow: string
  protected abstract step: string

  constructor(protected readonly _payload?: object) {}

  public get name() {
    return `flow-${this.flow}-${this.step}`
  }

  public get payload() {
    return { ...(this._payload || {}), flow: this.flow, step: this.step }
  }
}

export abstract class ActionEvent implements Event {
  protected abstract action: string

  constructor(protected readonly _payload?: object) {}

  public get name() {
    return `action-${this.action}`
  }

  public get payload() {
    return { ...(this._payload || {}), action: this.action }
  }
}

export class LoadedEvent implements Event {
  constructor(private routeName: string) {}

  public get name() {
    return `Loaded ${this.routeName}`
  }
}

export function isGapEvent(event: object): event is GapEvent {
  return 'gapSeconds' in event
}
