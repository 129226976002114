import { ComputedRef, MaybeRefOrGetter, Ref, ref, toValue, unref, watch } from 'vue'
import { ZodType } from 'zod'
import { ZodEffects, ZodObject, ZodRawShape } from 'zod/lib/types'
import { ZodFormattedError } from 'zod/lib/ZodError'

export const useZodValidateSimple = (schema: ZodType, value: Ref<unknown>) => {
  const isValid = ref(false)
  const errorMessage = ref('')

  const validate = async () => {
    const plainValue = unref(value)
    resetError()
    const result = await schema.safeParseAsync(plainValue)
    isValid.value = result.success
    if (!result.success) {
      errorMessage.value = result.error.format()._errors[0]
    }
    return isValid.value
  }

  const resetError = () => {
    isValid.value = false
    errorMessage.value = ''
  }

  watch(value, () => {
    resetError()
  })

  return { validate, resetError, errorMessage, isValid }
}

export const useZodValidateObject = <T extends ZodRawShape>(schema: ZodObject<T> | ZodEffects<ZodObject<T>>, value: MaybeRefOrGetter<Record<keyof T, unknown>>) => {
  type ErrorMap = Record<keyof T, string>
  const emptyErrors: () => ErrorMap = () => Object.keys(toValue(value)).reduce((agg, key) => {
    return {
      ...agg,
      [key]: '',
    }
  }, {}) as ErrorMap

  const isValid = ref(false)
  const fieldErrorMessages = ref<ErrorMap>(emptyErrors())as Ref<ErrorMap>
  const nonFieldErrorMessage = ref('')
  const changeWatch = ref()

  const validate = async () => {
    const plainValue = toValue(value)
    resetError()
    const result = await schema.safeParseAsync(plainValue)
    isValid.value = result.success
    if (!result.success) {
      const formattedErrors = result.error.format() as ZodFormattedError<T>
      nonFieldErrorMessage.value = formattedErrors._errors[0] || ''
      for (const key of Object.keys(plainValue)) {
        fieldErrorMessages.value = Object.assign({}, fieldErrorMessages.value, { [key]: formattedErrors[key]?._errors[0] || '' })
      }
    }
    if (!changeWatch.value) {
      changeWatch.value = watch(value, validate)
    }
    return isValid.value
  }

  const resetError = () => {
    isValid.value = false
    fieldErrorMessages.value = emptyErrors()
    nonFieldErrorMessage.value = ''
  }

  return { validate, resetError, fieldErrorMessages, nonFieldErrorMessage, isValid }
}

export const useZodValidateForm = <T extends ZodRawShape>(schema: ComputedRef<ZodObject<T> | ZodEffects<ZodObject<T>>>, value: Ref<Record<keyof T, unknown>>) => {
  type ErrorMap = Record<keyof T, string>

  const emptyErrors = Object.keys(value.value).reduce((agg, key) => {
    return {
      ...agg,
      [key]: '',
    }
  }, {}) as ErrorMap

  const showErrors = ref(false)
  const errorsCount = ref(0)
  const isValid = ref(false)
  const fieldErrorMessages = ref<ErrorMap>(emptyErrors) as Ref<ErrorMap>

  const validate = () => {
    const plainValue = unref(value)
    const result = schema.value.safeParse(plainValue)
    isValid.value = result.success
    if (!result.success) {
      fieldErrorMessages.value = result.error.issues.reduce((acc, currentValue) => {
        acc[currentValue.path[0]] = currentValue.message
        return acc
      }
      , {} as { [key: string]: string }) as ErrorMap
      errorsCount.value = result.error.issues.length
    } else {
      fieldErrorMessages.value = {} as ErrorMap
      errorsCount.value = 0
    }
    return { isValid: isValid.value, errorsCount: errorsCount.value }
  }

  const externalValidate = () => {
    showErrors.value  = true
    return validate()
  }

  const hideErrors = () => {
    showErrors.value = false
  }

  watch(value, () => {
    validate()
  })

  return { validate: externalValidate, fieldErrorMessages, isValid, showErrors, hideErrors, errorsCount }
}
