import {
  IdentifyInput,
} from '@core-lib/webapp-services/analytics/plugins/common'

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

export const gtag = (payload: Record<string, unknown>) => {
  if (window.dataLayer){
    window.dataLayer.push(payload)
  } else {
    console.debug('gtag payload', payload)
  }
}

export function pluginGoogleAnalytics() {
  return {
    name: 'ga-tracker',
    initialize: () => {
      return
    },
    track: () => {
      return
    },
    identify: ({ payload }: IdentifyInput) => {
      gtag({ 'user_id': payload.userId })
    },
  }
}
