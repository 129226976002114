import { ConnectionTypeType } from '@core-lib/models/wallet'
import { FlowEvent } from '@core-lib/webapp-services/analytics/events/base'

abstract class AuthFlow extends FlowEvent {
  protected flow = 'auth'

  constructor(payload: { connectionType: ConnectionTypeType }) {
    super(payload)
  }
}

export class AuthStarted extends AuthFlow {
  protected step = 'started'
}

export class AuthFailed extends AuthFlow {
  protected step = 'failed'
}

export class AuthAuthenticated extends AuthFlow {
  protected step = 'authenticated'
}
