import {
  AnalyticsApi,
  BillingApi, BillingV2Api,
  CreatorApi,
  DefaultApi, LoyaltyApi, OrganizationApi, RewardApi,
  UserApi,
  UtilityApi,
  UtilityTransactionApi,
} from '@core-lib/openapi/client/api'
import { BASE_PATH } from '@core-lib/openapi/client/base'
import { ApiService } from '@core-lib/webapp-services/api-service'
import axios from 'axios'
import { lastPublicApiSecret } from '@core-lib/openapi/secret'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'

const axiosInstance = axios.create({
  headers: {
    'x-api-key': lastPublicApiSecret,
  },
})
ApiService.prepareAxios(axiosInstance)
ServiceContainer.env.apiBaseUrl
const apiBaseUrlOrigin = ServiceContainer.env.apiBaseUrl.startsWith('/') ? '' : (new URL(ServiceContainer.env.apiBaseUrl).origin)
const basePath = apiBaseUrlOrigin ? `${apiBaseUrlOrigin}${BASE_PATH}` : BASE_PATH

export const client = new DefaultApi(undefined, basePath, axiosInstance)
export const utilityTransactionApiClient = new UtilityTransactionApi(undefined, basePath, axiosInstance)
export const creatorApiClient = new CreatorApi(undefined, basePath, axiosInstance)
export const billingApiClient = new BillingApi(undefined, basePath, axiosInstance)
export const billingV2ApiClient = new BillingV2Api(undefined, basePath, axiosInstance)
export const userApiClient = new UserApi(undefined, basePath, axiosInstance)
export const utilityApiClient = new UtilityApi(undefined, basePath, axiosInstance)
export const organizationApiClient = new OrganizationApi(undefined, basePath, axiosInstance)
export const loyaltyApiClient = new LoyaltyApi(undefined, basePath, axiosInstance)
export const analyticsApiClient = new AnalyticsApi(undefined, basePath, axiosInstance)
export const rewardApiClient = new RewardApi(undefined, basePath, axiosInstance)

