import { ActionEvent, FlowEvent } from '@core-lib/webapp-services/analytics/events/base'

abstract class UtilityClaimFlow extends FlowEvent {
  protected flow = 'utility_claim'

  constructor(payload: { utilityId: string }) {
    super(payload)
  }
}

export class TemplateUsedAction extends ActionEvent {
  protected action = 'template_used'
  constructor(payload: { templateId: string }) {
    super(payload)
  }
}

export class UtilityClaimFlowMinted extends UtilityClaimFlow {
  protected step = 'minted'
}

export class UtilityClaimFlowPaid extends UtilityClaimFlow {
  protected step = 'paid'
}

export class UtilityClaimFlowActivated extends UtilityClaimFlow {
  protected step = 'activated'
}

export class UtilityClaimFlowEnteredRaffle extends UtilityClaimFlow {
  protected step = 'entered_raffle'
}

abstract class SuggestedTropeeFlow extends FlowEvent {
  protected flow = 'suggested_tropee'

  constructor(payload: { templateName: string }) {
    super(payload)
  }
}

export class SuggestedTropeePreviewed extends SuggestedTropeeFlow {
  protected step = 'previewed'
}

export class SuggestedTropeeCreated extends SuggestedTropeeFlow {
  protected step = 'created'
}