<template>
  <DsMenuWithContent
    nav-class="!gap-0"
    :items="sharingList"
    :icons-size="24"
    :force-active-item="activeItem"
    content-class="px-3"
    @item-change="submenuItemClickCallback"
  >
    <DsCopyBox
      class="m-3"
      :text="cleanCurrentUrl"
      :image="tropeeImage"
    />
  </DsMenuWithContent>
</template>
<script setup lang="ts">

import DsMenuWithContent from '@core-design/components/Design/DsMenuWithContent.vue'
import DsCopyBox from '@core-design/components/Design/DsCopyBox.vue'
import { useOrigin } from '@core-lib/composables/origin'
import tropeeImage from '@core-design/assets/images/tropee-logo-green.svg'
import { loyaltyTwitterMessage, rewardTwitterMessage } from '@core-lib/const/twitter-message'
import { capitalize } from '@core-lib/helpers/string'
import translator from '@core-lib/webapp-services/i18n/translator'
import { useIsLoyalty } from '@core-lib/composables/loyalty/is-loyalty'
import { ref } from 'vue'
import { useTwitterShare } from '@core-lib/composables/twitter-share'

type MenuItem = {
  name: string
  hideArrow?: boolean
  icon?: string
  iconClass?: string
  oldIcon: boolean
  action: () => void
}

defineEmits<{
  (e: 'menuItemClick', item: number | null): void
}>()
const activeItem = ref<number | null>(null)

const { cleanCurrentUrl } = useOrigin()
const pathWithUtm = `${cleanCurrentUrl.value}?utm_source=tropee_profile_share`
const { isLoyaltyPage } = useIsLoyalty()
const socialText = isLoyaltyPage.value ? loyaltyTwitterMessage : rewardTwitterMessage
const socialMessage = `${socialText} - ${pathWithUtm}`
const { openTwitterUrl } = useTwitterShare(pathWithUtm, '', 'loyalty')
const openTwitterUrlWithText = () => {
  openTwitterUrl(socialText)
}
const openEmailUrl = () => {
  const body = `${socialMessage}`
  window.open(`mailto:?subject=${socialText}&body=${body}`, '_blank')
}

function shareOnFacebook() {
  window.open(`https://facebook.com/sharer/sharer.php?u=${pathWithUtm}`, '_blank')
}
function shareOnSnapchat() {
  window.open(`https://www.snapchat.com/scan?attachmentUrl=${pathWithUtm}`, '_blank')
}
function shareOnWhatsapp() {
  window.open(`https://api.whatsapp.com/send?text=${encodeURI(socialMessage)}`, '_blank')
}
function shareOnLinkedin() {
  window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${pathWithUtm}`, '_blank')
}
function shareViaMessenger() {
  window.open(`https://www.messenger.com/new`, '_blank')
}

const sharingList = [
  {
    name: capitalize(translator.translate('general__share_on_twitter')),
    icon: 'c_XSquared',
    oldIcon: true,
    action: openTwitterUrlWithText,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_on_facebook')),
    icon: 'c_FacebookSquared',
    oldIcon: true,
    action: shareOnFacebook,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_via_whatsapp')),
    icon: 'c_WhatsappSquared',
    oldIcon: true,
    action: shareOnWhatsapp,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_on_snapchat')),
    icon: 'c_SnapchatSquared',
    oldIcon: true,
    action: shareOnSnapchat,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_on_linkedin')),
    icon: 'c_LinkedinSquared',
    oldIcon: true,
    action: shareOnLinkedin,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_via_messenger')),
    icon: 'c_MessengerSquared',
    oldIcon: true,
    action: shareViaMessenger,
  },
  {
    name: capitalize(translator.translate('tropee_page__share_via_email')),
    icon: 'c_EmailGreySquared',
    oldIcon: true,
    action: openEmailUrl,
  },
] as unknown as MenuItem[]

function submenuItemClickCallback(item: number | null) {
  activeItem.value = item
  if (item === null) return
  sharingList[item-1].action()
}
</script>
<style scoped>

</style>