<template>
  <div
    class="flex items-center"
    :class="{'justify-center': centered}"
  >
    <div
      class="fixed bottom-8 z-[1000001]"
      :class="{'right-8': !centered}"
    >
      <transition-group
        name="slide"
        :css="true"
      >
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="toaster bg-white py-3 md:py-4 pr-5 md:pr-[22px] mb-3 rounded-xl max-w-lg"
          :class="[message.type ? 'pl-3.5 md:pl-[18px]' : 'pl-5 md:pl-[22px]', {'ml-8': !centered}]"
        >
          <div class="flex items-center">
            <DsIcon
              v-if="message.type"
              :icon="iconComponent(message.type).name"
              size="small"
              class="mr-2"
              :color-class="iconComponent(message.type).class"
            />
            <div class="text-sm md:text-[16px] md:leading-6 font-semibold text-left flex-1">
              {{ message.text }}
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import { useToasterMessages } from '@core-lib/composables/toasters'
import { IconType } from '@core-design/types/icon'

defineProps<{
  centered?: boolean
}>()
const { messages } = useToasterMessages()

const iconComponent = (type?: 'success' | 'error' | 'warn'): {name: IconType, class: string} => {
  switch (type) {
  case 'success':
    return {
      name: 'c_CheckCircleSmall',
      class: 'text-[#48EFCA]',
    }
  case 'error':
    return {
      name: {
        name: 'exclamation',
        type: 'solid',
      },
      class: 'text-red-500',
    }
  default:
    return {
      name: {
        name: 'info',
        type: 'solid',
      },
      class: 'text-ds-dark-primary',
    }
  }
}
</script>

<style lang="scss">

.toaster {
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.15), 0 4px 7px 0 rgba(0, 0, 0, 0.07), 0 0 2px 0 rgba(0, 0, 0, 0.15);
}

.slide-enter-active {
  transform: translateY(0);
}

.slide-leave-active {
  transform: translateY(0);
}

.slide-enter-from {
  transform: translateY(100%);
}

.slide-leave-to {
  transform: translateY(100%);
}
</style>
