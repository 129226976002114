import { isNullish } from '../models/common'
import { DateTime } from 'luxon'
import { z } from 'zod'
import { Currency } from '@core-lib/openapi/client'
import { currencyMap } from '@core-lib/const/currency'

export const formatNumberToUS = (x: number | string | undefined | null): number | string => {
  if (!x || isNullish(x) || Number(x)< 0 ) {
    return 0
  }
  return x.toLocaleString('en-US')
}

export const formatNumberToFR = (x: number | string | undefined | null): number | string => {
  if (!x || isNullish(x) || Number(x)< 0 ) {
    return 0
  }
  return x.toLocaleString('fr-FR')
}

export const formatNumber = (unformatted: number | string | undefined | null, formatFunction?: (( x: number | string | undefined | null) => number | string) | undefined, short = true): number | string => {
  if (!unformatted || isNullish(unformatted) || Number(unformatted)< 0 ) {
    return 0
  }
  const x = typeof unformatted === 'string' ? Number(unformatted) : unformatted
  if (x >= 1000000) {
    return Number((Number(x) / 1000000).toFixed(1)) + 'M'
  }
  const formatted = (typeof formatFunction === 'function' ? formatFunction(x) : formatNumberToUS(x))
  return x < 10000 ? formatted : (short ? Number((Number(x) / 1000).toFixed(1)) + 'K' : formatted)
}

const currenciesWithRightPosition: Currency[] = []
const currenciesWithDifferentFormat: {[p: string]: ( x: number | string | undefined | null) => number | string} = { [Currency.Eur]: formatNumberToFR }

export const formatWithCurrency = (value: number | string | undefined | null , currency: Currency, format: 's' | 'l' = 'l'): number | string  => {
  if (!value || isNullish(value) || Number(value)< 0 ) {
    return 0
  }
  const currencyText = currencyMap[currency] || '$'
  let formattedNumber: string | number
  if (format === 'l') {
    formattedNumber = (currency in currenciesWithDifferentFormat) ? currenciesWithDifferentFormat[currency](value) : formatNumberToUS(value)
  } else {
    formattedNumber = formatNumber(value, (currency in currenciesWithDifferentFormat) ? currenciesWithDifferentFormat[currency] : undefined)
  }
  return currenciesWithRightPosition.includes(currency) ? (formattedNumber + (format === 's' ? ' ' : '') + currencyText) : (currencyText + formattedNumber)
}

export const infiniteDate = DateTime.utc().set({ year: 2051 }).startOf('year')
export const isDateInfinite = (date: DateTime | Date) => {
  if (date instanceof Date) {
    date = DateTime.fromJSDate(date)
  }

  return date.toISODate() === infiniteDate.toISODate()
}

export const infiniteQuantity = 1_000_000
export const isPrizeQuantityInfinite = (quantity: number) => {
  return quantity >= infiniteQuantity
}

export const isActionsCountInfinite = (actionsCount: number) => {
  return actionsCount >= 2147483647
}

export const zodEnumFromObjKeys = <K extends string> ( obj: Record<K, unknown> ): z.ZodEnum<[ K, ...K[] ]> => {
  const [ firstKey, ...otherKeys ] = Object.keys( obj ) as K[]
  return z.enum( [ firstKey, ...otherKeys ] )
}

export const removeCharFromBeginning = (text: string, char: string) => {
  return text.startsWith(char) ? text.slice(char.length) : text
}

export const addCharToBeginning = (text: string, char: string) => {
  return (!text.length || text.startsWith(char)) ? text : char + text
}
