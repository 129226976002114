import { AxiosError } from 'axios'

export interface ApiService {
  get<T>(path: string): Promise<T>
  post<T>(path: string, payload: object): Promise<T>
  put<T>(path: string, payload: object): Promise<T>
  delete<T>(path: string): Promise<T>
}

type BadRequest = { error: { message: string, context: object, code: string } }

export class ApiError extends Error {
  public readonly status: number
  public readonly code: string
  public readonly context: object

  constructor(status: number, code: string, message: string, context: object) {
    super(message)
    this.status = status
    this.code = code
    this.context = context
    Object.setPrototypeOf(this, ApiError.prototype)
  }

  public static create(errorData: BadRequest, status: number) {
    return new ApiError(
      status,
      errorData.error.code,
      errorData.error.message,
      errorData.error.context,
    )
  }

  isBadRequest() {
    return this.status === 400
  }

  isNotFound() {
    return this.status === 404
  }

  is500() {
    return this.status >= 500
  }

  static wrapIfApiError(error: AxiosError) {
    if (error.response?.status === 404) return new ApiError(404, '', '', {})
    return error.response && isBadRequestAxiosError(error) ?
      ApiError.create(error.response.data, error.response.status) :
      error
  }
}

const isBadRequestAxiosError = (error: AxiosError): error is AxiosError<BadRequest> => {
  return !!error.response && !!(error.response.data as { error: unknown }).error
}