<template>
  <BaseModal
    @hide="close"
    :show="showModal"
    custom-size="md:max-w-[470px]"
    shadow-class="shadow-[0_0_30px_rgba(27,32,50,0.15)]"
    container-class="!p-0 align-end max-md:content-end h-full flex-wrap"
    close-button-position-class="top-4 right-4"
    without-padding
    close-button
  >
    <div class="p-8 md:p-12 text-center text-ds-dark-primary">
      <h4 class="text-2xl font-semibold mb-2 mx-0 md:mx-4">
        Sign the message in your wallet to continue
      </h4>
      <div class="text-[16px] leading-6 font-normal mb-8">
        Tropee uses this signature to verify that you’re the owner of this wallet
      </div>
      <div class="flex flex-col gap-3" />
      <DsButton
        style-type="primary"
        :loading="true"
        loading-positioned-relative-to="button"
        show-text-on-loading
        class="mx-auto mb-3 md:mb-4 w-full"
      >
        Sign message in wallet
      </DsButton>
      <DsButton
        class="w-full"
        style-type="secondary"
        @click="disconnect"
      >
        Cancel
      </DsButton>
    </div>
  </BaseModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import DsButton from '@core-design/components/Design/DsButton.vue'
import BaseModal from '@core-lib/components/BaseModal.vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

const showModal = ref(false)

const authService = ServiceContainer.authService
const signingInProgress = authService.isSigning
const isAuthenticated = authFacade.isAuthenticated

const disconnect = () => {
  showModal.value = false
  authService.stopSigning()
}

watch(signingInProgress, (newValue) => {
  showModal.value = newValue
})
watch(isAuthenticated, (isAuthenticated) => {
  if (isAuthenticated) showModal.value = false
})
const close = () => {
  disconnect()
}
</script>

<script>

export default {
  name: 'TheMessageSigningExplanation',
}
</script>
