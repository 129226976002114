<template>
  <div
    :class="twMerge('overflow-hidden relative', hasInnerBorder ? 'outline outline-1 outline-offset-[-1px] outline-gray-300/25' : '', imageClass)"
  >
    <ImageOrVideo
      v-if="avatarType || (mediaUrl && !forceShowPlaceholder)"
      :url="ImageService.fitImageToWidth(getAvatarImage(), imageServiceWidth)"
      :class="twMerge('object-cover w-full h-full', innerClass)"
      @image-error="forceShowPlaceholder = true"
      @image-loaded="$emit('onImageLoaded')"
    />
    <DsImagePlaceholder
      v-if="!mediaUrl || avatarType || forceShowPlaceholder"
      :identifier="identifier"
      :text="name"
      :class="twMerge('w-full h-full', avatarType ? 'absolute top-0 left-0 bg-transparent' : '', innerClass)"
      :text-class="`${placeholderTextClass} ${avatarType === AvatarType.Green ? 'text-black' : ''}`"
    />
  </div>
</template>

<script setup lang="ts">
import { ImageService } from '@core-lib/services/image-service'
import DsImagePlaceholder from '@core-design/components/Design/DsImagePlaceholder.vue'
import ImageOrVideo from '@core-lib/components/ImageOrVideo.vue'
import { ref } from 'vue'
import { twMerge } from 'tailwind-merge'
import { AvatarType } from '@core-lib/openapi/client'
import profile1 from '@core-lib/assets/images/loyalty/admin-auth/profile1.png'
import profile2 from '@core-lib/assets/images/loyalty/admin-auth/profile2.png'
import profile3 from '@core-lib/assets/images/loyalty/admin-auth/profile3.png'

const props = withDefaults(defineProps<{
  identifier: string
  name: string
  mediaUrl?: string | null | undefined
  imageClass?: string
  placeholderTextClass?: string
  hasInnerBorder?: boolean
  imageWithLetter?: boolean
  avatarType?: AvatarType | null
  imageServiceWidth?: number
  innerClass?: string
}>(), {
  imageClass: 'w-9 h-9 min-w-[36px] rounded-md',
  placeholderTextClass: undefined,
  mediaUrl: undefined,
  hasInnerBorder: true,
  imageWithLetter: false,
  avatarType: undefined,
  imageServiceWidth: 264,
  innerClass: '',
})

defineEmits<{
  (e: 'onImageLoaded'): void
}>()

const forceShowPlaceholder = ref(false)

function getAvatarImage() {
  if (props.avatarType) {
    switch (props.avatarType) {
    case AvatarType.Blue:
      return profile1 as string
    case AvatarType.Red:
      return profile2 as string
    case AvatarType.Green:
      return profile3 as string
    }
  }
  if (props.mediaUrl?.length) {
    return props.mediaUrl
  }
  return ''
}
</script>
<style scoped>

</style>