import {
  AuthProvider,
  AuthSession,
  convertSessionToAuthSession,
  FailedToAuthenticate,
} from '@core-lib/webapp-services/auth-providers/auth-provider'
import { Session } from '@core-lib/models/session'
import { useEmailModalController } from '@core-lib/composables/login-modal'
import { Maybe } from '@core-lib/models/common'

export type AuthenticationResult = {
  success: false
} | {
  success: true
  session: Session
}

const { showEmailModal } = useEmailModalController()

export class EmailAuthProvider implements AuthProvider {
  public readonly canSign = false
  private lastAuthenticationResolver: Maybe<(result: AuthenticationResult) => void>

  public authenticate(): Promise<AuthSession> {
    showEmailModal.value = true
    return new Promise((resolve, reject) => {
      this.lastAuthenticationResolver = (result: AuthenticationResult) => {
        if (result.success) {
          const session = result.session
          resolve(convertSessionToAuthSession(session))
        } else {
          reject(FailedToAuthenticate.rejected())
        }
      }
    })
  }

  public finalizeAuthentication(result: AuthenticationResult) {
    if (!this.lastAuthenticationResolver) throw new Error('Called finalizeAuthentication before authentication was started')
    this.lastAuthenticationResolver(result)
  }

  public async disconnect(): Promise<void> {
    return
  }
}