<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
  >
    <circle
      cx="14.4116"
      cy="9.02874"
      r="7.60878"
      stroke="#AD8803"
      stroke-width="1.67"
    />
    <path
      d="M15.5772 7.08692H19.1966C18.6747 6.43732 17.8741 6.0211 16.9758 6.0211C16.5885 6.0211 16.2194 6.09889 15.8827 6.23858L17.9201 4.65661C17.1092 4.46355 16.2217 4.62595 15.5125 5.17674C14.8867 5.6628 14.5194 6.35782 14.4319 7.08692H14.3916C14.3042 6.35782 13.9373 5.6628 13.311 5.17674C12.6018 4.62595 11.7137 4.46355 10.9034 4.65661L12.9408 6.23858C12.6041 6.09833 12.235 6.0211 11.8477 6.0211C10.9494 6.0211 10.1488 6.43732 9.62695 7.08692H13.2599C13.0947 7.17777 12.9425 7.2936 12.8102 7.43329C12.4241 7.84042 12.2458 8.39292 12.3213 8.94996L12.3497 9.15835L14.0549 7.36118L13.7278 13.4784H15.094L14.7664 7.34755L16.4846 9.15835L16.513 8.94996C16.5885 8.39349 16.4108 7.84042 16.0241 7.43329C15.8912 7.2936 15.7402 7.1772 15.5755 7.08692H15.5772Z"
      fill="#AD8803"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.82082 1.28046C9.45011 1.22661 9.07099 1.19873 8.68536 1.19873C4.36103 1.19873 0.855469 4.70429 0.855469 9.02862C0.855469 13.3529 4.36103 16.8585 8.68536 16.8585C9.07135 16.8585 9.45081 16.8306 9.82185 16.7766C8.84123 16.3547 7.95669 15.7521 7.21015 15.0107C4.52055 14.3497 2.52547 11.9222 2.52547 9.02862C2.52547 6.13537 4.52016 3.708 7.20936 3.04669C7.95581 2.30521 8.84027 1.70249 9.82082 1.28046Z"
      fill="#AD8803"
    />
  </svg>
</template>