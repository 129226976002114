import { z } from 'zod'

export enum QuestionCode {
  USERNAME = 'username',
  TWITTER_FOLLOWS_ACCOUNT = 'twitter.follows_accounts',
}

export enum UserSocialLink {
  'Twitch' = 'twitch',
  'Twitter' = 'twitter',
  'Discord' = 'discord',
  'ProductHunt' = 'productHunt',
  'Wallet' = 'wallet',
}

const usernameQuestionSchema = z.object({
  code: z.literal(QuestionCode.USERNAME),
})

const followsAccountQuestionSchema = z.object({
  code: z.literal(QuestionCode.TWITTER_FOLLOWS_ACCOUNT),
  accounts: z.array(z.string().regex(/^@?(\w){1,15}$/)),
})

export const questionSchema = z.discriminatedUnion('code', [
  usernameQuestionSchema,
  followsAccountQuestionSchema,
])

export type Question = z.infer<typeof questionSchema>