import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { ActionEvent } from '@core-lib/webapp-services/analytics/events/base'

type ShareTwitterPayload = {
  location: string
}

export class ShareTwitterActionClicked extends ActionEvent {
  protected action = 'share_twitter_clicked'

  constructor(payload: ShareTwitterPayload) {
    super(payload)
  }
}

export const useTwitterShare = (url: string, via: string, trackingLocation: string) => {
  const openTwitterUrl = (text: string, dynamicUrl?: string) => {
    const params = new URLSearchParams({
      text: dynamicUrl ? `${text}\n\n${dynamicUrl}` : `${text}\n\n${url}`,
      via: via || '',
    })
    const twitterShareUrl = `https://twitter.com/intent/tweet?${params.toString()}`
    TropeeAnalyticsFactory.instance().track(new ShareTwitterActionClicked({
      location: trackingLocation,
    }))
    window.open(twitterShareUrl, '_blank')
  }

  const generateTwitterTweetUrl = (text: string, url?: string) => {
    const params = new URLSearchParams({
      text: `${text}`,
      via: via || '',
    })
    if (url) {
      params.append('url', url)
    }
    return `https://twitter.com/intent/tweet?${params.toString()}`
  }

  return {
    openTwitterUrl,
    generateTwitterTweetUrl,
  }
}
