import { LoyaltyProgramIdentifier } from '@core-lib/openapi/client'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { InjectionKey } from 'vue/dist/vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useLoyaltyPreview } from '@core-lib/composables/loyalty/loyalty-preview'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'

export const loyaltyProgramIdentifierInjectionKey = Symbol('loyaltyProgramIdentifier') as InjectionKey<LoyaltyProgramIdentifier>

export const useLoyaltyProgramIdentifier = () => {
  const route = useRoute()
  const { isLoyaltyPreview } = useLoyaltyPreview()

  const orgSlug = computed(() => route.params.slug as string)
  const tropeeIdFromRoute = computed(() => route.params.utilityCampaignId as string)
  const loyaltyProgramIdentifier = computed((): LoyaltyProgramIdentifier => {
    if (isLoyaltyPreview.value && authFacade.selectedOrgId.value) return {
      type: 'orgId',
      orgId: authFacade.selectedOrgId.value,
    }
    if (tropeeIdFromRoute.value) {
      return {
        type: 'rewardId',
        rewardId: tropeeIdFromRoute.value,
      }
    }
    if (ServiceContainer.isCustomDomain) {
      return {
        type: 'orgCustomDomain',
        orgCustomDomain: window.location.hostname,
      }
    }
    if (!orgSlug.value) throw new Error('Could not determine loyalty program identifier')
    return {
      type: 'orgSlug',
      orgSlug: orgSlug.value,
    }
  })

  return { loyaltyProgramIdentifier }
}
