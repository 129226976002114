
        import process from 'process'
        import {Buffer} from 'buffer'
        import EventEmitter from 'events'

        window.Buffer = Buffer
        window.process = process
        window.EventEmitter = EventEmitter
        if (globalThis === undefined) {
            window.globalThis = window;
        }


        function scaleElement() {
            const viewportWidth = window.innerWidth;
            if (viewportWidth >= 350) {
                if (document.documentElement.style.zoom) {
                    document.documentElement.style.zoom = 1
                }
                return
            }
          document.documentElement.style.zoom = viewportWidth / 350;
        }

        // Initial scaling
        scaleElement();
        // Rescale on window resize
        window.addEventListener('resize', scaleElement);

    