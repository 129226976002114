import { useWindow } from '@core-lib/composables/window'

export const useAppName = (): { appName: 'creator' | 'explore' | 'admin' } => {
  const path = useWindow().window.location.pathname

  const determineAppName = () => {
    if (path.startsWith('/creator/')) {
      return 'creator'
    } else if (path.startsWith('/admin/')) {
      return 'admin'
    } else {
      return 'explore'
    }
  }

  return {
    appName: determineAppName(),
  }
}