import { WalletAdapter } from '@core-lib/webapp-services/web3/wallet-adaptors/wallet-adapter'
import walletService from '@core-lib/webapp-services/web3/wallet-service'
import { WalletProviderType } from '@core-lib/models/wallet'

export class Web3ConnectAdapter implements WalletAdapter {
  public readonly chainType: string = 'evm'

  constructor(private readonly walletProviderType: WalletProviderType) {}

  public async getWalletAddress(): Promise<string> {
    return await walletService.connectOrFail(this.walletProviderType)
  }

  public async signMessage(message: string): Promise<string> {
    return await walletService.signMessage(message, null, true)
  }
}
