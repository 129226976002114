import { WalletProviderType, WalletSignatureFailed } from '@core-lib/models/wallet'
import { buildWalletMessageWithVars, SignatureMessageType } from '@core-lib/const/wallet-message'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { FailedToAuthenticate } from '@core-lib/webapp-services/auth-providers/auth-provider'
import { ApiError } from '@core-lib/webapp-services/service-container/services/api-service'
import {
  WalletAdapterFactory,
} from '@core-lib/webapp-services/web3/wallet-adaptors/wallet-adapter-factory'

export class Web3WalletManager {
  public async addWallet(walletProviderType: WalletProviderType): Promise<void> {
    const date = new Date()
    const message = buildWalletMessageWithVars({ date, signatureMessageType: SignatureMessageType.LOGIN })
    const adapter = WalletAdapterFactory.create(walletProviderType)
    try {
      const connectedWalletAddress = await adapter.getWalletAddress()
      ServiceContainer.authService.isSigning.value = true
      const messageResponse = await ServiceContainer.apiService.post<{
        message: string,
        nonce: string
      }>('/user/profile/web3/signature-message', {
        message: message,
        walletAddress: connectedWalletAddress,
      })
      const signature = await adapter.signMessage(messageResponse.message)
      await ServiceContainer.apiService.post('/user/profile/web3/add-wallet', {
        messageNonce: messageResponse.nonce,
        signature: signature,
        walletAddress: connectedWalletAddress,
        type: adapter.chainType,
      })
    } catch (e) {
      if ((e as Error).message === 'No wallet connected') {
        throw FailedToAuthenticate.notConnected()
      }
      if (e instanceof WalletSignatureFailed) {
        throw FailedToAuthenticate.rejected()
      } else if (e instanceof ApiError && ['duplicated_wallet', 'already_added'].includes(e.code)) {
        throw e
      } else {
        throw FailedToAuthenticate.unknown()
      }
    } finally {
      ServiceContainer.authService.isSigning.value = false
    }
  }
}

export default new Web3WalletManager()
