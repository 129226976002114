export const sentryConfig = {
  allowUrls: [/https?:\/\/((demo|www)\.)?tropee\.com/],
  ignoreErrors: [
    /^\[CUSTOM]/,
    /^Failed to fetch dynamically imported module/,
    /^Unable to preload CSS/,
    'Request failed with status code 401',
    'network error (Error)',
    'Missing or invalid topic field',
    'Request aborted',
    'NetworkError when attempting to fetch resource.',
    'error loading dynamically imported module',
    'Signature for SignedWallet has been expired',
    'Could not verify SignedWallet',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'Session not found',
    'Account not found',
    'A network error occurred.',
    'Network Error',
    'canceled',
    'Talisman extension has not been configured yet. Please continue with onboarding.',
    `Can't find variable: _AutofillCallbackHandler`,
  ],
}