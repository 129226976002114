<template>
  <video
    ref="video"
    v-if="isVideo"
    :autoplay="isAutoplayOnLoad"
    @mouseover="startVideo"
    @mouseleave="stopVideo"
    loop
    muted
    :class="customClass"
  >
    <source
      :src="url"
      :type="mimeType"
    >
    Your browser does not support the video tag.
  </video>
  <img
    v-else-if="isImage || !useTropeeDefault"
    :src="url"
    :loading="lazyLoading ? 'lazy' : 'eager'"
    :alt="alt"
    :class="customClass"
    @error="setDefaultImage"
    @load="$emit('imageLoaded')"
  >
  <img
    v-else
    src="../assets/images/tropee-logo-black.svg"
    :alt="alt"
    :class="customClass"
    @load="$emit('imageLoaded')"
  >
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import defaultImage from '@core-lib/assets/images/default-ai-tropee.jpeg'

const props = withDefaults(defineProps<{
  url: string
  lazyLoading?: boolean
  useTropeeDefault?: boolean
  autoplay?: 'onHover' | 'onLoad' | 'disabled'
  alt?: string
  customClass?: string
  setDefaultImageOnError?: boolean
}>(), {
  lazyLoading: false,
  useTropeeDefault: false,
  autoplay: 'onHover',
  alt: '',
  customClass: '',
  setDefaultImageOnError: true,
})

const emit = defineEmits<{
  (e: 'isVideo', result: boolean): void
  (e: 'unknownMime', url: string): void
  (e: 'imageError'): void
  (e: 'imageLoaded'): void

}>()

// Computed properties and methods
const mimeType = computed(() => {
  if (!props.url || props.url.split('.').length < 2) {
    emit('unknownMime', props.url)
    return 'unknown'
  }

  const extension = props.url.split('.').pop()?.toLowerCase() || ''
  if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
    return `image/${extension}`
  } else if (extension === 'mp4') {
    return `video/${extension}`
  } else {
    emit('unknownMime', props.url)
    return 'unknown'
  }
})

const isVideo = computed(() => {
  const result = mimeType.value.split('/')[0] === 'video'
  emit('isVideo', result)
  return result
})

const isImage = computed(() => mimeType.value.split('/')[0] === 'image')

const isOnHoverPlayVideo = computed(() => isVideo.value && props.autoplay === 'onHover')

const isAutoplayOnLoad = computed(() => props.autoplay === 'onLoad')

const videoRef = ref<HTMLVideoElement | null>(null)

const startVideo = () => {
  if (!isOnHoverPlayVideo.value) return
  videoRef.value?.play()
}

const stopVideo = () => {
  if (!isOnHoverPlayVideo.value) return
  videoRef.value?.pause()
}

interface ImageErrorEvent {
  target: { src: string }
}

const setDefaultImage = (e: Event) => {
  if (props.setDefaultImageOnError) {
    const event = e as unknown as ImageErrorEvent
    event.target.src = defaultImage
  }
  emit('imageError')
}
</script>

<style scoped>

</style>
