import { openSignInWindow } from '@core-lib/webapp-services/auth/auth-opener'

export const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
export const isEmbed = window.location.host.startsWith('embed.') || window.location.host.endsWith('.embed.preview.tropee.com')
const searchParams = new URLSearchParams(window.location.search)
const embederUrl = searchParams.get('embederUrl') || window.location.href
export const redirectToNewTab = (url: string) => {
  redirectToExternal(url, true)
}
export const redirectToSameTab = (url: string) => {
  if (isEmbed) {
    openSignInWindow(url, 'Sign in')
  } else {
    redirectToExternal(url, false)
  }
}
export const currentUrl = (): string => {
  return isEmbed && embederUrl ? embederUrl : window.location.href
}

export const redirectToExternal = (url: string, newTab: boolean) => {
  if (window.screen.width <= 767 && url.startsWith('https://twitter.com')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTimeout(() =>  window.location = url, 50)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = 'twitter://'
  } else {
    if (newTab) {
      setTimeout(() => {
        window.open(url, '_blank')
      })
    } else {
      window.location.href = url
    }
  }
}

export const cleanUpUrl = (url: string): string => {
  return url.split('?')[0].replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
}