<template>
  <video
    ref="video"
    v-if="isVideo"
    :autoplay="isAutoplayOnLoad"
    @mouseover="startVideo"
    @mouseleave="stopVideo"
    loop
    muted
  >
    <source
      :src="url"
      :type="mimeType"
    >
    Your browser does not support the video tag.
  </video>
  <img
    v-else-if="isImage || !useTropeeDefault"
    :src="url"
    :loading="lazyLoading ? 'lazy' : ''"
    :alt="alt"
  >
  <img
    v-else
    src="../assets/images/tropee-logo-black.svg"
    :alt="alt"
  >
</template>

<script>
export default {
  name: 'ImageOrVideo',
  props: {
    url: {
      type: String,
      required: true,
    },
    lazyLoading: {
      type: Boolean,
      default: false,
    },
    useTropeeDefault: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: String,
      validator: function (value) {
        return ['onHover', 'onLoad', 'disabled'].includes(value)
      },
      default: 'onHover',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['isVideo', 'unknownMime'],
  methods: {
    startVideo() {
      if (!this.isOnHoverPlayVide) {
        return
      }
      this.$refs.video.play()
    },
    stopVideo() {
      if (!this.isOnHoverPlayVide) {
        return
      }
      this.$refs.video.pause()
    },
  },
  computed: {
    isOnHoverPlayVide() {
      return this.isVideo && this.autoplay === 'onHover'
    },
    isAutoplayOnLoad() {
      return this.autoplay === 'onLoad'
    },
    isVideo() {
      const res = this.mimeType.split('/')[0] === 'video'
      this.$emit('isVideo', res)
      return res
    },
    isImage() {
      const mimeResult = this.mimeType.split('/')[0]
      return mimeResult === 'image'
    },
    mimeType() {
      if (!this.url || this.url.split('.').length < 2)  {
        this.$emit('unknownMime', this.url)
        return 'unknown'
      }
      const extension = this.url.split('.').slice(-1)[0]
      if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
        return `image/${extension}`
      } else if (['mp4'].includes(extension)) {
        return `video/${extension}`
      } else {
        this.$emit('unknownMime', this.url)
        return 'unknown'
      }
    },
  },
}
</script>

<style scoped>

</style>
