<template>
  <div class="flex flex-col gap-y-3">
    <div
      v-for="(socialLink, index) in socialLinkList"
      :key="index"
      class="flex flex-col w-full items-start gap-y-3"
    >
      <div
        v-for="(value, index2) in socialLink.values"
        :key="index2"
        class="w-full flex flex-col"
      >
        <div class="flex justify-between items-center w-full gap-x-6">
          <div class="flex gap-x-3 items-center overflow-hidden">
            <DsIcon
              size="large"
              :icon="socialLink.icon"
              :color-class="socialLink.iconColorClass"
            />
            <div
              class="text-center text-[16px] leading-[24px] font-semibold"
              :class="{ 'truncate': socialLink.id !== 'wallet'}"
              v-tooltip="{ content: (value as UserPrivateProfileWeb3WalletsInner).walletAddress, disabled: socialLink.id !== UserSocialLink.Wallet, popperClass: 'wallet-tooltip' }"
            >
              {{ getSocialLabel(socialLink.id, value, socialLink.title) }}
            </div>
          </div>
          <DsButton
            v-if="!isProviderVerified(socialLink.id)"
            class="ml-auto min-w-[128px] !py-1.5 !px-5 !text-[16px] !leading-[24px]"
            style-type="secondary"
            :class="{'!border-red-500': hasError(socialLink.id)}"
            @click="connect(socialLink.id)"
          >
            {{ capitalize(translate('connect')) }}
          </DsButton>
          <DsButton
            v-else
            class="ml-auto min-w-[128px] !py-1.5 !px-5 !text-[16px] !leading-[24px]"
            style-type="secondary"
            @click="disconnect(socialLink.id, value)"
          >
            {{ capitalize(translate('disconnect')) }}
          </DsButton>
        </div>
        <p
          v-if="hasError(socialLink.id)"
          class="text-xs text-red-500 mt-1"
        >
          Something went wrong
        </p>
      </div>
      <div
        v-if="socialLink.id === UserSocialLink.Wallet"
        class="w-full"
      >
        <div class="flex justify-between items-center w-full gap-x-6">
          <div class="flex gap-3 items-center overflow-hidden">
            <DsIcon
              size="large"
              :icon="socialLink.icon"
              :color-class="socialLink.iconColorClass"
            />
            <div
              class="text-center text-[16px] leading-[24px] font-semibold"
              :class="{ 'truncate': socialLink.id !== UserSocialLink.Wallet}"
            >
              {{ socialLink.title }}
            </div>
          </div>
          <DsButton
            class="ml-auto min-w-[128px] !py-1.5 !px-5 !text-[16px] !leading-[24px]"
            style-type="secondary"
            :class="{'!border-red-500': hasLoyaltyWalletError}"
            @click="connect(socialLink.id)"
          >
            {{ capitalize(translate('connect')) }}
          </DsButton>
        </div>
        <p
          v-if="hasLoyaltyWalletError"
          class="text-xs text-red-500 mt-1"
        >
          Please sign to verify your wallet
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from 'vue'
import socialService from '@core-lib/webapp-services/social-service'
import DsButton from '@core-design/components/Design/DsButton.vue'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import { IconType } from '@core-design/types/icon'
import { useSocialErrors } from '@core-lib/composables/social-errors'
import useIsMobile from '@core-lib/composables/isMobile'
import { capitalize, walletTruncate } from '@core-lib/helpers/string'
import { UserPrivateProfile, UserPrivateProfileWeb3WalletsInner } from '@core-lib/openapi/client'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import { useLoyaltyConnectWallet } from '@core-lib/composables/connect-wallet'
import { useWindow } from '@core-lib/composables/window'
import { useI18n } from '@core-lib/composables/i18n'
import translator from '@core-lib/webapp-services/i18n/translator'
import { UserSocialLink } from '@core-lib/models/social'

const props = defineProps<{
      user: UserPrivateProfile;
    }>()

const emit = defineEmits<{
  (e: 'userUpdated'): void
  (e: 'connectWallet'): void
}>()

const isMobile = useIsMobile()
const { hasDiscordError } = useSocialErrors()

const { hasLoyaltyWalletError, showLoyaltyWalletConnectModal } = useLoyaltyConnectWallet()

const hasError = (provider: FormKey) => {
  if (provider === UserSocialLink.Wallet) return false
  return hasDiscordError
}

interface Form {
  twitch: string[]
  discord: string[]
  wallet: Array<UserPrivateProfileWeb3WalletsInner>
}
type SocialLink = {
  id: FormKey
  title: string
  description?: string
  icon: IconType
  iconColorClass?: string
  text: string
  values: string[] | UserPrivateProfileWeb3WalletsInner[] | undefined
}
const { window } = useWindow()

const initialFormValues = () => {
  return {
    twitch: [props.user.linkedAccounts?.twitch.username],
    discord: [props.user.linkedAccounts?.discord.username],
    wallet: props.user.web3Wallets,
  }
}

const form = ref<Form>(initialFormValues())

type FormKey = keyof Form;

const socialLinkList = computed<SocialLink[]>(() => {
  const userValue = props.user
  return [
    {
      id: UserSocialLink.Twitch,
      title: 'Twitch',
      icon: { name: 'twitch', type: 'brands' } as IconType,
      iconColorClass: 'fill-purple-1000 text-purple-1000',
      text: isMobile.value ? capitalize(translator.translateWithVars('settings__connect_x', { account: 'Twitch' })): capitalize(translator.translateWithVars('settings__connect_x_account', { account: 'Twitch' })),
      values: [
        userValue.linkedAccounts.twitch.username,
      ],
    },
    {
      id: UserSocialLink.Discord,
      title: 'Discord',
      icon: 'c_DiscordCircled' as IconType,
      iconColorClass: 'rounded-full bg-purple-1000 text-white',
      text: isMobile.value ? capitalize(translator.translateWithVars('settings__connect_x', { account: 'Discord' })): capitalize(translator.translateWithVars('settings__connect_x_account', { account: 'Discord' })),
      values: [
        userValue.linkedAccounts.discord.username,
      ],
    },
    {
      id: UserSocialLink.Wallet,
      title: capitalize(translator.translate('settings__wallet')),
      description: translator.translate('settings__connect_your_wallet_to_complete_on_chain_actions'),
      icon: 'c_WalletCircled' as IconType,
      text: capitalize(translator.translate('settings__connect_wallet')),
      values: userValue.web3Wallets,
    },
  ]
})

type Providers = Partial<{
  twitch: boolean
  discord: boolean
  wallet: boolean
}>

const verificationTokens = reactive<Providers>({})
const providerDisconnected = ref<Providers>({
  twitch: false,
  discord: false,
  wallet: false,
})

const isProviderVerified = (provider: FormKey) => {
  if (provider === UserSocialLink.Wallet) return props.user.web3Wallets.length > 0

  if (!form.value[provider]) return false
  return props.user.linkedAccounts[provider].isAuthenticated
}

const connect = async (provider: FormKey) => {
  if (provider === UserSocialLink.Wallet) {
    hasLoyaltyWalletError.value = false
    showLoyaltyWalletConnectModal.value = true
    return
  }
  try {
    window.location.href = await socialService.getRedirectUrl(
      provider,
      window.location.href,
    )
  } catch (e) {
    console.error(e)
  } finally {
    emit('userUpdated')
  }
}
const disconnecting = ref(false)
const disconnect = async (provider: FormKey, value: UserPrivateProfileWeb3WalletsInner | string) => {

  if (provider === UserSocialLink.Wallet) {
    hasLoyaltyWalletError.value = false
    try {
      disconnecting.value = true
      await ServiceContainer.defaultOpenApi.deleteWeb3Wallet({
        wallet: value as UserPrivateProfileWeb3WalletsInner,
      })
    } catch (e) {
      console.error(e)
    } finally {
      disconnecting.value = false
      emit('userUpdated')
    }
    return
  }
  form.value[provider] = []
  delete verificationTokens[provider]
  providerDisconnected.value[provider] = true
  const user = {
    ...props.user,
    bio: props.user.bio,
    linkedAccounts: props.user.linkedAccounts,
    avatar: props.user.avatar || '',
  }
  try {
    await socialService.disconnect(provider, user)
    providerDisconnected.value[provider] = false
  } catch (e) {
    console.error(e)
  } finally {
    emit('userUpdated')
  }
}

watch(() => props.user, () => {
  form.value = initialFormValues()
}, { deep: true })

const getSocialLabel = (socialLinkId: FormKey, socialLinkValue: UserPrivateProfileWeb3WalletsInner | string, socialLinkText: string) => {
  if (!isProviderVerified(socialLinkId)) return socialLinkText
  if (socialLinkId === UserSocialLink.Wallet) {
    return walletTruncate((socialLinkValue as UserPrivateProfileWeb3WalletsInner).walletAddress)
  }
  return socialLinkValue
}
const { translate } = useI18n({ prefix: 'settings__' })
</script>
<style lang="scss">
.wallet-tooltip .v-popper__inner {
  max-width: none;
}
</style>