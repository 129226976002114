export enum LoginType {
  EMAIL = 'email',
  TWITTER = 'twitter',
  DISCORD = 'discord',
  GOOGLE = 'google',
  CODE = 'code',
}

export enum WalletProviderType {
  METAMASK = 'metamask',
  COINBASE = 'coinbase',
  WALLET_CONNECT = 'wallet_connect',
  PHANTOM = 'phantom',
}

export const ConnectionType = { ...LoginType, ...WalletProviderType }
export type ConnectionTypeType = LoginType | WalletProviderType

export class WalletError extends Error {
}

export enum SignatureFailureCode {
  TIMED_OUT = 'timed_out',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  ALREADY_CONNECTING = 'already_connecting',
}

export class WalletSignatureFailed extends WalletError {
  private constructor(message: string, public code: SignatureFailureCode) {
    super(message)
  }

  public static timedOut() {
    return new WalletSignatureFailed('User did not sign in time', SignatureFailureCode.TIMED_OUT)
  }

  public static rejected() {
    return new WalletSignatureFailed('User rejected the signature', SignatureFailureCode.REJECTED)
  }

  public static cancelled() {
    return new WalletSignatureFailed('User closed the modal', SignatureFailureCode.CANCELLED)
  }

  public static alreadyConnecting() {
    return new WalletSignatureFailed('Already waiting for a connection', SignatureFailureCode.ALREADY_CONNECTING)
  }
}

export class WalletNotConnected extends WalletError {
  constructor() {
    super('Wallet is not connected.')
  }
}

export default ConnectionType
