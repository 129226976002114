import { ConnectionType, WalletProviderType } from '@core-lib/models/wallet'
import { AuthProvider } from '@core-lib/webapp-services/auth-providers/auth-provider'
import { BlockNativeAuthProvider } from '@core-lib/webapp-services/auth-providers/block-native-auth-provider'

export const extendedAuthProviders: Record<WalletProviderType, AuthProvider> = {
  [ConnectionType.COINBASE]: new BlockNativeAuthProvider(ConnectionType.COINBASE),
  [ConnectionType.METAMASK]: new BlockNativeAuthProvider(ConnectionType.METAMASK),
  [ConnectionType.WALLET_CONNECT]: new BlockNativeAuthProvider(ConnectionType.WALLET_CONNECT),
  [ConnectionType.PHANTOM]: new BlockNativeAuthProvider(ConnectionType.WALLET_CONNECT),
}
