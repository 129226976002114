import { ActionEvent } from '@core-lib/webapp-services/analytics/events/base'
import { ConnectionTypeType } from '@core-lib/models/wallet'

export class WalletConnectedEvent extends ActionEvent {
  public action = 'wallet_connected'

  constructor(connectionType: ConnectionTypeType) {
    super({
      connectionType,
    })
  }
}
