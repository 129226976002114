<template>
  <div>
    <div class="flex items-center gap-x-4">
      <div class="relative w-[96px] h-[96px]">
        <DsAvatarLetter
          :identifier="user.id.slice(-4)"
          :name="user.username"
          :media-url="uploadedImageUrl ? ImageService.fitImageToWidth(uploadedImageUrl, 600) : ''"
          image-class="w-[96px] h-[96px] min-w-[96px] h-[96px] rounded-full"
          placeholder-text-class="text-[32px] leading-[48x] font-semibold"
        />
        <div
          v-if="isUploading"
          class="flex justify-center items-center w-full h-full absolute inset-0 bg-black/30 transition-all duration-200 rounded-full"
        >
          <DsIcon
            icon="c_LoadingSpinner"
            color-class="text-white"
            size="w-6 h-6"
          />
        </div>
      </div>
      <div class="flex flex-col gap-3 items-center flex-1">
        <DsButton
          style-type="tertiary"
          :disabled="isUploading"
          class="!py-1.5 !border-none !text-[16px] !leading-[24px] font-semibold"
          :class="isScreenSmaller ? '!px-0 hover:!bg-transparent': '!pl-4 !pr-5'"
          @click="openUploadDialog"
        >
          <template #leftIcon>
            <div class="flex items-center h-6">
              <DsIconLucid
                name="Upload"
                :size="20"
                class="mr-2"
              />
            </div>
          </template>
          {{ capitalize(translateGeneral('upload_photo')) }}
        </DsButton>
        <DsButton
          v-if="!!uploadedImageUrl"
          style-type="tertiary"
          :disabled="isUploading"
          class="!py-1.5 !border-none !text-[16px] !leading-[24px] font-semibold"
          :class="isScreenSmaller ? '!px-0 hover:!bg-transparent': '!pl-4 !pr-5'"
          @click="removePhoto"
        >
          <template #leftIcon>
            <div class="flex items-center h-6">
              <DsIconLucid
                name="XCircle"
                :size="20"
                class="mr-2"
              />
            </div>
          </template>
          {{ capitalize(translateGeneral('remove_photo')) }}
        </DsButton>
      </div>
    </div>
    <input
      ref="uploadInput"
      :accept="acceptedImageTypes.join(',')"
      class="hidden"
      name="file-upload"
      type="file"
      @change="fileSelected"
    >
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { ImageService } from '@core-lib/services/image-service'
import DsButton from '@core-design/components/Design/DsButton.vue'
import { useFileUploader } from '@core-lib/composables/file-uploader'
import { UploadResourceType } from '@core-lib/models/upload'
import fileUploaderService from '@core-lib/webapp-services/file-uploader-service'
import { nanoid } from 'nanoid'
import DsIcon from '@core-design/components/Design/DsIcon.vue'
import userProfileService from '@core-lib/webapp-services/user-profile'
import { UserPrivateProfile } from '@core-lib/openapi/client'
import { acceptedImageTypes } from '@core-lib/const/image'
import { useImageValidator } from '@core-lib/composables/validate-image'
import { capitalize } from '@core-lib/helpers/string'
import { useI18n } from '@core-lib/composables/i18n'
import DsAvatarLetter from '@core-design/components/Design/DsAvatarLetter.vue'
import DsIconLucid from '@core-design/components/Design/DsIconLucid.vue'
import useIsScreenSmallerThan from '@core-lib/composables/is-screen-smaller-than'

const props = defineProps<{
  user: UserPrivateProfile
}>()

const emit = defineEmits<{
  (event: 'updateUser'): void;
}>()

const isScreenSmaller = useIsScreenSmallerThan(385)
const uploadInput = ref<HTMLInputElement>()
const isUploading = ref(false)

const avatarUploader = useFileUploader({
  initialFiles: props.user.avatar
    ? [props.user.avatar]
    : [],
  maxFilesAllowed: 1,
  maxFileSize: 1_000_000 * 15,
  isPublic: true,
  randomizeFileName: true,
  resourceId: props.user.id || nanoid(5),
  resourceType: UploadResourceType.USER,
  fileUploaderService: fileUploaderService,
})

const uploadedImageUrl = computed(() => {
  const file = avatarUploader.getFile(0)
  return file ? file.previewUrl : ''
})

const openUploadDialog = () => {
  if (!uploadInput.value) {
    return
  }
  uploadInput.value.click()
}

const fileSelected = async (e: Event) => {
  isUploading.value = true
  const target = e.target as HTMLInputElement
  const files = target.files
  if (!files || !files[0]) {
    isUploading.value = false
    return
  }
  const { isImageValid } = useImageValidator(files)
  if (!await isImageValid()) {
    isUploading.value = false
    return
  }

  avatarUploader.addFiles(files)
  if (!avatarUploader.hasFile(0)) {
    isUploading.value = false
    return
  }
  const [avatar] = await avatarUploader.waitBackendValues()

  try {
    await userProfileService.updateUserProfileV2({ avatar })
    emit('updateUser')
  } catch (e) {
    console.log('error', e)
  } finally {
    isUploading.value = false
  }
}

const removePhoto = async () => {
  avatarUploader.removeFile(0)
  avatarUploader.resetFiles([])

  try {
    await userProfileService.updateUserProfileV2({ avatar: '' })
    emit('updateUser')
  } catch (e) {
    console.log('error', e)
  } finally {
    isUploading.value = false
  }
}

watch(() => props.user, () => {
  avatarUploader.resetFiles(props.user.avatar ? [props.user.avatar] : [])
}, { deep: true })

const { translate: translateGeneral } = useI18n({ prefix: 'general__' })
</script>

<style scoped>

</style>