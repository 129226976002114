import { useQuery } from '@tanstack/vue-query'
import { useRouter } from 'vue-router'
import { computed, watch } from 'vue'
import { HolderRouteName } from '@core-lib/routes'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { excludedReferrers } from '@core-lib/const/analytics'

const getLastTrack = (tropeeId: string) => {
  const value = localStorage.getItem(`analytics_last_track.${tropeeId}`) || '0'

  return Number(value)
}

const setLastTrackNow = (tropeeId: string) => {
  localStorage.setItem(`analytics_last_track.${tropeeId}`, Date.now().toString())
}

const getUtmParams = (): Partial<Record<string, string>> => {
  const qp = new URLSearchParams(window.location.search)
  const utmParams: Record<string, string> = {}
  for (const [key, value] of qp.entries()) {
    if (key.toLowerCase().startsWith('utm_')) {
      utmParams[key.toLowerCase()] = value
    }
  }
  if (Object.keys(utmParams).length > 0) {
    return utmParams
  } else if (localStorage.getItem('utm_params')) {
    return JSON.parse(localStorage.getItem('utm_params') as string) as Record<string, string>
  } else if (document.referrer) {
    const referrer = new URL(document.referrer)
    if (!excludedReferrers.includes(referrer.hostname)) {
      return { utm_source: referrer.hostname, utm_medium: 'referral' }
    }
  }
  return {}
}

export const useAnalytics = () => {
  const router = useRouter()
  const isTropeeRoute = computed(() => !! router && router.currentRoute.value.name === HolderRouteName.UTILITY)
  const trackWait = 30_000
  const utmParams = getUtmParams()
  if (Object.keys(utmParams)) {
    ServiceContainer.defaultOpenApi.trackUtm({
      utm: getUtmParams(),
    }).catch(console.error)
  }
  useQuery({
    queryKey: ['tropeeVisitTrack'],
    queryFn: async () => {
      const tropeeId = router.currentRoute.value.params.utilityCampaignId as string
      if (!tropeeId) return true
      if (getLastTrack(tropeeId) + trackWait > Date.now()) return true
      setLastTrackNow(tropeeId)
      await ServiceContainer.defaultOpenApi.trackTropeeVisit({
        utmParams,
        tropeeId,
      })
      return true
    },
    enabled: isTropeeRoute,
  })

  watch(authFacade.isAuthenticated, async (isAuthenticated, wasAuthenticated) => {
    if (isAuthenticated && !wasAuthenticated) {
      await ServiceContainer.defaultOpenApi.convertVisitor()
    }
  })
}
