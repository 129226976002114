<template>
  <div
    ref="inputContainerRef"
    class="ly-input"
    :class="{ 'error': hasError, 'disabled': disabled, 'readonly': readonly, 'relative': errorAbsolutePositioned }"
    :data-app="app"
  >
    <slot
      v-if="$slots.label"
      name="label"
    />
    <label
      v-else-if="label"
      class="label"
      :class="labelClass"
    >{{ label }}
    </label>
    <div
      class="field"
      :class="[twMerge('field', fieldClass), {
        'focused': forcedFocus || isFocused,
        'filled': modelValue && !isFocused && !forcedFocus,
      }]"
      @click="inputContainerClick"
    >
      <div
        v-if="leadingIcon"
        :class="[twMerge('leading-icon-wrapper', leadingIconWrapperClass), {'disabled': disabled}]"
      >
        <DsIconLucid
          :name="leadingIcon"
          :class="twMerge('leading-icon', leadingIconClass)"
        />
      </div>
      <template v-else-if="$slots.leadingIcon">
        <slot name="leadingIcon" />
      </template>
      <input
        v-model="innerValue"
        :class="[twMerge('input', inputClass), {'truncate': !isFocused, 'fake-disabled': fakeDisabled}]"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :readonly="readonly || fakeDisabled"
        :maxlength="maxLength"
        ref="inputRef"
        @focus="$emit('focus'); isFocused = true"
        @keydown="handleKeyDown"
        @keyup.enter="handleEnterKey"
        @wheel="preventScroll"
        @input="updateValue"
        @paste="doHandlePaste"
        @blur="$emit('blur'); isFocused = false"
      >
      <div
        v-if="loading || trailingIcon || $slots.trailingIcon"
        @click.stop="trailingIconClick"
      >
        <div
          v-if="loading"
          class="trailing-icon-wrapper"
        >
          <LoadingSpinner
            class="loading-spinner spinner-dark"
          />
        </div>
        <div
          v-else-if="trailingIcon"
          :class="[twMerge('trailing-icon-wrapper', trailingIconWrapperClass), {'cursor-pointer': !disabled && trailingIconClickable, 'disabled': disabled}]"
        >
          <DsIconLucid
            :name="trailingIcon"
            :class="twMerge('trailing-icon', trailingIconClass)"
          />
        </div>
        <template
          v-else-if="$slots.trailingIcon"
        >
          <slot name="trailingIcon" />
        </template>
      </div>
    </div>
    <p
      v-if="helperText"
      class="helper-text"
      :class="[!hasError ? helperTextClass : 'input-error-message', {'absolute': errorAbsolutePositioned}]"
    >
      {{ helperText }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue'
import useDetectOutsideClick from '@core-lib/composables/detect-outside-click'
import DsIconLucid from '@core-design/components/Design/DsIconLucid.vue'
import LoadingSpinner from '@core-design/assets/icons/loyalty/LoadingSpinner.vue'
import { handlePaste } from '@core-lib/helpers/number-input'
import { twMerge } from 'tailwind-merge'

const props = withDefaults(
  defineProps<{
    app?: 'admin' | 'member'
    modelValue?: string | number | undefined
    fieldClass?: string
    type?: 'text' | 'email' | 'url' | 'number'
    label?: string
    labelClass?: string
    placeholder?: string
    leadingIcon?: string
    leadingIconClass?: string
    leadingIconWrapperClass?: string
    trailingIcon?: string
    trailingIconClass?: string
    trailingIconWrapperClass?: string
    trailingIconClickable?: boolean
    helperText?: string
    helperTextClass?: string
    hasError?: boolean
    disabled?: boolean
    readonly?: boolean
    forcedFocus?: boolean
    inputClass?: string
    maxLength?: number
    onlyPositiveNumbers?: boolean
    onlyIntegers?: boolean
    errorAbsolutePositioned?: boolean
    allowedCharacters?: string[]
    focus?: number
    urlPrefix?: string
    loading?: boolean
    fakeDisabled?: boolean
    outsideBlur?: number
  }>(), {
    app: 'admin',
    modelValue: '',
    type: 'text',
    hasError: false,
    disabled: false,
    label: '',
    labelClass: '',
    placeholder: '',
    leadingIcon: '',
    leadingIconClass: '',
    leadingIconWrapperClass: '',
    trailingIcon: '',
    trailingIconClass: '',
    trailingIconWrapperClass: '',
    helperText: '',
    helperTextClass: '',
    readonly: false,
    fieldClass: '',
    forcedFocus: false,
    inputClass: '',
    maxLength: 250,
    onlyPositiveNumbers: false,
    onlyIntegers: false,
    errorAbsolutePositioned: false,
    allowedCharacters: undefined,
    focus: 0,
    urlPrefix: '',
    loading: false,
    fakeDisabled: false,
    outsideBlur: undefined,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
  (e: 'trailingIconClick'): void
  (e: 'focus'): void
  (e: 'enter'): void
  (e: 'paste'): void
  (e: 'blur'): void
}>()

const innerValue = ref(props.modelValue)
const shouldWatchPaste = ref(false)
watch(innerValue, innerValue => {
  emit('update:modelValue', innerValue)
  if (shouldWatchPaste.value) {
    emit('paste')
    shouldWatchPaste.value = false
  }
})
watch(() => props.modelValue, value => {
  innerValue.value = value
})
const updateValue = () => {
  if (props.maxLength && (typeof innerValue.value === 'string' || typeof innerValue.value === 'number') && innerValue.value.toString().length > props.maxLength) {
    const sliced = innerValue.value.toString().slice(0, props.maxLength)
    innerValue.value = props.type === 'number' ? Number(sliced) : sliced
    return
  }
  if (props.type === 'url') {
    const regexObj = new RegExp('^(https?):\\/\\/' + props.urlPrefix)
    const url = 'https://' + props.urlPrefix
    if (innerValue.value.toString() && !regexObj.test(innerValue.value.toString()) && innerValue.value.toString().indexOf(url) === -1) {
      innerValue.value = url + innerValue.value
    }
  }
}

function doHandlePaste(event: ClipboardEvent) {
  const pasted = handlePaste(event, props.type)
  if (!pasted) return
  shouldWatchPaste.value = true
}

const isFocused = ref(false)

const inputRef = ref()

watchEffect(() => {
  if (props.disabled) {
    isFocused.value = false
  }
})

const inputContainerRef = ref()
useDetectOutsideClick(inputContainerRef, () => {
  isFocused.value = false
})
const trailingIconClick = () => {
  if (props.disabled) {
    return
  }
  emit('trailingIconClick')
}

const inputContainerClick = () => {
  inputRef.value.focus()
  isFocused.value = true
}

watch(() => props.focus, () => {
  inputContainerClick()
})

const whiteListedChars = ['Delete', 'Backspace', 'Enter', 'ArrowLeft', 'ArrowRight']

const handleKeyDown = (event: KeyboardEvent) => {
  if (props.allowedCharacters && !props.allowedCharacters.includes(event.key) && !whiteListedChars.includes(event.key) && !((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'a'))) {
    event.preventDefault()
    return
  }
  if (props.type === 'number') {
    if ((props.onlyPositiveNumbers && event.key === '-') || event.key === 'e' || props.onlyIntegers && (event.key === '.' || event.key === ',')) {
      event.preventDefault()
    }
  }
}

function handleEnterKey() {
  inputRef.value.blur()
  emit('enter')
}

const preventScroll = () => {
  if (props.type === 'number') {
    inputRef.value.blur()
  }
}

watch(() => props.outsideBlur, () => {
  if (!inputRef.value) return
  inputRef.value.blur()
})
</script>

<style lang="scss">

.ly-input {

  @media only screen and (min-width: 320px) and (max-width: 360px) {
    .input {
      min-width: 0;
    }
  }

  @media only screen and (min-width: 361px) and (max-width: 400px) {
    .input {
      min-width: 0;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  .input::-webkit-outer-spin-button,
  .input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .input[type=number] {
    -moz-appearance: textfield;
  }

  .field {
    @apply border flex items-center py-3 px-2 rounded-lg bg-stone-100 grow max-w-full border-stone-100;

    &:hover {
      @apply border-stone-200 bg-stone-200;
    }

    &.focused {
      @apply border-ds-dark-primary bg-white;
    }

    &.filled {
      @apply bg-stone-100;
    }
  }

  .label {
    @apply flex text-[16px] leading-6 font-medium mb-2 text-ds-dark-primary;
  }

  .leading-icon-wrapper {
    @apply px-2 py-0.5;

    .leading-icon {
      @apply w-5 h-5;
    }

    &.disabled {
      @apply text-stone-300;
    }
  }

  .trailing-icon-wrapper {
    @apply px-2 py-0.5;

    .loading-spinner {
      @apply w-5 h-5
    }

    .trailing-icon {
      @apply w-5 h-5
    }

    &.disabled {
      @apply text-stone-300;
    }
  }

  .input {
    @apply px-2 bg-inherit grow text-[16px] leading-6 text-ds-dark-primary outline-none border-none py-0 focus:ring-0 w-full;
    &::placeholder {
      @apply text-stone-500 text-[16px] leading-6 truncate;
    }

    &.fake-disabled {
      @apply cursor-pointer;
    }
  }

  .helper-text {
    @apply text-stone-500 text-sm font-normal mt-2;
  }

  &.disabled {
    pointer-events: none;

    .label {
      @apply text-stone-400;
    }

    .field {
      &:hover,
      &.focused {
        @apply border-transparent cursor-not-allowed;
      }
    }

    .input {
      @apply cursor-not-allowed text-stone-300;
      &::placeholder {
        @apply text-stone-300;
      }
    }

    .helper-text {
      @apply cursor-not-allowed text-stone-400;
    }
  }

  &.readonly {
    .field {
      @apply pointer-events-none overflow-x-hidden truncate bg-white border-stone-200;
      &:hover {
        @apply bg-white border-stone-200;
      }
    }

    .input {
      @apply text-stone-400;
      &::placeholder {
        @apply text-stone-500;
      }
    }
  }

  &.error {
    .field {
      @apply border-red-500 bg-white;
    }

    .helper-text {
      @apply text-red-500;
    }
  }

  &[data-app="admin"] {
    .label, .input, .input::placeholder {
      @apply text-sm;
    }
  }

  &[data-app="member"] {
    .field {
      @apply bg-white border-gray-300;

      &:hover {
        @apply border-gray-400 bg-white;
      }

      &.filled {
        @apply bg-white;
      }
    }

    .leading-icon-wrapper {
      .disabled {
        @apply text-gray-400;
      }
    }

    .trailing-icon-wrapper {
      .disabled {
        @apply text-gray-400;
      }
    }

    .input {
      @apply font-medium;
      &::placeholder {
        @apply text-gray-500;
      }
    }

    &:not(.error) {
      .helper-text {
        @apply text-gray-500;
      }
    }

    &.disabled {
      .label {
        @apply text-gray-400;
      }

      .input {
        @apply text-gray-400;
        &::placeholder {
          @apply text-gray-400;
        }
      }

      .helper-text {
        @apply cursor-not-allowed text-gray-400;
      }
    }

    &.readonly {
      .field {
        @apply bg-white border-gray-200;
        &:hover {
          @apply bg-white border-gray-200;
        }
      }

      .input {
        @apply text-gray-500;
        &::placeholder {
          @apply text-gray-500;
        }
      }
    }
  }
}
</style>
