import * as LaunchDarkly from 'launchdarkly-js-client-sdk'
import { ref, Ref, watch } from 'vue'
import { ServiceContainer } from '@core-lib/webapp-services/service-container/service-container'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { LDClient, LDContext } from 'launchdarkly-js-client-sdk'
import { Nullable } from '@core-lib/models/common'

export enum FeatureFlag {
  SHOW_CREATE_IMAGE_BUTTON = 'tw-3715-show-create-image-button',
  SHOW_TESTNETS = 'creator-webapp-tw-1475-temporary-show-testnets',
  SHOW_SOLANA = 'creator-webapp-tw-464-temporary-show-solana',
  SHOW_BSC = 'webapp-tw-2447-show-bsc',
  SHOW_EMBED_CONFIGURATION = 'show-embed-configuration',
  ALL_DONE_WITHOUT_SECRET_CODE = 'all-done-without-secret-code',
  SHOW_CUSTOM_DOMAINS = 'Allow-custom-domain',
  SHOW_FANS_SUPPORT_PAYMENT = 'show-fans-support-payment',
  SHOW_TRANSFER_OWNERSHIP = 'show-transfer-ownership',
  ENABLE_USER_EXPLORE = 'enable-user-explore',
  SHOW_TASK_SCHEDULE = 'show-task-schedule',
  SHOW_LATEST_POST_FIELD = 'show-latest-post-field',
}

export type User = {
  key: string
}

export class FeatureFlagService {
  public readonly booleanFlags: Record<FeatureFlag, Ref<boolean>>
  private client: Nullable<LDClient> = null

  constructor() {
    this.booleanFlags = {
      [FeatureFlag.SHOW_CREATE_IMAGE_BUTTON]: ref(false),
      [FeatureFlag.SHOW_TESTNETS]: ref(false),
      [FeatureFlag.SHOW_SOLANA]: ref(false),
      [FeatureFlag.SHOW_BSC]: ref(false),
      [FeatureFlag.SHOW_EMBED_CONFIGURATION]: ref(false),
      [FeatureFlag.ALL_DONE_WITHOUT_SECRET_CODE]: ref(false),
      [FeatureFlag.SHOW_CUSTOM_DOMAINS]: ref(false),
      [FeatureFlag.SHOW_FANS_SUPPORT_PAYMENT]: ref(false),
      [FeatureFlag.SHOW_TRANSFER_OWNERSHIP]: ref(false),
      [FeatureFlag.ENABLE_USER_EXPLORE]: ref(false),
      [FeatureFlag.SHOW_TASK_SCHEDULE]: ref(false),
      [FeatureFlag.SHOW_LATEST_POST_FIELD]: ref(false),
    }
  }

  init(clientId: string) {
    this.client = LaunchDarkly.initialize(clientId, { key: 'anon' })
    watch([authFacade.authSession], () => {
      this.update()
    }, { immediate: true })
  }

  public async update(selectedOrganization?: { id: string, name: string, slug: string }) {
    if (!this.client) {
      console.error('Missing client')
      return
    }
    const authSession = authFacade.authSession.value
    const multiContext: LDContext = {
      kind: 'multi',
    }
    const email = ServiceContainer.env.isCreatorApp ? authSession?.email : undefined
    const id = ServiceContainer.env.isCreatorApp ? authSession?.userId : 'anon'
    multiContext.user = {
      kind: 'user',
      key: id || 'anon',
      ...(email ? { email } : {}),
    }
    if (selectedOrganization) {
      multiContext.organization = {
        kind: 'organization',
        key: selectedOrganization.id,
        stage: ServiceContainer.env.env,
        name: `[${ServiceContainer.env.env}] ${selectedOrganization.name}`,
        slug: selectedOrganization.slug,
      }
    }
    const allFlags = await this.client.identify(multiContext)
    for (const [key, value] of Object.entries(allFlags)) {
      const r = this.booleanFlags[key as FeatureFlag]
      if (!r) continue
      switch (typeof value) {
      case 'boolean':
        r.value = value
        break
      }
    }
  }
}

export default new FeatureFlagService()
