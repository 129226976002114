export enum LensApplicationCode {
  LENSTER = 'lenster.xyz'
}

type LensApplicationMapping = {
  name: 'Lenster',
  publicationUrl: (publicationId: string) => string,
  profileUrl: (handle: string) => string,
}

const applications: Record<LensApplicationCode, LensApplicationMapping> = {
  [LensApplicationCode.LENSTER]: {
    name: 'Lenster',
    publicationUrl: (publicationId: string) => `https://lenster.xyz/posts/${publicationId}`,
    profileUrl: (handle: string) => `https://lenster.xyz/u/${handle}`,
  },
}

export class LensApplicationMapper {
  private readonly application: LensApplicationMapping
  private constructor(code: LensApplicationCode) {
    this.application = LensApplicationMapper.applications[code]
  }

  public static readonly applications = applications

  public static create(code: LensApplicationCode) {
    return new LensApplicationMapper(code)
  }

  public get name() {
    return this.application.name
  }

  public get publicationUrlRegex() {
    return new RegExp(this.application.publicationUrl('([0-9a-zA-z-_]+)'))
  }

  public getPublicationUrl(publicationId: string) {
    return this.application.publicationUrl(publicationId)
  }

  public getPublicationId(publicationUrl: string) {
    const match = this.publicationUrlRegex.exec(publicationUrl)

    if (!match) return ''

    return match[1]
  }

  public getProfileUrl(handle: string) {
    return this.application.profileUrl(handle)
  }
}