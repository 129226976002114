import { WalletProviderType } from '@core-lib/models/wallet'
import { Maybe } from '@core-lib/models/common'
import { ComputedRef, Ref } from 'vue'

export class SignatureCanceller {
  private cancelFn: () => void

  constructor() {
    this.cancelFn = () => {
      console.warn('No cancel fn was provided')
    }
  }

  public cancel() {
    this.cancelFn()
  }

  public onCancel(fn: () => void) {
    this.cancelFn = fn
  }
}

export interface WalletInterface {
  isWalletConnected: ComputedRef<boolean>

  getFormattedWalletAddressOrFail(): string

  connect(walletConnectionType: WalletProviderType): Promise<void>

  connectOrFail(walletConnectionType: WalletProviderType): Promise<string>

  disconnect(): Promise<void>

  connectingWallet: Readonly<Ref<boolean>>

  getWalletAddressOrFail(): Promise<string>

  getWalletAddress(): Maybe<string>

  isValidWalletAddress(address: string): boolean

  getSigner(): Promise<unknown>

  signMessage(message: string, cancelFn: SignatureCanceller, hideModal?: boolean): Promise<string>

  getWalletSignature(validForSeconds: number, cancelFn?: SignatureCanceller): Promise<{ token: string, message: string, signature: string }>
}
