import { WalletProviderType } from '@core-lib/models/wallet'
import { Web3ConnectAdapter } from '@core-lib/webapp-services/web3/wallet-adaptors/web3-connect-adapter'
import { WalletAdapter } from '@core-lib/webapp-services/web3/wallet-adaptors/wallet-adapter'
import { PhantomAdapter } from '@core-lib/webapp-services/web3/wallet-adaptors/phantom-adapter'

export class WalletAdapterFactory {
  static create(walletConnectionType: WalletProviderType): WalletAdapter {
    if (walletConnectionType === WalletProviderType.PHANTOM) return new PhantomAdapter()
    return new Web3ConnectAdapter(walletConnectionType)
  }
}
