<template>
  <div
    :class="[{ 'error': hasError, 'disabled': disabled, 'disabled-style': fakeDisabled, 'readonly': readonly, 'search': isSearch, 'transparent': transparent, 'relative': errorAbsolutePositioned }, wrapperClass]"
    class="ds-input"
    @click="emit('clickField')"
  >
    <label
      v-if="label"
      class="label text-sm font-medium mb-1 flex text-ds-dark-primary"
      :class="labelClass"
    >{{ label }}
      <span
        v-if="isRequired && !hideAsterisk"
        class="ml-1 mt-[3px]"
      >
        <DsIcon
          color-class="text-red-500 w-2 h-2 text-[8px]"
          icon="c_Asterisk"
        />
      </span>
      <DsLabelInfo
        v-if="labelInfo?.length > 0"
        :text="labelInfo || ''"
      />
    </label>
    <div
      ref="inputContainerRef"
      :style="{ width: width }"
      :class="[widthClass, {'w-full sm:w-78': !width && !widthClass}]"
      class="flex items-center"
    >
      <div
        @click="inputContainerClick"
        :class="[fieldClass, {
          'focused': forcedFocus || isFocused,
          'filled': modelValue && !isFocused && !forcedFocus,
          'rounded-2xl': !isSearch,
          'rounded-[100px] px-3.5 relative': isSearch,
        }]"
        class="field border border-transparent flex items-center py-2.5 px-3 lg:p-3 rounded-2xl bg-gray-100 grow max-w-full"
      >
        <span
          v-if="leadingIcon || isSearch"
          role="img"
          aria-label="leading-icon"
          class="mr-1"
          :class="leadingIconSize"
        >
          <DsIcon
            :color-class="leadingIconColor"
            :icon="leadingIcon || 'search'"
            :size="leadingIconSize"
          />
        </span>
        <template v-else-if="$slots.leadingIcon">
          <slot name="leadingIcon" />
        </template>
        <input
          v-bind="$attrs"
          v-model="innerValue"
          class="input"
          :class="[{'search-input': isSearch, 'search-input-focus': isSearch && isFocused}]"
          :disabled="disabled"
          :placeholder="placeholder"
          :type="isTypePassword && isValueShown ? 'text' : type"
          :readonly="readonly"
          :required="isRequired"
          :maxlength="maxLength"
          ref="inputRef"
          @focus="$emit('focus')"
          @keydown="handleKeyDown"
          @keyup.enter="$emit('enter')"
          @wheel="preventScroll"
          @input="updateValue"
        >

        <button
          @click="innerValue = ''"
          class="font-medium text-sm lg:mr-2 absolute right-3 md:right-9"
          :class="transparent ? 'text-gray-100 hover:text-gray-300' : 'text-gray-400 hover:text-ds-dark-primary'"
          v-if="isSearch && (isFocused || forcedFocus) && !disabled"
        >
          Clear
        </button>

        <span
          @click.stop="trailingIconClick"
          v-if="trailingIcon || withShowHide || (isSearch && (isFocused || forcedFocus) && !disabled)"
          role="img"
          :class="[{ 'cursor-pointer': !disabled && trailingIconClickable || withShowHide && isTypePassword || isSearch, 'hidden lg:block': isSearch }, trailingIconClass]"
          class="trailing-icon w-5 h-5 ml-1"
          aria-label="trailing-icon"
        >
          <DsIcon
            v-if="withShowHide && isValueShown"
            icon="crossed-eye"
          />
          <DsIcon
            v-else-if="withShowHide && !isValueShown"
            icon="eye"
          />
          <DsIcon
            v-else-if="trailingIcon || isSearch"
            v-tooltip="trailingIconTooltip"
            :class="[trailingIconSizeClass]"
            :type="trailingIconType"
            :color-class="trailingIconColor"
            :icon="trailingIcon || 'cross-small'"
          />
        </span>
        <span
          v-else-if="$slots.trailingIcon"
          class="trailing-icon ml-1"
          :class="trailingIconClass"
        >
          <slot name="trailingIcon" />
        </span>
      </div>
      <span
        @click.stop="trailingIconClick"
        v-if="(isSearch && !disabled)"
        role="img"
        class="w-5 h-5 ml-5 lg:hidden"
        aria-label="cross-icon"
      >
        <DsIcon
          :color-class="trailingIconColorClass || 'text-ds-dark-primary'"
          icon="cross"
          type="bold"
          size="large"
        />
      </span>
      <div
        class="tooltip flex items-center ml-3 cursor-pointer"
        :class="tooltip?.class"
        v-if="tooltip?.text"
      >
        <DsIcon
          v-tooltip="{ content: tooltip.text, popperClass: tooltip.popperClass, placement: tooltip.placement || 'top'}"
          size="small"
          :icon="tooltip.icon || 'interrogation'"
          :color-class="disabled || fakeDisabled ? 'text-gray-400': ''"
        />
      </div>
    </div>
    <span
      v-if="helperText"
      class="helper-text text-xs font-normal text-ds-dark-primary mt-1 block text-start"
      :class="[!hasError ? helperTextClass : 'input-error-message', {'absolute': errorAbsolutePositioned}]"
    >{{ helperText }}</span>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue'
import DsIcon from './DsIcon.vue'
import useDetectOutsideClick from '@core-lib/composables/detect-outside-click'
import { IconType, SimpleIconType } from '@core-design/types/icon'
import DsLabelInfo from '@core-design/components/Design/DsLabelInfo.vue'

const props = withDefaults(
  defineProps<{
    modelValue?: string | number | undefined
    width?: string
    widthClass?: string
    fieldClass?: string
    type?: 'text' | 'email' | 'password' | 'search' | 'url' | 'tel' | 'number'
    label?: string
    labelInfo?: string
    labelClass?: string
    placeholder?: string
    leadingIcon?: IconType
    leadingIconColorClass?: string
    leadingIconSize?: string
    trailingIcon?: string
    trailingIconClickable?: boolean
    tooltip?: {
      text: string
      icon?: IconType
      class?: string,
      popperClass?: string
      placement?: string
    };
    helperText?: string
    helperTextClass?: string
    hasError?: boolean
    disabled?: boolean
    fakeDisabled?: boolean
    withShowHide?: boolean
    readonly?: boolean
    trailingIconTooltip?: string | object
    isSearch?: boolean
    forcedFocus?: boolean
    isRequired?: boolean
    hideAsterisk?: boolean
    wrapperClass?: string
    maxLength?: number
    trailingIconClass?: string | object
    trailingIconColorClass?: string
    trailingIconType?: SimpleIconType
    trailingIconSizeClass?: string
    transparent?: boolean
    onlyPositiveNumbers?: boolean
    onlyIntegers?: boolean
    errorAbsolutePositioned?: boolean
    allowedCharacters?: string[]
    focus?: number
    urlPrefix?: string
  }>(), {
    modelValue: '',
    type: 'text',
    hasError: false,
    disabled: false,
    fakeDisabled: false,
    withShowHide: false,
    label: '',
    labelInfo: '',
    labelClass: '',
    placeholder: '',
    leadingIcon: '',
    leadingIconSize: 'w-5 h-5 text-xl min-w-[20px]',
    leadingIconColorClass: '',
    trailingIcon: '',
    tooltip: undefined,
    helperText: '',
    helperTextClass: '',
    readonly: false,
    trailingIconTooltip: undefined,
    width: '',
    widthClass: '',
    fieldClass: '',
    isSearch: false,
    forcedFocus: false,
    isRequired: false,
    hideAsterisk: true,
    wrapperClass: '',
    maxLength: 250,
    trailingIconClass: '',
    trailingIconColorClass: '',
    trailingIconType: 'regular',
    trailingIconSizeClass: '',
    transparent: false,
    onlyPositiveNumbers: false,
    onlyIntegers: false,
    errorAbsolutePositioned: false,
    allowedCharacters: undefined,
    focus: 0,
    urlPrefix: '',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
  (e: 'trailingIconClick'): void
  (e: 'clickField'): void
  (e: 'focus'): void
  (e: 'enter'): void
}>()

const innerValue = ref(props.modelValue)
watch(innerValue, innerValue => {
  emit('update:modelValue', innerValue)
})
watch(() => props.modelValue, value => {
  innerValue.value = value
})
const updateValue = () => {
  if (props.maxLength && (typeof innerValue.value === 'string' || typeof innerValue.value === 'number') && innerValue.value.toString().length > props.maxLength) {
    const sliced = innerValue.value.toString().slice(0, props.maxLength)
    innerValue.value = props.type === 'number' ? Number(sliced) : sliced
    return
  }
  if (props.type === 'url') {
    const regexObj = new RegExp('^(https?):\\/\\/' + props.urlPrefix)
    const url = 'https://' + props.urlPrefix
    if (innerValue.value.toString() && !regexObj.test(innerValue.value.toString()) && innerValue.value.toString().indexOf(url) === -1) {
      innerValue.value = url + innerValue.value
    }
  }
}

const isFocused = ref(false)

const isTypePassword = computed(() => props.type === 'password')
const isValueShown = ref(false)

const inputRef = ref()

watchEffect(() => {
  if (props.disabled) {
    isFocused.value = false
  }
})

const inputContainerRef = ref()
useDetectOutsideClick(inputContainerRef, () => {
  isFocused.value = false
})
const trailingIconClick = () => {
  if (props.withShowHide) {
    isValueShown.value = !isValueShown.value
  }
  if (props.disabled) {
    return false
  }
  if (props.isSearch) {
    innerValue.value = ''
    inputRef.value.blur()
    isFocused.value = false
  }
  emit('trailingIconClick')
}

const leadingIconColor = computed(() => {
  if (props.isSearch) {
    if (props.transparent) {
      if (props.forcedFocus || isFocused.value || props.modelValue) {
        return 'text-white'
      }
      if (props.disabled || props.fakeDisabled) {
        return 'text-gray-100/[.5]'
      }
      return 'text-gray-100'
    }
    if (props.disabled || props.fakeDisabled) {
      return 'text-gray-300'
    }
    return 'text-gray-400'
  }
  return props.leadingIconColorClass || ''
})

const trailingIconColor = computed(() => {
  if (props.transparent) {
    return 'text-gray-100 hover:text-gray-300'
  }
  return props.disabled || props.fakeDisabled || props.isSearch ? 'hover:text-ds-dark-primary text-gray-400' : props.trailingIconColorClass
})

const inputContainerClick = () => {
  inputRef.value.focus()
  isFocused.value = true
}

watch(() => props.focus, () => {
  inputContainerClick()
})

const whiteListedChars = ['Delete', 'Backspace', 'Enter', 'ArrowLeft', 'ArrowRight']

const handleKeyDown = (event: KeyboardEvent) => {
  if (props.allowedCharacters && !props.allowedCharacters.includes(event.key) && !whiteListedChars.includes(event.key) && !((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'a'))) {
    event.preventDefault()
    return
  }
  if (props.type === 'number') {
    if ((props.onlyPositiveNumbers && event.key === '-') || event.key === 'e' || props.onlyIntegers && (event.key === '.' || event.key === ',')) {
      event.preventDefault()
    }
  }
}

const preventScroll = () => {
  if (props.type === 'number') {
    inputRef.value.blur()
  }
}
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .input {
    min-width: 0;
  }
}

@media only screen and (min-width: 361px) and (max-width: 400px) {
  .input {
    min-width: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}

.ds-input {
  .field {
    &:hover {
      @apply border-gray-300;
    }

    &.focused {
      @apply border-gray-400 bg-transparent;

      &.filled {
        @apply border-gray-400;
      }
    }

    &.filled {
      @apply bg-gray-100;
    }
  }

  .input {
    @apply px-1 bg-inherit grow text-[16px] sm:text-sm font-medium text-ds-dark-primary outline-none border-none py-0 focus:ring-0;
    &::placeholder {
      @apply text-gray-400 text-sm font-medium;
    }

    &.search-input, &.search-input:placeholder-shown {
      @apply truncate;

      &.search-input-focus {
        @apply pr-[54px];
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .label {
      @apply text-gray-400 cursor-not-allowed;
    }

    .field {
      &:hover,
      &.focused {
        @apply border-transparent cursor-not-allowed;
      }
    }

    .input {
      @apply cursor-not-allowed text-gray-400 truncate;
    }

    .helper-text {
      @apply cursor-not-allowed text-gray-400;
    }

    &.search {
      .input, .input::placeholder {
        @apply text-gray-300;
      }
    }
  }

  &.disabled-style {

    .label {
      @apply text-gray-400;
    }

    .field {
      &:hover,
      &.focused {
        @apply border-transparent;
      }
    }

    .input {
      @apply text-gray-400;
    }

    .helper-text {
      @apply text-gray-400;
    }

    &.search {
      .input, .input::placeholder {
        @apply text-gray-300;
      }
    }
  }

  &.readonly {
    .field {
      @apply pointer-events-none overflow-x-hidden truncate;
      &:hover,
      &.focused {
        @apply border-transparent bg-gray-100;
      }
    }

    .input {
      @apply truncate;
    }

    .trailing-icon {
      @apply pointer-events-auto;
    }
  }

  &.error {
    .field {
      @apply border-red-500;
      @apply bg-transparent #{!important};
    }

    .helper-text {
      @apply text-red-500;
    }
  }

  &.transparent {
    .field {
      @apply border border-transparent bg-gray-100/[.35];
      &:hover {
        @apply bg-gray-100/[.45];
      }

      &.focused {
        @apply bg-gray-100/[.45];

        &.filled {
          @apply bg-gray-100/[.45];
        }

        .input {
          &::placeholder {
            @apply text-white;
          }
        }
      }

      &.filled {
        @apply bg-gray-100/[.35];
      }
    }

    .input {
      @apply text-white bg-transparent;
      &::placeholder {
        @apply text-gray-100;
      }
    }

    &.disabled, &.disabled-style  {
      .input {
        @apply text-gray-100/[.5];
        &::placeholder {
          @apply text-gray-100/[.5];
        }
      }
    }

    &.error {
      .field {
        @apply border-red-500;
      }
    }
  }
}
</style>
