<template>
  <DsBottomSheet
    :show="show"
    close-button
    :back-button="activeItem === 1"
    content-class="!p-3 overflow-y-auto"
    wrapper-class="font-inter"
    @hide="$emit('hide'); activeItem = null"
    @back="activeItem = null"
  >
    <template #title>
      <span class="flex items-center gap-x-3">
        <DsAvatarLetter
          v-if="activeItem !== 1"
          :identifier="organizationId"
          :name="organizationName"
          :media-url="organizationImage"
          image-class="w-6 h-6 min-w-[24px] rounded-full object-cover border border-gray-200"
          :avatar-type="organizationAvatarType"
        />
        <span v-if="activeItem === 1">{{ items[activeItem - 1].name + ' this Tropee' }}</span>
        <span
          v-else
          class="flex items-center overflow-hidden"
        >
          <span class="truncate">
            {{ organizationName }}
          </span>
          <OrganizationCertifiedBadge
            v-if="organizationCertified"
            class="ml-1"
            size="small"
          />
        </span>
      </span>
    </template>
    <DsMenuWithContent
      nav-class="!gap-1"
      :items="items"
      :force-active-item="activeItem"
      @item-change="menuItemClickCallback"
    >
      <template #item1>
        <LoyaltyNavbarShare />
      </template>
    </DsMenuWithContent>
    <template
      v-if="activeItem === null"
      #footer
    >
      <ClLoyaltyNavbarMenuFooter
        @signup="goToHome"
        @find-out-more="goToLandingPageNewTab( `utm_source=tropee&utm_medium=page&utm_campaign=share_menu_cta&utm_content=${organizationSlug}`)"
      />
    </template>
  </DsBottomSheet>
</template>
<script setup lang="ts">
import DsBottomSheet from '@core-design/components/Design/DsBottomSheet.vue'
import DsMenuWithContent from '@core-design/components/Design/DsMenuWithContent.vue'
import { ref } from 'vue'
import { useGoToLandingPage } from '@core-lib/composables/go-to-landing-page'
import { useRouter } from 'vue-router'
import { HolderRouteName } from '@core-lib/routes'
import { capitalize } from '@core-lib/helpers/string'
import translator from '@core-lib/webapp-services/i18n/translator'
import { TypeFormId, useTypeformWindow } from '@/composables/typeform-popup'
import DsAvatarLetter from '@core-design/components/Design/DsAvatarLetter.vue'
import ClLoyaltyNavbarMenuFooter from '@core-design/components/ComponentsLayout/Loyalty/ClLoyaltyNavbarMenuFooter.vue'
import LoyaltyNavbarShare from '@core-design/components/ComponentsLayout/Loyalty/LoyaltyNavbarShare.vue'
import OrganizationCertifiedBadge from '@core-design/components/ComponentsLayout/Loyalty/OrganizationCertifiedBadge.vue'
import { AvatarType } from '@core-lib/openapi/client/api'
import { Nullable } from '@core-lib/models/common'
import { TropeeAnalyticsFactory } from '@core-lib/webapp-services/analytics/analytics'
import { CreatorClickedShareEvent } from '@core-lib/webapp-services/analytics/events/admin'

const props = defineProps<{
  show: boolean
  organizationId: string
  organizationSlug: string
  organizationName: string
  organizationImage: string
  organizationAvatarType?: Nullable<AvatarType>
  organizationCertified: boolean
}>()

defineEmits<{
  (e: 'hide'): void
}>()

const router = useRouter()
const { openFormInNewTab } = useTypeformWindow(TypeFormId.REPORT_ABUSE)

const items = [
  {
    name: 'Share',
    icon: 'Share2',
  },
  {
    name: capitalize(translator.translate('tropee_page__report')),
    icon: 'Flag',
    action: openFormInNewTab,
  },
]

const activeItem = ref<number | null>(null)

function menuItemClickCallback(item: number | null) {
  if (item === null) return
  const action = items[item - 1].action
  if (!action) {
    activeItem.value = item
    trackShare()
  } else {
    action()
  }
}

const { goToLandingPageNewTab } = useGoToLandingPage()

function trackShare() {
  const shareLocation = router.currentRoute.value.fullPath.startsWith('/r/') ? 'reward_page' : 'tropee_page'
  TropeeAnalyticsFactory.instance().track(new CreatorClickedShareEvent('tropee', shareLocation))
}

function goToHome() {
  const url = router.resolve({ name: HolderRouteName.HOMEPAGE }).href
  const origin = window.location.origin

  window.open(`${origin}${url}?utm_source=tropee&utm_medium=page&utm_campaign=profile_menu_cta&utm_content=${props.organizationSlug}`, '_blank')
}

</script>

<style scoped>

</style>
