import { onMounted, onUnmounted, ref } from 'vue'

const useScreenSize = () => {
  const width = ref<number>(window.innerWidth)
  const height = ref<number>(window.innerHeight)

  const updateSizes = () => {
    width.value = window.innerWidth
    height.value = window.innerHeight
  }

  onMounted(() => {
    window.addEventListener('resize', updateSizes)
  })
  onUnmounted(() => {
    window.removeEventListener('resize', updateSizes)
  })

  return {
    width,
    height,
  }
}

export { useScreenSize }